import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import { DocumentContent } from '../components/PageContents';
import { getDocumentContent } from '../redux/documentContent/documentContentActions';
import { getDocument } from '../redux/documents/documentsActions';
import { getModel } from '../redux/models/modelsActions';
import { getProjects } from '../redux/projects/projectsActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector, useQuery } from '../utils/hooks';

const Document: React.FC = () => {
  useTrackMetric(reactPlugin, 'DocumentPage');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, documentId, modelId } = useParams<IRoutePathParams>();
  const { documents, workspaces, projects, documentContent, models, error } = useAppSelector((state) => state);
  const [hasCalledDocumentContent, setHasCalledDocumentContent] = useState(false);

  // If page query parameter is unset, fetch first page of results
  const query = useQuery();
  let currentPage = Number(query.get('page'));
  if (!currentPage) {
    currentPage = 1;
  }

  // Fetches workspace and project
  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasProjects = !isEmpty(projects.ids);

    if (!hasWorkspaces && !hasProjects) {
      batch(() => {
        dispatch(getWorkspaces());
        dispatch(getProjects(workspaceId));
      });
    } else if (!hasWorkspaces) {
      dispatch(getWorkspaces());
    } else if (!hasProjects) {
      dispatch(getProjects(workspaceId));
    }
  }, [dispatch, workspaces.ids, projects.ids, workspaceId]);

  // Model data is required to render the breadcrumb correctly on
  // page reload for a document from the model details page
  useEffect(() => {
    if (modelId) {
      const hasModels = !isEmpty(models.ids);
      if (!hasModels || isEmpty(models.data[modelId])) {
        dispatch(getModel(modelId));
      }
    }
  }, [dispatch, modelId, models.ids, models.data]);

  // Fetches document information
  useEffect(() => {
    const isMissingDocumentData =
      isEmpty(documents.data) || isEmpty(documents.data[documentId]) || isEmpty(documents.data[documentId].files);
    if (
      !documents.isLoading &&
      isMissingDocumentData &&
      isEmpty(error.message) &&
      error.category !== ErrorCategories.Document
    ) {
      dispatch(getDocument(documentId));
    }
  }, [dispatch, error, documentId, documents.data, documents.isLoading]);

  // Fetches file contents
  useEffect(() => {
    const isMissingDocumentData =
      isEmpty(documents.data) || isEmpty(documents.data[documentId]) || isEmpty(documents.data[documentId].files);

    if (!isMissingDocumentData) {
      const hasPageChanged =
        documentContent[documentId] &&
        !documentContent[documentId].isLoading &&
        currentPage &&
        currentPage !== documentContent[documentId].pageIndex;

      if (!hasCalledDocumentContent || hasPageChanged) {
        setHasCalledDocumentContent(true);
        const fileIds = documents.data[documentId].files;

        // TODO: Update to support monolingual documents.
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/120410
        const [sourceFileId, targetFileId] = fileIds;

        dispatch(
          getDocumentContent({
            documentId,
            sourceFileId,
            targetFileId,
            pageIndex: currentPage,
            resetPagination: !hasCalledDocumentContent,
          })
        );
      }
    }
  }, [dispatch, documentId, hasCalledDocumentContent, documents.data, currentPage, documentContent]);

  let content;
  if (!isEmpty(error.message) && error.category === ErrorCategories.Document) {
    content = <Error />;
  } else {
    content = <DocumentContent />;
  }
  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('components.appSidebar.manageDocuments')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default Document;
