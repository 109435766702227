import { Stack, FontIcon } from "@fluentui/react";

import { Indicator } from "./Indicator";

export interface ICarouselProps {
    currentStep: JSX.Element;
    totalSteps: number;
    onChangeStep: (step: number) => void;

    className?: string;
    carouselIndex?: number;

    iconSettings?: {
        nextIconName: string;
        prevIconName: string;
    }
}

export const Carousel = (props: ICarouselProps) => {

    const currentIndex = props.carouselIndex || 0;

    const nextStep = () => props.onChangeStep(currentIndex + 1 < props.totalSteps ? currentIndex + 1 : 0);
    const prevStep = () => props.onChangeStep(currentIndex - 1 < 0 ? props.totalSteps - 1 : currentIndex - 1);

    return <Stack className={props.className} >
        {props.currentStep}
        <FontIcon iconName={props.iconSettings ? props.iconSettings.nextIconName : "ChevronRight"} className="carousel-button next" onClick={nextStep} />
        <FontIcon iconName={props.iconSettings ? props.iconSettings.prevIconName : "ChevronLeft"} className="carousel-button prev" onClick={prevStep} />
        <Indicator
            className="dots-container"
            total={props.totalSteps}
            currentIndex={currentIndex}
            setIndex={props.onChangeStep}
        />
    </Stack>
};