/*
 * This application uses DayJS for date formatting. Unfortunately DayJS does not have a very clean
 * way of getting the time differences between timestamps.
 */

interface ITimeDifference {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

/** Returns difference object in days, hours, minutes and seconds between two dates */
const timeDifference = (fromDate: string, toDate: string): ITimeDifference => {
  const fromTime = new Date(fromDate).getTime();
  const toTime = new Date(toDate).getTime();

  let difference = toTime - fromTime;

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondsDifference = Math.floor(difference / 1000);

  return {
    days: daysDifference,
    hours: hoursDifference,
    minutes: minutesDifference,
    seconds: secondsDifference,
  };
};

export default timeDifference;
