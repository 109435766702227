import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error, Loading } from '../components/GenericStates';
import initialLoadContext from '../components/InitialLoad/InitialLoad.context';
import { WorkspaceContent } from '../components/PageContents';
import { getDocuments } from '../redux/documents/documentsActions';
import { getModelsVersion } from '../redux/modelsVersion/modelsVersionActions';
import { getProjects } from '../redux/projects/projectsActions';
import getUploadHistory from '../redux/uploadHistory/uploadHistoryActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import WorkspacePivot from '../utils/constants/pivots';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import getCurrentPivot from '../utils/getCurrentPivot';
import { useAppDispatch, useAppSelector, useQuery } from '../utils/hooks';

const WorkspacePage: React.FC = () => {
  useTrackMetric(reactPlugin, 'WorkspacePage');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { workspaceId } = useParams<IRoutePathParams>();
  const { workspaces, documents, error, uploadHistory } = useAppSelector((state) => state);
  const [hasCalledDocuments, setHasCalledDocuments] = useState(false);
  const pivot = getCurrentPivot(location);
  const query = useQuery();
  const currentPage = Number(query.get('page')) || 1;
  const { setInitialLoad } = React.useContext(initialLoadContext);
  const noError = isEmpty(error.message);

  // Fetch workspaces on load
  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);

    if (!hasWorkspaces && !workspaces.isLoading && noError) {
      dispatch(getWorkspaces());
    }
  }, [dispatch, workspaces.ids, workspaces.isLoading, noError, workspaceId]);

  // Fetch projects on load
  useEffect(() => {
    batch(() => {
      dispatch(getProjects(workspaceId));
      dispatch(getModelsVersion({ workspaceId }));
    });
  }, [dispatch, workspaceId]);

  // Fetch uploadHistory jobs on load and when page changes and the pivot is on the uploadHistory view
  useEffect(() => {
    const hasUploadHistory = Array.isArray(uploadHistory.jobs);
    const hasPageChanged = currentPage !== uploadHistory.pageIndex;
    const isNewWorkspace = uploadHistory.workspaceId !== workspaceId;
    const shouldGetUploadHistory =
      (!uploadHistory.isLoading && !uploadHistory.isFiltering && (hasPageChanged || !hasUploadHistory)) ||
      (!uploadHistory.isLoading && isNewWorkspace);

    if (pivot === WorkspacePivot.UploadHistory && shouldGetUploadHistory && !uploadHistory.failed) {
      batch(() => {
        dispatch(getUploadHistory({ workspaceId, currentPage }));
      });
    }
  }, [dispatch, uploadHistory, workspaceId, currentPage, pivot]);

  // Fetch documents on load and when the page changes and the pivot is on documents view
  useEffect(() => {
    const hasPageChanged = !documents.isLoading && currentPage !== documents.pageIndex;
    const filter = `isAvailable eq 'True' and isParallel eq true`;
    if ((!hasCalledDocuments || hasPageChanged || documents.hasNewDocuments) && pivot === WorkspacePivot.Documents) {
      setHasCalledDocuments(true);
      if (isEmpty(error.message)) {
        batch(() => {
          dispatch(
            getDocuments(
              { workspaceId, filter, page: currentPage, resetPagination: !hasPageChanged },
              documents.hasNewDocuments
            )
          );
          dispatch({ type: 'SET_DOCUMENTS_FILTERED', payload: false });
        });
      }
    }
  }, [
    dispatch,
    workspaceId,
    currentPage,
    documents.isLoading,
    documents.pageIndex,
    documents.hasNewDocuments,
    hasCalledDocuments,
    pivot,
    error.message,
  ]);

  // Change initialLoad value to false after first load
  useEffect(() => {
    setInitialLoad(false);
  });

  // Reset value on pivot change
  useEffect(() => {
    if (pivot === WorkspacePivot.Projects && documents.areDocumentsFiltered) {
      setHasCalledDocuments(false);
    }
  }, [pivot, documents.areDocumentsFiltered]);

  let content = null;

  if (workspaces.isLoading) {
    content = <Loading loadingText={t('pages.workspace.loadingWorkspace')} isFullHeight />;
  } else if (
    !noError &&
    (error.category === ErrorCategories.Workspaces || error.category === ErrorCategories.Projects)
  ) {
    content = <Error />;
  } else if (!isEmpty(workspaces.currentWorkspace)) {
    content = <WorkspaceContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {/* 
            Using ternary operator here instead of short circuit logic because 'false' will be returned in the title if no name.
            Empty string is used because 'undefined' will be returned in the title otherwise. 
          */}
          {`${process.env.REACT_APP_APPLICATION_NAME} ${
            !isEmpty(workspaces.currentWorkspace) ? `| ${workspaces.currentWorkspace.name}` : ''
          }`}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default WorkspacePage;
