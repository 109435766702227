import { FontWeights, makeStyles } from '@fluentui/react';

const useStyles = makeStyles(() => ({
  main: {
    marginTop: '1rem',
  },
  section: {
    margin: 0,
    padding: 0,
  },
  heading: {
    display: 'block',
    fontWeight: FontWeights.semibold,
    marginBottom: '0.5rem',
  },
  history: {
    display: 'block',
    marginBottom: '1rem',
  },
}));

export default useStyles;
