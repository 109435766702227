import { Checkbox, ICheckboxStyles, Text } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { updateModelPublishStatus } from '../../../redux/models/modelsActions';
import FormActions from '../Shared/FormActions/FormActions';
import useUpdatePublishFormStyles from './UpdatePublishForm.styles';
import { IUpdatePublishFormProps, IUpdatePublishFormValues } from './UpdatePublishForm.types';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const UpdatePublishForm: React.FC<IUpdatePublishFormProps> = ({ handleCancelAndClose, type }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useUpdatePublishFormStyles();
  const {
    models,
    modelRegions,
    entitySelections: { selectedModel },
  } = useAppSelector((state) => state);
  const { modelId, projectId } = useParams<IRoutePathParams>();
  let model = models.data[modelId] || null;
  if (model === null) {
    model = models.data[selectedModel] || null;
  }
  const primaryButtonText =
    model.modelStatus === ModelStatus.Published
      ? t('components.commandBars.model.updateButton.text')
      : t('components.commandBars.model.publishButton.text');

  const allUndeployed = Object.keys(modelRegions.data).map((key) => ({
    region: Number(key),
    isDeployed: false,
    isChanged: false,
  }));

  const originalStatuses =
    model.modelRegionStatuses && model.modelRegionStatuses.length > 0
      ? model.modelRegionStatuses.map((v) => ({ ...v, isChanged: false }))
      : allUndeployed;
  const modelRegionStatuses = [...originalStatuses];

  const [formValues, setFormValues] = useState(
    (): IUpdatePublishFormValues => {
      const initialState = {
        projectId,
        modelId: model.id,
        modelName: model.name,
        nextModelStatus: ModelStatus[model.modelStatus as keyof typeof ModelStatus],
        modelRegionStatuses,
      };
      return initialState;
    }
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    dispatch(updateModelPublishStatus(formValues, type));
    handleCancelAndClose();
  };

  const handleChange = useCallback(
    (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean): void => {
      if (ev?.target && (ev.target as HTMLInputElement).name) {
        const newModelRegionStatuses = formValues.modelRegionStatuses.map((item) => {
          if (Number((ev.target as HTMLInputElement).name) === item.region) {
            const originalStatus = originalStatuses.find((m) => m.region === item.region)?.isDeployed || false;
            return {
              region: item.region,
              isDeployed: Boolean(checked),
              isChanged: originalStatus !== Boolean(checked),
            };
          }
          return item;
        });
        let nextModelStatus = ModelStatus.Publishing;
        if (model.modelStatus === ModelStatus.Published) {
          let isUndeploying = true;
          newModelRegionStatuses.forEach((item) => {
            if (item.isDeployed) {
              isUndeploying = false;
            }
          });
          if (isUndeploying) {
            nextModelStatus = ModelStatus.Unpublishing;
          } else {
            nextModelStatus = ModelStatus.UpdatingPublishment;
          }
        }
        setFormValues({ ...formValues, modelRegionStatuses: newModelRegionStatuses, nextModelStatus });
      }
    },
    [formValues, model.modelStatus, originalStatuses]
  );

  const buttonStatus = (): boolean => {
    let returnStatus = false;
    formValues.modelRegionStatuses.forEach((item) => {
      if (
        model.modelRegionStatuses &&
        model.modelRegionStatuses.filter((region) => region.region === item.region).length > 0 &&
        model.modelRegionStatuses.filter((region) => region.region === item.region)[0].isDeployed !== item.isDeployed
      ) {
        returnStatus = true;
      } else if (model.modelRegionStatuses && model.modelRegionStatuses.length <= 0 && item.isDeployed) {
        returnStatus = true;
      }
    });
    return returnStatus;
  };

  const checkboxes = formValues.modelRegionStatuses.map((item) => {
    const boldStyle: ICheckboxStyles = {
      label: {
        fontWeight: 800,
      },
    };
    const styles = item.isChanged ? boldStyle : undefined;
    return (
      <Checkbox
        key={`region_${item.region}`}
        name={`${item.region}`}
        onChange={handleChange}
        checked={item.isDeployed}
        label={modelRegions.data[item.region].regionName}
        className={classes.checkbox}
        styles={styles}
      />
    );
  });

  return (
    <form onSubmit={handleSubmit}>
      <Text>{`${t('components.forms.publish.subtext')}`}</Text>
      <Text className={classes.subHeadings} as="h4" block>{`${t('components.forms.publish.selectRegions')}`}</Text>
      {checkboxes}
      <FormActions
        primaryButtonText={primaryButtonText}
        isDisabled={!buttonStatus()}
        handleCancelAndClose={handleCancelAndClose}
        onCancelTelemetryMessage="model-update-publish-status/cancel"
      />
    </form>
  );
};

export default UpdatePublishForm;
