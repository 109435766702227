import { merge } from 'lodash';

import {
  ADD_COPY_NOTIFICATION,
  CopyNotificationsDispatchTypes,
  ICopyNotificationItem,
  UPDATE_COPY_NOTIFICATION,
} from './copyNotifications.types';

export interface ICopyNotificationsState {
  data: {
    [key: string]: ICopyNotificationItem;
  };
}

const initialState: ICopyNotificationsState = { data: {} };

const filterNotificationData = (
  id: string,
  state: {
    [key: string]: ICopyNotificationItem;
  }
): { [key: string]: ICopyNotificationItem } => {
  const notificationData = Object.entries(state);

  const filterNotificationDataArray = notificationData.filter(([key]) => {
    return key !== id;
  });

  return Object.fromEntries(filterNotificationDataArray);
};

const CopyNotificationsReducer = (
  state: ICopyNotificationsState = initialState,
  action: CopyNotificationsDispatchTypes
): ICopyNotificationsState => {
  switch (action.type) {
    case ADD_COPY_NOTIFICATION: {
      const filteredData = filterNotificationData(action.payload.id, state.data);
      const newNotification = {
        [action.payload.id]: action.payload,
      };
      return {
        ...state,
        data: merge(filteredData, newNotification),
      };
    }
    case UPDATE_COPY_NOTIFICATION: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            data: {
              ...state.data[action.payload.id].data,
              modelStatus: action.payload.data.modelStatus,
            },
          },
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default CopyNotificationsReducer;
