/*
  Since this is a wrapper component, we need to spread props
  to the inner DetailsList.
*/

/* eslint-disable react/jsx-props-no-spreading */
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import {
  ColumnActionsMode,
  DetailsList,
  IColumn,
  IDetailsList,
  IDetailsListProps,
} from '@fluentui/react/lib/DetailsList';
import Dialog, { DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { IShimmeredDetailsListProps, ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { ITextField, TextField } from '@fluentui/react/lib/TextField';
import React from 'react';

/*
  <DetailsListKeyboardAccessible />

  Feature(s):
  - Adds a selectable column header that activates a dialog prompting
    a user to enter in pixels a new column width.

  Development drawbacks:
  - If a column uses `onColumnClick` or `columnActionsMode` then
    the resize column feature will not be added. This component
    will use the one provided in props rather
    than replace it.
*/
const DetailsListKeyboardAccessible: React.FC<IDetailsListProps & IShimmeredDetailsListProps> = (props) => {
  const detailsListRef = React.useRef<IDetailsList>(null);
  const columnToEdit = React.useRef<IColumn | null>(null);
  const textfieldRef = React.useRef<ITextField>(null);
  const input = React.useRef<number | undefined>(undefined);
  const [isDialogHidden, setIsDialogHidden] = React.useState(true);
  const { columns, enableShimmer } = props;

  const hideDialog = () => setIsDialogHidden(true);
  const showDialog = () => setIsDialogHidden(false);

  const handleColumnHeaderSelect = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    columnToEdit.current = column;

    showDialog();
  };

  const confirmDialog = () => {
    const detailsList = detailsListRef.current;

    if (textfieldRef.current) {
      input.current = Number(textfieldRef.current.value);
    }

    if (columnToEdit.current && detailsList) {
      const width = input.current;
      detailsList.updateColumn(columnToEdit.current, { width });
    }

    input.current = undefined;

    hideDialog();
  };

  const keyboardResizableColumns = columns?.map((column) => {
    return {
      ...column,
      columnActionsMode: column.columnActionsMode || ColumnActionsMode.hasDropdown,
      onColumnClick: column.onColumnClick || handleColumnHeaderSelect,
    };
  });

  const resizeDialogContentProps = {
    type: DialogType.normal,
    title: `Resize Column`,
    closeButtonAriaLabel: 'Close',
  };

  return (
    <React.Fragment>
      <Dialog hidden={isDialogHidden} onDismiss={hideDialog} dialogContentProps={resizeDialogContentProps}>
        <TextField label="Enter column width in pixels" componentRef={textfieldRef} />
        <DialogFooter>
          <PrimaryButton onClick={confirmDialog} text="Resize" />
          <DefaultButton onClick={hideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
      {typeof enableShimmer === 'boolean' ? (
        <ShimmeredDetailsList {...props} columns={keyboardResizableColumns} componentRef={detailsListRef} />
      ) : (
        <DetailsList {...props} columns={keyboardResizableColumns} componentRef={detailsListRef} />
      )}
    </React.Fragment>
  );
};

export default DetailsListKeyboardAccessible;
