import { useIsAuthenticated } from '@azure/msal-react';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import AuthRoute from '../components/AuthRoute';
import { GridPanelTypes, gridPanelContext } from '../components/GridPanel/GridPanel.context';
import initialLoadContext from '../components/InitialLoad/InitialLoad.context';
import Document from './Document';
import ManageDocuments from './ManageDocuments';
import Model from './Model';
import Models from './Models';
import NotFound from './NotFound';
import PublishModel from './PublishModel';
import SignedOut from './SignedOut';
import TestModel from './TestModel';
import TestModelResults from './TestModelResults';
import TrainModel from './TrainModel';
import UploadHistory from './UploadHistory';
import Workspace from './Workspace';
import WorkspaceSettings from './WorkspaceSettings';
import Workspaces from './Workspaces';

import { useAppDispatch } from '../utils/hooks/appRedux.helpers';
import isUrlPaginationChange from '../utils/isUrlPaginationChange';

const Routes: React.FC = () => {
  // Because hooks shouldn't be called conditionally, for testing purposes this value will be overridden in the AuthRoute component when the environment is set to 'test'
  const isAuthed = useIsAuthenticated();
  const appDispatch = useAppDispatch();
  const location = useLocation();
  const { setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const { setInitialLoad } = useContext(initialLoadContext);

  useEffect(() => {
    // Resets all entity selections on any route change.
    // Clearing all entities instead of individually to prevent maintenance/scaling problems.
    appDispatch({ type: 'CLEAR_ALL_ENTITY_SELECTIONS' });
    appDispatch({ type: 'CLEAR_ERROR' });
    appDispatch({ type: 'SET_DOCUMENTS_FILTERED', payload: false });

    setIsGridPanelOpen(false);
    setGridPanelType(GridPanelTypes.Empty);
    // The selected documents slice stores data about which documents are selected over multiple pages.
    // That state should be cleared if the route (excluding pagination changes) is modified.
    if (!isUrlPaginationChange(location.search)) {
      appDispatch({ type: 'CLEAR_PAGINATED_DOCUMENTS_SELECTION' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, location]);

  useEffect(() => {
    // Initial load is used to route to the default workspace on first load, this doesn't apply if
    // if the user refresh in any view different than home, so initial load is set to false
    if (!['/', '/workspaces'].includes(location.pathname)) {
      setInitialLoad(false);
    }
  }, [location.pathname, setInitialLoad]);

  return (
    <Switch>
      <Route path="/" exact component={SignedOut} />
      <AuthRoute path="/workspaces" exact isAuthed={isAuthed} component={Workspaces} />
      {/* Using Redirect from and to for param forwarding of the :workspaceId */}
      <Redirect exact from="/workspaces/:workspaceId" to="/workspaces/:workspaceId/projects" />
      <AuthRoute
        path={[
          '/workspaces/:workspaceId/projects',
          '/workspaces/:workspaceId/documents',
          '/workspaces/:workspaceId/upload-history',
        ]}
        exact
        isAuthed={isAuthed}
        component={Workspace}
      />
      <AuthRoute path="/workspaces/:workspaceId/documents/:documentId" exact isAuthed={isAuthed} component={Document} />
      <AuthRoute
        path="/workspaces/:workspaceId/upload-history/:uploadHistoryId"
        exact
        isAuthed={isAuthed}
        component={UploadHistory}
      />
      <AuthRoute
        path={[
          '/workspaces/:workspaceId/workspace-settings/',
          '/workspaces/:workspaceId/workspace-settings/retrain-models/',
        ]}
        exact
        isAuthed={isAuthed}
        component={WorkspaceSettings}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/train-model"
        exact
        isAuthed={isAuthed}
        component={TrainModel}
        hasStickyFooter
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/train-model/document/:documentId"
        exact
        isAuthed={isAuthed}
        component={Document}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/models"
        exact
        isAuthed={isAuthed}
        component={Models}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/models/:modelId"
        exact
        isAuthed={isAuthed}
        component={Model}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/models/:modelId/document/:documentId"
        exact
        isAuthed={isAuthed}
        component={Document}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/test"
        exact
        isAuthed={isAuthed}
        component={TestModel}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/test/:modelId"
        exact
        isAuthed={isAuthed}
        component={TestModelResults}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/publish"
        exact
        isAuthed={isAuthed}
        component={PublishModel}
      />
      {/* Using Redirect from and to for param forwarding of :workspaceId and :projectId */}
      <Redirect
        exact
        from="/workspaces/:workspaceId/projects/:projectId"
        to="/workspaces/:workspaceId/projects/:projectId/manage-documents"
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/manage-documents"
        exact
        isAuthed={isAuthed}
        component={ManageDocuments}
      />
      <AuthRoute
        path="/workspaces/:workspaceId/projects/:projectId/manage-documents/document/:documentId"
        exact
        isAuthed={isAuthed}
        component={Document}
      />
      <AuthRoute path="*" isAuthed={isAuthed} component={NotFound} hasSidebar={false} />
    </Switch>
  );
};

export default Routes;
