/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Announced, DetailsListLayoutMode, IColumn, Overlay, Selection, SelectionMode } from '@fluentui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import { NameGroupCell, StatusCell, TextCell } from '../SharedTableCells';

import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const ProjectsTable: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const {
    categories,
    languages,
    projects: { ids, data, isUpdating },
    users,
  } = useAppSelector((state) => state);

  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItem = selectionState.getSelection()[0] || '';

        dispatch({ type: 'SET_SELECTED_PROJECT', payload: selectedItem });
      },
    })
  );

  useEffect(() => {
    if (isUpdating) {
      selectionState.selectToKey('', true);
    }
  }, [selectionState, isUpdating]);

  const projectsTableColumns: IColumn[] = [
    {
      key: 'projectName',
      name: t('components.tables.sharedColumns.name'),
      minWidth: 150,
      maxWidth: 300,
      onRender: (projectId: string): React.ReactElement => {
        const { name } = data[projectId];
        // TODO: Implement inline grid actions
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118981
        return <NameGroupCell link={{ href: `${match.url}/${projectId}`, name }} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectPublished',
      name: t('components.tables.projects.columns.publishedColumn'),
      minWidth: 120,
      maxWidth: 140,
      onRender: (projectId: string): React.ReactElement | null => {
        const { status, modelRegionStatus } = data[projectId];
        return (
          <StatusCell
            publishStatusRegion={modelRegionStatus}
            status={status}
            showTrainingStatus={false}
            showNonMatchingStatuses={false}
          />
        );
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectSource',
      name: t('components.tables.projects.columns.sourceColumn'),
      minWidth: 60,
      maxWidth: 100,
      onRender: (projectId: string): React.ReactElement => {
        const { sourceLanguage } = data[projectId].languagePair;
        const { displayName } = languages[sourceLanguage];
        return <TextCell text={displayName} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectTarget',
      name: t('components.tables.projects.columns.targetColumn'),
      minWidth: 60,
      maxWidth: 125,
      onRender: (projectId: string): React.ReactElement => {
        const { targetLanguage } = data[projectId].languagePair;
        const { displayName } = languages[targetLanguage];
        return <TextCell text={displayName} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectCategory',
      name: t('components.tables.projects.columns.categoryColumn'),
      minWidth: 60,
      maxWidth: 80,
      onRender: (projectId): React.ReactElement => {
        const { category } = data[projectId];
        const { name } = categories.data[category];
        return <TextCell text={name} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectCategoryApiDomain',
      name: t('components.tables.projects.columns.domainApiColumn'),
      minWidth: 220,
      maxWidth: 280,
      onRender: (projectId): React.ReactElement => {
        const { apiDomain } = data[projectId];
        return <TextCell text={apiDomain} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectUpdated',
      name: t('components.tables.sharedColumns.updated'),
      minWidth: 80,
      maxWidth: 100,
      onRender: (projectId): React.ReactElement => {
        const { modifiedDate } = data[projectId];
        // TODO: Localize date/time https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119689
        return <TextCell text={dayjs(modifiedDate).format('MM/DD/YYYY')} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'projectModifiedBy',
      name: t('components.tables.projects.columns.modifiedByColumn'),
      minWidth: 100,
      maxWidth: 200,
      onRender: (projectId): React.ReactElement | null => {
        const { modifiedBy } = data[projectId];
        if (modifiedBy) {
          const { userName } = users[modifiedBy];
          return <TextCell text={userName} />;
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
  ];

  const itemsCount = ids.length;

  return (
    <div className="table-container" data-test-id="projects-table">
      {isUpdating && <Overlay className="cover-content" />}
      {itemsCount > 0 && (
        <Announced
          id="projects-details-list-accounced"
          message={`${itemsCount} ${
            itemsCount === 1
              ? t('components.tables.projects.announcement.name.singular')
              : t('components.tables.projects.announcement.name.plural')
          }`}
        />
      )}
      <DetailsListKeyboardAccessible
        items={ids}
        columns={projectsTableColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.single}
        selection={selectionState}
        checkButtonAriaLabel={t('components.tables.projects.checkButtonAria')}
      />
    </div>
  );
};

export default ProjectsTable;
