import { FontWeights, NeutralColors, makeStyles } from '@fluentui/react';

const useFileInputButtonStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing.s1,
    fontWeight: FontWeights.semibold,
  },
  buttonOuter: {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
    maxWidth: 215,
    border: `1px solid ${NeutralColors.gray110}`,
    padding: '0 16px',
    minWidth: '80px',
    textAlign: 'center',
    fontWeight: '600',
    height: '32px',
    borderRadius: '2px',
    lineHeight: '2.2',
    ':focus-within': {
      border: `2px solid ${theme.palette.blue}`,
    },
  },
  buttonInner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
    left: 0,
  },
  buttonError: {
    border: `1px solid ${theme.palette.redDark}`,
  },
  errorText: {
    color: theme.palette.redDark,
    paddingTop: 5,
  },
  fileName: {
    maxWidth: 300,
    lineHeight: 32,
    textAlign: 'center',
  },
  closeButton: {
    color: theme.palette.neutralPrimary,
    selectors: {
      '&:hover': {
        color: theme.palette.neutralDark,
      },
    },
  },
}));

export default useFileInputButtonStyles;
