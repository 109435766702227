import { Panel, PanelType } from '@fluentui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GridPanelTypes, gridPanelContext } from './GridPanel.context';
import useGridPanelStyles from './GridPanel.styles';
import IGridPanelProps from './GridPanel.types';

/** In grid sliding panel */
const GridPanel: React.FC<IGridPanelProps> = ({ title, body, isOpen, isSharingPanel }) => {
  const { t } = useTranslation();
  const { setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const styles = useGridPanelStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function dismissPanel(): void {
    setIsGridPanelOpen(false);
    setGridPanelType(GridPanelTypes.Empty);
  }

  const panelWidth = isSharingPanel ? '440px' : '320px';

  return (
    <Panel
      isOpen={isOpen}
      isBlocking={false}
      onDismiss={dismissPanel}
      headerText={title}
      closeButtonAriaLabel={t('components.gridPanel.closeButton')}
      customWidth={panelWidth}
      className={styles.panel}
      type={PanelType.custom}
      isFooterAtBottom
    >
      {body}
    </Panel>
  );
};

export default GridPanel;
