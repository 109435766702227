import { Icon, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';

import useTrainingProgressStyles from '../../TrainingProgress/TrainingProgress.styles';
import { IExtractedFileProgressProps, IFileStateProps } from './ExtractedFileProgress.types';

import { UploadNotificationStatus } from '../../../utils/constants/notifications';

const FileState: React.FC<IFileStateProps> = ({ status, name, errorMessage }) => {
  const classes = useTrainingProgressStyles();

  let fileContent;

  switch (status) {
    case UploadNotificationStatus.InProgress:
      fileContent = (
        <Stack horizontal>
          <Spinner size={SpinnerSize.xSmall} className={clsx(classes.spinner)} />
          <Text className={classes.reducedFontSize}>{name}</Text>
        </Stack>
      );
      break;
    case UploadNotificationStatus.Succeeded:
      fileContent = (
        <Fragment>
          <Icon iconName="Completed" className={clsx(`${classes.icon} ${classes.reducedFontSize}`)} />
          <Text className={classes.reducedFontSize}>{name}</Text>
        </Fragment>
      );
      break;
    case UploadNotificationStatus.Failed:
      fileContent = (
        <Fragment>
          <Icon iconName="ErrorBadge" className={clsx(`${classes.errorIcon} ${classes.reducedFontSize}`)} />
          <Text className={classes.reducedFontSize}>{name}</Text>
          <Text className={clsx(`${classes.reducedFontSize} ${classes.inlineError}`)} block>
            {errorMessage}
          </Text>
        </Fragment>
      );
      break;
    default:
      break;
  }
  return <div className={classes.blockRoot}>{fileContent}</div>;
};

const ExtractedFileProgress: React.FC<IExtractedFileProgressProps> = ({ notification }) => {
  const classes = useTrainingProgressStyles();

  const extractedFiles = Object.keys(notification.files).map((fileId) => {
    const file = notification.files[fileId];
    return (
      <Stack.Item key={fileId}>
        <FileState status={file.status} name={file.name} errorMessage={file.errorMessage} />
      </Stack.Item>
    );
  });

  const statusContent = (
    <Stack tokens={{ childrenGap: 2 }} className={classes.noBottomMargin}>
      {extractedFiles}
    </Stack>
  );
  return <div>{statusContent}</div>;
};

export default ExtractedFileProgress;
