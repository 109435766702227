import { Stack, Text, TextField } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Action } from 'redux';

import { CreateWorkspaceWizardSlide } from '../../../../redux/createWorkspaceWizard/createWorkspaceWizard.types';
import {
  setWorkspaceName,
  setWorkspaceWizardSlide,
} from '../../../../redux/createWorkspaceWizard/createWorkspaceWizardActions';
import WizardActions from '../../Shared/WizardActions';
import { ICreateWorkspaceWizardProps } from '../CreateWorkspaceWizard.types';
import useSetWorkspaceNameStyles from './SetWorkspaceName.styles';

import { useAppDispatch } from '../../../../utils/hooks';

const SetWorkspaceName: React.FC<ICreateWorkspaceWizardProps> = ({ handleCancelAndClose }) => {
  const classes = useSetWorkspaceNameStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue !== undefined) {
        setName(newValue);
      }
    },
    []
  );

  const handleSubmit = (): Action | void => {
    if (name.length === 0) {
      setError(t('components.forms.generalErrors.required'));
      appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-create/inline-errors' });
    } else {
      batch(() => {
        appDispatch(setWorkspaceName(name));
        appDispatch(setWorkspaceWizardSlide(CreateWorkspaceWizardSlide.AddKey));
      });
    }
  };

  return (
    <Stack>
      <Stack.Item>
        <Text as="p" block className={classes.subtitle}>
          {t('components.forms.createWorkspaceWizard.workspaceName.subtitle')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          label={t('components.forms.createWorkspaceWizard.workspaceName.label')}
          name="workspaceName"
          placeholder={t('components.forms.createWorkspaceWizard.workspaceName.placeholder')}
          value={name}
          onChange={handleChange}
          errorMessage={error}
          required
        />
      </Stack.Item>
      <Stack.Item>
        <WizardActions
          handleCancelAndClose={handleCancelAndClose}
          confirmationButton={{
            text: t('components.popups.common.next'),
            onClick: handleSubmit,
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default SetWorkspaceName;
