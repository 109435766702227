import { MessageBar, SharedColors } from '@fluentui/react';
import React, { useState } from 'react';

const AppMessageBar: React.FC = () => {
  const [dismissed, setDismissed] = useState(false);

  if (dismissed) {
    return null;
  }

  return (
    <MessageBar onDismiss={(): void => setDismissed(true)} dismissButtonAriaLabel="Close">
      A selection of pre-built, ready to publish models are available for many language pairs and domains on Azure
      Marketplace.
      <a
        href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/tausbv1667479865417.demt_tr_001?tab=Overview"
        style={{ textDecoration: 'underline', color: SharedColors.cyanBlue20 }}
      >
        Go to Azure Marketplace
      </a>
      .
    </MessageBar>
  );
};

export default AppMessageBar;
