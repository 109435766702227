import { ChoiceGroup, IChoiceGroupOption, TextField } from '@fluentui/react';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import React, { FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { createModel } from '../../../redux/models/modelsActions';
import FormActions from '../Shared/FormActions';
import useDuplicateModelFormStyles from './DuplicateModelForm.styles';
import { IDuplicateModelFormProps, IDuplicateModelFormValues } from './DuplicateModelForm.types';

import DocumentTypes from '../../../utils/constants/documentTypes';
import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/appRedux.helpers';

const DuplicateModelForm: React.FC<IDuplicateModelFormProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const {
    entitySelections: { selectedModel },
  } = useAppSelector((state) => state);
  const [isAutoTrain, setIsAutoTrain] = useState<boolean>(true);
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const dispatch = useAppDispatch();
  const classes = useDuplicateModelFormStyles();
  const history = useHistory();
  const [formValues, setFormValues] = useState(
    (): IDuplicateModelFormValues => {
      const initialState = {
        modelName: '',
      };
      return initialState;
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    modelName: undefined,
  });

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
      const inputName = (event.target as HTMLInputElement).name;
      if (!newName || newName?.length <= FormValidationLengths.MAX_MODEL_NAME_LENGTH) {
        setFormValues({ ...formValues, [inputName]: newName || '' });
      }
      if (!newName) {
        setErrors({ ...errors, [inputName]: t('components.forms.generalErrors.required') });
      } else {
        setErrors({ ...errors, [inputName]: undefined });
      }
    },
    [formValues, errors, t]
  );

  const validateTuningAndTestingAuto = (documentsResponse: any): any => {
    let isTuningAuto = true;
    let isTestingAuto = true;
    const documentIds = documentsResponse.map((document: any) => document.documentInfo.id);
    documentsResponse.forEach((document: any) => {
      if (document.documentInfo.documentType === DocumentTypes.Testing) {
        isTestingAuto = false;
      }
      if (document.documentInfo.documentType === DocumentTypes.Tuning) {
        isTuningAuto = false;
      }
    });
    return { documentIds, isTuningAuto, isTestingAuto };
  };

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    if (formValues.modelName.length === 0) {
      setErrors({ ...errors, modelName: t('components.forms.generalErrors.required') });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-duplicate/inline-errors' });
    } else {
      handleCancelAndClose();
      axios.get(`${process.env.REACT_APP_API_URL}models/${modelId || selectedModel}`).then((response) => {
        const { documentIds, isTestingAuto, isTuningAuto } = validateTuningAndTestingAuto(response.data.documents);
        dispatch(
          createModel(
            {
              name: formValues.modelName,
              projectId,
              documentIds,
              isTestingAuto,
              isTuningAuto,
              isAutoTrain,
              isAutoDeploy: false,
            },
            true
          )
        );
      });
      if (modelId) {
        history.push(`/workspaces/${workspaceId}/projects/${projectId}/models`);
      }
    }
  };

  const options: IChoiceGroupOption[] = [
    { key: 'trainNow', text: t('components.forms.duplicateModel.options.trainNow') },
    { key: 'saveDraft', text: t('components.forms.duplicateModel.options.saveDraft') },
  ];

  const onOptionsChange = useCallback(
    (ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) => {
      if (option?.key === 'trainNow') {
        setIsAutoTrain(true);
      } else {
        setIsAutoTrain(false);
      }
    },
    []
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.subText}>{t('components.forms.duplicateModel.subText')} </div>
      <TextField
        label={t('components.forms.duplicateModel.newModelName')}
        name="modelName"
        onChange={handleChange}
        errorMessage={errors?.modelName}
        value={formValues.modelName}
        placeholder={t('components.forms.duplicateModel.placeholder')}
        required
      />
      <ChoiceGroup
        className={classes.optionsTitle}
        label={t('components.forms.duplicateModel.options.title')}
        options={options}
        defaultSelectedKey="trainNow"
        onChange={onOptionsChange}
      />
      <FormActions
        primaryButtonText={t('components.forms.duplicateModel.primaryButton')}
        isDisabled={!isEmpty(Object.values(errors).filter((value) => value))}
        handleCancelAndClose={handleCancelAndClose}
        onCancelTelemetryMessage="model-duplicate/cancel"
      />
    </form>
  );
};

export default DuplicateModelForm;
