import { makeStyles } from '@fluentui/react';

const useWizardActionStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing.l2,
  },
  actionButtons: {
    padding: 0,
    selectors: {
      '.ms-Button': {
        lineHeight: 'normal',
      },
      '& > :first-child': {
        marginRight: theme.spacing.s1,
      },
    },
  },
}));

export default useWizardActionStyles;
