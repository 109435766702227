import { useAppSelector } from '.';
import { ModelStatus } from '../constants/modelStatus';

const useAreOtherModelsUpdating = (): boolean => {
  const { models } = useAppSelector((state) => state);
  let hasModelsInProgress = false;
  const inProgressStatuses: string[] = [
    ModelStatus.Publishing,
    ModelStatus.UpdatingPublishment,
    ModelStatus.Unpublishing,
  ];

  models.ids.forEach((id: string) => {
    const model = models.data[id];
    if (inProgressStatuses.includes(model.modelStatus)) {
      hasModelsInProgress = true;
    }
  });

  return hasModelsInProgress;
};

export default useAreOtherModelsUpdating;
