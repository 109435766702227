import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WorkspacesCommandBar } from '../../CommandBars';
import { Empty } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { WorkspacesTable } from '../../Tables';
import ContentContainer from '../ContentContainer';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { useAppSelector } from '../../../utils/hooks';

const WorkspacesContent: React.FC = () => {
  const { workspaces } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const breadcrumbItems = [
    {
      text: t('components.appSidebar.workspaces'),
      key: 'my-workspaces',
    },
  ];

  let workspaceContent;
  if (isEmpty(workspaces.ids)) {
    workspaceContent = <Empty type={EmptyTypes.Workspaces} />;
  } else {
    workspaceContent = <WorkspacesTable />;
  }
  return (
    <ContentContainer>
      <PageHeading>{t('components.appSidebar.workspaces')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      <WorkspacesCommandBar />
      {workspaceContent}
      {/** 
         * TODO: Implement pivots: moving above into "Recent" and adding a pinned workspaces pivot 
         * https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/123196
        <Pivot className={classes.pivot} aria-label={t('pages.workspaces.contentSection.pivotSection.aria')}>
         <PivotItem
          headerText={t('pages.workspaces.contentSection.pivotSection.pinnedPivot.text')}
          headerButtonProps={{
            ariaLabel: `${t('pages.workspaces.contentSection.pivotSection.pinnedPivot.aria')}`,
          }}
          >
          <Fragment>
            <WorkspacesCommandBar />
            <Empty title={t('pages.workspaces.contentSection.pivotSection.noPinnedWorkspaces')} />
          </Fragment>
        </PivotItem> */}
    </ContentContainer>
  );
};

export default WorkspacesContent;
