export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESSFUL = 'GET_SUBSCRIPTION_SUCCESSFUL';
export const GET_SUBSCRIPTION_FAILED = 'GET_SUBSCRIPTION_FAILED';

export const UPDATE_SUBSCRIPTION_KEY = 'UPDATE_SUBSCRIPTION_KEY';
export const UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL = 'UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL';
export const UPDATE_SUBSCRIPTION_KEY_FAILED = 'UPDATE_SUBSCRIPTION_KEY_FAILED';

export interface IBillingItem {
  isPaidSubscription: boolean;
  maximumCharacterCount: number;
  pricePerMillionCharacters: number;
}

export interface ISubscriptionItem {
  billing: IBillingItem;
  id: string;
  isCMKEnabled: boolean;
  kind: string;
  name: string;
  region: string;
  sku: string;
}

export interface ISubscriptionEntities {
  subscription: {
    [key: string]: ISubscriptionItem;
  };
}

export interface IGetSubscription {
  type: typeof GET_SUBSCRIPTION;
}

export interface IGetSubscriptionSuccessful {
  type: typeof GET_SUBSCRIPTION_SUCCESSFUL;
  payload: {
    workspaceId: string;
    data: ISubscriptionItem;
  };
}

export interface IGetSubscriptionFailed {
  type: typeof GET_SUBSCRIPTION_FAILED;
  payload: string;
}

export interface IUpdateSubscriptionKey {
  type: typeof UPDATE_SUBSCRIPTION_KEY;
}

export interface IUpdateSubscriptionKeySuccessful {
  type: typeof UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL;
  payload: {
    workspaceId: string;
    data: ISubscriptionItem;
  };
}

export interface IUpdateSubscriptionKeyFailed {
  type: typeof UPDATE_SUBSCRIPTION_KEY_FAILED;
}

export type SubscriptionsDispatchTypes =
  | IGetSubscription
  | IGetSubscriptionSuccessful
  | IGetSubscriptionFailed
  | IUpdateSubscriptionKey
  | IUpdateSubscriptionKeySuccessful
  | IUpdateSubscriptionKeyFailed;
