import { IDocumentsState } from '../redux/documents/documentsReducer';

const isModelFullTraining = (modelDocumentIds?: number[], documents?: IDocumentsState): boolean => {
  let isFullTraining = true;
  if (modelDocumentIds === undefined || documents === undefined) {
    return false;
  }
  modelDocumentIds.forEach((id) => {
    if (documents.data[id].documentType.includes('dictionary')) {
      isFullTraining = false;
    }
  });
  return isFullTraining;
};

export default isModelFullTraining;
