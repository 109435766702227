import { DisplayedTrainingSteps, TrainingStepState } from './constants/trainingProgress';

const checkProgressStep = (
  currentStep: keyof typeof DisplayedTrainingSteps,
  statusStep: keyof typeof DisplayedTrainingSteps
): string => {
  if (DisplayedTrainingSteps[currentStep] < DisplayedTrainingSteps[statusStep]) return TrainingStepState.NotStarted;
  if (DisplayedTrainingSteps[currentStep] === DisplayedTrainingSteps[statusStep]) return TrainingStepState.Running;
  return TrainingStepState.Completed;
};

export default checkProgressStep;
