import React from 'react';

type ContextProps = {
  isInitialLoad: boolean;
  setInitialLoad: (value: boolean) => void;
};

const initialState = {
  isInitialLoad: true,
  setInitialLoad: (): void => {},
};

const initialLoadContext = React.createContext<ContextProps>(initialState);

export default initialLoadContext;
