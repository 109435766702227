import { AccountInfo } from '@azure/msal-common';
import axios, { AxiosRequestConfig } from 'axios';

import { msalInstance, requestScopes } from './authentication';

axios.interceptors.request.use(
  async (config): Promise<AxiosRequestConfig> => {
    const axiosConfig = config;
    const myAccounts: AccountInfo[] = msalInstance.getAllAccounts();

    const request = {
      scopes: requestScopes.scopes,
      account: myAccounts[0],
    };
    await msalInstance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        axiosConfig.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
      })
      .catch((error): Promise<void> | void | null => {
        if (error.name === 'InteractionRequiredAuthError') {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(request);
        }

        return null;
      });

    return axiosConfig;
  }
);
