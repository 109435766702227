/*
 * Regex does the following:
 * String can only start and end with alphanumeric characters
 * Middle portion of string can be zero to unlimited characters that are either alphanumeric, -, or _
 * Characters are case-insensitive
 */
const isProjectLabelValid = (projectLabel: string): boolean => {
  const projectLabelRegEx = new RegExp('^(?!-|_)[a-z0-9-_]*$(?<!-|_)', 'gi');
  return projectLabelRegEx.test(projectLabel);
};

export default isProjectLabelValid;
