import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ExtractedFileProgress from '../ExtractedFileProgress/ExtractedFileProgress';
import NotificationBase from '../NotificationBase/NotificationBase';
import useNotificationBaseStyles from '../NotificationBase/NotificationBase.styles';
import { IUploadNotificationProps } from './UploadNotification.types';

import { NotificationType, UploadNotificationStatus } from '../../../utils/constants/notifications';

const UploadNotification: React.FC<IUploadNotificationProps> = ({ notification }) => {
  const classes = useNotificationBaseStyles();
  const { t } = useTranslation();

  const { status, name, errorMessage, files } = notification;

  let error = '';
  let cssClass = classes.notificationItem;
  let icon;
  let errorLink;
  if (status === UploadNotificationStatus.Succeeded) {
    cssClass = clsx(classes.notificationItem, { success: true });
    icon = {
      name: 'Completed',
      class: clsx(classes.icon, { success: true }),
    };
  } else if (status === UploadNotificationStatus.Failed) {
    cssClass = clsx(classes.notificationItem, { failure: true });
    icon = {
      name: 'ErrorBadge',
      class: clsx(classes.icon, { failure: true }),
    };

    // Determine error message
    if (!isEmpty(errorMessage)) {
      error = errorMessage;
    } else {
      // For a zip file, individual errors can be shown on each file. If there are no
      // individual errors shown on each filem show the generic error for the zip as
      // a whole.
      const noExtractedFileErrors =
        typeof files === 'object'
          ? Object.keys(files).every((fileId) => {
              return isEmpty(notification.files[fileId].errorMessage);
            })
          : true;
      const showGenericError = !notification.isZip || (notification.isZip && noExtractedFileErrors);
      if (showGenericError) {
        error = t('components.notificationPanel.upload.genericFailureText1');
        errorLink = {
          url:
            'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/document-formats-naming-convention',
          urlText: t('components.notificationPanel.upload.genericFailureLink'),
          postLinkText: t('components.notificationPanel.upload.genericFailureText2'),
        };
      }
    }
  }

  let title = '';
  let subtitle = '';
  switch (status) {
    case UploadNotificationStatus.InProgress:
      title = t('components.notificationPanel.upload.inProgressTitle', { name });
      subtitle = notification.isZip
        ? t('components.notificationPanel.upload.zipInProgressSubtitle')
        : t('components.notificationPanel.upload.inProgressSubtitle');
      break;
    case UploadNotificationStatus.Succeeded:
      title = t('components.notificationPanel.upload.completedTitle', { name });
      break;
    case UploadNotificationStatus.Failed:
      title = t('components.notificationPanel.upload.failedTitle', { name });
      break;
    default:
      break;
  }

  const display = { title, subtitle, class: cssClass, icon, error, errorLink };
  return (
    <NotificationBase display={display} id={notification.id} type={NotificationType.Upload}>
      {!isEmpty(notification.files) && <ExtractedFileProgress notification={notification} />}
    </NotificationBase>
  );
};

export default UploadNotification;
