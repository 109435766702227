import { FontIcon, Stack } from "@fluentui/react";
import styled from "styled-components";
import { defaultTheme } from '../../../themes';

export interface IIndicatorProps {
    total: number;
    currentIndex: number;
    setIndex: (index: number) => void;

    className?: string;

    options?: {
        color?: string;
        iconSize?: string;

        defaultIconName?: string;
        onFocusIconName?: string;

        arrangement?: "row" | "column";
    }
}

export const Indicator = styled((props: IIndicatorProps) => (
    <Stack className={props.className || ""} >
        {Array.from({ length: props.total }).map((_, index) => (
            <FontIcon
                key={index}
                onClick={() => props.setIndex(index)}
                iconName={
                    props.currentIndex === index ?
                        props.options?.onFocusIconName || "statusCircleInner" :
                        props.options?.defaultIconName || "statusCircleRing"
                }
            />
        ))}
    </Stack>
))`
    align-items: center;     
    justify-content: center; 
    font-size: ${props => props.options?.iconSize || "12px"};
    flex-direction: ${props => props.options?.arrangement || "row"};
    i {
        padding: 8px;
        cursor: pointer;
        color: ${props => props.options?.color || defaultTheme.body.palette.white};
    }
`;
