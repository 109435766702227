import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import ManageDocumentsContent from '../components/PageContents/ManageDocumentsContent';

import ErrorCategories from '../utils/constants/errorCategories';
import { useAppSelector } from '../utils/hooks';
import useGetProjectDocuments from '../utils/hooks/useGetProjectDocuments';

const ManageDocuments: React.FC = () => {
  useTrackMetric(reactPlugin, 'ManageDocumentsPage');

  const { t } = useTranslation();
  const { error } = useAppSelector((state) => state);

  useGetProjectDocuments(undefined, undefined, true);

  let content;
  if (!isEmpty(error.message) && error.category === ErrorCategories.Documents) {
    content = <Error />;
  } else {
    content = <ManageDocumentsContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('components.appSidebar.manageDocuments')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default ManageDocuments;
