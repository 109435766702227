import { makeStyles } from '@fluentui/react';

const useAppModalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  fullWidth: {
    width: '95vw',
  },
  header: {
    padding: `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.s1} ${theme.spacing.l2}`,
  },
  closeButton: {
    margin: '4px 2px 0 auto',
    color: theme.palette.neutralPrimary,
    selectors: {
      '&:hover': {
        color: theme.palette.neutralDark,
      },
    },
  },
  subText: {
    display: 'inline-block',
    margin: `${theme.spacing.s1} 0`,
  },
  body: {
    padding: `0 ${theme.spacing.l2} ${theme.spacing.l2}`,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    width: '100%',
    minHeight: '24px',
    lineHeight: '24px',
    padding: `0 ${theme.spacing.m} ${theme.spacing.m}`,
    fontSize: '0',
    selectors: {
      '.ms-Button': {
        lineHeight: 'normal',
      },
      '& > :first-child': {
        marginRight: theme.spacing.s1,
      },
    },
  },
}));

export default useAppModalStyles;
