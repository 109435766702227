import { DefaultButton, Icon, NeutralColors } from '@fluentui/react';
import React, { Fragment, useEffect } from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getDocuments } from '../../redux/documents/documentsActions';
import { clearFilters } from '../../redux/filters/filtersActions';
import { getProjects } from '../../redux/projects/projectsActions';
import getUploadHistory from '../../redux/uploadHistory/uploadHistoryActions';

import IRoutePathParams from '../../utils/constants/routePathParams.types';
import filterDocumentsDispatch from '../../utils/filterDocumentsDispatch';
import filterModels from '../../utils/filterModels';
import filterProjectsQuery from '../../utils/filterProjectsQuery';
import filterUploadHistoryQuery from '../../utils/filterUploadHistoryQuery';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

type FilterBarKinds = 'documents' | 'projects' | 'models' | 'uploadHistory';

const FilterBarContainer: React.FC<{ kind: FilterBarKinds }> = ({ kind }) => {
  return (
    <div
      style={{
        paddingTop: '10px',
      }}
    >
      <FilterBar kind={kind} />
    </div>
  );
};

const FilterBar: React.FC<{ kind: FilterBarKinds }> = ({ kind }) => {
  const appDispatch = useAppDispatch();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { projects, languages, filters, models, categories, workspaces, supportedLanguages } = useAppSelector(
    (state) => state
  );

  useEffect(() => {
    // Only clear filters when within projectId routes.
    // This is to mirror the fetching and reset logic of filters to results.
    if (projectId) {
      appDispatch(clearFilters());
    }
  }, [appDispatch, projectId]);

  function handleRemoveDocumentsFilter({
    filterKey,
    filterTypes,
    filterLanguages,
  }: {
    filterKey: 'name' | 'types' | 'languages' | 'monolingual';
    filterTypes?: string[];
    filterLanguages?: string[];
  }): void {
    const { isFiltering, filter } = filterDocumentsDispatch({
      showMonolingualDocuments: filterKey === 'monolingual' ? false : filters.documents.monolingual,
      documentSearchTerm: filterKey === 'name' ? '' : filters.documents.name,
      selectedDocumentTypeKeys: filterKey === 'types' ? filterTypes || [] : filters.documents.types,
      projectId,
      projects,
      languages,
      selectedLanguageKeys:
        filterKey === 'languages' ? filterLanguages || [] : filters.documents.languages.map((lang) => lang.key),
    });

    batch(() => {
      appDispatch(
        getDocuments({
          workspaceId,
          filter,
          page: 1,
          resetPagination: true,
          prioritizeModel: modelId,
        })
      );
      appDispatch({ type: 'SET_DOCUMENTS_FILTERED', payload: isFiltering });
    });
  }

  if (kind === 'documents') {
    const { documents } = filters;
    return (
      <Fragment>
        {documents.name && (
          <FilterButton
            label={documents.nameLabel}
            value={documents.name}
            onClick={(): void => {
              appDispatch({
                type: 'SET_DOCUMENT_FILTER_NAME',
                payload: {
                  name: '',
                },
              });
              handleRemoveDocumentsFilter({ filterKey: 'name' });
            }}
          />
        )}
        {documents.types.map((type) => (
          <FilterButton
            key={type + documents.typesLabel}
            label={documents.typesLabel}
            value={type}
            onClick={(): void => {
              const newTypes = documents.types.filter((t) => type !== t);

              appDispatch({
                type: 'SET_DOCUMENT_FILTER_TYPES',
                payload: {
                  types: newTypes,
                },
              });
              handleRemoveDocumentsFilter({ filterKey: 'types', filterTypes: newTypes });
            }}
          />
        ))}
        {documents.languages.map((lang) => (
          <FilterButton
            key={lang.key}
            label={documents.languagesLabel}
            value={lang.label}
            onClick={(): void => {
              const newLangs = documents.languages.filter((l) => lang !== l);

              appDispatch({
                type: 'SET_DOCUMENT_FILTER_LANGUAGES',
                payload: {
                  languages: newLangs,
                },
              });
              handleRemoveDocumentsFilter({ filterKey: 'languages', filterLanguages: newLangs.map((l) => l.key) });
            }}
          />
        ))}
        {documents.monolingual && (
          <FilterButton
            value={documents.monolingualLabel}
            onClick={(): void => {
              appDispatch({
                type: 'SET_DOCUMENT_FILTER_MONOLINGUAL',
                payload: {
                  monolingual: false,
                },
              });
              handleRemoveDocumentsFilter({ filterKey: 'monolingual' });
            }}
          />
        )}
      </Fragment>
    );
  }

  if (kind === 'projects') {
    return (
      <Fragment>
        {filters.projects.name && (
          <FilterButton
            label={filters.projects.nameLabel}
            value={filters.projects.name}
            onClick={(): void => {
              appDispatch({
                type: 'SET_PROJECT_FILTER_NAME',
                payload: {
                  name: '',
                },
              });
              appDispatch(
                getProjects(
                  workspaces.currentWorkspace.id,
                  filterProjectsQuery({
                    projectSearchTerm: '',
                    selectedCategoryKeys: filters.projects.categories,
                    categories,
                    selectedSourceLanguageKeys: filters.projects.sourceLanguages,
                    languages,
                    selectedTargetLanguageKeys: filters.projects.targetLanguages,
                  })
                )
              );
            }}
          />
        )}
        {filters.projects.sourceLanguages.map((langKey) => (
          <FilterButton
            key={langKey}
            label={filters.projects.sourceLanguagesLabel}
            value={Object.values(languages).find((lang) => lang.id === langKey)?.displayName || ''}
            onClick={(): void => {
              const newSourceLanguages = filters.projects.sourceLanguages.filter((key) => key !== langKey);
              appDispatch({
                type: 'SET_PROJECT_FILTER_SOURCE_LANGUAGES',
                payload: {
                  sourceLanguages: newSourceLanguages,
                },
              });
              appDispatch(
                getProjects(
                  workspaces.currentWorkspace.id,
                  filterProjectsQuery({
                    projectSearchTerm: filters.projects.name,
                    selectedCategoryKeys: filters.projects.categories,
                    categories,
                    selectedSourceLanguageKeys: newSourceLanguages,
                    languages,
                    selectedTargetLanguageKeys: filters.projects.targetLanguages,
                  })
                )
              );
            }}
          />
        ))}
        {filters.projects.targetLanguages.map((langKey) => (
          <FilterButton
            label={filters.projects.targetLanguagesLabel}
            value={Object.values(languages).find((lang) => lang.id === langKey)?.displayName || ''}
            onClick={(): void => {
              const newTargetLanguages = filters.projects.targetLanguages.filter((key) => key !== langKey);
              appDispatch({
                type: 'SET_PROJECT_FILTER_TARGET_LANGUAGES',
                payload: {
                  targetLanguages: newTargetLanguages,
                },
              });
              appDispatch(
                getProjects(
                  workspaces.currentWorkspace.id,
                  filterProjectsQuery({
                    projectSearchTerm: filters.projects.name,
                    selectedCategoryKeys: filters.projects.categories,
                    categories,
                    selectedSourceLanguageKeys: filters.projects.sourceLanguages,
                    languages,
                    selectedTargetLanguageKeys: newTargetLanguages,
                  })
                )
              );
            }}
          />
        ))}
        {filters.projects.categories.map((categoryKey) => (
          <FilterButton
            label={filters.projects.categoriesLabel}
            value={Object.values(categories.data).find((c) => c.id === categoryKey)?.name || ''}
            onClick={(): void => {
              const newCategories = filters.projects.categories.filter((key) => key !== categoryKey);
              appDispatch({
                type: 'SET_PROJECT_FILTER_TARGET_LANGUAGES',
                payload: {
                  categories: newCategories,
                },
              });
              appDispatch(
                getProjects(
                  workspaces.currentWorkspace.id,
                  filterProjectsQuery({
                    projectSearchTerm: filters.projects.name,
                    selectedCategoryKeys: newCategories,
                    categories,
                    selectedSourceLanguageKeys: filters.projects.sourceLanguages,
                    languages,
                    selectedTargetLanguageKeys: filters.projects.targetLanguages,
                  })
                )
              );
            }}
          />
        ))}
      </Fragment>
    );
  }

  if (kind === 'models') {
    return (
      <Fragment>
        {filters.models.name && (
          <FilterButton
            label={filters.models.nameLabel}
            value={filters.models.name}
            onClick={(): void => {
              appDispatch({
                type: 'SET_MODEL_FILTER_NAME',
                payload: {
                  name: '',
                },
              });
              appDispatch({ type: 'FILTER_MODELS', payload: filterModels(models, filters.models.categoryNames, '') });
            }}
          />
        )}
        {filters.models.categoryNames.map((categoryName) => (
          <FilterButton
            label={filters.models.categoryNamesLabel}
            value={categoryName}
            onClick={(): void => {
              const newCategoryNames = filters.models.categoryNames.filter((n) => n !== categoryName);

              appDispatch({
                type: 'SET_MODEL_FILTER_CATEGORY_NAMES',
                payload: {
                  categoryNames: newCategoryNames,
                },
              });
              appDispatch({
                type: 'FILTER_MODELS',
                payload: filterModels(models, newCategoryNames, filters.models.name),
              });
              // TODO: Filter out category names and make a new search.
            }}
          />
        ))}
      </Fragment>
    );
  }

  if (kind === 'uploadHistory') {
    return (
      <Fragment>
        {filters.uploadHistory.name && (
          <FilterButton
            label={filters.uploadHistory.nameLabel}
            value={filters.uploadHistory.name}
            onClick={(): void => {
              const filter = filterUploadHistoryQuery({
                searchTerm: '',
                selectedStatusKey: filters.uploadHistory.status,
                selectedLanguageKey: filters.uploadHistory.language,
                afterDate: filters.uploadHistory.after,
                beforeDate: filters.uploadHistory.before,
              });
              appDispatch(
                getUploadHistory({
                  workspaceId,
                  filter,
                  currentPage: 1,
                })
              );
              appDispatch({
                type: 'SET_UPLOAD_HISTORY_FILTER_NAME',
                payload: {
                  name: '',
                },
              });
            }}
          />
        )}
        {filters.uploadHistory.status && (
          <FilterButton
            label={filters.uploadHistory.statusLabel}
            value={filters.uploadHistory.status === 254 ? 'Failed' : 'Succeeded'}
            onClick={(): void => {
              const filter = filterUploadHistoryQuery({
                searchTerm: filters.uploadHistory.name,
                selectedStatusKey: null,
                selectedLanguageKey: filters.uploadHistory.language,
                afterDate: filters.uploadHistory.after,
                beforeDate: filters.uploadHistory.before,
              });
              appDispatch(
                getUploadHistory({
                  workspaceId,
                  filter,
                  currentPage: 1,
                })
              );
              appDispatch({
                type: 'SET_UPLOAD_HISTORY_FILTER_STATUS',
                payload: {
                  status: undefined,
                },
              });
            }}
          />
        )}
        {filters.uploadHistory.language && (
          <FilterButton
            label={filters.uploadHistory.languageLabel}
            value={
              Object.values(supportedLanguages.data || {}).find(
                (lang) => lang.languageCode === filters.uploadHistory.language
              )?.displayName || ''
            }
            onClick={(): void => {
              const filter = filterUploadHistoryQuery({
                searchTerm: filters.uploadHistory.name,
                selectedStatusKey: filters.uploadHistory.status,
                selectedLanguageKey: '',
                afterDate: filters.uploadHistory.after,
                beforeDate: filters.uploadHistory.before,
              });
              appDispatch(
                getUploadHistory({
                  workspaceId,
                  filter,
                  currentPage: 1,
                })
              );
              appDispatch({
                type: 'SET_UPLOAD_HISTORY_FILTER_LANGUAGE',
                payload: {
                  language: undefined,
                },
              });
            }}
          />
        )}
        {filters.uploadHistory.after && (
          <FilterButton
            label={filters.uploadHistory.afterLabel}
            value={filters.uploadHistory.after.toDateString()}
            onClick={(): void => {
              const filter = filterUploadHistoryQuery({
                searchTerm: filters.uploadHistory.name,
                selectedStatusKey: filters.uploadHistory.status,
                selectedLanguageKey: filters.uploadHistory.language,
                beforeDate: filters.uploadHistory.before,
                afterDate: undefined,
              });
              appDispatch(
                getUploadHistory({
                  workspaceId,
                  filter,
                  currentPage: 1,
                })
              );
              appDispatch({
                type: 'SET_UPLOAD_HISTORY_FILTER_AFTER',
                payload: {
                  after: undefined,
                },
              });
            }}
          />
        )}
        {filters.uploadHistory.before && (
          <FilterButton
            label={filters.uploadHistory.beforeLabel}
            value={filters.uploadHistory.before.toDateString()}
            onClick={(): void => {
              const filter = filterUploadHistoryQuery({
                searchTerm: filters.uploadHistory.name,
                selectedStatusKey: filters.uploadHistory.status,
                selectedLanguageKey: filters.uploadHistory.language,
                afterDate: filters.uploadHistory.after,
                beforeDate: undefined,
              });
              appDispatch(
                getUploadHistory({
                  workspaceId,
                  filter,
                  currentPage: 1,
                })
              );
              appDispatch({
                type: 'SET_UPLOAD_HISTORY_FILTER_BEFORE',
                payload: {
                  before: undefined,
                },
              });
            }}
          />
        )}
      </Fragment>
    );
  }

  return null;
};

interface IFilterButtonProps {
  label?: string;
  value: string;
  onClick: () => void;
}

const FilterButton: React.FC<IFilterButtonProps> = ({ label, value, onClick }) => {
  return (
    <DefaultButton
      onClick={onClick}
      style={{
        background: NeutralColors.gray20,
        marginRight: '10px',
        marginBottom: '10px',
      }}
    >
      {label && <span style={{ paddingRight: '5px' }}>{label}:</span>}
      <span style={{ fontWeight: 500, paddingRight: '10px' }}>{value}</span>
      <Icon iconName="Cancel" style={{ color: NeutralColors.gray150 }} />
    </DefaultButton>
  );
};

export default FilterBarContainer;
