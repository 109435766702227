/*
 ** The AppSidebar functions as a persistent navigation for users to return to all workspaces, current project links, or current workspace settings.
 ** When data is returned for a workspace or project, the app sidebar will look at the associated URL parameter(s) and use that to locate the ID in the returned data.
 ** If that ID is matched this component will set the currentWorkspace and/or currentProject.
 ** Clearing a currentWorkspace or currentProject will happen at the corresponding page level component
 */
import { IconButton, Stack, useTheme } from '@fluentui/react';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clearModels } from '../../redux/models/modelsActions';
import { clearCurrentProject, setCurrentProject } from '../../redux/projects/projectsActions';
import { clearCurrentWorkspace, setCurrentWorkspace } from '../../redux/workspaces/workspacesActions';
import AppSidebarContext from './AppSidebar.context';
import useAppSidebarStyles from './AppSidebar.styles';
import IPathParams from './AppSidebar.types';
import AppSidebarLinks from './AppSidebarLinks';

import { useAppSelector } from '../../utils/hooks';

const AppSidebar: React.FC = () => {
  const theme = useTheme();
  const classes = useAppSidebarStyles();
  const { t } = useTranslation();
  const { workspaceId: workspaceParamId, projectId: projectParamId } = useParams<IPathParams>();
  const dispatch = useDispatch();
  const { workspaces, projects, error } = useAppSelector((state) => state);
  const { isAppSidebarCollapsed, setIsAppSidebarCollapsed } = useContext(AppSidebarContext);
  const appSidebarClass = clsx(classes.root, { collapsed: isAppSidebarCollapsed });

  const noError = error.message === undefined;
  // SET currentWorkspace
  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasCurrentWorkspace = !isEmpty(workspaces.currentWorkspace);

    if (hasWorkspaces && workspaceParamId) {
      /* If there is no currentWorkspace or if there is a currentWorkspace and the ID doesn't match the workspace param ID
       ** then we need to set currentWorkspace.
       ** When the user is changing between workspaces on the workspaces page or refreshes the page within a project,
       ** this ensures data is up to date for the current workspace
       */
      if (!hasCurrentWorkspace || (hasCurrentWorkspace && workspaces.currentWorkspace.id !== workspaceParamId)) {
        const currentWorkspaceId = workspaces.ids.find((id) => {
          return workspaceParamId === id;
        });

        if (currentWorkspaceId) {
          const currentWorkspace = workspaces.data[currentWorkspaceId];
          const { id, name } = currentWorkspace;

          dispatch(setCurrentWorkspace({ id, name }));
        }
      }
    }
  }, [dispatch, workspaces.ids, workspaces.data, workspaces.currentWorkspace, workspaceParamId]);

  // CLEAR currentWorkspace
  useEffect(() => {
    /* If there are workspaces and a currentWorkspace, verify if the currentWorkspace ID and name are still valid.
     ** A currentWorkspace would become invalid if the associated workspace is deleted or the name is changed and will need to be cleared.
     ** As a precaution clear the currentProject.
     */
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasCurrentWorkspace = !isEmpty(workspaces.currentWorkspace);

    if (hasWorkspaces && hasCurrentWorkspace && !workspaces.isLoading && noError) {
      const currentWorkspace = workspaces.data[workspaces.currentWorkspace.id];
      const isCurrentWorkspaceValid = currentWorkspace && workspaces.currentWorkspace.name === currentWorkspace.name;

      if (!isCurrentWorkspaceValid) {
        batch(() => {
          dispatch(clearCurrentWorkspace());
          dispatch(clearCurrentProject());
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspaces.ids, workspaces.isLoading, noError, workspaces.currentWorkspace]);

  // SET/CLEAR currentProject
  useEffect(() => {
    const hasProjects = !isEmpty(projects.ids);
    const hasCurrentProject = !isEmpty(projects.currentProject);

    if (hasProjects && projectParamId) {
      /* If there is no currentProject or if there is a currentProject and the ID doesn't match the project param ID
       ** then we need to set currentProject
       ** When the user is changing between projects on a workspace page, or refreshes while on a project page (like Train models)
       ** this ensures data is up to date for the current project
       */
      const hasProjectChangedFromSidebar = hasCurrentProject && projects.currentProject.id !== projectParamId;
      if (!hasCurrentProject || hasProjectChangedFromSidebar) {
        const currentProjectId = projects.ids.find((id) => {
          return projectParamId === id;
        });

        if (currentProjectId) {
          const currentProject = projects.data[currentProjectId];
          const { id, name } = currentProject;

          if (hasProjectChangedFromSidebar) {
            batch(() => {
              dispatch(setCurrentProject({ id, name }));
              dispatch(clearModels());
            });
          } else {
            dispatch(setCurrentProject({ id, name }));
          }
        }
      }
    } else if (hasCurrentProject && !projects.isLoading && noError) {
      if (!hasProjects) {
        // If user has a currentProject then clicks into a new workspace that has no projects yet, clear the currentProject
        dispatch(clearCurrentProject());
      } else if (hasProjects) {
        // If user has a currentProject and clicks into a new workspace that has projects, verify the currentProject is still valid.
        // If currentProject is not valid, meaning the project has been deleted or the name has been changed, clear currentProject
        const currentProject = projects.data[projects.currentProject.id];
        const isCurrentProjectValid = currentProject && projects.currentProject.name === currentProject.name;

        if (!isCurrentProjectValid) {
          dispatch(clearCurrentProject());
        }
      }
    }
  }, [dispatch, projects.ids, projects.isLoading, noError, projects.data, projects.currentProject, projectParamId]);

  return (
    <aside className={appSidebarClass} data-test-id="appSidebar">
      <Stack verticalAlign="space-between" styles={{ root: { height: '100%' } }}>
        <Stack.Item>
          <div className={classes.sidebarToggleContainer}>
            <IconButton
              iconProps={{ iconName: isAppSidebarCollapsed ? 'DoubleChevronRight' : 'DoubleChevronLeft' }}
              title={t('components.appSidebar.toggleButton.title')}
              ariaLabel={t('components.appSidebar.toggleButton.aria')}
              data-test-id="appSidebar-toggle-button"
              styles={{ root: { marginRight: 8 }, icon: { color: theme.palette.neutralDark, fontSize: 8 } }}
              onClick={(): void => setIsAppSidebarCollapsed(!isAppSidebarCollapsed)}
            />
          </div>
          <AppSidebarLinks />
        </Stack.Item>
      </Stack>
    </aside>
  );
};

export default AppSidebar;
