import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { updateModelPublishStatus } from '../../../redux/models/modelsActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import DuplicateModelForm from '../../Forms/DuplicateModelForm';
import SwapModelForm from '../../Forms/SwapModelForm/SwapModelForm';
import UpdatePublishForm from '../../Forms/UpdatePublishForm';
import { IUpdatePublishFormValues } from '../../Forms/UpdatePublishForm/UpdatePublishForm.types';
import { CommandBarLoader } from '../SharedCommandBarElements';

import canModelPublishBeUpdated from '../../../utils/canModelPublishBeUpdated';
import { ModelStatus } from '../../../utils/constants/modelStatus';
import { ModelsPopUpTypes } from '../../../utils/constants/popUps.types';
import PublishOperation from '../../../utils/constants/publishOperation';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useIsReader,
} from '../../../utils/hooks';
import useAreOtherModelsUpdating from '../../../utils/hooks/useAreOtherModelsUpdating';
import useCanModelBePublished from '../../../utils/hooks/useCanModelBePublished';
import { useCreateUnpublishSubtext } from '../../../utils/hooks/usePublishTextHelper';
import isModelPublished from '../../../utils/isModelPublished';
import isModelTestAvailable from '../../../utils/isModelTestAvailable';

const ModelCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { appDialog, setAppDialog } = useAppDialog();
  const { appModal, setAppModal } = useAppModal();
  const { models } = useAppSelector((state) => state);
  const appDispatch = useAppDispatch();

  const areOtherModelsUpdating = useAreOtherModelsUpdating();
  const isTestAvailable = isModelTestAvailable(models.data[modelId]);
  const canPublish = useCanModelBePublished(modelId, false);
  const isPublished = isModelPublished(models.data[modelId]);
  const isSwappable = useCanModelBePublished(modelId, true);
  const noPublishOrSwap = !canPublish && !isSwappable;
  const canUpdate = canModelPublishBeUpdated(models.data[modelId]) && !areOtherModelsUpdating;

  const currentRegions = models.data[modelId].modelRegionStatuses;
  const modelName = models.data[modelId].name;
  const unpublishSubtext = useCreateUnpublishSubtext(
    currentRegions,
    t('components.popups.model.unpublish.subText1'),
    t('components.popups.model.unpublish.subText2')
  );

  const isReader = useIsReader();

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'viewTest',
      text: t('components.commandBars.model.viewTestButton.text'),
      ariaLabel: t('components.commandBars.model.viewTestButton.aria'),
      iconProps: { iconName: 'TestBeaker' },
      className: clsx(!isTestAvailable && 'no-display'),
      onClick: (): void => {
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-details-view-test/open' });
        history.push(`/workspaces/${workspaceId}/projects/${projectId}/test/${modelId}`);
      },
    },
    {
      key: 'publish',
      text: t('components.commandBars.model.publishButton.text'),
      ariaLabel: t('components.commandBars.model.publishButton.aria'),
      iconProps: { iconName: 'Deploy' },
      className: clsx((noPublishOrSwap || isReader) && 'no-display'),
      onClick: (): void => {
        if (isSwappable) {
          setAppModal({ contentType: ModelsPopUpTypes.Swap, isOpen: true });
          appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-details-swap/open' });
        } else {
          setAppModal({ contentType: ModelsPopUpTypes.Publish, isOpen: true });
          appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-details-publish/open' });
        }
      },
    },
    {
      key: 'unpublish',
      text: t('components.commandBars.model.unpublishButton.text'),
      ariaLabel: t('components.commandBars.model.unpublishButton.aria'),
      iconProps: { iconName: 'Clear' },
      className: clsx((!isPublished || areOtherModelsUpdating || isReader) && 'no-display'),
      onClick: (): void => {
        setAppDialog({ contentType: ModelsPopUpTypes.Unpublish, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-details-unpublish/open' });
      },
    },
    {
      key: 'update',
      text: t('components.commandBars.model.updateButton.text'),
      ariaLabel: t('components.commandBars.model.updateButton.aria'),
      iconProps: { iconName: 'Deploy' },
      className: clsx((!canUpdate || isReader) && 'no-display'),
      onClick: (): void => {
        setAppModal({ contentType: ModelsPopUpTypes.UpdatePublishment, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-details-update/open' });
      },
    },
    {
      key: 'duplicate',
      text: t('components.commandBars.common.duplicate'),
      ariaLabel: t('components.commandBars.models.duplicateButton.aria'),
      iconProps: { iconName: 'Copy' },
      className: clsx(isReader && 'no-display'),
      disabled: models.data[modelId].isCopied,
      onClick: (): void => {
        setAppModal({ contentType: ModelsPopUpTypes.Duplicate, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-details-duplicate/open' });
      },
    },
    // TODO: Add support for model download
    // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/122331
    /**  {
      key: 'download',
      text: t('components.commandBars.model.downloadButton.text'),
      ariaLabel: t('components.commandBars.model.downloadButton.aria'),
      iconProps: { iconName: 'DownloadDocument' },
      disabled: true,
    }, */
  ];

  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => <CommandBarLoader isVisible={models.isUpdating} />,
    },
  ];

  /** TODO: Model details panel
  https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119002
  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'moreInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    },
  ];
  */

  const appDialogContentMap: AppDialogContentMap = {
    [ModelsPopUpTypes.Unpublish]: {
      contentProps: {
        title: t('components.popups.model.unpublish.title'),
        subText: unpublishSubtext,
      },
      confirmationActionProps: {
        text: t('components.popups.model.unpublish.action'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            if (currentRegions != null) {
              const regions = currentRegions.map((item) => ({
                region: item.region,
                isDeployed: false,
                isChanged: item.isDeployed,
              }));
              const formValues: IUpdatePublishFormValues = {
                projectId,
                modelId: Number(modelId),
                modelName,
                modelRegionStatuses: regions,
                nextModelStatus: ModelStatus.Unpublishing,
              };
              appDispatch(updateModelPublishStatus(formValues, PublishOperation.Unpublish));
            }
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const appModalContentMap: AppModalContentMap = {
    [ModelsPopUpTypes.Publish]: {
      contentProps: {
        title: t('components.popups.model.publish.title'),
        body: (
          <UpdatePublishForm
            type={PublishOperation.Publish}
            handleCancelAndClose={(): void => setAppModal({ isOpen: false })}
          />
        ),
      },
      maxWidth: 560,
    },
    [ModelsPopUpTypes.UpdatePublishment]: {
      contentProps: {
        title: t('components.popups.model.updatePublishRegions.title'),
        body: (
          <UpdatePublishForm
            type={PublishOperation.Update}
            handleCancelAndClose={(): void => setAppModal({ isOpen: false })}
          />
        ),
      },
      maxWidth: 560,
    },
    [ModelsPopUpTypes.Swap]: {
      contentProps: {
        title: t('components.popups.model.publish.title'),
        body: <SwapModelForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 560,
    },
    [ModelsPopUpTypes.Duplicate]: {
      contentProps: {
        title: t('components.popups.model.duplicate.title'),
        body: <DuplicateModelForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 560,
    },
  };

  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
      <AppDialog
        showCancelButton={appDialog.showCancelButton}
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={appModalContent?.maxWidth || 700}
      />
    </Fragment>
  );
};

export default ModelCommandBar;
