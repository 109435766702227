import { isEmpty } from 'lodash';

import { ICategoriesState } from '../redux/categories/categoriesReducer';
import { ILanguagesState } from '../redux/languages/languagesReducer';

interface IFilterProjects {
  projectSearchTerm: string;
  selectedCategoryKeys: number[];
  categories: ICategoriesState;
  selectedSourceLanguageKeys: number[];
  languages: ILanguagesState;
  selectedTargetLanguageKeys: number[];
}

const filterProjectsQuery = ({
  projectSearchTerm,
  selectedCategoryKeys,
  categories,
  selectedSourceLanguageKeys,
  languages,
  selectedTargetLanguageKeys,
}: IFilterProjects): string => {
  const filterQuery: string[] = [];
  if (!isEmpty(projectSearchTerm)) {
    filterQuery.push(`substringof(name,'${projectSearchTerm}')`);
  }
  if (!isEmpty(selectedCategoryKeys)) {
    const categoryNames: string[] = [];
    selectedCategoryKeys.forEach((categoryKey) => {
      categoryNames.push(categories.data[categoryKey].name);
    });

    filterQuery.push(`category eq '${categoryNames.join(',')}'`);
  }

  if (!isEmpty(selectedSourceLanguageKeys)) {
    const sourceLanguages: string[] = [];
    selectedSourceLanguageKeys.forEach((languageKey) => {
      sourceLanguages.push(languages[languageKey].languageCode);
    });

    filterQuery.push(`sourceLanguage eq '${sourceLanguages.join(',')}'`);
  }

  if (!isEmpty(selectedTargetLanguageKeys)) {
    const targetLanguages: string[] = [];
    selectedTargetLanguageKeys.forEach((languageKey) => {
      targetLanguages.push(languages[languageKey].languageCode);
    });

    filterQuery.push(`targetLanguage eq '${targetLanguages.join(',')}'`);
  }

  return filterQuery.join(' and ');
};

export default filterProjectsQuery;
