import React from 'react';

const PageHeading: React.FC = ({ children }) => {
  return (
    <h1
      id="page-heading"
      style={{
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        fontSize: 22,
        fontWeight: 600,
        margin: '12px 0',
      }}
    >
      {children}
    </h1>
  );
};

export default PageHeading;
