export const SET_FILTERS_WORKSPACE = 'SET_FILTERS_WORKSPACE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const SET_DOCUMENT_FILTER_NAME = 'SET_DOCUMENT_FILTER_NAME';
export const SET_DOCUMENT_FILTER_TYPES = 'SET_DOCUMENT_FILTER_TYPES';
export const SET_DOCUMENT_FILTER_LANGUAGES = 'SET_DOCUMENT_FILTER_LANGUAGES';
export const SET_DOCUMENT_FILTER_MONOLINGUAL = 'SET_DOCUMENT_FILTER_MONOLINGUAL';

export const SET_PROJECT_FILTER_NAME = 'SET_PROJECT_FILTER_NAME';
export const SET_PROJECT_FILTER_SOURCE_LANGUAGES = 'SET_PROJECT_FILTER_SOURCE_LANGUAGES';
export const SET_PROJECT_FILTER_TARGET_LANGUAGES = 'SET_PROJECT_FILTER_TARGET_LANGUAGES';
export const SET_PROJECT_FILTER_CATEGORIES = 'SET_PROJECT_FILTER_CATEGORIES';

export const SET_MODEL_FILTER_NAME = 'SET_MODEL_FILTER_NAME';
export const SET_MODEL_FILTER_CATEGORY_NAMES = 'SET_MODEL_FILTER_CATEGORY_NAMES';

export const SET_UPLOAD_HISTORY_FILTER_NAME = 'SET_UPLOAD_HISTORY_FILTER_NAME';
export const SET_UPLOAD_HISTORY_FILTER_STATUS = 'SET_UPLOAD_HISTORY_FILTER_STATUS';
export const SET_UPLOAD_HISTORY_FILTER_LANGUAGE = 'SET_UPLOAD_HISTORY_FILTER_LANGUAGE';
export const SET_UPLOAD_HISTORY_FILTER_AFTER = 'SET_UPLOAD_HISTORY_FILTER_AFTER';
export const SET_UPLOAD_HISTORY_FILTER_BEFORE = 'SET_UPLOAD_HISTORY_FILTER_BEFORE';

export type IDocumentSetNameFilterDispatch = {
  type: 'SET_DOCUMENT_FILTER_NAME';
  payload: {
    name: string;
    nameLabel: string;
  };
};

export type IDocumentSetTypesFilterDispatch = {
  type: 'SET_DOCUMENT_FILTER_TYPES';
  payload: {
    types: string[];
    typesLabel: string;
  };
};

interface IFilterKeyLabelItem {
  key: string;
  label: string;
}

export type IDocumentSetLanguagesFilterDispatch = {
  type: 'SET_DOCUMENT_FILTER_LANGUAGES';
  payload: {
    langauges: IFilterKeyLabelItem[];
    languagesLabel: string;
  };
};

export type IDocumentSetMonolingualFilterDispatch = {
  type: 'SET_DOCUMENT_FILTER_MONOLINGUAL';
  payload: {
    monolingual: boolean;
    monolingualLabel: string;
  };
};

export type IProjectSetNameFilterDispatch = {
  type: 'SET_PROJECT_FILTER_NAME';
  payload: {
    name: string;
    nameLabel: string;
  };
};

export type IProjectSetSourceLanguagesFilterDispatch = {
  type: 'SET_PROJECT_FILTER_SOURCE_LANGUAGES';
  payload: {
    sourceLanguages: [];
    sourceLanguagesLabel: string;
  };
};

export type IProjectSetTargetLanguagesFilterDispatch = {
  type: 'SET_PROJECT_FILTER_TARGET_LANGUAGES';
  payload: {
    targetLanguages: [];
    targetLanguagesLabel: string;
  };
};

export type IProjectSetCategorieFilterDispatch = {
  type: 'SET_PROJECT_FILTER_CATEGORIES';
  payload: {
    categories: [];
    categoriesLabel: string;
  };
};

export type IClearFiltersDispatch = {
  type: 'CLEAR_FILTERS';
};

export type IModelSetNameFilterDispatch = {
  type: 'SET_MODEL_FILTER_NAME';
  payload: {
    name: string;
    nameLabel: string;
  };
};

export type IModelSetCategoryNamesFilterDispatch = {
  type: 'SET_MODEL_FILTER_CATEGORY_NAMES';
  payload: {
    categoryNames: string[];
    categoryNamesLabel: string;
  };
};

export type IUploadHistoryNameFilterDispatch = {
  type: 'SET_UPLOAD_HISTORY_FILTER_NAME';
  payload: {
    name: string;
    nameLabel: string;
  };
};

export type IUploadHistoryStatusFilterDispatch = {
  type: 'SET_UPLOAD_HISTORY_FILTER_STATUS';
  payload: {
    status: number;
    statusLabel: string;
  };
};

export type IUploadHistoryLanguageFilterDispatch = {
  type: 'SET_UPLOAD_HISTORY_FILTER_LANGUAGE';
  payload: {
    language: string;
    languageLabel: string;
  };
};

export type IUploadHistoryAfterFilterDispatch = {
  type: 'SET_UPLOAD_HISTORY_FILTER_AFTER';
  payload: {
    after: Date | undefined;
    afterLabel: string;
  };
};

export type IUploadHistoryBeforeFilterDispatch = {
  type: 'SET_UPLOAD_HISTORY_FILTER_BEFORE';
  payload: {
    before: Date | undefined;
    beforeLabel: string;
  };
};

export type IFiltersDispatchTypes =
  | IDocumentSetNameFilterDispatch
  | IDocumentSetTypesFilterDispatch
  | IDocumentSetLanguagesFilterDispatch
  | IDocumentSetMonolingualFilterDispatch
  | IProjectSetNameFilterDispatch
  | IProjectSetSourceLanguagesFilterDispatch
  | IProjectSetTargetLanguagesFilterDispatch
  | IProjectSetCategorieFilterDispatch
  | IClearFiltersDispatch
  | IModelSetNameFilterDispatch
  | IModelSetCategoryNamesFilterDispatch
  | IUploadHistoryNameFilterDispatch
  | IUploadHistoryStatusFilterDispatch
  | IUploadHistoryLanguageFilterDispatch
  | IUploadHistoryAfterFilterDispatch
  | IUploadHistoryBeforeFilterDispatch;

export interface IFiltersState {
  documents: {
    name: string;
    nameLabel: string;
    types: string[];
    typesLabel: string;
    languages: IFilterKeyLabelItem[];
    languagesLabel: string;
    monolingual: boolean;
    monolingualLabel: string;
  };
  projects: {
    name: string;
    nameLabel: string;
    sourceLanguages: number[];
    sourceLanguagesLabel: string;
    targetLanguages: number[];
    targetLanguagesLabel: string;
    categories: number[];
    categoriesLabel: string;
  };
  models: {
    name: string;
    nameLabel: string;
    categoryNames: string[];
    categoryNamesLabel: string;
  };
  uploadHistory: {
    name: string;
    nameLabel: string;
    status: number | null;
    statusLabel: string;
    language: number | string | null;
    languageLabel: string;
    before: Date | undefined;
    beforeLabel: string;
    after: Date | undefined;
    afterLabel: string;
  };
}
