import clsx from 'clsx';
import React from 'react';

import NotificationBase from '../NotificationBase';
import useNotificationBaseStyles from '../NotificationBase/NotificationBase.styles';
import { ICopyNotificationProps } from './CopyingNotification.types';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import { NotificationType } from '../../../utils/constants/notifications';

const CopyingNotification: React.FC<ICopyNotificationProps> = ({ notification }) => {
  const classes = useNotificationBaseStyles();
  const { modelName, targetProjectName, targetWorkspaceName, modelStatus } = notification.data;
  const hasSucceeded = modelStatus === ModelStatus.CopyCompleted;
  const copyStatus = hasSucceeded ? 'copied' : 'copying';

  let icon;
  let cssClass = classes.notificationItem;

  if (hasSucceeded) {
    cssClass = clsx(classes.notificationItem, { success: true });
    icon = {
      name: 'Completed',
      class: clsx(classes.icon, { success: true }),
    };
  }

  const display = {
    title: `${modelName} ${copyStatus} to ${targetProjectName} in ${targetWorkspaceName}`,
    class: cssClass,
    icon,
  };

  return <NotificationBase display={display} id={notification.id} type={NotificationType.Copying} />;
};

export default CopyingNotification;
