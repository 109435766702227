import {
  FETCH_MODELS_VERSION,
  GET_MODELS_VERSION,
  GET_MODELS_VERSION_FAILED,
  GET_MODELS_VERSION_SUCCESSFUL,
  IModelsVersionState,
  MODELS_VERSION_SELECT,
  ModelsVersionDispatchTypes,
} from './modelsVersion.types';

const initialState: IModelsVersionState = {
  isLoading: false,
  failed: false,
  fetch: false,
};

const modelsVersionReducer = (
  state: IModelsVersionState = initialState,
  action: ModelsVersionDispatchTypes
): IModelsVersionState => {
  switch (action.type) {
    case GET_MODELS_VERSION: {
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    }
    case GET_MODELS_VERSION_SUCCESSFUL: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }
    case GET_MODELS_VERSION_FAILED: {
      return {
        ...initialState,
        failed: true,
      };
    }
    case MODELS_VERSION_SELECT: {
      const { modelIds } = action.payload;

      return {
        ...state,
        selectedModels: modelIds,
      };
    }
    case FETCH_MODELS_VERSION: {
      return {
        ...state,
        fetch: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default modelsVersionReducer;
