import { Announced, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import dayjs from 'dayjs';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { RootStore } from '../../../redux/Store';
import ErrorFallback from '../../ErrorFallback';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import { NameGroupCell, TextCell } from '../SharedTableCells';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { capitalize } from '../../../utils/textTransform';

const ModelDocumentsTable: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const match = useRouteMatch<IRoutePathParams>();
  const { models, modelDocuments, languages, files, projects } = useSelector((state: RootStore) => state);
  // Determining paired language
  const currentProject = projects.data[projects.currentProject.id];
  const sourceLanguage = languages[currentProject.languagePair.sourceLanguage];
  const targetLanguage = languages[currentProject.languagePair.targetLanguage];

  const documentsTableColumns: IColumn[] = [
    {
      key: 'documentName',
      name: t('components.tables.sharedColumns.name'),
      minWidth: 200,
      maxWidth: 300,
      onRender: (documentId: number): React.ReactElement => {
        const { name } = modelDocuments[documentId];
        // TODO: Implement inline grid actions
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118981
        return <NameGroupCell link={{ href: `${match.url}/document/${documentId}`, name }} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'uploaded',
      name: t('components.tables.sharedColumns.createdDate'),
      onRender: (documentId: number): React.ReactElement => {
        const { createdDate } = modelDocuments[documentId];

        // TODO: Localize date/time https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119689
        return <TextCell text={dayjs(createdDate).format('MM/DD/YYYY')} />;
      },
      minWidth: 80,
      maxWidth: 130,
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'type',
      name: t('components.tables.sharedColumns.type'),
      minWidth: 80,
      maxWidth: 120,
      onRender: (documentId: number): React.ReactElement => {
        const { documentType } = modelDocuments[documentId];

        return <TextCell text={capitalize(documentType)} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'sourceSentences',
      name: t('components.tables.sharedColumns.sentences', { language: sourceLanguage.displayName }),
      minWidth: 80,
      maxWidth: 160,
      onRender: (documentId: number): React.ReactElement | null => {
        const documentFiles = modelDocuments[documentId].files;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          const fileLanguageCode = languages[file.language].languageCode;
          if (fileLanguageCode === 'en') {
            return <TextCell text={file.extractedSentenceCount.toLocaleString(language)} />;
          }
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'targetSentences',
      name: t('components.tables.sharedColumns.sentences', { language: targetLanguage.displayName }),
      minWidth: 80,
      maxWidth: 215,
      onRender: (documentId: number): React.ReactElement | null => {
        const documentFiles = modelDocuments[documentId].files;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          const fileLanguageCode = languages[file.language].languageCode;
          if (fileLanguageCode !== 'en') {
            return <TextCell text={file.extractedSentenceCount.toLocaleString(language)} />;
          }
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'alignedSentences',
      name: t('components.tables.modelDocuments.columns.alignedSentences'),
      onRender: (documentId: number): React.ReactElement | string => {
        const { alignedSentenceCount } = modelDocuments[documentId];

        return alignedSentenceCount ? <TextCell text={alignedSentenceCount.toLocaleString(language)} /> : '';
      },
      minWidth: 110,
      maxWidth: 125,
      isSorted: false,
      columnActionsMode: 0,
    },
    {
      key: 'usedSentences',
      name: t('components.tables.modelDocuments.columns.usedSentences'),
      onRender: (documentId: number): React.ReactElement | string => {
        const { usedSentenceCount } = modelDocuments[documentId];

        return usedSentenceCount ? <TextCell text={usedSentenceCount.toLocaleString(language)} /> : '';
      },
      minWidth: 110,
      maxWidth: 160,
      isSorted: false,
      columnActionsMode: 0,
    },
  ];

  const items = models.data[match.params.modelId].documents;
  const itemsCount = items.length;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {itemsCount > 0 && (
        <Announced
          id="model-documents-details-list-accounced"
          message={`${itemsCount} ${
            itemsCount === 1
              ? t('components.tables.documents.announcement.name.singular')
              : t('components.tables.documents.announcement.name.plural')
          }`}
        />
      )}
      <div data-test-id="documents-table">
        <DetailsListKeyboardAccessible
          items={items}
          columns={documentsTableColumns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.single}
          ariaLabel={t('components.tables.modelDocuments.tableAria')}
          checkButtonAriaLabel={t('components.tables.modelDocuments.checkButtonAria')}
          ariaLabelForSelectionColumn={t('components.tables.modelDocuments.tableAria')}
        />
      </div>
    </ErrorBoundary>
  );
};

export default ModelDocumentsTable;
