import { PanelsDispatchTypes, SET_ACTIVE_OPEN_PANEL, SET_ACTIVE_PANEL, SET_IS_PANEL_OPEN } from './panels.types';

import { AppBarPanels } from '../../utils/constants/appBarPanels';

interface IPanelsState {
  activePanel: string;
  isPanelOpen: boolean;
}

const initialState: IPanelsState = {
  activePanel: AppBarPanels.Notification,
  isPanelOpen: false,
};

const panelsReducer = (state: IPanelsState = initialState, action: PanelsDispatchTypes): IPanelsState => {
  switch (action.type) {
    case SET_ACTIVE_PANEL: {
      return {
        ...state,
        activePanel: action.payload,
      };
    }
    case SET_IS_PANEL_OPEN: {
      return {
        ...state,
        isPanelOpen: action.payload,
      };
    }
    case SET_ACTIVE_OPEN_PANEL: {
      return {
        isPanelOpen: true,
        activePanel: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default panelsReducer;
