import { ChoiceGroup, Stack, Text } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from 'redux';

import parallelDocumentsImage from '../../../../assets/images/documents/parallel-documents.svg';
import translationMemoryImage from '../../../../assets/images/documents/tm.svg';
import zipImage from '../../../../assets/images/documents/zip.svg';
import { UploadTypes } from '../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import {
  clearDictionaryType,
  setInitialUploadDocumentWizard,
} from '../../../../redux/uploadDocumentsWizard/uploadDocumentsWizardActions';
import WizardActions from '../../Shared/WizardActions';
import DocumentSetLabel from '../DocumentSetLabel';
import DocumentTypeDescription from '../DocumentTypeDescription';
import useUploadDocumentsWizardStyles from '../UploadDocumentsWizard.styles';
import ParallelDocumentsForm from './ParallelDocumentsForm';
import TranslationMemoryForm from './TranslationMemoryForm';
import { IUploadDocumentsSetProps, IUploadDocumentsSetWizardOption } from './UploadDocumentsSet.types';
import ZipForm from './ZipForm';

import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

const UploadDocumentsSet: React.FC<IUploadDocumentsSetProps> = ({ handleCancelAndClose }) => {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const [uploadType, setUploadType] = useState<UploadTypes>(UploadTypes.ParallelDocuments);
  const { dictionaryType } = useAppSelector((state) => state.uploadDocumentsWizard);
  const classes = useUploadDocumentsWizardStyles();

  const handleSetChange = useCallback(
    (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: Partial<IUploadDocumentsSetWizardOption>): void => {
      if (option && option.wizardKey) {
        setUploadType(option.wizardKey);
      }
    },
    []
  );

  const options: IUploadDocumentsSetWizardOption[] = [
    {
      key: UploadTypes.ParallelDocuments,
      wizardKey: UploadTypes.ParallelDocuments,
      text: '',
      onRenderLabel: (): JSX.Element => {
        const isParallelDocumentsSelected = uploadType === UploadTypes.ParallelDocuments;
        return (
          <DocumentSetLabel
            title={t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.label.title')}
            text=""
            body={
              isParallelDocumentsSelected ? (
                <ParallelDocumentsForm handleCancelAndClose={handleCancelAndClose} />
              ) : (
                <Text as="p">
                  {t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.label.text')}
                </Text>
              )
            }
          />
        );
      },
    },
    {
      key: UploadTypes.TranslationMemoryFile,
      wizardKey: UploadTypes.TranslationMemoryFile,
      text: '',
      onRenderLabel: (): JSX.Element => {
        const isTMTypeSelected = uploadType === UploadTypes.TranslationMemoryFile;

        return (
          <DocumentSetLabel
            title={t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.label.title')}
            text=""
            body={
              isTMTypeSelected ? (
                <TranslationMemoryForm handleCancelAndClose={handleCancelAndClose} />
              ) : (
                <Text as="p">
                  {t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.label.text')}
                </Text>
              )
            }
          />
        );
      },
    },
    {
      key: UploadTypes.ZipFiles,
      wizardKey: UploadTypes.ZipFiles,
      text: '',
      onRenderLabel: (): JSX.Element => {
        const isZipFileTypeSelected = uploadType === UploadTypes.ZipFiles;
        return (
          <DocumentSetLabel
            title={t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.label.title')}
            text=""
            body={
              isZipFileTypeSelected ? (
                <ZipForm handleCancelAndClose={handleCancelAndClose} />
              ) : (
                <Text as="p">{t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.label.text')}</Text>
              )
            }
          />
        );
      },
    },
  ];

  const formDescriptionsMap = {
    [UploadTypes.ParallelDocuments]: {
      title: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.description.title'),
      text: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.label.text'),
      image: {
        source: parallelDocumentsImage,
        alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.parallel'),
      },
    },
    [UploadTypes.TranslationMemoryFile]: {
      title: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.description.title'),
      text: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.description.text'),
      image: {
        source: translationMemoryImage,
        alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.tm'),
      },
    },
    [UploadTypes.ZipFiles]: {
      title: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.description.title'),
      text: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.description.text'),
      image: {
        source: zipImage,
        alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.zip'),
      },
    },
  };

  const backButtonAction = dictionaryType ? clearDictionaryType() : setInitialUploadDocumentWizard();

  return (
    <Stack>
      <Stack horizontal verticalAlign="center" className={classes.reflow}>
        {uploadType && (
          <Stack.Item>
            <DocumentTypeDescription
              image={{
                source: formDescriptionsMap[uploadType].image.source,
                alt: formDescriptionsMap[uploadType].image.alt,
              }}
              title={formDescriptionsMap[uploadType].title}
              text={formDescriptionsMap[uploadType].text}
            />
          </Stack.Item>
        )}
        <Stack.Item grow>
          <ChoiceGroup options={options} selectedKey={uploadType} onChange={handleSetChange} />
        </Stack.Item>
      </Stack>
      <Stack.Item>
        <WizardActions
          backButton={{
            onClick: (): Action => appDispatch(backButtonAction),
          }}
          handleCancelAndClose={handleCancelAndClose}
          confirmationButton={{
            text: t('components.forms.uploadDocumentsWizard.upload'),
            onClick: (): void => {},
            form: uploadType,
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default UploadDocumentsSet;
