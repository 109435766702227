export const GET_SUPPORTED_LANGUAGE_PAIRS = 'GET_SUPPORTED_LANGUAGE_PAIRS';
export const GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL = 'GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL';
export const GET_SUPPORTED_LANGUAGE_PAIRS_FAILED = 'GET_SUPPORTED_LANGUAGE_PAIRS_FAILED';
export const SET_SUPPORTED_LANGUAGE_PAIRS = 'SET_SUPPORTED_LANGUAGE_PAIRS';

interface ILanguage {
  id: number;
  displayName: string;
  languageCode: string;
}

export interface ISupportedLanguagePairItem {
  id: number;
  sourceLanguage: ILanguage;
  targetLanguage: ILanguage;
}

export interface ISupportedLanguagePairPayload {
  data: {
    [key: string]: ISupportedLanguagePairItem;
  };
}

export interface ISupportedLanguagePairEntities {
  supportedLanguagePairs: {
    [key: string]: ISupportedLanguagePairItem;
  };
}

export interface IGetSupportedLanguagePairs {
  type: typeof GET_SUPPORTED_LANGUAGE_PAIRS;
}

export interface IGetSupportedLanguagePairsSuccessful {
  type: typeof GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL;
  payload: ISupportedLanguagePairPayload;
}

export interface IGetSupportedLanguagePairsFailed {
  type: typeof GET_SUPPORTED_LANGUAGE_PAIRS_FAILED;
}

export interface ISetSupportedLanguagePairs {
  type: typeof SET_SUPPORTED_LANGUAGE_PAIRS;
  payload: {
    data: {
      [key: string]: ISupportedLanguagePairItem;
    };
  };
}

export type SupportedLanguagePairsDispatchTypes =
  | IGetSupportedLanguagePairs
  | IGetSupportedLanguagePairsSuccessful
  | IGetSupportedLanguagePairsFailed
  | ISetSupportedLanguagePairs;
