import { IStackTokens, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import errorImage from '../../assets/images/features/Error500.svg';
import useErrorFallbackStyles from './ErrorFallback.styles';

const ErrorFallback = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useErrorFallbackStyles();
  const stackTokens: IStackTokens = { childrenGap: 15 };

  return (
    <div className={classes.root}>
      <Stack tokens={stackTokens}>
        <Stack.Item className={classes.item}>
          <img src={errorImage} alt={t('components.errorContent.500.imageAlt')} />
        </Stack.Item>
        <Stack.Item className={classes.item}>
          <Text className={classes.title}>{t('components.errorFallback.title')}</Text>
        </Stack.Item>
        <Stack.Item className={classes.item}>
          <Text className={classes.subText}>{t('components.errorFallback.subText')}</Text>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default ErrorFallback;
