import { IBaseDocumentItem } from '../documents/documents.types';
import { IFilesState } from '../files/filesReducer';
import {
  ADD_DOCUMENT_TO_SELECTION,
  CLEAR_PAGINATED_DOCUMENTS_SELECTION,
  IDictionaryCountItem,
  ITotalCountItem,
  ITrainingCountItem,
  PaginatedDocumentsSelectionDispatchTypes,
  REMOVE_DOCUMENT_FROM_SELECTION,
  UPDATE_COUNT,
} from './paginatedDocumentsSelection.types';

import DocumentTypes from '../../utils/constants/documentTypes';

interface IPaginatedDocumentsSelectionState {
  data: {
    [key: string]: IBaseDocumentItem;
  };
  counts: {
    total: ITotalCountItem;
    training: ITrainingCountItem;
    dictionary: IDictionaryCountItem;
  };
}

const initialState: IPaginatedDocumentsSelectionState = {
  data: {},
  counts: {
    total: { sentence: 0, document: 0, character: 0 },
    training: { sentence: 0 },
    dictionary: { sentence: 0, document: 0 },
  },
};

const getMinSentenceCount = (documentFileIds: number[], files: IFilesState): number => {
  if (files[documentFileIds[0]].extractedSentenceCount < files[documentFileIds[1]].extractedSentenceCount) {
    return files[documentFileIds[0]].extractedSentenceCount;
  }
  return files[documentFileIds[1]].extractedSentenceCount;
};

const paginatedDocumentsSelectionReducer = (
  state: IPaginatedDocumentsSelectionState = initialState,
  action: PaginatedDocumentsSelectionDispatchTypes
): IPaginatedDocumentsSelectionState => {
  switch (action.type) {
    case ADD_DOCUMENT_TO_SELECTION: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    }
    case REMOVE_DOCUMENT_FROM_SELECTION: {
      const filterDocumentsData = (): { [key: number]: IBaseDocumentItem } => {
        const documentsDataArray = Object.entries(state.data);

        const filteredDocumentsData = documentsDataArray.filter(([key]) => {
          return Number(key) !== action.payload;
        });
        return Object.fromEntries(filteredDocumentsData);
      };
      return {
        ...state,
        data: filterDocumentsData(),
      };
    }
    case UPDATE_COUNT: {
      const { document } = action.payload;
      let sentenceCount = getMinSentenceCount(document.files, action.payload.files);
      let { characterCount } = document;
      let documentCount = 1;

      if (!action.payload.isAdding) {
        sentenceCount *= -1;
        characterCount *= -1;
        documentCount *= -1;
      }
      switch (action.payload.document.documentType) {
        case DocumentTypes.PhraseDictionary:
        case DocumentTypes.SentenceDictionary: {
          return {
            ...state,
            counts: {
              dictionary: {
                sentence: state.counts.dictionary.sentence + sentenceCount,
                document: state.counts.dictionary.document + documentCount,
              },
              training: state.counts.training,
              total: {
                sentence: state.counts.total.sentence + sentenceCount,
                document: state.counts.total.document + documentCount,
                character: state.counts.total.character + characterCount,
              },
            },
          };
        }
        case DocumentTypes.Training: {
          return {
            ...state,
            counts: {
              training: {
                sentence: state.counts.training.sentence + sentenceCount,
              },
              dictionary: state.counts.dictionary,
              total: {
                sentence: state.counts.total.sentence + sentenceCount,
                document: state.counts.total.document + documentCount,
                character: state.counts.total.character + characterCount,
              },
            },
          };
        }
        default: {
          return {
            ...state,
            counts: {
              training: state.counts.training,
              dictionary: state.counts.dictionary,
              total: {
                sentence: state.counts.total.sentence + sentenceCount,
                document: state.counts.total.document + documentCount,
                character: state.counts.total.character + characterCount,
              },
            },
          };
        }
      }
    }
    case CLEAR_PAGINATED_DOCUMENTS_SELECTION: {
      return initialState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default paginatedDocumentsSelectionReducer;
