import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import TrainModelContent from '../components/PageContents/TrainModelContent';
import { getSubscription } from '../redux/subscription/subscriptionActions';

import IRoutePathParams from '../utils/constants/routePathParams.types';
import { TrainingTypes } from '../utils/constants/trainingTypes';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import useGetProjectDocuments from '../utils/hooks/useGetProjectDocuments';

const TrainModel: React.FC = () => {
  useTrackMetric(reactPlugin, 'TrainModelPage');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams<IRoutePathParams>();
  const [isDictionaryOnly, setIsDictionaryOnly] = useState<boolean>();
  const {
    subscription,
    trainModel: { trainingType },
    error,
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (subscription.workspaceId !== workspaceId) {
      dispatch(getSubscription(workspaceId));
    }
    if (trainingType === TrainingTypes.DictionaryOnly) {
      setIsDictionaryOnly(true);
    } else {
      setIsDictionaryOnly(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspaceId, trainingType]);
  useGetProjectDocuments(isDictionaryOnly);

  useEffect(() => {
    dispatch({ type: 'CLEAR_TRAIN_MODEL_DATA' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content;
  if (!isEmpty(error.message)) {
    content = <Error />;
  } else {
    content = <TrainModelContent />;
  }
  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('components.appSidebar.trainModel')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default TrainModel;
