import { makeStyles } from '@fluentui/react';

const useModelProgressIndicatorStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    paddingTop: theme.spacing.s1,
  },
  hidden: {
    maxHeight: '0%',
    transition: '0.5s',
    overflow: 'hidden',
  },
  container: {
    borderRight: `2px solid ${theme.palette.neutralLight}`,
    borderBottom: `2px solid ${theme.palette.neutralLight}`,
    borderLeft: `2px solid ${theme.palette.neutralLight}`,
  },
  title: {
    padding: theme.spacing.s2,
    selectors: {
      '&.success': {
        backgroundColor: theme.semanticColors.successBackground,
      },
      '&.neutral': {
        backgroundColor: theme.palette.neutralLight,
      },
      '&.failed': {
        backgroundColor: theme.semanticColors.errorBackground,
      },
    },
  },
  icon: {
    color: theme.palette.green,
    padding: theme.spacing.s2,
    selectors: {
      '&.success': {
        color: theme.palette.green,
      },
      '&.neutral': {
        color: theme.palette.neutralLight,
      },
      '&.failed': {
        color: theme.palette.red,
      },
    },
  },
  costSection: {
    width: '120px',
    textAlign: 'center',
    paddingTop: theme.spacing.s1,
  },
  cost: {
    padding: theme.spacing.s2,
    textAlign: 'center',
    fontWeight: 'bold',
    selectors: {
      '&.free': {
        color: theme.palette.green,
        fontSize: 16,
      },
    },
  },
  progressIndicatorSection: {
    alignSelf: 'center',
    paddingBottom: theme.spacing.s2,
  },
  progressIndicator: {
    width: `60%`,
    maxWidth: 360,
  },
  titleText: {
    paddingLeft: theme.spacing.s2,
  },
  iconTooltip: {
    width: 18,
    selectors: {
      '.ms-Icon': {
        color: theme.palette.black,
        fontSize: 14,
        position: 'relative',
        top: 2,
      },
    },
  },
}));

export default useModelProgressIndicatorStyles;
