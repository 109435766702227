import { makeStyles } from '@fluentui/react';

const useErrorStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    alignItems: 'center',
  },
  item: {
    alignSelf: 'center',
  },
  title: {
    display: 'inline-block',
    maxWidth: '700px',
    textAlign: 'center',
    fontSize: '28px',
  },
  subText: {
    display: 'inline-block',
    maxWidth: '700px',
    textAlign: 'center',
    fontSize: '16px',
    alignSelf: 'center',
  },
}));

export default useErrorStyles;
