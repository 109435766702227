import {
  GET_UPLOAD_HISTORY_CONTENT,
  GET_UPLOAD_HISTORY_CONTENT_FAILED,
  GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL,
  IUploadHistoryContentState,
  UploadHistoryContentDispatchTypes,
} from './uploadHistoryContent.types';

const initialState: IUploadHistoryContentState = {
  isLoading: false,
  data: null,
};

const uploadHistoryReducer = (
  state: IUploadHistoryContentState = initialState,
  action: UploadHistoryContentDispatchTypes
): IUploadHistoryContentState => {
  switch (action.type) {
    case GET_UPLOAD_HISTORY_CONTENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL: {
      return {
        data: action.payload,
        isLoading: false,
      };
    }
    case GET_UPLOAD_HISTORY_CONTENT_FAILED: {
      return {
        isLoading: false,
        failed: true,
        data: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default uploadHistoryReducer;
