import {
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FAILED,
  DELETE_DOCUMENT_SUCCESSFUL,
  DOWNLOAD_DOCUMENTS,
  DOWNLOAD_DOCUMENTS_FAILED,
  DOWNLOAD_DOCUMENTS_SUCCESSFUL,
  DocumentsDispatchTypes,
  GET_DOCUMENT,
  GET_DOCUMENTS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENTS_SUCCESSFUL,
  GET_DOCUMENT_FAILED,
  GET_DOCUMENT_SUCCESSFUL,
  IDocumentItem,
  SET_DOCUMENTS_FILTERED,
  SET_HAS_NEW_DOCUMENTS,
} from './documents.types';

export interface IDocumentsState {
  isUpdating: boolean;
  isLoading: boolean;
  isDownloading: boolean;
  hasNewDocuments: boolean;
  ids: string[];
  data: {
    [key: string]: IDocumentItem;
  };
  pageIndex: number;
  totalPageCount: number;
  areDocumentsFiltered: boolean;
}

const initialState: IDocumentsState = {
  isUpdating: false,
  isLoading: false,
  isDownloading: false,
  hasNewDocuments: false,
  ids: [],
  data: {},
  pageIndex: 1,
  totalPageCount: 1,
  areDocumentsFiltered: false,
};

const documentsReducer = (state: IDocumentsState = initialState, action: DocumentsDispatchTypes): IDocumentsState => {
  switch (action.type) {
    case GET_DOCUMENTS: {
      const newPageIndex = action.payload.resetPagination ? initialState.pageIndex : action.payload.pageIndex;
      return {
        ...state,
        ...(action.payload.resetPagination && { totalPageCount: initialState.totalPageCount }),
        pageIndex: newPageIndex,
        isLoading: true,
        hasNewDocuments: false,
        ids: initialState.ids,
        data: initialState.data,
      };
    }
    case DOWNLOAD_DOCUMENTS: {
      return {
        ...state,
        isDownloading: true,
      };
    }
    case DELETE_DOCUMENT: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case GET_DOCUMENT: {
      return {
        ...state,
        isLoading: true,
        ids: initialState.ids,
        data: initialState.data,
        pageIndex: initialState.pageIndex,
        totalPageCount: initialState.totalPageCount,
      };
    }
    case GET_DOCUMENTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        hasNewDocuments: false,
        ids: action.payload.ids,
        data: action.payload.data,
        pageIndex: action.payload.pageIndex,
        totalPageCount: action.payload.totalPageCount,
      };
    }
    case GET_DOCUMENT_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        ids: action.payload.ids,
        data: action.payload.data,
        pageIndex: action.payload.pageIndex,
        totalPageCount: action.payload.totalPageCount,
      };
    }
    case DELETE_DOCUMENT_SUCCESSFUL: {
      const selectedDocumentId = action.payload;
      const filterDocumentData = (): { [key: string]: IDocumentItem } => {
        const documentDataArray = Object.entries(state.data);

        const filterDocumentDataArray = documentDataArray.filter(([key]) => {
          return String(key) !== String(selectedDocumentId);
        });

        return Object.fromEntries(filterDocumentDataArray);
      };
      return {
        ...state,
        ids: state.ids.filter((id) => String(id) !== String(action.payload)),
        data: filterDocumentData(),
        isUpdating: false,
      };
    }
    case GET_DOCUMENTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        hasNewDocuments: false,
      };
    }
    case GET_DOCUMENT_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DOWNLOAD_DOCUMENTS_FAILED:
    case DOWNLOAD_DOCUMENTS_SUCCESSFUL: {
      return {
        ...state,
        isDownloading: false,
      };
    }
    case DELETE_DOCUMENT_FAILED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case SET_DOCUMENTS_FILTERED: {
      return {
        ...state,
        areDocumentsFiltered: action.payload,
      };
    }
    case SET_HAS_NEW_DOCUMENTS: {
      return {
        ...state,
        hasNewDocuments: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default documentsReducer;
