import { FontWeights, makeStyles } from '@fluentui/react';

import components from '../../../utils/constants/components';

const useTestModelResultsTableStyles = makeStyles((theme) => ({
  root: {
    selectors: {
      '.ms-DetailsRow-fields': {
        alignItems: 'flex-start',
      },
    },
  },
  container: {
    position: 'relative',
    height: `calc(100vh - ${components.ScrollableDetailsList.testModelResultsNonScrollableContent}px)`,
    minHeight: '240px',
  },
  targetLanguageList: {
    selectors: {
      li: {
        marginBottom: theme.spacing.s1,
      },
      'li span': {
        width: 85,
      },
      'li p': {
        whiteSpace: 'break-spaces',
      },
      'li:last-child': {
        marginBottom: 0,
      },
      'li:nth-child(2) span, li:nth-child(2) p': {
        fontWeight: FontWeights.bold,
      },
    },
  },
}));

export default useTestModelResultsTableStyles;
