import React from 'react';

import { IModelRegionStatusItem } from '../../../../redux/models/models.types';
import TextCell from '../TextCell/TextCell';
import PublishedStatus from './PublishedStatus/PublishedStatus';
import TrainingStatus from './TrainingStatus/TrainingStatus';

import { ModelStatus } from '../../../../utils/constants/modelStatus';
import { capitalize } from '../../../../utils/textTransform';

interface IStatusCellProps {
  publishStatusRegion?: IModelRegionStatusItem[] | null;
  /** Will match value to ModelStatus enums for publish or training.
   * If no match, passes status through */
  status: string;
  /** Optional: default=true, displays published badge with model regions. */
  showDeploymentStatus?: boolean;
  /** Optional: default=true, displays Training[Succeed,Failed,Running] specific status UIs or text. */
  showTrainingStatus?: boolean;
  /** Optional: default=true, displays any text status strings that pass through Published or Training conditionals.
   * If false will be null for pass through statuses.
   * Can be used in conjunction with showDeploymentStatus or showTrainingStatus
   */
  showNonMatchingStatuses?: boolean;
}

/** Returns Published (deployed) statuses, training statues, string or null. */
const StatusCell: React.FC<IStatusCellProps> = ({
  publishStatusRegion,
  status,
  showDeploymentStatus = true,
  showTrainingStatus = true,
  showNonMatchingStatuses = true,
}) => {
  if (status === ModelStatus.Published && publishStatusRegion) {
    return showDeploymentStatus ? <PublishedStatus publishStatusRegion={publishStatusRegion} /> : null;
  }

  const trainingStatuses: string[] = [
    ModelStatus.DataProcessingFailed,
    ModelStatus.PublishFailed,
    ModelStatus.TrainingSucceeded,
    ModelStatus.TrainingFailed,
    ModelStatus.TrainingRunning,
  ];

  if (showTrainingStatus && trainingStatuses.includes(status)) {
    return <TrainingStatus status={status} />;
  }

  if (!showNonMatchingStatuses) {
    return null;
  }

  return <TextCell text={capitalize(status)} />;
};

export default StatusCell;
