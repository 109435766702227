import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ADD_NOTIFICATION, NotificationsDispatchTypes } from '../notifications/notifications.types';
import {
  ADD_DEPLOYMENT_NOTIFICATION,
  CLEAR_DEPLOYMENT_NOTIFICATION,
  DeploymentNotificationsDispatchTypes,
  IAddDeploymentNotificationPayload,
  IUpdateDeploymentNotificationPayload,
  UPDATE_DEPLOYMENT_NOTIFICATION,
} from './deploymentNotifications.types';

import { NotificationType } from '../../utils/constants/notifications';

export const addDeploymentNotification = (payload: IAddDeploymentNotificationPayload) => (
  dispatch: Dispatch<DeploymentNotificationsDispatchTypes | NotificationsDispatchTypes>
): void => {
  return batch(() => {
    dispatch({
      type: ADD_DEPLOYMENT_NOTIFICATION,
      payload,
    });
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        id: payload.id,
        type: NotificationType.Deployment,
      },
    });
  });
};

export const updateDeploymentNotification = (payload: IUpdateDeploymentNotificationPayload) => (
  dispatch: Dispatch<DeploymentNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: UPDATE_DEPLOYMENT_NOTIFICATION,
    payload,
  });
};

// TODO: Should remove user from SignalR group as well.
// https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/123536
export const clearDeploymentNotification = (id: string) => (
  dispatch: Dispatch<DeploymentNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: CLEAR_DEPLOYMENT_NOTIFICATION,
    payload: id,
  });
};
