import { makeStyles } from '@fluentui/react';

const useLoadingStyles = makeStyles(() => ({
  root: {
    selectors: {
      '&.--full-height': {
        height: '100%',
      },
    },
  },
}));

export default useLoadingStyles;
