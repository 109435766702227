import { CLEAR_CURRENT_USER, CurrentUserDispatchTypes, SET_CURRENT_USER } from './currentUser.types';

interface ICurrentUserState {
  username: string;
  email: string;
  msalId: string;
}

const initialState: ICurrentUserState = {
  username: '',
  email: '',
  msalId: '',
};

const currentUserReducer = (
  state: ICurrentUserState = initialState,
  action: CurrentUserDispatchTypes
): ICurrentUserState => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      return {
        username: action.payload.username,
        email: action.payload.email,
        msalId: action.payload.msalId,
      };
    }
    case CLEAR_CURRENT_USER: {
      return {
        username: initialState.username,
        email: initialState.email,
        msalId: initialState.msalId,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default currentUserReducer;
