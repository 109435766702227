import {
  DOWNLOAD_MODEL_TEST_RESULTS,
  DOWNLOAD_MODEL_TEST_RESULTS_FAILED,
  DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL,
  GET_MODEL_TEST_RESULTS,
  GET_MODEL_TEST_RESULTS_FAILED,
  GET_MODEL_TEST_RESULTS_SUCCESSFUL,
  ITestResultsItem,
  ModelTestResultsDispatchTypes,
} from './modelTestResults.types';

interface IModelsState {
  isLoading: boolean;
  isDownloading: boolean;
  data: ITestResultsItem[];
  gridError: string;
}

const initialState: IModelsState = {
  isLoading: false,
  isDownloading: false,
  data: [],
  gridError: '',
};

const projectsReducer = (state: IModelsState = initialState, action: ModelTestResultsDispatchTypes): IModelsState => {
  switch (action.type) {
    case GET_MODEL_TEST_RESULTS: {
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
        gridError: initialState.gridError,
      };
    }
    case DOWNLOAD_MODEL_TEST_RESULTS: {
      return {
        ...state,
        isDownloading: true,
      };
    }
    case GET_MODEL_TEST_RESULTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.testEntries,
      };
    }
    case GET_MODEL_TEST_RESULTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        gridError: action.payload,
      };
    }
    case DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL:
    case DOWNLOAD_MODEL_TEST_RESULTS_FAILED: {
      return {
        ...state,
        isDownloading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default projectsReducer;
