import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import {
  ADD_NOTIFICATION,
  NotificationsDispatchTypes,
  REPLACE_NOTIFICATION_ID,
} from '../notifications/notifications.types';
import {
  ADD_UPLOAD_NOTIFICATION,
  CLEAR_UPLOAD_NOTIFICATION,
  IAddUploadNotificationPayload,
  IUpdateExtractedFileUploadNotificationPayload,
  IUpdateJobIdUploadNotificationPayload,
  IUpdateUploadNotificationPayload,
  UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION,
  UPDATE_JOBID_UPLOAD_NOTIFICATION,
  UPDATE_UPLOAD_NOTIFICATION,
  UploadNotificationsDispatchTypes,
} from './uploadNotifications.types';

import { NotificationType } from '../../utils/constants/notifications';

export const addUploadNotification = (payload: IAddUploadNotificationPayload) => (
  dispatch: Dispatch<UploadNotificationsDispatchTypes | NotificationsDispatchTypes>
): void => {
  return batch(() => {
    dispatch({
      type: ADD_UPLOAD_NOTIFICATION,
      payload,
    });
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        id: payload.id,
        type: NotificationType.Upload,
      },
    });
  });
};

export const updateUploadNotification = (payload: IUpdateUploadNotificationPayload) => (
  dispatch: Dispatch<UploadNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: UPDATE_UPLOAD_NOTIFICATION,
    payload,
  });
};

export const addExtractedFileToUploadNotification = (payload: IUpdateExtractedFileUploadNotificationPayload) => (
  dispatch: Dispatch<UploadNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION,
    payload,
  });
};

export const updateUploadNotificationJobId = (payload: IUpdateJobIdUploadNotificationPayload) => (
  dispatch: Dispatch<UploadNotificationsDispatchTypes | NotificationsDispatchTypes>
): void => {
  return batch(() => {
    dispatch({
      type: UPDATE_JOBID_UPLOAD_NOTIFICATION,
      payload,
    });
    dispatch({
      type: REPLACE_NOTIFICATION_ID,
      payload,
    });
  });
};

// TODO: Should remove user from SignalR group as well.
// https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/123536
export const clearUploadNotification = (id: string) => (
  dispatch: Dispatch<UploadNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: CLEAR_UPLOAD_NOTIFICATION,
    payload: id,
  });
};
