import {
  DocumentContentDispatchTypes,
  GET_DOCUMENT_CONTENT,
  GET_DOCUMENT_CONTENT_FAILED,
  GET_DOCUMENT_CONTENT_SUCCESSFUL,
  IDocumentContentItem,
} from './documentContent.types';

interface IDocumentContentState {
  [key: string]: IDocumentContentItem;
}

const initialState: IDocumentContentItem = {
  isLoading: false,
  data: [],
  pageIndex: 0,
  totalPageCount: {
    sourceTotal: 0,
    targetTotal: 0,
    max: 0,
  },
  error: '',
};

const documentContentReducer = (
  state: IDocumentContentState = {},
  action: DocumentContentDispatchTypes
): IDocumentContentState => {
  switch (action.type) {
    case GET_DOCUMENT_CONTENT: {
      const newPageIndex = action.payload.resetPagination ? initialState.pageIndex : action.payload.pageIndex;
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isLoading: true,
          data: initialState.data,
          error: initialState.error,
          pageIndex: newPageIndex,
          ...(action.payload.resetPagination && {
            totalPageCount: {
              sourceTotal: initialState.totalPageCount.sourceTotal,
              targetTotal: initialState.totalPageCount.targetTotal,
              max: initialState.totalPageCount.max,
            },
          }),
        },
      };
    }
    case GET_DOCUMENT_CONTENT_SUCCESSFUL: {
      const { id, data, pageIndex, totalPageCount } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isLoading: false,
          data,
          pageIndex,
          totalPageCount,
        },
      };
    }
    case GET_DOCUMENT_CONTENT_FAILED: {
      const { id, error } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isLoading: false,
          error,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default documentContentReducer;
