import { Theme, makeStyles } from '@fluentui/react';

import components from '../../utils/constants/components';

const usePaginationStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    selectors: {
      '.ms-Dropdown-title': {
        borderColor: theme.palette.neutralTertiary,
      },
      '.ms-Dropdown-label': {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
      },
    },
  },
  doubleFooter: {
    padding: '20px',
  },
  root: {
    borderTop: `1px solid ${theme.palette.neutralLight}`,
    '@media(min-height: 480px)': {
      position: 'absolute',
      paddingLeft: 20,
    },
    left: `${components.AppSidebar.expandedWidth}px`,
    width: `calc(100% - ${components.AppSidebar.expandedWidth}px - ${components.ContentContainer.rightLeftBorder}px)`,
    '&.collapsed-width': {
      left: `${components.AppSidebar.collapsedWidth}px`,
      width: `calc(100% - ${components.AppSidebar.collapsedWidth}px - ${components.ContentContainer.rightLeftBorder}px)`,
      '@media(max-width: 340px)': {
        width: '100%',
      },
    },
    height: `${components.PaginationFooter.height}px`,
    bottom: 0,
  },
}));

export default usePaginationStyles;
