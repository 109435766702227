import {
  ADD_WORKSPACE,
  CLEAR_CURRENT_WORKSPACE,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_FAILED,
  DELETE_WORKSPACE_SUCCESSFUL,
  GET_WORKSPACES,
  GET_WORKSPACES_FAILED,
  GET_WORKSPACES_SUCCESSFUL,
  ICurrentWorkspaceItem,
  IWorkspaceItem,
  RENAME_WORKSPACE,
  RENAME_WORKSPACE_FAILED,
  RENAME_WORKSPACE_SUCCESSFUL,
  SET_CURRENT_WORKSPACE,
  SET_DEFAULT_WORKSPACE,
  SET_DEFAULT_WORKSPACE_FAILED,
  SET_DEFAULT_WORKSPACE_SUCCESSFUL,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_COMPLETED,
  WorkspacesDispatchTypes,
} from './workspaces.types';

interface IWorkspacesState {
  isLoading: boolean;
  isDeleting: boolean;
  isUpdating: boolean;
  isRenaming: boolean;
  ids: string[];
  data: {
    [key: string]: IWorkspaceItem;
  };
  currentWorkspace: ICurrentWorkspaceItem;
  updatingRoleEmails: string[];
}

const initialState: IWorkspacesState = {
  isLoading: false,
  isDeleting: false,
  isUpdating: false,
  isRenaming: false,
  ids: [],
  data: {},
  currentWorkspace: {} as ICurrentWorkspaceItem,
  updatingRoleEmails: [],
};

const workspacesReducer = (
  state: IWorkspacesState = initialState,
  action: WorkspacesDispatchTypes
): IWorkspacesState => {
  switch (action.type) {
    case GET_WORKSPACES: {
      return {
        ...state,
        isLoading: true,
        ids: initialState.ids,
        data: initialState.data,
      };
    }
    case ADD_WORKSPACE: {
      return {
        ...state,
        ids: [...state.ids, action.payload.id],
        data: {
          ...state.data,
          [action.payload.id]: action.payload.workspace,
        },
      };
    }
    case DELETE_WORKSPACE: {
      return {
        ...state,
        isDeleting: true,
      };
    }
    case RENAME_WORKSPACE: {
      return {
        ...state,
        isRenaming: true,
      };
    }
    case UPDATE_USER_ROLE: {
      return {
        ...state,
        updatingRoleEmails: state.updatingRoleEmails.concat(action.payload),
      };
    }
    case SET_DEFAULT_WORKSPACE: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case UPDATE_USER_ROLE_COMPLETED: {
      return {
        ...state,
        updatingRoleEmails: state.updatingRoleEmails.filter((email) => email !== action.payload),
      };
    }
    case GET_WORKSPACES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        ids: action.payload.ids,
        data: action.payload.data,
      };
    }
    case DELETE_WORKSPACE_SUCCESSFUL: {
      return {
        ...state,
        isDeleting: false,
        ids: action.payload.ids,
        data: action.payload.data,
      };
    }
    case RENAME_WORKSPACE_SUCCESSFUL: {
      return {
        ...state,
        isRenaming: false,
        data: {
          ...state.data,
          [action.payload.workspaceId]: {
            ...state.data[action.payload.workspaceId],
            name: action.payload.workspaceName,
          },
        },
        currentWorkspace: {
          ...state.currentWorkspace,
          name: action.payload.workspaceName,
        },
      };
    }
    case SET_DEFAULT_WORKSPACE_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        ids: action.payload.ids,
        data: action.payload.data,
      };
    }
    case GET_WORKSPACES_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_WORKSPACE_FAILED: {
      return {
        ...state,
        isDeleting: false,
      };
    }
    case RENAME_WORKSPACE_FAILED: {
      return {
        ...state,
        isRenaming: false,
      };
    }
    case SET_DEFAULT_WORKSPACE_FAILED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case SET_CURRENT_WORKSPACE: {
      return {
        ...state,
        currentWorkspace: action.payload,
      };
    }
    case CLEAR_CURRENT_WORKSPACE: {
      return {
        ...state,
        currentWorkspace: initialState.currentWorkspace,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default workspacesReducer;
