import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAILED,
  GET_SUBSCRIPTION_SUCCESSFUL,
  ISubscriptionItem,
  SubscriptionsDispatchTypes,
  UPDATE_SUBSCRIPTION_KEY,
  UPDATE_SUBSCRIPTION_KEY_FAILED,
  UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL,
} from './subscription.types';

interface ISubscriptionsState {
  isLoading: boolean;
  isUpdating: boolean;
  workspaceId: string;
  data: ISubscriptionItem;
}

const initialState: ISubscriptionsState = {
  isLoading: false,
  isUpdating: false,
  workspaceId: '',
  data: {} as ISubscriptionItem,
};

const subscriptionReducer = (
  state: ISubscriptionsState = initialState,
  action: SubscriptionsDispatchTypes
): ISubscriptionsState => {
  switch (action.type) {
    case GET_SUBSCRIPTION: {
      return {
        ...state,
        isLoading: true,
        workspaceId: initialState.workspaceId,
        data: initialState.data,
      };
    }
    case UPDATE_SUBSCRIPTION_KEY: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case GET_SUBSCRIPTION_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        workspaceId: action.payload.workspaceId,
        data: action.payload.data,
      };
    }
    case GET_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        isLoading: false,
        workspaceId: action.payload,
        data: initialState.data,
      };
    }
    case UPDATE_SUBSCRIPTION_KEY_FAILED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        workspaceId: action.payload.workspaceId,
        data: action.payload.data,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default subscriptionReducer;
