import { FontWeights, makeStyles } from '@fluentui/react';

const useUpdatePublishFormStyles = makeStyles((theme) => ({
  checkbox: {
    paddingTop: theme.spacing.s1,
    paddingLeft: theme.spacing.l2,
    paddingBottom: theme.spacing.s1,
  },
  subHeadings: {
    paddingTop: theme.spacing.l1,
    margin: `0 0 ${theme.spacing.s1}`,
    fontWeight: FontWeights.semibold,
    paddingLeft: theme.spacing.l2,
  },
}));

export default useUpdatePublishFormStyles;
