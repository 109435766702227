import axios from 'axios';
import i18next from 'i18next';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ICreateProjectFormValues } from '../../components/Forms/CreateProjectForm/CreateProjectForm.types';
import { IEditProjectFormValues } from '../../components/Forms/EditProjectForm/EditProjectForm.types';
import { CategoriesDispatchTypes, SET_CATEGORIES } from '../categories/categories.types';
import { CLEAR_SELECTED_PROJECT, EntitySelectionDispatchTypes } from '../entitySelections/entitySelections.types';
import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import { LanguagesDispatchTypes, SET_LANGUAGES } from '../languages/languages.types';
import normalizeResponse from '../normalizeResponse';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import { projectSchema, projectsListSchema } from '../schema';
import { TelemetryDispatchTypes } from '../telemetry/telemetry.types';
import { SET_USERS, UsersDispatchTypes } from '../users/users.types';
import {
  CLEAR_CURRENT_PROJECT,
  CREATE_PROJECT,
  CREATE_PROJECT_FAILED,
  CREATE_PROJECT_SUCCESSFUL,
  DELETE_PROJECT,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_SUCCESSFUL,
  GET_PROJECT,
  GET_PROJECTS,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_SUCCESSFUL,
  GET_PROJECT_FAILED,
  GET_PROJECT_SUCCESSFUL,
  ICurrentProjectItem,
  IProjectsEntities,
  ProjectsDispatchTypes,
  SET_CURRENT_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_SUCCESSFUL,
} from './projects.types';

import ErrorCategories from '../../utils/constants/errorCategories';
import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';

export const getProjects = (workspaceId: string, filterQuery?: string) => async (
  dispatch: Dispatch<
    ProjectsDispatchTypes | CategoriesDispatchTypes | LanguagesDispatchTypes | UsersDispatchTypes | ErrorDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: GET_PROJECTS });

    let response;

    if (process.env.REACT_APP_ENV === 'test') {
      const { default: mockData } = await import('../../__test__/fixtures/projects.data.json');

      response = { data: mockData };
    } else {
      response = await axios.get(`${process.env.REACT_APP_API_URL}projects`, {
        params: { workspaceid: workspaceId, $filter: filterQuery },
      });
    }

    const { entities, result } = await normalizeResponse<IProjectsEntities>(response.data.projects, projectsListSchema);

    return batch(() => {
      dispatch({
        type: SET_CATEGORIES,
        payload: {
          data: entities.categories,
        },
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: GET_PROJECTS_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.projects,
        },
      });
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_PROJECTS_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Projects,
        },
      });
    });
  }
};

export const getProject = (projectId: string, silent = false) => async (
  dispatch: Dispatch<
    ProjectsDispatchTypes | CategoriesDispatchTypes | LanguagesDispatchTypes | UsersDispatchTypes | ErrorDispatchTypes
  >
): Promise<void | Action | null> => {
  try {
    if (!silent) {
      dispatch({ type: GET_PROJECT });
    }

    const response = await axios.get(`${process.env.REACT_APP_API_URL}projects/${projectId}`);

    const { entities } = await normalizeResponse<IProjectsEntities>(response.data, projectSchema);

    return batch(() => {
      dispatch({
        type: SET_CATEGORIES,
        payload: {
          data: entities.categories,
        },
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });

      dispatch({
        type: GET_PROJECT_SUCCESSFUL,
        payload: {
          ids: [Object.keys(entities.projects)[0]],
          data: entities.projects,
        },
      });
    });
  } catch (err: any) {
    if (!silent) {
      return batch(() => {
        dispatch({
          type: GET_PROJECT_FAILED,
        });
        dispatch({
          type: SET_ERROR,
          payload: {
            message: err.message,
            statusCode: err.response?.status,
            category: ErrorCategories.Project,
          },
        });
      });
    }
    return null;
  }
};

export const createProject = (projectFormValues: ICreateProjectFormValues, workspaceId: string) => async (
  dispatch: Dispatch<
    | ProjectsDispatchTypes
    | CategoriesDispatchTypes
    | LanguagesDispatchTypes
    | UsersDispatchTypes
    | EntitySelectionDispatchTypes
    | PopUpErrorsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: CREATE_PROJECT });

    const {
      projectName,
      projectDescription,
      projectCategoryDescription,
      projectLabel,
      projectLanguagePairId,
      projectDomainId,
    } = projectFormValues;

    await axios.post(
      `${process.env.REACT_APP_API_URL}projects`,
      {
        name: projectName,
        description: projectDescription,
        categoryDescriptor: projectCategoryDescription,
        label: projectLabel,
        languagePairId: projectLanguagePairId,
        categoryId: projectDomainId,
      },
      { params: { workspaceId } }
    );

    const projectsList = await axios.get(`${process.env.REACT_APP_API_URL}projects`, {
      params: { workspaceid: workspaceId },
    });

    const { entities, result } = await normalizeResponse<IProjectsEntities>(
      projectsList.data.projects,
      projectsListSchema
    );

    return batch(() => {
      dispatch({
        type: SET_CATEGORIES,
        payload: {
          data: entities.categories,
        },
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: CREATE_PROJECT_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.projects,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-create/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.projects.create')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Projects,
      requestType: RequestTypes.Create,
    });

    return batch(() => {
      dispatch({ type: CREATE_PROJECT_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-create/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const updateProject = (projectFormValues: IEditProjectFormValues, selectedProject: string) => async (
  dispatch: Dispatch<
    | ProjectsDispatchTypes
    | CategoriesDispatchTypes
    | LanguagesDispatchTypes
    | UsersDispatchTypes
    | EntitySelectionDispatchTypes
    | PopUpErrorsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: UPDATE_PROJECT });

    const { projectName, projectDescription, projectCategoryDescription, projectLabel } = projectFormValues;

    const response = await axios.put(`${process.env.REACT_APP_API_URL}projects/${selectedProject}`, {
      name: projectName,
      description: projectDescription,
      categoryDescriptor: projectCategoryDescription,
      label: projectLabel,
    });

    const { entities } = await normalizeResponse<IProjectsEntities>(response.data, projectSchema);

    return batch(() => {
      dispatch({
        type: SET_CATEGORIES,
        payload: {
          data: entities.categories,
        },
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: CLEAR_SELECTED_PROJECT,
      });
      dispatch({
        type: UPDATE_PROJECT_SUCCESSFUL,
        payload: {
          ids: [Object.keys(entities.projects)[0]],
          data: entities.projects,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-edit/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.projects.update.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Projects,
      requestType: RequestTypes.Delete,
    });

    return batch(() => {
      dispatch({ type: UPDATE_PROJECT_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-edit/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const deleteProject = (selectedProject: string) => async (
  dispatch: Dispatch<ProjectsDispatchTypes | PopUpErrorsDispatchTypes | TelemetryDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: DELETE_PROJECT });

    await axios.delete(`${process.env.REACT_APP_API_URL}projects/${selectedProject}`);

    return batch(() => {
      dispatch({ type: DELETE_PROJECT_SUCCESSFUL, payload: selectedProject });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-delete/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.projects.delete.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Projects,
      requestType: RequestTypes.Delete,
    });

    return batch(() => {
      dispatch({ type: DELETE_PROJECT_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-delete/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const setCurrentProject = (currentProject: ICurrentProjectItem) => async (
  dispatch: Dispatch<ProjectsDispatchTypes>
): Promise<void | Action> => {
  return batch(() => {
    dispatch({ type: SET_CURRENT_PROJECT, payload: currentProject });
  });
};

export const clearCurrentProject = (): ProjectsDispatchTypes => {
  return {
    type: CLEAR_CURRENT_PROJECT,
  };
};
