import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TrainingProgress from '../../TrainingProgress/TrainingProgress';
import NotificationBase from '../NotificationBase/NotificationBase';
import useNotificationBaseStyles from '../NotificationBase/NotificationBase.styles';
import { ITrainingNotificationProps } from './TrainingNotification.types';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import { NotificationType } from '../../../utils/constants/notifications';
import { TrainingErrorCodes } from '../../../utils/constants/trainingTypes';
import { useAppSelector } from '../../../utils/hooks';
import isModelFullTraining from '../../../utils/isModelFullTraining';

const TrainingNotification: React.FC<ITrainingNotificationProps> = ({ notification }) => {
  const classes = useNotificationBaseStyles();
  const { t } = useTranslation();
  const { models, documents } = useAppSelector((state) => state);
  const [isFullTraining, setIsFullTraining] = React.useState(false);

  const { modelStatus, name, errorCode, id } = notification;
  const model = models.data[id];
  let hoursEstimateToRetrain = '8';

  React.useEffect(() => {
    if (model && model.modelType) setIsFullTraining(isModelFullTraining(model.documents, documents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  hoursEstimateToRetrain = isFullTraining === true ? '48' : '8';

  const successStatus: string[] = [ModelStatus.TrainingSucceeded, ModelStatus.Published];
  const failureStatus: string[] = [
    ModelStatus.DataProcessingFailed,
    ModelStatus.TrainingFailed,
    ModelStatus.PublishFailed,
  ];

  let error = '';
  let cssClass = classes.notificationItem;
  let icon;
  if (successStatus.includes(modelStatus)) {
    cssClass = clsx(classes.notificationItem, { success: true });
    icon = {
      name: 'Completed',
      class: clsx(classes.icon, { success: true }),
    };
  } else if (failureStatus.includes(modelStatus)) {
    cssClass = clsx(classes.notificationItem, { failure: true });
    icon = {
      name: 'ErrorBadge',
      class: clsx(classes.icon, { failure: true }),
    };
    if (errorCode === TrainingErrorCodes.SelectTestSets) {
      error = t('pages.model.contentSection.details.status.errorMessage.lackingUniqueSentences');
    } else {
      error = t('pages.model.contentSection.details.status.errorMessage.defaultWithEmail');
    }
  }

  let title = '';
  let subtitle = '';
  switch (modelStatus) {
    case ModelStatus.DataProcessing:
    case ModelStatus.TrainingSubmitted:
    case ModelStatus.TrainingQueued:
    case ModelStatus.TrainingRunning:
      title = t('components.notificationPanel.training.inProgress', { name });
      subtitle = t('components.notificationPanel.training.inProgressSubtitle', { hours: hoursEstimateToRetrain });
      break;
    case ModelStatus.TrainingSucceeded:
      title = t('components.notificationPanel.training.succeeded', { name });
      break;
    case ModelStatus.TrainingFailed:
    case ModelStatus.DataProcessingFailed:
      title = t('components.notificationPanel.training.failure', { name });
      break;
    default:
      break;
  }

  const display = { title, subtitle, class: cssClass, icon, error };
  return (
    <NotificationBase display={display} id={notification.id} type={NotificationType.Training}>
      <TrainingProgress status={modelStatus} notification />
    </NotificationBase>
  );
};

export default TrainingNotification;
