import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import normalizeResponse from '../normalizeResponse';
import { supportedLanguagePairListSchema } from '../schema';
import {
  GET_SUPPORTED_LANGUAGE_PAIRS,
  GET_SUPPORTED_LANGUAGE_PAIRS_FAILED,
  GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL,
  ISupportedLanguagePairEntities,
  SupportedLanguagePairsDispatchTypes,
} from './supportedLanguagePairs.types';

import ErrorCategories from '../../utils/constants/errorCategories';

const getSupportedLanguagePairs = () => async (
  dispatch: Dispatch<SupportedLanguagePairsDispatchTypes | ErrorDispatchTypes>
): Promise<Action | void> => {
  try {
    dispatch({ type: GET_SUPPORTED_LANGUAGE_PAIRS });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}languages/supportedlanguagepairs`);
    const { entities } = await normalizeResponse<ISupportedLanguagePairEntities>(
      response.data,
      supportedLanguagePairListSchema
    );
    return dispatch({
      type: GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL,
      payload: {
        data: entities.supportedLanguagePairs,
      },
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_SUPPORTED_LANGUAGE_PAIRS_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.SupportedLanguages,
        },
      });
    });
  }
};

export default getSupportedLanguagePairs;
