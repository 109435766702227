import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { createPromise } from 'redux-promise-middleware';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import RootReducer from './RootReducer';

import notificationsMiddleware from '../utils/notificationsMiddleware';

const middleware = applyMiddleware(createPromise(), thunk as ThunkMiddleware, createLogger(), notificationsMiddleware);

const Store = createStore(RootReducer, composeWithDevTools(middleware));

export type RootStore = ReturnType<typeof RootReducer>;
export type AppDispatch = typeof Store.dispatch;

export default Store;
