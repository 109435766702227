export const GET_SUPPORTED_LANGUAGES = 'GET_SUPPORTED_LANGUAGES';

export interface ILanguage {
  id: number;
  displayName: string;
  languageCode: string;
}

export interface ISupportedLanguagesPayload {
  data: {
    [key: string]: ILanguage;
  };
}

export interface ISupportedLanguagesEntities {
  supportedLanguages: {
    [key: string]: ILanguage;
  };
}

export interface IGetSupportedLanguages {
  type: typeof GET_SUPPORTED_LANGUAGES;
  payload: ISupportedLanguagesPayload;
}

export type SupportedLanguagesDispatchTypes = IGetSupportedLanguages;
