import { IDialogContentProps } from '@fluentui/react';
import { useEffect, useState } from 'react';

import { IConfirmationActionProps, IContentProps } from '../../components/AppPopUps/AppModal/AppModal.types';
import {
  DocumentPopUpTypes,
  ModelsPopUpTypes,
  ProjectPopUpTypes,
  TestResultsPopUpTypes,
  WorkspacePopUpTypes,
} from '../constants/popUps.types';

/*
 * Dialogs, simple pop-ups using text and single confirmation action
 */

/** Required in constructing dialog content options */
export type AppDialogContentMap = {
  [key in ProjectPopUpTypes | ModelsPopUpTypes | DocumentPopUpTypes | WorkspacePopUpTypes | TestResultsPopUpTypes]?: {
    contentProps: IDialogContentProps;
    confirmationActionProps: {
      text: string;
      onClick: () => void;
    };
  };
};

interface IAppDialog {
  showCancelButton?: boolean;
  isHidden: boolean;
  contentType?:
    | ProjectPopUpTypes
    | ModelsPopUpTypes
    | DocumentPopUpTypes
    | WorkspacePopUpTypes
    | TestResultsPopUpTypes
    | undefined;
}

interface IAppDialogState {
  appDialog: IAppDialog;
  setAppDialog: React.Dispatch<React.SetStateAction<IAppDialog>>;
}

export const useAppDialog = (): IAppDialogState => {
  const [contentType, setContentType] = useState<
    ProjectPopUpTypes | ModelsPopUpTypes | DocumentPopUpTypes | WorkspacePopUpTypes | TestResultsPopUpTypes | undefined
  >(undefined);

  const [appDialog, setAppDialog] = useState<IAppDialog>({
    showCancelButton: true,
    isHidden: true,
    contentType,
  });

  useEffect(() => {
    if (appDialog.contentType) {
      setContentType(appDialog.contentType);
    }
  }, [appDialog]);

  return { appDialog, setAppDialog };
};

/*
 * Modals, complex pop-ups having customized bodies
 */

interface IAppModal {
  isOpen: boolean;
  contentType?: ProjectPopUpTypes | ModelsPopUpTypes | DocumentPopUpTypes | WorkspacePopUpTypes | undefined;
}

interface IAppModalState {
  appModal: IAppModal;
  setAppModal: React.Dispatch<React.SetStateAction<IAppModal>>;
}

/** Required in constructing modal content options */
export type AppModalContentMap = {
  [key in ProjectPopUpTypes | DocumentPopUpTypes | ModelsPopUpTypes | WorkspacePopUpTypes]?: {
    contentProps: IContentProps;
    maxWidth?: number;
    confirmationActionProps?: IConfirmationActionProps;
  };
};

export const useAppModal = (overrides?: Partial<IAppModal>): IAppModalState => {
  const defaultAppModal: IAppModal = {
    isOpen: false,
    contentType: undefined,
  };

  const [appModal, setAppModal] = useState<IAppModal>({
    ...defaultAppModal,
    ...overrides,
  });

  return { appModal, setAppModal };
};
