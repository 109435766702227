import { IStackTokens, Link, Stack } from '@fluentui/react';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import speechImage from '../../../assets/images/features/speech.png';
import translatorImage from '../../../assets/images/features/translator.png';
import voiceImage from '../../../assets/images/features/voice.png';
import FeatureCard from '../../FeatureCard';
import Hero from '../../Hero';
import NavBar from '../../NavBar';
import useSignedOutContentStyles from './SignedOutContent.styles';

const SignedOutContent: React.FC = () => {
  const { t } = useTranslation();
  const classes = useSignedOutContentStyles();
  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 30,
    padding: 10,
  };
  return (
    <Fragment>
      <Helmet>
        <title>{process.env.REACT_APP_APPLICATION_NAME}</title>
      </Helmet>
      <NavBar />
      <Hero />

      <Stack
        className={classes.featureContainer}
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 40 }}
      >
        <Stack.Item className={classes.feature}>
          <FeatureCard
            image={{
              src: translatorImage,
              alt: t('pages.signedOut.featureSection.feature1.title'),
            }}
            title={t('pages.signedOut.featureSection.feature1.title')}
            description={t('pages.signedOut.featureSection.feature1.description')}
            link={{
              text: t('pages.signedOut.featureSection.feature1.linkText'),
              href: 'https://aka.ms/customtranslatorhelp',
              target: '_blank',
            }}
          />
        </Stack.Item>
        <Stack.Item className={classes.feature}>
          <FeatureCard
            image={{
              src: speechImage,
              alt: t('pages.signedOut.featureSection.feature2.title'),
            }}
            title={t('pages.signedOut.featureSection.feature2.title')}
            description={t('pages.signedOut.featureSection.feature2.description')}
            link={{
              text: t('pages.signedOut.featureSection.feature2.linkText'),
              href: 'https://aka.ms/customspeech',
              target: '_blank',
            }}
          />
        </Stack.Item>
        <Stack.Item className={classes.feature}>
          <FeatureCard
            image={{
              src: voiceImage,
              alt: t('pages.signedOut.featureSection.feature3.title'),
            }}
            title={t('pages.signedOut.featureSection.feature3.title')}
            description={t('pages.signedOut.featureSection.feature3.description')}
            link={{
              text: t('pages.signedOut.featureSection.feature2.linkText'),
              href: 'https://aka.ms/customvoice',
              target: '_blank',
            }}
          />
        </Stack.Item>
      </Stack>
      <Stack className={classes.footer} horizontal horizontalAlign="center" tokens={horizontalGapStackTokens}>
        <Stack.Item align="center">&copy; 2018 Microsoft</Stack.Item>
        <Stack.Item align="center">
          <Link className={classes.link} href="https://aka.ms/translatorsupport">
            {t('pages.signedOut.footerSection.contactUs')}
          </Link>
        </Stack.Item>
        <Stack.Item align="center">
          <Link
            className={classes.link}
            href="https://go.microsoft.com/fwlink/?LinkId=521839"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('pages.signedOut.footerSection.privacyAndCookies')}
          </Link>
        </Stack.Item>
        <Stack.Item align="center">
          <Link className={classes.link} href="https://aka.ms/aistudio/terms" target="_blank" rel="noreferrer noopener">
            {t('pages.signedOut.footerSection.termsOfUse')}
          </Link>
        </Stack.Item>
        <Stack.Item align="center">
          <Link
            className={classes.link}
            href="https://www.microsoft.com/trademarks"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('pages.signedOut.footerSection.trademarks')}
          </Link>
        </Stack.Item>
        <Stack.Item align="center">
          <Link
            className={classes.link}
            href="https://go.microsoft.com/fwlink/?LinkId=698895"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('pages.signedOut.footerSection.codeOfConduct')}
          </Link>
        </Stack.Item>
      </Stack>
    </Fragment>
  );
};

export default SignedOutContent;
