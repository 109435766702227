import { FontSizes, IFontStyles, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import React from 'react';

import useTextCellStyles from './TextCell.styles';

interface ITextCellProps {
  text: string;
  /** Will wrap text if set to true and will not render tooltip, default is false */
  wrapText?: boolean;
  /** Fluent UI Text component variant */
  variant?: keyof IFontStyles;
}

const TextCell: React.FC<ITextCellProps> = ({ text, wrapText = false, variant = 'small' }) => {
  const classes = useTextCellStyles();
  const parentTooltipId = useId('text-tooltip');
  const textCellClass = clsx(!wrapText && classes.root, wrapText && classes.wrapText);
  const textComponent = (
    <p
      style={{
        margin: 0,
        fontSize: variant === 'small' ? FontSizes.small : FontSizes.medium,
      }}
    >
      {text}
    </p>
  );
  let textCellContent;

  if (wrapText) {
    textCellContent = textComponent;
  } else {
    textCellContent = (
      <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={text} id={parentTooltipId}>
        {textComponent}
      </TooltipHost>
    );
  }

  return <div className={textCellClass}>{textCellContent}</div>;
};

export default TextCell;
