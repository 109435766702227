/*
 * Regex does the following:
 * Check if one of the URL query parameters is "page"
 * Characters are case-insensitive
 */
const isUrlPaginationChange = (url: string): boolean => {
  const urlRegEx = new RegExp('page=', 'gi');
  return urlRegEx.test(url);
};

export default isUrlPaginationChange;
