import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  IconButton,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AppSidebarContext from '../AppSidebar/AppSidebar.context';
import './Pagination.css';
import usePaginationStyles from './Pagination.styles';
import IPaginationProps from './Pagination.types';

const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  totalPageCount,
  currentPath,
  isDoubleFooter = false,
}) => {
  const classes = usePaginationStyles();

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const { isAppSidebarCollapsed } = useContext(AppSidebarContext);
  const paginationPositionClass = isDoubleFooter
    ? classes.doubleFooter
    : clsx(classes.root, { 'collapsed-width': isAppSidebarCollapsed });

  const options: IDropdownOption[] = [];
  for (let i = 1; i <= totalPageCount; i += 1) {
    const page = i.toLocaleString(language);
    options.push({ key: page, text: page });
  }

  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 15,
    padding: '20px 0 0 0',
  };

  const innerHorizontalGapStackTokens: IStackTokens = {
    childrenGap: 6,
  };

  return (
    <Stack horizontal tokens={horizontalGapStackTokens} className={paginationPositionClass}>
      <Stack.Item>
        <DefaultButton
          styles={{ root: { borderColor: `${theme.palette.neutralTertiary}` } }}
          iconProps={{ iconName: 'ChevronLeft' }}
          text={t('components.pagination.previous')}
          onClick={(): void => {
            history.push(`${currentPath}?page=${currentPage - 1}`);
          }}
          disabled={currentPage === 1}
          className="pagination-btn-large-screen"
        />
        <IconButton
          iconProps={{ iconName: 'ChevronLeft' }}
          ariaLabel={t('components.pagination.previous')}
          onClick={(): void => {
            history.push(`${currentPath}?page=${currentPage - 1}`);
          }}
          disabled={currentPage === 1}
          className="pagination-btn-small-screen"
        />
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={innerHorizontalGapStackTokens} verticalAlign="center">
          <Stack.Item>
            <Text>{t('components.pagination.page')}</Text>
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              defaultSelectedKey={currentPage.toLocaleString(language)}
              className={classes.dropdown}
              options={options}
              onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
                if (option) {
                  history.push(`${currentPath}?page=${Number(option.key)}`);
                }
              }}
              label={t('components.pagination.page')}
            />
          </Stack.Item>
          <Stack.Item>
            <Text>{t('components.pagination.counter', { total: totalPageCount })}</Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <DefaultButton
          styles={{
            flexContainer: { flexDirection: 'row-reverse' },
            root: { borderColor: `${theme.palette.neutralTertiary}` },
          }}
          iconProps={{ iconName: 'ChevronRight' }}
          text={t('components.pagination.next')}
          onClick={(): void => {
            history.push(`${currentPath}?page=${currentPage + 1}`);
          }}
          disabled={currentPage === totalPageCount}
          className="pagination-btn-large-screen"
        />
        <IconButton
          iconProps={{ iconName: 'ChevronRight' }}
          ariaLabel={t('components.pagination.next')}
          onClick={(): void => {
            history.push(`${currentPath}?page=${currentPage + 1}`);
          }}
          disabled={currentPage === totalPageCount}
          className="pagination-btn-small-screen"
        />
      </Stack.Item>
    </Stack>
  );
};

export default Pagination;
