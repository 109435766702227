import { DirectionalHint, Icon, Shimmer, ShimmerElementType, TooltipHost } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { RootStore } from '../../redux/Store';
import AppSidebarContext from './AppSidebar.context';
import useAppSidebarStyles from './AppSidebar.styles';
import getProjectLinks from './getProjectLinks';

const AppSidebarLinks: React.FC = () => {
  const homeTooltipId = useId('home-tooltip');
  const classes = useAppSidebarStyles();
  const { t } = useTranslation();
  const workspaceUrlMatches = useRouteMatch({
    path: [
      '/workspaces/:workspaceId/projects',
      '/workspaces/:workspaceId/documents',
      '/workspaces/:workspaceId/documents/:documentId',
      '/workspaces/:workspaceId/upload-history',
      '/workspaces/:workspaceId/upload-history/:uploadHistoryId',
    ],
    exact: true,
    strict: false,
  });
  const { workspaces, projects } = useSelector((state: RootStore) => state);
  const { isAppSidebarCollapsed } = useContext(AppSidebarContext);

  const workspacePath = `/workspaces/${workspaces.currentWorkspace.id}`;
  const projectsPath = `/projects/${projects.currentProject.id}/`;

  // Base class for NavLink
  const navLinkClass = clsx(
    classes.sidebarLink,
    isAppSidebarCollapsed ? classes.collapsedSidebarLink : classes.expandedSidebarLink
  );
  // Class for icons inside NavLink when app sidebar is in expanded state
  const navLinkIconClass = clsx(!isAppSidebarCollapsed && classes.expandedSidebarLinkIcon);
  // Class for text inside NavLink when app sidebar is in expanded state
  const navLinkTextClass = clsx(!isAppSidebarCollapsed && classes.expandedSidebarLinkText);
  // Class for the project links, which are nested inside the Workspace links for expanded state
  const nestedLinkListClass = clsx(!isAppSidebarCollapsed && classes.nestedSidebarList);
  // This adds a light unified background to the collapsed icons for a project, including the Workspace name icon
  const projectLinkGroupClass = clsx(navLinkClass, isAppSidebarCollapsed && classes.collapsedSidebarProjectLink);
  // When fetching projects disable currentProject links until they can be verified as not stale
  const projectLinkClass = clsx(projectLinkGroupClass, { '--disabled': projects.isLoading });

  const workspacesLoading = workspaces.isLoading;
  const hasCurrentWorkspace = !isEmpty(workspaces.currentWorkspace);
  const hasCurrentProject = !isEmpty(projects.currentProject);

  let workspaceLinkContent = null;
  let projectLinkContent = null;

  if (hasCurrentProject) {
    const projectLinks = getProjectLinks();
    const currentProjectName = projects.currentProject.name;

    projectLinkContent = (
      <li>
        <ul className={nestedLinkListClass}>
          {!isAppSidebarCollapsed && <li className={classes.nestedSidebarListTitle}>{currentProjectName}</li>}
          {projectLinks.map(({ icon, title, translationKey, partialPathName, isExact }) => {
            const projectLinkTooltipId = `${currentProjectName}-${title}-sidebar-tooltip`;

            return (
              <li key={translationKey}>
                <TooltipHost
                  id={projectLinkTooltipId}
                  directionalHint={DirectionalHint.rightCenter}
                  content={isAppSidebarCollapsed ? t(`components.appSidebar.${translationKey}`) : undefined}
                >
                  <NavLink
                    to={`${workspacePath}${projectsPath}${partialPathName}`}
                    className={projectLinkClass}
                    activeClassName="--active"
                    exact={isExact}
                  >
                    <Icon iconName={icon} className={navLinkIconClass} />
                    {!isAppSidebarCollapsed && (
                      <span className={navLinkTextClass}>{t(`components.appSidebar.${translationKey}`)}</span>
                    )}
                  </NavLink>
                </TooltipHost>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }

  if (!hasCurrentWorkspace && workspacesLoading) {
    workspaceLinkContent = (
      <Shimmer
        className={classes.sidebarLinkLoader}
        shimmerElements={[{ type: ShimmerElementType.line, height: 44 }]}
      />
    );
  } else if (hasCurrentWorkspace) {
    const currentWorkspaceName = workspaces.currentWorkspace.name;

    workspaceLinkContent = (
      <Fragment>
        <li>
          <TooltipHost
            id={`${currentWorkspaceName}-sidebar-tooltip`}
            directionalHint={DirectionalHint.rightCenter}
            content={currentWorkspaceName}
          >
            {/* In collapsed state, the workspace icon is highlighted along with the projects to better identify where the user is so we are using the same class, projectLinkGroupClass */}
            <NavLink
              to={`/workspaces/${workspaces.currentWorkspace.id}/projects`}
              className={projectLinkGroupClass}
              activeClassName="--active"
              isActive={(): boolean => {
                return !!workspaceUrlMatches?.isExact;
              }}
              aria-label={currentWorkspaceName}
            >
              <Icon iconName="MicrosoftTranslatorLogo" className={navLinkIconClass} />
              {!isAppSidebarCollapsed && <span className={navLinkTextClass}>{currentWorkspaceName}</span>}
            </NavLink>
          </TooltipHost>
        </li>
        {projectLinkContent}
        <li>
          <TooltipHost
            id={`${currentWorkspaceName}-sidebar-settings-tooltip`}
            directionalHint={DirectionalHint.rightCenter}
            content={isAppSidebarCollapsed ? t('components.appSidebar.workspaceSettings') : undefined}
          >
            <NavLink
              to={`/workspaces/${workspaces.currentWorkspace.id}/workspace-settings`}
              className={navLinkClass}
              activeClassName="--active"
            >
              <Icon iconName="Settings" className={navLinkIconClass} />
              {!isAppSidebarCollapsed && (
                <span className={navLinkTextClass}>{t('components.appSidebar.workspaceSettings')}</span>
              )}
            </NavLink>
          </TooltipHost>
        </li>
      </Fragment>
    );
  }

  return (
    <ul>
      <li>
        <TooltipHost
          id={homeTooltipId}
          directionalHint={DirectionalHint.rightCenter}
          content={isAppSidebarCollapsed ? t('components.appSidebar.workspaces') : undefined}
        >
          <NavLink to="/workspaces" exact className={navLinkClass} activeClassName="--active">
            <Icon iconName="List" className={navLinkIconClass} />
            {!isAppSidebarCollapsed && (
              <span className={navLinkTextClass}>{t('components.appSidebar.workspaces')}</span>
            )}
          </NavLink>
        </TooltipHost>
      </li>
      {workspaceLinkContent}
    </ul>
  );
};

export default AppSidebarLinks;
