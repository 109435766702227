import { makeStyles } from '@fluentui/react';

const useTextCalloutStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing.s1,
    selectors: {
      title: {
        fontWeight: 600,
      },
      p: {
        margin: 0,
      },
    },
  },
  titleDefault: {
    margin: `0 0 ${theme.spacing.s2}`,
  },
  divider: {
    marginRight: theme.spacing.s2,
  },
  titleHorizontal: {
    margin: `0 ${theme.spacing.s2} 0 0`,
  },
}));

export default useTextCalloutStyles;
