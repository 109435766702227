import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { downloadTestResults } from '../../../redux/modelTestResults/modelTestResultsActions';
import { AppDialog } from '../../AppPopUps';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { TestResultsPopUpTypes } from '../../../utils/constants/popUps.types';
import { AppDialogContentMap, useAppDialog, useAppDispatch, useAppSelector } from '../../../utils/hooks';

const TestModelCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const { appDialog, setAppDialog } = useAppDialog();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const appDispatch = useAppDispatch();
  const {
    entitySelections: { selectedModel },
    modelTestResults,
    models,
  } = useAppSelector((state) => state);

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'view',
      text: t('components.commandBars.testModel.viewButton.text'),
      ariaLabel: t('components.commandBars.testModel.viewButton.aria'),
      iconProps: { iconName: 'TestBeaker' },
      disabled: !selectedModel,
      onClick: (): void => history.push(`${match.url}/${selectedModel}`),
    },
    {
      key: 'testModelResultsDownload',
      text: t('components.commandBars.testModelResults.downloadButton.text'),
      ariaLabel: t('components.commandBars.testModelResults.downloadButton.aria'),
      iconProps: { iconName: 'Download' },
      disabled: !selectedModel,
      onClick: (): void => setAppDialog({ contentType: TestResultsPopUpTypes.Download, isHidden: false }),
    },
  ];

  const rightCommandBarItems: ICommandBarItemProps[] = [
    /** TODO: Model details panel
    https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119002
    { 
      key: 'moreInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    }, */
    {
      key: 'modelFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.models.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(models.testedOrPublishedModelsIds),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.ModelsFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
    {
      key: 'downloading',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader
          isVisible={modelTestResults.isDownloading}
          message={t('components.commandBars.common.downloading')}
        />
      ),
    },
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [TestResultsPopUpTypes.Download]: {
      contentProps: {
        title: t('components.popups.testModel.download.title'),
        subText: t('components.popups.testModel.download.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.download'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(downloadTestResults(selectedModel));
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  return (
    <Fragment>
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
    </Fragment>
  );
};

export default TestModelCommandBar;
