import {
  Announced,
  IColumn,
  Icon,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  SharedColors,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { IUploadHistoryJob } from '../../../redux/uploadHistory/uploadHistory.types';
import ErrorFallback from '../../ErrorFallback';
import { Empty, Loading } from '../../GenericStates';
import { Pagination } from '../../Pagination';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import usePaginatedDetailsListStyles from '../DocumentsTable/DocumentsTable.styles';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import { useAppSelector } from '../../../utils/hooks';

const UploadHistoryTable: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { uploadHistory } = useAppSelector((state) => state);
  const displayPagination = uploadHistory.pageIndex !== 0 && uploadHistory.totalPageCount > 1;
  const classes = usePaginatedDetailsListStyles();
  const currentRoute = useRouteMatch('/workspace/:worksapceId/upload-history');

  const uploadHistoryTableColumns: IColumn[] = [
    {
      key: 'jobName',
      name: t('components.tables.uploadHistory.columns.name'),
      minWidth: 120,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      fieldName: 'name',
      onRender: (item: IUploadHistoryJob): React.ReactElement => (
        <Link
          to={`${match.url}/${item.jobId}`}
          style={{
            color: SharedColors.cyanBlue10,
            fontSize: '14px',
          }}
        >
          {item.name}
        </Link>
      ),
    },
    {
      key: 'status',
      name: t('components.tables.uploadHistory.columns.status'),
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      onRender: (item): React.ReactElement => (
        <span>
          {item.status.id === 255 ? (
            <Icon iconName="Completed" style={{ color: SharedColors.green20 }} />
          ) : (
            <Icon iconName="Error" style={{ color: SharedColors.red20 }} />
          )}{' '}
          {item.status.displayName}
        </span>
      ),
    },
    {
      key: 'uploadDate',
      name: 'Upload date',
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      onRender: (item: IUploadHistoryJob): React.ReactElement => (
        <span>{dayjs(item.createdDate).format('MM/DD/YYYY')}</span>
      ),
    },
    {
      key: 'numberOfFiles',
      name: t('components.tables.uploadHistory.columns.numberOfFiles'),
      minWidth: 100,
      maxWidth: 120,
      columnActionsMode: 0,
      isResizable: true,
      fieldName: 'numberFiles',
    },
    {
      key: 'type',
      name: t('components.tables.uploadHistory.columns.type'),
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      fieldName: 'documentType',
    },
    {
      key: 'languagePair',
      name: t('components.tables.uploadHistory.columns.languagePair'),
      minWidth: 100,
      columnActionsMode: 0,
      isResizable: true,
      onRender: (item: IUploadHistoryJob): React.ReactElement => (
        <span>{item.languages.map((lang) => lang.displayName).join(', ')}</span>
      ),
    },
    {
      key: 'createdBy',
      name: t('components.tables.uploadHistory.columns.createdBy'),
      minWidth: 160,
      columnActionsMode: 0,
      isResizable: true,
      onRender: (item: IUploadHistoryJob): React.ReactElement => (
        <span>{item.createdBy ? item.createdBy.userName : 'N/A'}</span>
      ),
    },
  ];

  const items = uploadHistory.jobs;

  if (!items) {
    return null;
  }

  const itemsCount = items.length;

  const uploadHistoryTableContent = (
    <React.Fragment>
      {uploadHistory.isLoading ? (
        <Loading
          loadingText={t('pages.uploadHistory.loading')}
          spacing={{
            type: SpacingType.padding,
            vertical: Spacing.xxLarge,
            horizontal: Spacing.large,
          }}
        />
      ) : (
        <div className="table-container">
          {!isEmpty(items) && !uploadHistory.isLoading && (
            <React.Fragment>
              <Announced
                id="upload-history-details-list-accounced"
                message={`${itemsCount} ${
                  itemsCount === 1
                    ? t('components.tables.uploadHistory.announcement.name.singular')
                    : t('components.tables.uploadHistory.announcement.name.plural')
                }`}
              />
              <DetailsListKeyboardAccessible
                selectionMode={SelectionMode.none}
                columns={uploadHistoryTableColumns}
                items={uploadHistory.jobs}
              />
            </React.Fragment>
          )}
          {Array.isArray(uploadHistory.jobs) &&
            uploadHistory.jobs.length === 0 &&
            !uploadHistory.isLoading &&
            !uploadHistory.isFiltering && <Empty type={EmptyTypes.Documents} />}

          {Array.isArray(uploadHistory.jobs) &&
            uploadHistory.jobs.length === 0 &&
            !uploadHistory.isLoading &&
            uploadHistory.isFiltering && (
              <Stack horizontalAlign="center">
                <Text>{t('components.forms.filterCommon.noResults')}</Text>
              </Stack>
            )}
        </div>
      )}
    </React.Fragment>
  );

  const documentsContent = displayPagination ? (
    <Stack>
      <StackItem className={classes.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{uploadHistoryTableContent}</ScrollablePane>
      </StackItem>
      <StackItem>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Pagination
            totalPageCount={uploadHistory.totalPageCount}
            currentPage={uploadHistory.pageIndex}
            currentPath={currentRoute ? currentRoute.url : ''}
          />
        </Sticky>
      </StackItem>
    </Stack>
  ) : (
    <div>{uploadHistoryTableContent}</div>
  );

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{documentsContent}</ErrorBoundary>;
};

export default UploadHistoryTable;
