import { IStackTokens, Link, Stack, Text, TextField, useTheme } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { uploadParallelDocumentsSet } from '../../../../../redux/documents/documentsActions';
import { DocumentSetTypes, UploadTypes } from '../../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import { FileInputButton } from '../../../SharedFormElements';
import useSharedUploadFormStyles from '../SharedUploadForm.styles';
import { IUploadDocumentsSetProps } from '../UploadDocumentsSet.types';
import { IParallelDocumentsFormValues } from './ParallelDocumentsForm.types';

import FormValidationLengths from '../../../../../utils/constants/formValidationLengths';
import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';

const ParallelDocumentsForm: React.FC<IUploadDocumentsSetProps> = ({ handleCancelAndClose }) => {
  const classes = useSharedUploadFormStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    languages,
    projects,
    workspaces: { currentWorkspace },
    uploadDocumentsWizard,
  } = useAppSelector((state) => state);

  let sourceLanguage;
  let targetLanguage;

  // If current project is undefined, the information will be pulled from uploadDocumentsWizard slice
  if (uploadDocumentsWizard?.sourceLanguage) {
    sourceLanguage = uploadDocumentsWizard.sourceLanguage;
    targetLanguage = uploadDocumentsWizard.targetLanguage;
  } else {
    const currentProject = projects.data[projects.currentProject.id];
    sourceLanguage = languages[currentProject?.languagePair.sourceLanguage];
    targetLanguage = languages[currentProject?.languagePair.targetLanguage];
  }

  const acceptedFileTypes = '.txt, .html, .htm, .pdf, .docx, .align';
  const fileUploadIds = {
    sourceFileUpload: 'sourceFileUpload',
    targetFileUpload: 'targetFileUpload',
  };

  const initialParallelDocumentFormState = {
    documentSetName: '',
    documentSetType:
      uploadDocumentsWizard.documentSetType === DocumentSetTypes.Dictionary
        ? uploadDocumentsWizard.dictionaryType || ''
        : uploadDocumentsWizard.documentSetType || '',
    sourceFileDetails: {
      name: '',
      languageCode: sourceLanguage?.languageCode || '',
    },
    targetFileDetails: {
      name: '',
      languageCode: targetLanguage?.languageCode || '',
    },
    sourceFileUpload: {} as Blob,
    targetFileUpload: {} as Blob,
  };

  const [formValues, setFormValues] = useState(
    (): IParallelDocumentsFormValues => {
      return initialParallelDocumentFormState;
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    documentSetName: undefined,
    sourceFile: undefined,
    targetFile: undefined,
  });

  const parallelLearnMoreLink =
    'https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/what-are-parallel-documents';

  const largeStackTokens: IStackTokens = {
    childrenGap: theme.spacing.l1,
  };

  const mediumStackTokens: IStackTokens = {
    childrenGap: theme.spacing.m,
  };

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      const validationLengthMap: { [key: string]: number } = {
        documentSetName: FormValidationLengths.MAX_DOCUMENT_SET_NAME_LENGTH,
      };

      const inputName = (event.target as HTMLInputElement).name;
      const inputFile = (event.target as HTMLInputElement).files;

      const isFileInputButtonType = Object.keys(fileUploadIds).includes(inputName);

      // Set inputs for file input buttons
      if (isFileInputButtonType && inputFile) {
        const fileDetails = inputName.includes('source') ? 'sourceFileDetails' : 'targetFileDetails';

        setFormValues({
          ...formValues,
          [inputName]: inputFile[0],
          [fileDetails]: { ...formValues[fileDetails], name: inputFile[0].name },
        });
      } else if (!newValue || newValue?.length <= validationLengthMap[inputName]) {
        // Set inputs for document name
        setFormValues({ ...formValues, [inputName]: newValue || '' });
        if (!newValue) {
          setErrors((prevErrors) => ({ ...prevErrors, documentSetName: t('components.forms.generalErrors.required') }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, documentSetName: undefined }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    let hasError = false;

    if (formValues.documentSetName.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, documentSetName: t('components.forms.generalErrors.required') }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, documentSetName: undefined }));
    }

    if (formValues.sourceFileDetails.name.length === 0 || formValues.sourceFileUpload.size === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, sourceFile: t('components.forms.generalErrors.required') }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, sourceFile: undefined }));
    }

    if (formValues.targetFileDetails.name.length === 0 || formValues.targetFileUpload.size === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, targetFile: t('components.forms.generalErrors.required') }));
      hasError = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, targetFile: undefined }));
    }

    if (!hasError) {
      appDispatch(uploadParallelDocumentsSet(formValues, currentWorkspace.id));
      handleCancelAndClose();
    }
  };

  const unselectSourceFile = (): void => {
    setFormValues({
      ...formValues,
      sourceFileDetails: initialParallelDocumentFormState.sourceFileDetails,
      sourceFileUpload: initialParallelDocumentFormState.sourceFileUpload,
    });
  };

  const unselectTargetFile = (): void => {
    setFormValues({
      ...formValues,
      targetFileDetails: initialParallelDocumentFormState.targetFileDetails,
      targetFileUpload: initialParallelDocumentFormState.targetFileUpload,
    });
  };

  return (
    <Stack className={classes.root} tokens={largeStackTokens}>
      <Stack.Item>
        <Stack tokens={mediumStackTokens}>
          <Stack.Item>
            <Text>
              {t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.form.textLine1')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {`${t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.form.textLine2')} `}
              <Link href={parallelLearnMoreLink} target="_blank" rel="noreferrer noopener">
                {t('components.links.learnMore')}
              </Link>
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>

      <Stack.Item>
        <form id={UploadTypes.ParallelDocuments} className={classes.form} onSubmit={handleSubmit}>
          <Stack tokens={mediumStackTokens}>
            <Stack.Item>
              <TextField
                label={t(
                  'components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.form.textInput.label'
                )}
                placeholder={t(
                  'components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.form.textInput.placeholder'
                )}
                name="documentSetName"
                onChange={handleChange}
                value={formValues.documentSetName}
                errorMessage={errors?.documentSetName}
                required
              />
            </Stack.Item>
            <Stack.Item>
              <FileInputButton
                text={t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.common.fileInputButtonText')}
                label={t(
                  'components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.form.documentSetName.sourceLanguage',
                  {
                    sourceLanguageDisplayName: sourceLanguage?.displayName,
                    sourceLanguageCode: sourceLanguage?.languageCode.toUpperCase(),
                  }
                )}
                inputId={fileUploadIds.sourceFileUpload}
                fileName={formValues.sourceFileDetails.name || ''}
                onChange={handleChange}
                accept={acceptedFileTypes}
                error={errors?.sourceFile}
                unselectFile={unselectSourceFile}
              />
            </Stack.Item>
            <Stack.Item>
              <FileInputButton
                text={t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.common.fileInputButtonText')}
                label={t(
                  'components.forms.uploadDocumentsWizard.uploadDocumentsSet.parallelDocuments.form.documentSetName.targetLanguage',
                  {
                    targetLanguageDisplayName: targetLanguage?.displayName,
                    targetLanguageCode: targetLanguage?.languageCode.toUpperCase(),
                  }
                )}
                inputId={fileUploadIds.targetFileUpload}
                fileName={formValues.targetFileDetails.name || ''}
                onChange={handleChange}
                accept={acceptedFileTypes}
                error={errors?.targetFile}
                unselectFile={unselectTargetFile}
              />
            </Stack.Item>
          </Stack>
        </form>
      </Stack.Item>
    </Stack>
  );
};

export default ParallelDocumentsForm;
