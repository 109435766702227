import { FontWeights, IStackTokens, Icon, Link, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Badge from '../../../Badge';
import BleuScore from '../../../BleuScore';
import { BleuScoreSize } from '../../../BleuScore/BleuScore.types';
import TextCallout from '../../../TextCallout';
import TrainingProgress from '../../../TrainingProgress';
import TrainingStatus from '../../../TrainingStatus';
import useModelDetailsStyles from './ModelDetails.styles';

import { ModelStatus } from '../../../../utils/constants/modelStatus';
import { TrainingErrorCodes } from '../../../../utils/constants/trainingTypes';
import { useAppSelector } from '../../../../utils/hooks';
import timeDifference from '../../../../utils/timeDifference';

interface IModelDetailsProps {
  projectId: string;
  modelId: string;
}

const ModelDetails: React.FC<IModelDetailsProps> = ({ projectId, modelId }) => {
  const classes = useModelDetailsStyles();
  const theme = useTheme();
  const tooltipId = useId('tooltip');
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { models, projects, modelDocuments } = useAppSelector((state) => state);

  const graphListTokens: IStackTokens = { childrenGap: 60 };
  const generatedTestTokens: IStackTokens = { childrenGap: theme.spacing.s1 };
  const sentenceCountTokens: IStackTokens = { childrenGap: theme.spacing.s1 };

  const { apiDomain } = projects.data[projectId];
  const {
    modelStatus,
    baselineBleuScoreCIPunctuated,
    bleuScoreCIPunctuated,
    completionDate,
    createdDate,
    testingSentenceCount,
    trainingSentenceCount,
    tuningSentenceCount,
    phraseDictionarySentenceCount,
    sentenceDictionarySentenceCount,
    documents,
    isCopied,
  } = models.data[modelId];

  const totalTrainingTime = timeDifference(createdDate, completionDate);
  const totalDictionaryCount = phraseDictionarySentenceCount + sentenceDictionarySentenceCount;
  const hasBleuScore = bleuScoreCIPunctuated && baselineBleuScoreCIPunctuated;
  let bleuScoreDelta = 0;
  if (bleuScoreCIPunctuated && baselineBleuScoreCIPunctuated) {
    bleuScoreDelta = bleuScoreCIPunctuated - baselineBleuScoreCIPunctuated;
  }
  const trainingSuccessfulStatuses: string[] = [
    ModelStatus.TrainingSucceeded,
    ModelStatus.Published,
    ModelStatus.Unpublished,
    ModelStatus.Unpublishing,
    ModelStatus.PublishFailed,
    ModelStatus.UpdatingPublishment,
    ModelStatus.CopyCompleted,
  ];
  const trainingInProgressStatutes: string[] = [
    ModelStatus.Unknown,
    ModelStatus.TrainingSubmitted,
    ModelStatus.TrainingQueued,
    ModelStatus.DataProcessing,
    ModelStatus.TrainingRunning,
    ModelStatus.Copying,
  ];
  const trainingFailedStatuses: string[] = [ModelStatus.TrainingFailed, ModelStatus.DataProcessingFailed];
  const errorMessageStatuses: string[] = [...trainingFailedStatuses, ModelStatus.PublishFailed];
  const badgeVisibilityStatusMessages: { [key: string]: string } = {
    [ModelStatus.Published]: `${t('components.tables.projects.columns.publishedColumn')}`,
    [ModelStatus.PublishFailed]: `${t('pages.model.contentSection.details.badge.failedToPublish')}`,
    [ModelStatus.Publishing]: `${t('pages.model.contentSection.details.badge.publishing')}`,
    [ModelStatus.Unpublishing]: `${t('pages.model.contentSection.details.badge.unpublishing')}`,
    [ModelStatus.UpdatingPublishment]: `${t('pages.model.contentSection.details.badge.updatingPublishment')}`,
  };
  const isTrainingInProgress = trainingInProgressStatutes.includes(modelStatus);
  const isTrainingSuccessful = trainingSuccessfulStatuses.includes(modelStatus);
  const isTrainingFailed = trainingFailedStatuses.includes(modelStatus);
  const isModelBadgeVisible = !!badgeVisibilityStatusMessages[modelStatus];
  const modelBadgeStatusMessage = isModelBadgeVisible ? badgeVisibilityStatusMessages[modelStatus] : '';
  const isModelBadgeFailed = modelStatus === ModelStatus.PublishFailed;

  const bleuScoreLink =
    'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/view-model-test-translation#bleu-score';

  const processErrorStatusMessage = (status: string, error: string): JSX.Element => {
    if (status === ModelStatus.TrainingFailed && error === TrainingErrorCodes.SelectTestSets) {
      return <Text>{t('pages.model.contentSection.details.status.errorMessage.lackingUniqueSentences')}</Text>;
    }
    if (status === ModelStatus.PublishFailed) {
      return (
        <Text>
          {t('pages.model.contentSection.details.status.errorMessage.publishFailed')}{' '}
          <Link href="https://aka.ms/translatorsupport">Translator Support</Link>
        </Text>
      );
    }
    return (
      <Text>
        {t('pages.model.contentSection.details.status.errorMessage.default')}{' '}
        <Link href="https://aka.ms/translatorsupport">Translator Support</Link>
      </Text>
    );
  };

  const model = models.data[modelId];

  return (
    <Stack className={classes.root}>
      <Stack horizontal>
        <Stack.Item className={classes.trainingStatus}>
          <TrainingStatus status={isTrainingSuccessful ? ModelStatus.TrainingSucceeded : modelStatus} isSolidIcon />
          {errorMessageStatuses.includes(modelStatus) && processErrorStatusMessage(model.modelStatus, model.errorCode)}
        </Stack.Item>
        <Stack.Item>
          {isModelBadgeVisible && (
            <div className={classes.badge}>
              <Badge
                text={modelBadgeStatusMessage}
                size="large"
                type={isModelBadgeFailed ? 'failure' : undefined}
                icon={isModelBadgeFailed ? <Icon iconName="StatusErrorFull" /> : undefined}
              />
            </div>
          )}
        </Stack.Item>
      </Stack>
      <Stack horizontal tokens={graphListTokens}>
        {bleuScoreCIPunctuated && baselineBleuScoreCIPunctuated && (
          <Stack.Item>
            <Stack>
              <Text variant="medium" styles={{ root: { fontWeight: FontWeights.semibold, marginBottom: 6 } }}>
                {t('pages.model.contentSection.details.bleuScore')}
              </Text>
              <Stack.Item className={classes.bleuScore}>
                <BleuScore
                  fullColor={false}
                  size={BleuScoreSize.Large}
                  bleuScore={bleuScoreCIPunctuated}
                  baselineBleuScore={baselineBleuScoreCIPunctuated}
                />
              </Stack.Item>
              <Text className={classes.bleuScoreDelta} variant="medium">
                {`(${bleuScoreDelta.toFixed(2)})`}
              </Text>
              <TextCallout
                title={`${t('pages.model.contentSection.details.baselineScore')}:`}
                text={`${baselineBleuScoreCIPunctuated}`}
                horizontal
              />
            </Stack>
          </Stack.Item>
        )}
        <Stack.Item>
          <ul>
            {modelStatus === ModelStatus.Draft && (
              <Text>{t('pages.model.contentSection.details.status.draft.text')}</Text>
            )}
            {completionDate && (
              <li>
                <TextCallout
                  title={
                    isTrainingFailed
                      ? `${t('pages.model.contentSection.details.trainingCompleted.failed')}:`
                      : `${t('pages.model.contentSection.details.trainingCompleted.success')}:`
                  }
                  text={`${dayjs(completionDate).format('M/DD/YYYY HH:mmA')}`}
                  horizontal
                />
              </li>
            )}
            {isTrainingSuccessful && !isCopied && (
              <Fragment>
                <li>
                  <TextCallout
                    title={`${t('pages.model.contentSection.details.totalTrainingTime.title')}:`}
                    text={`${
                      totalTrainingTime.days > 0
                        ? `${totalTrainingTime.days} ${t('pages.model.contentSection.details.totalTrainingTime.days')}`
                        : ''
                    } ${totalTrainingTime.hours} ${t('pages.model.contentSection.details.totalTrainingTime.hours')} ${
                      totalTrainingTime.minutes
                    } 
              ${t('pages.model.contentSection.details.totalTrainingTime.minutes')}`}
                    horizontal
                  />
                </li>
                <li>
                  <Stack horizontal tokens={sentenceCountTokens}>
                    <TextCallout
                      title={`${t('pages.model.contentSection.details.sentenceCounts.training')}:`}
                      text={`${trainingSentenceCount.toLocaleString(language)}`}
                      horizontal
                    />
                    <TextCallout
                      title={`${t('pages.model.contentSection.details.sentenceCounts.tuning')}:`}
                      text={`${tuningSentenceCount.toLocaleString(language)}`}
                      horizontal
                    />
                    <TextCallout
                      title={`${t('pages.model.contentSection.details.sentenceCounts.testing')}:`}
                      text={`${testingSentenceCount.toLocaleString(language)}`}
                      horizontal
                    />
                    <TextCallout
                      title={`${t('pages.model.contentSection.details.sentenceCounts.dictionary')}:`}
                      text={`${totalDictionaryCount.toLocaleString(language)}`}
                      horizontal
                    />
                  </Stack>
                </li>
                <li>
                  <Stack horizontal tokens={generatedTestTokens}>
                    <TextCallout
                      title={`${t('pages.model.contentSection.details.systemGeneratedTestSet.title')}:`}
                      text={
                        documents.find(
                          (id) =>
                            modelDocuments[id].documentType.localeCompare('testing', 'en', { sensitivity: 'base' }) ===
                            0
                        )
                          ? t('pages.model.contentSection.details.systemGeneratedTestSet.text.no')
                          : t('pages.model.contentSection.details.systemGeneratedTestSet.text.yes')
                      }
                      horizontal
                    />
                    <TooltipHost
                      content={`${t('pages.model.contentSection.details.systemGeneratedTestSet.tooltip')}`}
                      id={tooltipId}
                      styles={{ root: { margin: `0 ${theme.spacing.s1}` } }}
                    >
                      <Icon iconName="Info" />
                    </TooltipHost>
                  </Stack>
                </li>
              </Fragment>
            )}
            {isTrainingSuccessful && (
              <li>
                <TextCallout
                  title={`${t('pages.model.contentSection.details.categoryId')}:`}
                  text={apiDomain}
                  horizontal
                />
              </li>
            )}
            {isTrainingInProgress && (
              <Fragment>
                <TrainingProgress status={modelStatus} />
                <TextCallout
                  title={`${t('pages.model.contentSection.details.trainingStarted')}:`}
                  text={`${dayjs(createdDate).format('M/DD/YYYY, HH:mm:ss A')}`}
                  horizontal
                />
                <Text>{`${t('pages.model.contentSection.details.trainingInProgress')}`}</Text>
              </Fragment>
            )}
          </ul>
          {hasBleuScore && (
            <Link
              href={bleuScoreLink}
              target="_blank"
              rel="noreferrer noopener"
              style={{ textDecoration: 'underline' }}
            >
              {t('pages.model.contentSection.details.learnMoreLink')}
            </Link>
          )}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default ModelDetails;
