import { useMemo, useRef, useState, useEffect } from 'react';
import {
    CommandBarButton,
    ConstrainMode,
    DetailsListLayoutMode,
    IDetailsList,
    ISearchBoxStyles,
    Link as FabricLink,
    Pivot,
    PivotItem,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    Stack,
    Text,
    PrimaryButton,
    IconButton,
    IShimmerProps,
    Shimmer,
    ShimmerElementType,
    TooltipHost,
    Announced,
    ActionButton,
    ISelection
} from "@fluentui/react";
import _, { isArray, isEmpty } from "lodash";
import { format } from "util";
import { ThemedExternalLinkIcon } from '../Icons'
import { CustomShimmeredDetailsList, ICustomColumnsList } from "../ItemList/ItemList";
import { ColumnSelector, IColumnSelectorItem } from "../ItemList/ColumnSelector";
import { HintButton } from '../HintButton/HintButton';

import { FetchStatusUtil } from '../../../util/common'
import { INTL } from "../../../util/intlUtil";
import { urlLinks } from "../../../util/urlLinks";

import { ResourceLocalizationFormatMessages, AzureLocationMessages } from "../../../clientResources";
import { initializeComponent, withLocalization } from "../../../services/localization";

// Types
import {
  Subscription,
  AzureSubscription,
  AzureRoleDefinitionType,
  TenantInformation,
  SubscriptionViewModel,
  ProvisioningStates
} from "../../../models";

import { Loading } from '../Loading/Loading';
import { Maybe } from '../../../util/typeUtil';
import { defaultTheme } from "../../../themes";
import { JsonViewArea } from '../JsonView/JsonView';
import styled from 'styled-components';

// Component
export interface IShimmerSpanProp extends IShimmerProps {
    enableShimmer?: boolean;
}

export const ShimmerSpan = (props: IShimmerSpanProp): JSX.Element => {
    const defaultShimmerElements = [{ type: ShimmerElementType.line, width: "80px" }];
    if (props.enableShimmer) {
        return (
            <Shimmer styles={{ root: { display: "inline-block" } }} shimmerElements={defaultShimmerElements} {...props} />
        );
    } else {
        return <>{props.children}</>;
    }
};


//  Constants
const PlaceHolder = "--";

export interface ISelectResourceTabProps {
    selectedTenant: Maybe<TenantInformation>;
    selectedSubscription: Maybe<Subscription>;
    subscriptions: Maybe<Subscription[]>;
     /**
     * @description  Use favorites it is mandatory to be passed 
     */
    favoriteResource?: Subscription[];
    azureSubscriptions?: AzureSubscription[];
    onChangeResource: (resource: Subscription) => void;
    createResourceLink: string;
    learnMoreAboutCreatingResourceLink: string;
    studioBuiltInRoleIdsArray: string[];
    hasCreateNewResourceAction?: boolean | false;
    onCreateNewResource?: () => void;
    onFetchItemKey?: (item: SubscriptionViewModel) => Promise<SubscriptionViewModel>;
    onFetchItemKeyError?: (ex: Error) => void;
    onResourceRefresh ?: JSX.Element;
    toggleFavResources?: (id: string) => void;
     /**
     * @description  Use favorites it is mandatory to be passed 
     */
    loading?: boolean,
    percentComplete?: number,
    isFavoritesShown?: boolean,
    isUserLoggedIn?: boolean,
    onSignIn?: () => void,
    signOutPageTop?: JSX.Element,
    showRoleAssignment?: boolean,
    showKeyColumn?: boolean;
    onResourceNav?: (item: SubscriptionViewModel) => void;
    selectedSubscriptionRawJson?: string;
    cognitiveServiceResourceKind?: string;
    hintText?: string;
}

export interface ISelectResourceTabInternalProps{
    onNoResourceClick: ()=>void;
    tenants:TenantInformation[];
}

export const settingItemKeys: {
    directoryItemKey: string;
    subscriptionItemKeys: { itemKey: string; favoriteItemKey: string; allSubscriptionsItemKey: string };
    generalItemKey: string;
} = {
    directoryItemKey: "0",
    subscriptionItemKeys: { itemKey: "1", favoriteItemKey: "1-0", allSubscriptionsItemKey: "1-1" },
    generalItemKey: "2",
};
const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        width: 200,
    },
};

const FavoriteResource = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    .resource-link {
        color: #005a9e;
        margin: auto 0;
        cursor: pointer;
    }
    .resource-text {
        margin: auto 0;
        cursor: pointer;
    }
    .resource-tooltip {
        padding-top: 3px;
        i {
            font-size: 14px;
        }
    }
    .fav-icon {
        opacity: 1;
    }
`

export const ResourceAreaWrapped = (props: ISelectResourceTabProps & ISelectResourceTabInternalProps): JSX.Element => {
    const {
      selectedSubscription: subscription,
      onChangeResource,
      selectedTenant,
      createResourceLink,
      studioBuiltInRoleIdsArray,
      subscriptions,
      tenants,
      onNoResourceClick,
      favoriteResource,
      showRoleAssignment = true,
      showKeyColumn = true,
      hintText,
      cognitiveServiceResourceKind,
      isFavoritesShown,
      isUserLoggedIn = true,
    } = props;

    const [iconEleId] = useState(Math.random().toString());
    const [selectedSubscription, setSelectedSubscription] = useState(subscription);
    const [searchSubscriptionName, setSearchSubscriptionName] = useState("");
    const subscriptionListRef = useRef<IDetailsList&{selection:ISelection}>();
    // favorite
    const defaultPivotItem = favoriteResource?.length ? settingItemKeys.subscriptionItemKeys.favoriteItemKey : settingItemKeys.subscriptionItemKeys.allSubscriptionsItemKey;
    
    const [currentSubscriptionItemKey, setCurrentSubscriptionItemKey] = useState(defaultPivotItem);

    const [announcedMessage, setAnnouncedMessage] = useState<string>("");

    const currentSubscriptionItem = currentSubscriptionItemKey === settingItemKeys.subscriptionItemKeys.favoriteItemKey ? favoriteResource : subscriptions;

    useEffect(() => {
        if(!favoriteResource?.length){
            setCurrentSubscriptionItemKey(settingItemKeys.subscriptionItemKeys.allSubscriptionsItemKey);
        }
    }, [favoriteResource])

    let showHintButton: boolean = true;
    let showTooltip: boolean = false;
    
    if (!cognitiveServiceResourceKind) {
      showHintButton = true;
      showTooltip = false;
    } else {
      showHintButton = selectedSubscription && cognitiveServiceResourceKind === selectedSubscription?.kind;
      showTooltip = selectedSubscription && cognitiveServiceResourceKind !== selectedSubscription?.kind
    }

    const [isJSONViewShow, setIsJSONViewShow] = useState(false)

    // Function
    function getLocalizationMessage(name: string): string {
        const id = name?.replace(/ /g, "").toLowerCase();
        if (id in AzureLocationMessages) return INTL.formatMessage(AzureLocationMessages[id]);
        return name;
    }
    
    function onLinkClickOfResources(currentPivotItem: PivotItem) {
        const itemKey = currentPivotItem.props.itemKey;
        if (itemKey === currentSubscriptionItemKey) return;
        setCurrentSubscriptionItemKey(itemKey);
        onLinkChangeOfSetting();
    }
    function onLinkChangeOfSetting() {
        setSelectedSubscription(subscription);
    }
    function canSwitchResource(): boolean {
        return selectedSubscription && (!subscription || selectedSubscription.id !== subscription.id);
    }
    function hasProvisioningState(actualProvisioningState: string, expectedProvisioningState: ProvisioningStates): boolean {
        return expectedProvisioningState?.toLowerCase() === actualProvisioningState?.toLowerCase();
    }
    function copyKey(item: SubscriptionViewModel) {
        if (item) {
            const selBox = document.createElement("textarea");
            selBox.style.position = "fixed";
            selBox.style.left = "0";
            selBox.style.top = "0";
            selBox.style.opacity = "0";
            selBox.value = item.key1;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand("copy");
            document.body.removeChild(selBox);
            item.keyCopied = true;
            subscriptionListRef.current?.forceUpdate();

            setTimeout(() => {
                item.keyCopied = false;
                subscriptionListRef.current?.forceUpdate();
            }, 2 * 1000);

            const element = document.getElementById(iconEleId);
            if (element) {
                element.focus();
            }
        }
    }

    function showKey(item: SubscriptionViewModel) {
        if (item.key1 && item.key1 !== "") {
            item.showKeyFlag = true;
            subscriptionListRef.current?.forceUpdate();
        } else {
            props.onFetchItemKey(item)?.then((result: Subscription) => {
                if (result) {
                    item.key1 = result.key1;
                    item.key2 = result.key2;

                    item.showKeyFlag = true;
                    subscriptionListRef.current?.forceUpdate();
                }
            }).catch((ex: Error) => props?.onFetchItemKeyError(ex));
        }
    }

    function hideKey(item: SubscriptionViewModel) {
        item.showKeyFlag = false;
        subscriptionListRef.current?.forceUpdate();
    }

    const roleAssignmentText = useMemo((): string => {
        let result = "";
        if (subscription) {
            const azureSubscription = _.find(
                props?.azureSubscriptions,
                item => item.subscriptionId === subscription.azureSubscriptionId
            );

            if (azureSubscription) {
                _.forEach(subscription.roleAssignments, item => {
                    const roleDefinition = _.find(
                        azureSubscription.roleDefinitions,
                        definition => definition.id === item.properties?.roleDefinitionId
                    );
                    if (
                        roleDefinition &&
                        (roleDefinition.properties?.type === AzureRoleDefinitionType.CustomRole ||
                            _.find(
                                studioBuiltInRoleIdsArray,
                                item => item === roleDefinition.id.substring(roleDefinition.id.lastIndexOf("/") + 1)
                            ))
                    ) {
                        const name =
                            roleDefinition.properties?.type === AzureRoleDefinitionType.CustomRole
                                ? `${roleDefinition.properties?.roleName} (${INTL.formatMessage(
                                    ResourceLocalizationFormatMessages.CustomRole
                                )})`
                                : roleDefinition.properties?.roleName;

                        result = result === "" ? name : `${result}, ${name}`;
                    }
                });
            }
        }

        return result === "" ? PlaceHolder : result;
    }, [subscription, props?.azureSubscriptions, studioBuiltInRoleIdsArray]);
    let allResourcesSchema: ICustomColumnsList<SubscriptionViewModel> = [
        {
            name: INTL.formatMessage(ResourceLocalizationFormatMessages.ResourceName),
            key: ResourceLocalizationFormatMessages.ResourceName.defaultMessage,
            minWidth: props.isFavoritesShown ? 300 : 200,
            maxWidth: 400,
            sortKey: item => item.name,
            onRender: (item: SubscriptionViewModel) => {
    
                return (
                    props.isFavoritesShown ?
                    (<FavoriteResource>
                        {(item?.hasLink && props?.onResourceNav) ? (
                            <div id="resource-name" className="resource-link" onClick={() => props?.onResourceNav(item)}>
                                {item.name}
                            </div>
                        ) : (
                            <Stack horizontal>
                                <div id="resource-name" className="resource-text">
                                    {item.name}
                                </div>
                                <TooltipHost content={INTL.formatMessage(ResourceLocalizationFormatMessages.TooltipMessage)}>
                                    <IconButton
                                        iconProps={{ iconName: "info" }}
                                        ariaLabel={INTL.formatMessage(ResourceLocalizationFormatMessages.HintButton)}
                                        className="resource-tooltip"
                                    />
                                </TooltipHost>
                            </Stack>
                        )}
                        <IconButton
                            className="fav-icon"
                            ariaLabel={INTL.formatMessage(ResourceLocalizationFormatMessages.ToggleFavoriteResource)}
                            ariaDescription={
                                item.isFavorite
                                    ? INTL.formatMessage(ResourceLocalizationFormatMessages.FavoriteSelected)
                                    : INTL.formatMessage(ResourceLocalizationFormatMessages.FavoriteUnselected)
                            }
                            iconProps={{
                                className: `${item.isFavorite ? "" : "inactive"}`,
                                iconName: item.isFavorite ? "FavoriteStarFill" : "FavoriteStar"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                
                                const msg = item.isFavorite
                                    ? (INTL.formatMessage(ResourceLocalizationFormatMessages.AddToFavorites, item.name))
                                    : (INTL.formatMessage(ResourceLocalizationFormatMessages.RemoveFromFavorites, item.name));
                                setAnnouncedMessage(msg);
                                props.toggleFavResources(item.id)
                            }}
                        />
                    </FavoriteResource>): (
                    <Stack horizontal verticalAlign="center">
                        <Stack.Item>{item.name}</Stack.Item>
                    </Stack>
                )
                );
            },
            isResizable: true,
            isRowHeader: true,
            isHiddenFromColumnSelector: false

        },
        {
            name: INTL.formatMessage(ResourceLocalizationFormatMessages.AzureSubscription),
            key: ResourceLocalizationFormatMessages.AzureSubscription.defaultMessage,
            minWidth: 200,
            maxWidth: 400,
            sortKey: item => item.azureSubscriptionName,
            onRender: (item: SubscriptionViewModel) => <span>{item.azureSubscriptionName}</span>,
            isResizable: true,
            isHiddenFromColumnSelector: false
        },
        {
            name: INTL.formatMessage(ResourceLocalizationFormatMessages.Region),
            key: ResourceLocalizationFormatMessages.Region.defaultMessage,
            minWidth: 150,
            maxWidth: 300,
            sortKey: item => getLocalizationMessage(item.localeDisplayName),
            onRender: (item: SubscriptionViewModel) => (
                <span>{getLocalizationMessage(item.localeDisplayName)}</span>
            ),
            isResizable: true,
            isHiddenFromColumnSelector: false
        },
        {
            name: INTL.formatMessage(ResourceLocalizationFormatMessages.PricingTier),
            key: ResourceLocalizationFormatMessages.Plan.defaultMessage,
            minWidth: 100,
            maxWidth: 300,
            sortKey: item => item.sku,
            onRender: (item: SubscriptionViewModel) => (
                <span>
                    {item.sku?.toLowerCase().startsWith("s")
                        ? `${INTL.formatMessage(ResourceLocalizationFormatMessages.Standard)} ${item.sku}`
                        : `${INTL.formatMessage(ResourceLocalizationFormatMessages.Free)} ${item.sku}`}
                </span>
            ),
            isResizable: true,
            isHiddenFromColumnSelector: false
        },
        {
            name: INTL.formatMessage(ResourceLocalizationFormatMessages.Endpoint),
            key: ResourceLocalizationFormatMessages.Endpoint.defaultMessage,
            minWidth: props.isFavoritesShown ? 400 : 300,
            maxWidth: 500,
            sortKey: item => item.endpoint,
            onRender: (item: SubscriptionViewModel) => <span>{item.endpoint}</span>,
            isResizable: true,
            isHiddenFromColumnSelector: false
        },
        {
            name: INTL.formatMessage(ResourceLocalizationFormatMessages.Key),
            key: ResourceLocalizationFormatMessages.Key.defaultMessage,
            minWidth: props.isFavoritesShown ? 300 : 400,
            maxWidth: 500,
            sortKey: item => item.key1,
            // onRender: (item: SubscriptionViewModel) => <RevealableResourceKey item={item} />,
            onRender: (item: SubscriptionViewModel) => <Stack horizontal verticalAlign="center">
                <span>{item.showKeyFlag && item.key1 ? item.key1 : "----------------------------------------"}</span>
                <Stack horizontal verticalAlign="center" style={{ marginTop: -6, marginBottom: -6 }}>
                    {!item.showKeyFlag && (
                        <IconButton
                            className="hover-show"
                            iconProps={{ iconName: "View" }}
                            title={INTL.formatMessage(ResourceLocalizationFormatMessages.ShowKey)}
                            ariaLabel="Show key"
                            onClick={() => {
                                showKey(item);
                            }}
                        />
                    )}
                    {item.showKeyFlag && (
                        <IconButton
                            className="hover-show"
                            iconProps={{ iconName: "Hide3" }}
                            title={INTL.formatMessage(ResourceLocalizationFormatMessages.HideKey)}
                            ariaLabel="Hide key"
                            onClick={() => {
                                hideKey(item);
                            }}
                        />
                    )}
                    {item.showKeyFlag && item.key1 && !item.keyCopied && (
                        <IconButton
                            className="hover-show"
                            id={"copyButton" + item.id}
                            iconProps={{ iconName: "Copy" }}
                            title={INTL.formatMessage(ResourceLocalizationFormatMessages.Copy)}
                            ariaLabel="Copy"
                            onClick={() => {
                                copyKey(item);
                            }}
                        />
                    )}
                    {item.keyCopied && (
                        <span className="hover-show">{INTL.formatMessage(ResourceLocalizationFormatMessages.Copied)}</span>
                    )}
                </Stack>
            </Stack>,
            isResizable: true,
            isHiddenFromColumnSelector: false
        },
    ];

    if(!showKeyColumn){
        allResourcesSchema.pop()
    }

    const [tableSchemaCols, setTableSchemaCols] = useState(allResourcesSchema);
    const [columnSelectorCols, setColumnSelectorCols] = useState(getColumnsForColumnSelector(allResourcesSchema));
    const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
    const updateTable = (columns: IColumnSelectorItem[]) => {
        const newTableSchema: any = [];
        const olderTableSchema = tableSchemaCols;

        columns.forEach(column => {
            const tableSchemaCol = olderTableSchema.find(schema => schema.key === column.key);
            tableSchemaCol.isHiddenFromColumnSelector = column.isItemUnselected;
            newTableSchema.push(tableSchemaCol);
        });

        setTableSchemaCols(newTableSchema);
        setColumnSelectorCols(getColumnsForColumnSelector(newTableSchema));
    };

    function getColumnsForColumnSelector(tableSchema: ICustomColumnsList<SubscriptionViewModel>): IColumnSelectorItem[] {
        return tableSchema.map(tableColumn => {
            return {
                name: tableColumn.name,
                key: tableColumn.key,
                isItemUnselected: tableColumn.isHiddenFromColumnSelector
            };
        });
    }

    return (
        <Stack grow>
            <Stack.Item>
                { isUserLoggedIn ? 
                <>
                    {/* title */}
                    <div style={{ marginTop: 18, marginBottom: 18 }}>
                        <p>{INTL.formatMessage(ResourceLocalizationFormatMessages.LearnMoreAboutCreatingResourcesDesc)}</p>
                        <FabricLink href={props.learnMoreAboutCreatingResourceLink} target="_blank">
                            {INTL.formatMessage(ResourceLocalizationFormatMessages.LearnMoreAboutCreatingResourcesInAzure)}
                        </FabricLink>
                    </div>
                    {/* Resource */}
                    <div>
                        <Text style={{ fontWeight: 500 }} variant="mediumPlus">
                            {INTL.formatMessage(ResourceLocalizationFormatMessages.CurrentResource) + " "}
                        </Text>
                        <Text variant="mediumPlus">
                            {format(
                                "%s (%s, %s)",
                                subscription?.name ?? PlaceHolder,
                                getLocalizationMessage(subscription?.localeDisplayName) ?? PlaceHolder,
                                subscription?.sku ?? PlaceHolder
                            )}
                        </Text>
                        {props?.selectedSubscriptionRawJson && <ActionButton
                            onClick={() => { setIsJSONViewShow(true) }}
                            text={INTL.formatMessage(ResourceLocalizationFormatMessages.JSONView)}
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                color: defaultTheme.body.palette.themePrimary,
                            }}
                        />}
                    </div>
                    {/* Subscription */}
                    <div>
                        <Text style={{ fontWeight: 500 }} variant="mediumPlus">
                            {INTL.formatMessage(ResourceLocalizationFormatMessages.CurrentSubscription) + " "}
                        </Text>
                        <Text variant="mediumPlus">{subscription?.azureSubscriptionName ?? PlaceHolder}</Text>
                    </div>
                    {/* Role assignment: */}
                    {showRoleAssignment && <div style={{ marginBottom: "1rem" }}>
                        <Text style={{ fontWeight: 500 }} variant="mediumPlus">
                            {INTL.formatMessage(ResourceLocalizationFormatMessages.CurrentRoleAssignments) + " "}
                        </Text>
                        {subscription && (
                            <>
                                <ShimmerSpan enableShimmer={!FetchStatusUtil.isEnd(subscription.roleAssignmentsFetchStatus)}>
                                    <Text variant="mediumPlus">{roleAssignmentText}</Text>
                                </ShimmerSpan>
                                <FabricLink
                                    href={`${urlLinks.learnMoreAboutAzurePortal()}#@${selectedTenant?.defaultDomain}/resource/${subscription?.azureId
                                        }/users`}
                                    style={{ marginLeft: 25, marginRight: 10 }}
                                    target="_blank"
                                >
                                    {INTL.formatMessage(ResourceLocalizationFormatMessages.ViewLevelOfAccessForThisResource)}
                                </FabricLink>
                                <ThemedExternalLinkIcon />
                            </>
                        )}
                        {!subscription && <Text variant="mediumPlus">--</Text>}
                    </div> }
                </> : (
                    props.signOutPageTop
                )}
                
                 {/* Pivot*/}
                 <Announced message={announcedMessage} aria-live="assertive" />
                <Pivot
                    defaultSelectedKey={currentSubscriptionItemKey}
                    onLinkClick={onLinkClickOfResources}
                    linkSize='large'
                    className='pivot-wrapper'
                >
                    {/* Favorite */}
                    { favoriteResource?.length > 0 && <PivotItem
                        itemKey={settingItemKeys.subscriptionItemKeys.favoriteItemKey}
                        headerText={INTL.formatMessage(ResourceLocalizationFormatMessages.Favorites)}
                    >
                        <Stack horizontal tokens={{ childrenGap: 8 }} style={{ marginTop: 8, marginBottom: 8 }}>
                            <SearchBox
                                styles={searchBoxStyles}
                                value={searchSubscriptionName}
                                placeholder={INTL.formatMessage(ResourceLocalizationFormatMessages.Search)}
                                onChange={(event, text) => {
                                    setSearchSubscriptionName(text ? text : "");
                                }}
                            />
                            {props.hasCreateNewResourceAction ?
                                (<CommandBarButton
                                    style={{ marginLeft: 30 }}
                                    onClick={props.onCreateNewResource}
                                    iconProps={{ iconName: "Add" }}
                                    title={INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                >
                                    {INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                </CommandBarButton>) :
                                (<CommandBarButton
                                    style={{ marginLeft: 30 }}
                                    href={createResourceLink}
                                    iconProps={{ iconName: "Add" }}
                                    title={INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                >
                                    {INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                </CommandBarButton>)
                            }
                            <CommandBarButton
                                iconProps={{ iconName: "Repair" }}
                                title={INTL.formatMessage(ResourceLocalizationFormatMessages.ColumnOptions)}
                                onClick={() => { setIsColumnSelectorOpen(!isColumnSelectorOpen) }}
                            >
                                {INTL.formatMessage(ResourceLocalizationFormatMessages.ColumnOptions)}
                            </CommandBarButton>
                            {props.onResourceRefresh }
                        </Stack>
                    </PivotItem>
                    }
                    {/* all resource */}
                    <PivotItem
                        itemKey={settingItemKeys.subscriptionItemKeys.allSubscriptionsItemKey}
                        headerText={INTL.formatMessage(ResourceLocalizationFormatMessages.AllResources)}
                    >
                        <Stack horizontal tokens={{ childrenGap: 8 }} style={{ marginTop: 8, marginBottom: 8 }}>
                            <SearchBox
                                styles={searchBoxStyles}
                                value={searchSubscriptionName}
                                placeholder={INTL.formatMessage(ResourceLocalizationFormatMessages.Search)}
                                onChange={(event, text) => {
                                    setSearchSubscriptionName(text ? text : "");
                                }}
                            />
                            {props.hasCreateNewResourceAction ?
                                (<CommandBarButton
                                    style={{ marginLeft: 30 }}
                                    onClick={props.onCreateNewResource}
                                    iconProps={{ iconName: "Add" }}
                                    title={INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                >
                                    {INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                </CommandBarButton>) :
                                (<CommandBarButton
                                    style={{ marginLeft: 30 }}
                                    href={createResourceLink}
                                    iconProps={{ iconName: "Add" }}
                                    title={INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                >
                                    {INTL.formatMessage(ResourceLocalizationFormatMessages.CreateAzureResource)}
                                </CommandBarButton>)
                            }
                            <CommandBarButton
                                iconProps={{ iconName: "Repair" }}
                                title={INTL.formatMessage(ResourceLocalizationFormatMessages.ColumnOptions)}
                                onClick={() => { setIsColumnSelectorOpen(!isColumnSelectorOpen) }}
                            >
                                {INTL.formatMessage(ResourceLocalizationFormatMessages.ColumnOptions)}
                            </CommandBarButton>
                            {props.onResourceRefresh }
                        </Stack>
                    </PivotItem>
                </Pivot>
            </Stack.Item>
            <Stack.Item grow>
                {/* Table list */}
                <>
                    {(props.loading && props.percentComplete) ? (
                        <Loading message={(100 * props.percentComplete).toFixed(0) + "%"} />
                        ) : (
                                <ScrollablePane style={{ position: "relative", width: "100%", height: "100%", minHeight: 120, zIndex: isFavoritesShown ? 1 : 'auto' }}>
                                    <ColumnSelector tableColumns={columnSelectorCols} isOpen={isColumnSelectorOpen} onCloseColumnSelector={() => { setIsColumnSelectorOpen(false) }} onChange={updateTable} />
                                    <CustomShimmeredDetailsList<SubscriptionViewModel>
                                        componentRef={subscriptionListRef}
                                        isDefaultTopItem={item => subscription && item.id === subscription.id}
                                        items={_.values(
                                            isArray(currentSubscriptionItem) && currentSubscriptionItem
                                                .filter((item) => {
                                                    const filterBySearch = item.name.toLowerCase().indexOf(searchSubscriptionName ? searchSubscriptionName.toLowerCase() : "") >= 0;
                                                    const filterByProvisioningState = hasProvisioningState(item.provisioningState, ProvisioningStates.Succeeded);
                                                    return filterBySearch && filterByProvisioningState;
                                                }).sort((a, b) => (a.name > b.name ? 1 : -1))
                                        )}
                                        selectedKeys={[selectedSubscription?.id]}
                                        columns={tableSchemaCols}
                                        ariaLabel={INTL.formatMessage(ResourceLocalizationFormatMessages.AllResources)}
                                        checkButtonAriaLabel={INTL.formatMessage(ResourceLocalizationFormatMessages.SelectNow)}
                                        ariaLabelForSelectAllCheckbox={INTL.formatMessage(ResourceLocalizationFormatMessages.ToggleSelectionForAllItems)}
                                        onSelect={items => {
                                            if (!_.isEmpty(items)) {
                                                setSelectedSubscription(_.first(items));
                                            } else {
                                                setSelectedSubscription(undefined);
                                            }
                                        }}
                                        selectionMode={SelectionMode.single}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        constrainMode={ConstrainMode.unconstrained}
                                        onRenderRow={(props, defaultRender) => (
                                            <div className="detailsList-Row-Hover">{defaultRender(props)}</div>
                                        )}
                                        enableShimmer={false}
                                        contextualMenuProps={{
                                            getContextualMenuItems: (items) => [] as any,
                                            contextMenuContainerColumnKey: "",
                                        }}
                                    />
                                    <NoResource
                                        tennats={tenants}
                                        resources={subscriptions}
                                        selectedTennat={selectedTenant}
                                        onNoResourceClick={onNoResourceClick}
                                    ></NoResource>
                                </ScrollablePane>
                            )
                        }
                </>
            </Stack.Item>      
            {/* Footer */}
            <Stack horizontal verticalAlign='center' style={{ marginTop: 16 }}>
                <PrimaryButton
                    disabled={!canSwitchResource() || !showHintButton}
                    onClick={() => {
                        if (canSwitchResource()) { onChangeResource(selectedSubscription) }
                    }}
                    text={INTL.formatMessage(ResourceLocalizationFormatMessages.UseResource)}
                />
                { !isUserLoggedIn ? null : (
                    <>
                    {
                       showTooltip && <HintButton hintText={hintText}/>
                    }
                    <FabricLink
                        href={urlLinks.learnMoreAboutCognitiveServicesSubscription()}
                        style={{ marginLeft: 25, marginRight: 10 }}
                        target="_blank"
                    >
                        {INTL.formatMessage(ResourceLocalizationFormatMessages.ViewAllPropertiesInAzurePortal)}
                    </FabricLink>
                    <ThemedExternalLinkIcon />
                </>
                ) }
                
            </Stack>
            {props?.selectedSubscriptionRawJson && <JsonViewArea
                isOpen={isJSONViewShow}
                onClose={() => { setIsJSONViewShow(false) }}
                jsonObject={props.selectedSubscriptionRawJson}
                resourceName={subscription?.name ?? PlaceHolder}
            />}
        </Stack>
    )
}

const NoResourceWrapper = styled(Stack)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(20px);
    overflow: visible;
    justify-content: center;
    align-items: center;
`
const NoResource: React.FC<{ tennats: TenantInformation[], resources: Maybe<Subscription[]>, selectedTennat: Maybe<TenantInformation>, onNoResourceClick: () => void }> = ({ tennats, resources, selectedTennat, onNoResourceClick }) => {
    if(!isEmpty(resources)) return null;
    if (isEmpty(tennats)) {
        return (
            <NoResourceWrapper>
                <Text variant="medium"> {INTL.formatMessage(ResourceLocalizationFormatMessages.NoResourcesFound)}</Text>
            </NoResourceWrapper>
        )
    } else if (Array.isArray(tennats) && tennats.length === 1 && selectedTennat ) {
        return (
            <NoResourceWrapper>
                <Text variant="medium"> {INTL.formatMessage(ResourceLocalizationFormatMessages.NoResourceInDirectory)}</Text>
            </NoResourceWrapper>
        )      
    } else {
        return (
            <NoResourceWrapper>
                <Text variant="medium"> {INTL.formatMessage(ResourceLocalizationFormatMessages.NoResourceInSelectedDirectory)}</Text>
                <FabricLink onClick={onNoResourceClick}> {INTL.formatMessage(ResourceLocalizationFormatMessages.SwitchDirectory)}</FabricLink>
            </NoResourceWrapper>
        );
    }
}

export const SelectResourceTab = withLocalization(initializeComponent(ResourceAreaWrapped));
