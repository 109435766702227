import {
  CLEAR_FILTERS,
  IFiltersDispatchTypes,
  IFiltersState,
  SET_DOCUMENT_FILTER_LANGUAGES,
  SET_DOCUMENT_FILTER_MONOLINGUAL,
  SET_DOCUMENT_FILTER_NAME,
  SET_DOCUMENT_FILTER_TYPES,
  SET_MODEL_FILTER_CATEGORY_NAMES,
  SET_MODEL_FILTER_NAME,
  SET_PROJECT_FILTER_CATEGORIES,
  SET_PROJECT_FILTER_NAME,
  SET_PROJECT_FILTER_SOURCE_LANGUAGES,
  SET_PROJECT_FILTER_TARGET_LANGUAGES,
  SET_UPLOAD_HISTORY_FILTER_AFTER,
  SET_UPLOAD_HISTORY_FILTER_BEFORE,
  SET_UPLOAD_HISTORY_FILTER_LANGUAGE,
  SET_UPLOAD_HISTORY_FILTER_NAME,
  SET_UPLOAD_HISTORY_FILTER_STATUS,
} from './filters.types';

const initialState: IFiltersState = {
  documents: {
    name: '',
    nameLabel: '',
    types: [],
    typesLabel: '',
    languages: [],
    languagesLabel: '',
    monolingual: false,
    monolingualLabel: '',
  },
  projects: {
    name: '',
    nameLabel: '',
    sourceLanguages: [],
    sourceLanguagesLabel: '',
    targetLanguages: [],
    targetLanguagesLabel: '',
    categories: [],
    categoriesLabel: '',
  },
  models: {
    name: '',
    nameLabel: '',
    categoryNames: [],
    categoryNamesLabel: '',
  },
  uploadHistory: {
    name: '',
    nameLabel: '',
    status: null,
    statusLabel: '',
    language: null,
    languageLabel: '',
    before: undefined,
    beforeLabel: '',
    after: undefined,
    afterLabel: '',
  },
};

const filtersReducer = (state: IFiltersState = initialState, action: IFiltersDispatchTypes): IFiltersState => {
  switch (action.type) {
    case SET_DOCUMENT_FILTER_NAME:
    case SET_DOCUMENT_FILTER_LANGUAGES:
    case SET_DOCUMENT_FILTER_TYPES:
    case SET_DOCUMENT_FILTER_MONOLINGUAL: {
      return {
        ...state,
        documents: {
          ...state.documents,
          ...action.payload,
        },
      };
    }
    case SET_PROJECT_FILTER_SOURCE_LANGUAGES:
    case SET_PROJECT_FILTER_TARGET_LANGUAGES:
    case SET_PROJECT_FILTER_CATEGORIES:
    case SET_PROJECT_FILTER_NAME: {
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.payload,
        },
      };
    }
    case CLEAR_FILTERS:
      return initialState;
    case SET_MODEL_FILTER_CATEGORY_NAMES:
    case SET_MODEL_FILTER_NAME: {
      return {
        ...state,
        models: {
          ...state.models,
          ...action.payload,
        },
      };
    }
    case SET_UPLOAD_HISTORY_FILTER_NAME:
    case SET_UPLOAD_HISTORY_FILTER_STATUS:
    case SET_UPLOAD_HISTORY_FILTER_LANGUAGE:
    case SET_UPLOAD_HISTORY_FILTER_AFTER:
    case SET_UPLOAD_HISTORY_FILTER_BEFORE: {
      return {
        ...state,
        uploadHistory: {
          ...state.uploadHistory,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default filtersReducer;
