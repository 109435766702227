import { Icon, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useTrainingProgressStyles from './TrainingProgress.styles';
import { ITrainingDetailsProps, ITrainingProgressProps } from './TrainingProgress.types';

import checkProgressStep from '../../utils/checkProgressStep';
import { ModelStatus } from '../../utils/constants/modelStatus';
import { DisplayedTrainingSteps, TrainingStepState } from '../../utils/constants/trainingProgress';
import { capitalize } from '../../utils/textTransform';

const TrainingDetails: React.FC<ITrainingDetailsProps> = ({ progressState, name, notification = false }) => {
  const classes = useTrainingProgressStyles();
  let progressContent = null;

  switch (progressState) {
    case TrainingStepState.NotStarted:
      progressContent = (
        <Text className={clsx(notification ? `${classes.reducedFontSize} ${classes.notStarted}` : classes.notStarted)}>
          {name}
        </Text>
      );
      break;
    case TrainingStepState.Running:
      progressContent = (
        <Fragment>
          <Spinner size={notification ? SpinnerSize.xSmall : SpinnerSize.small} className={clsx(classes.spinner)} />
          <Text className={clsx(notification ? classes.reducedFontSize : classes.inProgress)}>{name}</Text>
        </Fragment>
      );
      break;
    case TrainingStepState.Completed:
      progressContent = (
        <Fragment>
          <Icon
            iconName="Completed"
            className={clsx(notification ? `${classes.icon} ${classes.reducedFontSize}` : classes.icon)}
          />
          {notification ? <Text className={clsx(classes.reducedFontSize)}>{name}</Text> : { name }}
        </Fragment>
      );
      break;
    case TrainingStepState.Failed:
      progressContent = (
        <Fragment>
          <Icon
            iconName="ErrorBadge"
            className={clsx(notification ? `${classes.errorIcon} ${classes.reducedFontSize}` : classes.errorIcon)}
          />
          {notification ? <Text className={clsx(classes.reducedFontSize)}>{name}</Text> : { name }}
        </Fragment>
      );
      break;
    default:
      progressContent = capitalize(name);
  }
  return <div className={classes.root}>{progressContent}</div>;
};

/** Displays the model training progress with the appropiate descriptions for every step */
const TrainingProgress: React.FC<ITrainingProgressProps> = ({ status, notification = false }) => {
  const classes = useTrainingProgressStyles();
  const statusEnumKey = status as keyof typeof DisplayedTrainingSteps;

  const { t } = useTranslation();

  const statusContent = (
    <Stack className={clsx(notification ? classes.noBottomMargin : classes.progressList)}>
      <Stack.Item>
        <TrainingDetails
          progressState={checkProgressStep(statusEnumKey, ModelStatus.TrainingSubmitted)}
          name={t(`pages.model.contentSection.details.status.trainingProgress.trainingSubmitted`)}
          notification
        />
      </Stack.Item>
      <Stack.Item>
        <TrainingDetails
          progressState={
            status === ModelStatus.DataProcessingFailed
              ? TrainingStepState.Failed
              : checkProgressStep(statusEnumKey, ModelStatus.DataProcessing)
          }
          name={t(`pages.model.contentSection.details.status.trainingProgress.dataProcessing`)}
          notification
        />
      </Stack.Item>
      <Stack.Item>
        <TrainingDetails
          progressState={checkProgressStep(statusEnumKey, ModelStatus.TrainingQueued)}
          name={t(`pages.model.contentSection.details.status.trainingProgress.trainingQueued`)}
          notification
        />
      </Stack.Item>
      <Stack.Item>
        <TrainingDetails
          progressState={
            status === ModelStatus.TrainingFailed
              ? TrainingStepState.Failed
              : checkProgressStep(statusEnumKey, ModelStatus.TrainingRunning)
          }
          name={t(`pages.model.contentSection.details.status.trainingProgress.trainingRunning`)}
          notification
        />
      </Stack.Item>
    </Stack>
  );
  return <div className={classes.root}>{statusContent}</div>;
};

export default TrainingProgress;
