import { makeStyles } from '@fluentui/react';

const useSharedFilterFormStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  body: {
    padding: `${theme.spacing.l1} 0`,
    selectors: {
      '.ms-Label': {
        whiteSpace: 'nowrap',
      },
    },
  },
  dropdown: {
    marginBottom: theme.spacing.s2,
    selectors: {
      '.ms-Label': {
        marginBottom: theme.spacing.s2,
      },
    },
  },
}));

export default useSharedFilterFormStyles;
