import { FontIcon, IStackTokens, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ICreateWorkspaceWizardProps } from '../CreateWorkspaceWizard.types';
import useCreateWorkspaceWizardSuccessStyles from './CreateWorkspaceWizardSuccess.styles';

import { useAppSelector } from '../../../../utils/hooks';

const CreateWorkspaceWizardSuccess: React.FC<ICreateWorkspaceWizardProps> = ({ handleCancelAndClose }) => {
  const classes = useCreateWorkspaceWizardSuccessStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { data } = useAppSelector((state) => state.subscription);
  const { name } = useAppSelector((state) => state.createWorkspaceWizard);
  const { billingRegions } = useAppSelector((state) => state);

  const stackTokens: IStackTokens = { childrenGap: 8 };

  return (
    <Stack tokens={stackTokens}>
      <Stack.Item>
        <Text as="p" className={classes.subtitle}>
          {`${t('components.forms.createWorkspaceWizard.success.subtitle')} `}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <div className={classes.iconContainer}>
          <FontIcon iconName="CheckMark" className={classes.icon} />
        </div>
      </Stack.Item>
      <Stack.Item>
        <Stack styles={{ root: { padding: '12px 24px', background: theme.palette.neutralLighter } }}>
          <Stack.Item>
            <Text variant="xLarge" className={classes.workspaceName}>
              {name}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text className={classes.infoTitle}>
              {`${t('components.forms.createWorkspaceWizard.success.resource.name')} `}
            </Text>
            <Text>{data.name}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text className={classes.infoTitle}>
              {`${t('components.forms.createWorkspaceWizard.success.resource.type')} `}
            </Text>
            <Text>{data.kind}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text className={classes.infoTitle}>
              {`${t('components.forms.createWorkspaceWizard.success.resource.tier')} `}
            </Text>
            <Text>{data.sku}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text className={classes.infoTitle}>
              {`${t('components.forms.createWorkspaceWizard.success.resource.region')} `}
            </Text>
            <Text>{billingRegions[data.region].billingRegionName}</Text>
          </Stack.Item>
          {data.isCMKEnabled && (
            <Stack.Item>
              <Text className={classes.infoTitle}>
                {`${t('components.forms.createWorkspaceWizard.success.resource.CMK')} `}
              </Text>
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
      <Stack.Item align="end">
        <PrimaryButton
          text={t('components.popups.common.done')}
          onClick={handleCancelAndClose}
          styles={{ root: { marginTop: theme.spacing.l1 } }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default CreateWorkspaceWizardSuccess;
