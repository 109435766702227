import { FontWeights, makeStyles } from '@fluentui/react';

import components from '../../../utils/constants/components';

const useTrainModelStyles = makeStyles((theme) => ({
  list: {
    paddingLeft: 25,
    selectors: {
      li: {
        fontWeight: FontWeights.bold,
        fontSize: 15,
        marginBottom: 26,
      },
      'li:last-child': {
        marginBottom: 0,
      },
    },
  },
  modelName: {
    paddingTop: `${theme.spacing.s1}`,
  },
  stickyContainer: {
    '@media (min-height: 450px)': {
      borderTop: `1px solid ${theme.palette.neutralLight}`,
      boxShadow: `-1px 0 ${theme.palette.neutralLight}`,
      height: `${components.PaginationFooter.height}px`,
      transition: 'width .5s ease-in-out',
      width: `calc(100% - ${components.AppSidebar.expandedWidth}px)`,
      backgroundImage: 'none',
      backgroundRepeat: 'repeat',
      backgroundAttachment: 'scroll',
      backgroundPosition: '0% 0%',
      position: 'fixed',
      bottom: '0px',
      '&.collapsed-width': {
        width: `calc(100% - ${components.AppSidebar.collapsedWidth}px)`,
      },
    },
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    margin: 15,
    alignSelf: 'center',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
  },
  footerStack: {
    height: '100%',
  },
  pagination: {
    padding: 20,
  },
}));

export default useTrainModelStyles;
