import { FontWeights, makeStyles } from '@fluentui/react';

const useNotificationBaseStyles = makeStyles((theme) => ({
  spinner: {
    position: 'relative',
    top: 2,
    marginRight: theme.spacing.s2,
    justifyContent: 'normal',
  },
  iconTitle: {
    fontWeight: FontWeights.semibold,
  },
  statusContainer: {
    marginLeft: 15,
  },
  notificationItem: {
    margin: '5px 0px',
    padding: 10,
    background: theme.semanticColors.infoBackground,
    selectors: {
      '&.failure': {
        background: theme.semanticColors.errorBackground,
      },
      '&.success': {
        background: theme.semanticColors.successBackground,
      },
    },
  },
  closeIcon: {
    color: theme.palette.black,
    width: 'fit-content',
    height: 'fit-content',
  },
  icon: {
    fontSize: 14,
    position: 'relative',
    top: 2,
    marginRight: theme.spacing.s2,
    justifyContent: 'normal',
    selectors: {
      '&.success': {
        color: theme.palette.green,
      },
      '&.failure': {
        color: theme.semanticColors.errorIcon,
      },
    },
  },
}));

export default useNotificationBaseStyles;
