import { makeStyles } from '@fluentui/react';

import components from '../../../utils/constants/components';

const useContentContainerStyles = makeStyles(() => ({
  root: {
    padding: `8px ${components.ContentContainer.rightLeftBorder}px 0`,
    height: '100%',
  },
}));

export default useContentContainerStyles;
