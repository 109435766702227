import { makeStyles } from '@fluentui/react';

import components from '../../utils/constants/components';

const useAppBarStyles = makeStyles((theme) => ({
  skipToContentLink: {
    color: theme.palette.themeDarker,
    textDecoration: 'underline',
    background: theme.palette.white,
    position: 'absolute',
    padding: '10px 20px',
    left: '50%',
    height: '48px',
    alignItems: 'center',
    display: 'flex',
    transform: 'translateY(-100%)',
    '&:focus': {
      transform: 'translateY(0)',
    },
  },
  root: {
    height: components.AppBar.height,
    color: theme.palette.white,
    background: theme.palette.themePrimary,
  },
  parentLinks: {
    marginLeft: 15,
  },
  appName: {
    padding: '0 10px',
    alignItems: 'center',
    display: 'flex',
    height: components.AppBar.height,
    color: theme.palette.white,
    textDecoration: 'none',
    selectors: {
      '&:hover': {
        background: theme.palette.themeDarkAlt,
      },
      '&:active': {
        background: theme.palette.themeDark,
      },
    },
  },
  appBarButton: {
    width: 48,
    height: 48,
    padding: 0,
    color: theme.palette.white,
    borderRadius: 0,
    selectors: {
      '&.active': {
        color: theme.palette.themePrimary,
        background: theme.palette.white,
      },
    },
  },
}));

export default useAppBarStyles;
