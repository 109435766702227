import { ICategoryItem } from '../categories/categories.types';
import { ILanguageItem, ILanguagePairItem } from '../languages/languages.types';
import { IModelRegionStatusItem } from '../models/models.types';
import { IUserItem } from '../users/users.types';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESSFUL = 'GET_PROJECTS_SUCCESSFUL';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESSFUL = 'GET_PROJECT_SUCCESSFUL';
export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESSFUL = 'CREATE_PROJECT_SUCCESSFUL';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESSFUL = 'UPDATE_PROJECT_SUCCESSFUL';
export const UPDATE_PROJECT_FAILED = 'UPDATE_PROJECT_FAILED';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESSFUL = 'DELETE_PROJECT_SUCCESSFUL';
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED';

export const USE_FREE_CREDIT = 'USE_FREE_CREDIT';

// Set will use from existing projects array and pull out desired object
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';

export interface IProjectItem {
  id: string;
  name: string;
  label: string;
  description: string | null;
  languagePair: ILanguagePairItem;
  category: number;
  categoryDescriptor: string | null;
  baselineBleuScorePunctuated: string | null;
  bleuScorePunctuated: string | null;
  baselineBleuScoreUnpunctuated: string | null;
  bleuScoreUnpunctuated: string | null;
  baselineBleuScoreCIPunctuated: string | null;
  bleuScoreCIPunctuated: string | null;
  baselineBleuScoreCIUnpunctuated: string | null;
  bleuScoreCIUnpunctuated: string | null;
  status: string;
  modifiedDate: string;
  createdDate: string;
  createdBy: string;
  modifiedBy: string | null;
  apiDomain: string;
  isAvailable: boolean;
  hubCategory: string | null;
  hasFreeTraining: boolean;
  modelRegionStatus: IModelRegionStatusItem[] | null;
}

export interface IProjectsEntities {
  languages: {
    [key: string]: ILanguageItem;
  };
  categories: {
    [key: string]: ICategoryItem;
  };
  users: {
    [key: string]: IUserItem;
  };
  projects: {
    [key: string]: IProjectItem;
  };
}

export interface ICurrentProjectItem {
  id: string;
  name: string;
}

export interface IProjectsPayload {
  ids: string[];
  data: {
    [key: string]: IProjectItem;
  };
}

// PROJECTS
export interface IGetProjects {
  type: typeof GET_PROJECTS;
}

export interface IGetProjectsSuccessful {
  type: typeof GET_PROJECTS_SUCCESSFUL;
  payload: IProjectsPayload;
}

export interface IGetProjectsFailed {
  type: typeof GET_PROJECTS_FAILED;
}

// SINGLE PROJECT
export interface IGetProject {
  type: typeof GET_PROJECT;
}

export interface IGetProjectSuccessful {
  type: typeof GET_PROJECT_SUCCESSFUL;
  payload: IProjectsPayload;
}

export interface IGetProjectFailed {
  type: typeof GET_PROJECT_FAILED;
}

export interface ICreateProject {
  type: typeof CREATE_PROJECT;
}

export interface ICreateProjectSuccessful {
  type: typeof CREATE_PROJECT_SUCCESSFUL;
  payload: IProjectsPayload;
}

export interface ICreateProjectFailed {
  type: typeof CREATE_PROJECT_FAILED;
}

export interface IUpdateProject {
  type: typeof UPDATE_PROJECT;
}

export interface IUpdateProjectSuccessful {
  type: typeof UPDATE_PROJECT_SUCCESSFUL;
  payload: IProjectsPayload;
}

export interface IUpdateProjectFailed {
  type: typeof UPDATE_PROJECT_FAILED;
}

export interface IDeleteProject {
  type: typeof DELETE_PROJECT;
}

export interface IDeleteProjectSuccessful {
  type: typeof DELETE_PROJECT_SUCCESSFUL;
  payload: string;
}

export interface IDeleteProjectFailed {
  type: typeof DELETE_PROJECT_FAILED;
}

export interface IUseFreeCredit {
  type: typeof USE_FREE_CREDIT;
  payload: string;
}
// SETTING AND CLEARING PROJECT DATA
export interface ISetCurrentProject {
  type: typeof SET_CURRENT_PROJECT;
  payload: ICurrentProjectItem;
}

export interface IClearCurrentProject {
  type: typeof CLEAR_CURRENT_PROJECT;
}

export type ProjectsDispatchTypes =
  | IGetProjects
  | IGetProjectsSuccessful
  | IGetProjectsFailed
  | IGetProject
  | IGetProjectSuccessful
  | IGetProjectFailed
  | IUpdateProject
  | ICreateProject
  | ICreateProjectSuccessful
  | ICreateProjectFailed
  | IUpdateProjectSuccessful
  | IUpdateProjectFailed
  | IDeleteProject
  | IDeleteProjectSuccessful
  | IDeleteProjectFailed
  | IUseFreeCredit
  | ISetCurrentProject
  | IClearCurrentProject;
