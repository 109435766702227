import { Stack, Text } from '@fluentui/react';
import React from 'react';

import useDocumentTypeDescription from './DocumentTypeDescription.styles';
import IDocumentTypeDescription from './DocumentTypeDescription.types';

/** Document set descriptions are components to display an image, title, and text description for a document set.
 * These are common on the left hand side of the wizard.
 */
const DocumentTypeDescription: React.FC<IDocumentTypeDescription> = ({ image, title, text }) => {
  const classes = useDocumentTypeDescription();
  return (
    <Stack className={classes.root}>
      <Stack.Item>
        <Stack horizontalAlign="center">
          <img className={classes.image} src={image.source} alt={image.alt} />
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Text className={classes.title} variant="medium" as="p" block>
          {title}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Text as="p">{text}</Text>
      </Stack.Item>
    </Stack>
  );
};

export default DocumentTypeDescription;
