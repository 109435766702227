import { Action, Dispatch } from 'redux';

import { CopyNotificationsDispatchTypes, UPDATE_COPY_NOTIFICATION } from './copyNotifications.types';

export const updateCopyNotification = (payload: any) => (
  dispatch: Dispatch<CopyNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: UPDATE_COPY_NOTIFICATION,
    payload,
  });
};

export default updateCopyNotification;
