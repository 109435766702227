import { Spinner, SpinnerSize } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';

import useLoadingStyles from './Loading.styles';
import ILoadingProps from './Loading.types';

const Loading: React.FC<ILoadingProps> = ({
  loadingText,
  isFullHeight = false,
  spacing = { vertical: 0, horizontal: 0 },
}) => {
  const classes = useLoadingStyles();
  const loadingClass = clsx(classes.root, isFullHeight && '--full-height');

  return (
    <Spinner
      className={loadingClass}
      styles={{
        root: {
          [`${spacing.type}`]: `${spacing.vertical}px ${spacing.horizontal}px`,
        },
      }}
      label={loadingText}
      size={SpinnerSize.large}
      ariaLive="assertive"
    />
  );
};

export default Loading;
