/**
 * Various states of a model or project.
 * 3/24/21, The term "deploy" has been updated to "publish". However, the API will still retain
 * "deploy" terminology. Moving forward the front end "deploy" terms will be replaced with
 * "publish" terminology.
 */
enum ModelStatus {
  // Specifies that the default state for this type.
  Unknown = 'Created',

  // Specifies that the backend is processing the documents.
  DataProcessing = 'Data processing',

  // Specifies that the training request has been submitted to the queue.
  TrainingSubmitted = 'Training submitted',

  // Specifies that the training is being queued to MT system.
  TrainingQueued = 'Training queued',

  // Specifies that the training is running in MT system.
  TrainingRunning = 'Training running',

  // Specifies that the training succeeded in MT system. The user can see a BLEU score in this state.
  TrainingSucceeded = 'Training succeeded',

  // Specifies that the training failed. The user can see no BLEU score in this state due to failure.
  TrainingFailed = 'Training failed',

  // Specifies that the successful training is submitted to MT system for publishing purposes.
  Publishing = 'Deploying',

  // Specifies that the successful training is deployed in MT system.
  Published = 'Deployed',

  // Specifies that the deployed training is unpublishing.
  Unpublishing = 'Undeploying',

  // Specifies that the unpublish process of a training belonging to a removed project has completed successfully.
  Unpublished = 'Undeployed',

  // Specifies that data processing has failed for one or more documents belonging to the model.
  DataProcessingFailed = 'Data processing failed',

  // Specifies that the model publish has failed.
  PublishFailed = 'Deployment failed',

  // Specifies that the model is in a migrated draft state. This state only occurs when the user has migrated from Hub.
  MigratedDraft = 'Migrated draft',

  // Specifies that the successful training that has been published is being published and unpublished from some regions.
  UpdatingPublishment = 'Updating deployment',

  // Specifies that the model is in the draft state. The user can add or remove documents from a model when it is in the draft state.
  Draft = 'Draft',

  // Specifies that the model is an SMT model from Hub published for API V3. The user can see documents used.
  // They can also choose to unpublish the model from V3.
  HubPublished = 'Hub deployed',

  // Specifies a model is being copied to another workspace project with the same language pairs.
  Copying = 'Copying',

  // Specifies a model completed it's copying.
  CopyCompleted = 'Copy Completed',

  // Specifies when a model failed to copy.
  CopyFailed = 'Copy Failed',
}

const ModelStatusMap: Map<number, ModelStatus> = new Map([
  [0, ModelStatus.Unknown],
  [1, ModelStatus.DataProcessing],
  [2, ModelStatus.TrainingSubmitted],
  [3, ModelStatus.TrainingQueued],
  [4, ModelStatus.TrainingRunning],
  [5, ModelStatus.TrainingSucceeded],
  [6, ModelStatus.TrainingFailed],
  [7, ModelStatus.Publishing],
  [8, ModelStatus.Published],
  [9, ModelStatus.Unpublishing],
  [10, ModelStatus.Unpublished],
  [11, ModelStatus.DataProcessingFailed],
  [12, ModelStatus.PublishFailed],
  [13, ModelStatus.MigratedDraft],
  [14, ModelStatus.UpdatingPublishment],
  [15, ModelStatus.Draft],
  [16, ModelStatus.HubPublished],
  [17, ModelStatus.Copying],
  [18, ModelStatus.CopyCompleted],
  [19, ModelStatus.CopyFailed],
]);

export { ModelStatus, ModelStatusMap };
