import { IBreadcrumbItem } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TestModelResultsCommandBar } from '../../CommandBars';
import { Empty, Error, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { TestModelResultsTable } from '../../Tables';
import ContentContainer from '../ContentContainer';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import { useAppSelector } from '../../../utils/hooks/appRedux.helpers';

const TestModelResultsContent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { models, modelTestResults } = useAppSelector((state) => state);
  const modelName = models.data[modelId]?.name || '';

  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t('pages.testModel.pageName'),
      key: 'testModel',
      onClick: (): void => history.push(`/workspaces/${workspaceId}/projects/${projectId}/test`),
    },
    {
      text: modelName,
      key: 'modelName',
    },
  ];

  let testResultsContent = null;

  const isTestResultsLoading = models.isLoading || modelTestResults.isLoading;

  if (isTestResultsLoading) {
    testResultsContent = (
      <Loading
        loadingText={t('pages.testModelResults.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!isTestResultsLoading) {
    if (modelTestResults.gridError) {
      testResultsContent = <Error errorText={modelTestResults.gridError} />;
    } else if (!isEmpty(modelTestResults.data)) {
      testResultsContent = <TestModelResultsTable />;
    } else if (isEmpty(modelTestResults.data)) {
      testResultsContent = <Empty title={t('pages.testModelResults.empty')} />;
    }
  }

  return (
    <ContentContainer>
      <PageHeading>{t('pages.testModel.pageName')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      <TestModelResultsCommandBar />
      {testResultsContent}
    </ContentContainer>
  );
};

export default TestModelResultsContent;
