import { registerIcons } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';

import fabricIcons from '../assets/fonts/fabric-icons.woff';

const initializeAndRegisterIcons = (): void => {
  initializeIcons();

  registerIcons({
    fontFace: {
      fontFamily: 'FabricMDL2Icons',
      src: `url('${fabricIcons}') format('woff')`,
    },
    icons: {
      MachineLearning: '\ue3b8',
      CRMArticles: '\ueff5',
      PinNavMenu: '\ue682',
    },
  });
};

export default initializeAndRegisterIcons;
