export const SET_CREATE_WORKSPACE_WIZARD_NAME = 'SET_CREATE_WORKSPACE_WIZARD_NAME';
export const SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY = 'SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY';
export const SET_CREATE_WORKSPACE_WIZARD_SLIDE = 'SET_CREATE_WORKSPACE_WIZARD_SLIDE';

export const CLEAR_CREATE_WORKSPACE_WIZARD = 'CLEAR_CREATE_WORKSPACE_WIZARD';

export const CREATE_WORKSPACE_SUCCESSFUL = 'CREATE_WORKSPACE_SUCCESSFUL';
export const CREATE_WORKSPACE_FAILED_KEY_VALIDATION = 'CREATE_WORKSPACE_FAILED_KEY_VALIDATION';
export const CREATE_WORKSPACE_FAILED_OTHER = 'CREATE_WORKSPACE_FAILED_OTHER';

export enum CreateWorkspaceWizardSlide {
  AddName = 'addName',
  AddKey = 'addKey',
  Success = 'success',
}

export enum CreateWorkspaceErrorCodes {
  InvalidSubscription = 0,
  Other = 1,
}

export interface ICreateWorkspaceWizardFormValues {
  name: string;
  region: string;
  key: string;
}

export interface ICreateWorkspaceFailedKeyValidation {
  type: typeof CREATE_WORKSPACE_FAILED_KEY_VALIDATION;
  payload: string;
}

export interface ICreateWorkspaceFailedOther {
  type: typeof CREATE_WORKSPACE_FAILED_OTHER;
}

export interface ISetCreateWorkspaceWizardIsValidatingKey {
  type: typeof SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY;
  payload: boolean;
}

export interface ICreateWorkspaceSuccessful {
  type: typeof CREATE_WORKSPACE_SUCCESSFUL;
}

export interface ISetCreateWorkspaceWizardSlide {
  type: typeof SET_CREATE_WORKSPACE_WIZARD_SLIDE;
  payload: CreateWorkspaceWizardSlide;
}

export interface ISetCreateWorkspaceWizardName {
  type: typeof SET_CREATE_WORKSPACE_WIZARD_NAME;
  payload: string;
}

export interface IClearCreateWorkspaceWizard {
  type: typeof CLEAR_CREATE_WORKSPACE_WIZARD;
}

export type CreateWorkspaceWizardDispatchTypes =
  | ISetCreateWorkspaceWizardName
  | IClearCreateWorkspaceWizard
  | ISetCreateWorkspaceWizardSlide
  | ISetCreateWorkspaceWizardIsValidatingKey
  | ICreateWorkspaceFailedKeyValidation
  | ICreateWorkspaceFailedOther
  | ICreateWorkspaceSuccessful;
