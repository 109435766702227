export const GET_MODEL_TEST_RESULTS = 'GET_MODEL_TEST_RESULTS';
export const GET_MODEL_TEST_RESULTS_SUCCESSFUL = 'GET_MODEL_TEST_RESULTS_SUCCESSFUL';
export const GET_MODEL_TEST_RESULTS_FAILED = 'GET_MODEL_TEST_RESULTS_FAILED';

export const DOWNLOAD_MODEL_TEST_RESULTS = 'DOWNLOAD_MODEL_TEST_RESULTS';
export const DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL = 'DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL';
export const DOWNLOAD_MODEL_TEST_RESULTS_FAILED = 'DOWNLOAD_MODEL_TEST_RESULTS_FAILED';

export interface ITestResultsItem {
  source: string;
  target: string;
  referenceString: string;
  general: string;
}

export interface ITestResultsEntity {
  testEntries: ITestResultsItem[];
}

export interface IGetModelTestResults {
  type: typeof GET_MODEL_TEST_RESULTS;
}

export interface IGetModelTestResultsSuccessful {
  type: typeof GET_MODEL_TEST_RESULTS_SUCCESSFUL;
  payload: ITestResultsEntity;
}

export interface IGetModelTestResultsFailed {
  type: typeof GET_MODEL_TEST_RESULTS_FAILED;
  payload: string;
}

export interface IDownloadModelTestResults {
  type: typeof DOWNLOAD_MODEL_TEST_RESULTS;
}

export interface IDownloadModelTestResultsSuccessful {
  type: typeof DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL;
}

export interface IDownloadModelTestResultsFailed {
  type: typeof DOWNLOAD_MODEL_TEST_RESULTS_FAILED;
}

export type ModelTestResultsDispatchTypes =
  | IGetModelTestResults
  | IGetModelTestResultsSuccessful
  | IGetModelTestResultsFailed
  | IDownloadModelTestResults
  | IDownloadModelTestResultsSuccessful
  | IDownloadModelTestResultsFailed;
