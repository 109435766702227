import { FontWeights, makeStyles } from '@fluentui/react';

const useHelpPanelStyles = makeStyles((theme) => ({
  title: {
    marginTop: '20px',
    fontSize: '20px',
    fontWeight: FontWeights.semibold,
  },
  list: {
    height: '60vh',
    overflowY: 'auto',
  },
  itemContent: {
    fontSize: 14,
    margin: '0 0 15px 30px',
    overflow: 'hidden',
    flexGrow: 1,
  },
  footerLink: {
    alignSelf: 'center',
  },
  details: {
    'summary::-webkit-details-marker, summary::marker': {
      display: 'none',
      content: `""`,
    },
    '.group-icon': {
      alignSelf: 'center',
      color: theme.palette.neutralTertiary,
      fontSize: theme.fonts.large.fontSize,
      flexShrink: 0,
      marginRight: 10,
      textDecoration: 'none',
    },
    '.group-icon--open': {
      display: 'none',
    },
    '&[open]': {
      '.group-icon--close': {
        display: 'none',
      },
      '.group-icon--open': {
        display: 'block',
      },
    },
  },
  summary: {
    display: 'flex',
    padding: '12px 0',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    '&:hover, &:focus': {
      cursor: 'pointer',
      '.group-name': {
        'text-decoration': 'underline',
      },
    },
  },
}));

export default useHelpPanelStyles;
