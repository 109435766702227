import WorkspacePivot from './constants/pivots';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCurrentPivot = (location: any): WorkspacePivot => {
  const selectedKey = location.pathname.split('/').slice(-1)[0];

  switch (selectedKey) {
    case WorkspacePivot.Documents:
    case WorkspacePivot.UploadHistory:
      return selectedKey;
    default:
      return WorkspacePivot.Projects;
  }
};

export default getCurrentPivot;
