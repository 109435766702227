import { IModelItem } from '../redux/models/models.types';
import { ModelStatus } from './constants/modelStatus';

const isModelTestAvailable = (currentModel: IModelItem | undefined): boolean => {
  if (currentModel === undefined) {
    return false;
  }
  const noTestAvailableStatuses: string[] = [ModelStatus.TrainingFailed, ModelStatus.Draft];
  const isModelDictionaryOnly =
    currentModel.trainingSentenceCount === 0 &&
    currentModel.tuningSentenceCount === 0 &&
    currentModel.testingSentenceCount === 0;
  return !(noTestAvailableStatuses.includes(currentModel.modelStatus) || isModelDictionaryOnly);
};

export default isModelTestAvailable;
