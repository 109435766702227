import { useTranslation } from 'react-i18next';

import { DictionaryTypes, DocumentSetTypes } from '../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';

const useRenderUploadDocumentWizardTitle = (
  documentSetType: DocumentSetTypes | undefined,
  dictionaryType: DictionaryTypes | undefined,
  showLanguageSelection = false
): string => {
  const { t } = useTranslation();
  let wizardTitle;

  if (documentSetType === DocumentSetTypes.Dictionary) {
    if (dictionaryType === DictionaryTypes.PhraseDictionary || dictionaryType === DictionaryTypes.SentenceDictionary) {
      wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.uploadDictionaryType');
    } else {
      wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.chooseDictionaryType');
    }
  } else if (documentSetType === DocumentSetTypes.Training) {
    wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.training');
  } else if (documentSetType === DocumentSetTypes.Testing) {
    wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.test');
  } else if (documentSetType === DocumentSetTypes.Tuning) {
    wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.tuning');
  } else if (showLanguageSelection) {
    wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.showLanguageSelection');
  } else {
    wizardTitle = t('components.forms.uploadDocumentsWizard.wizardTitles.default');
  }

  return wizardTitle;
};

export default useRenderUploadDocumentWizardTitle;
