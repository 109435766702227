import { Icon, IconButton, Link, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { clearNotification } from '../../../redux/notifications/notificationsActions';
import useNotificationBaseStyles from './NotificationBase.styles';
import { INotificationBaseProps } from './NotificationBase.types';

import { useAppDispatch } from '../../../utils/hooks';

const NotificationBase: React.FC<INotificationBaseProps> = ({ id, display, type, children }) => {
  const classes = useNotificationBaseStyles();
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();

  const close = (notificationId: string) => (): void => {
    appDispatch(clearNotification({ id: notificationId, type }));
  };

  let error = (
    <Stack.Item>
      <Text variant="small">{display.error}</Text>
    </Stack.Item>
  );

  if (display.errorLink) {
    const errorText = `${display.error} `;
    const linkText = `${display.errorLink.urlText} `;
    error = (
      <Stack.Item>
        <Text variant="small">{errorText}</Text>
        <Link
          styles={{ root: { fontSize: 12 } }}
          href={display.errorLink.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          {linkText}
        </Link>
        {display.errorLink.postLinkText && <Text variant="small">{display.errorLink.postLinkText}</Text>}
      </Stack.Item>
    );
  }

  return (
    <Stack className={display.class} tokens={{ childrenGap: 8 }}>
      <Stack horizontal verticalAlign="start" tokens={{ childrenGap: 5 }}>
        {display.icon ? (
          <Stack.Item>
            <Icon iconName={display.icon.name} className={display.icon.class} />
          </Stack.Item>
        ) : (
          <Stack.Item>
            <Spinner size={SpinnerSize.small} className={classes.spinner} />
          </Stack.Item>
        )}
        <Stack.Item grow>
          <Text block variant="medium" className={classes.iconTitle}>
            {display.title}
          </Text>
          {display.subtitle && <Text variant="small">{display.subtitle}</Text>}
        </Stack.Item>
        <Stack.Item>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={t('components.popups.common.closeAria')}
            onClick={close(id)}
            className={classes.closeIcon}
            styles={{ icon: { fontSize: 12 } }}
            value={id}
          />
        </Stack.Item>
      </Stack>
      {React.Children.toArray(children).length && (
        <Stack.Item className={classes.statusContainer}>{children}</Stack.Item>
      )}
      {display.error && error}
    </Stack>
  );
};

export default NotificationBase;
