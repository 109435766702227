import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  IconButton,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { getDocuments } from '../../../redux/documents/documentsActions';
import { createModel } from '../../../redux/models/modelsActions';
import { AppDialog } from '../../AppPopUps';
import appSidebarContext from '../../AppSidebar/AppSidebar.context';
import AzureModelAnnouncement from '../../AzureModelAnnouncement';
import { ModelDocumentsCommandBar } from '../../CommandBars';
import FilterBar from '../../FilterBar';
import { Empty, Loading } from '../../GenericStates';
import ModelProgressIndicator from '../../ModelProgressIndicator';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { Pagination } from '../../Pagination';
import { ProjectDocumentsTable } from '../../Tables';
import useTrainModelStyles from './TrainModelContent.styles';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import { ModelsPopUpTypes } from '../../../utils/constants/popUps.types';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import REQUIRED_TRAINING_SENTENCES from '../../../utils/constants/trainingSentences';
import { TrainingTypes } from '../../../utils/constants/trainingTypes';
import { AppDialogContentMap, useAppDialog, useAppDispatch, useAppSelector } from '../../../utils/hooks';
import validateDocumentsType from '../../../utils/validateDocumentsType';

const TrainModelContent: React.FC = () => {
  const { t } = useTranslation();
  const classes = useTrainModelStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentRoute = useRouteMatch(`/workspaces/:workspaceId/projects/:projectId/manage-documents`);
  const { projects, documents, subscription, languages, paginatedDocumentsSelection, trainModel } = useAppSelector(
    (state) => state
  );
  const { workspaceId, projectId } = useParams<IRoutePathParams>();

  const { isTestingAuto, isTuningAuto, selectedDocumentsIds } = validateDocumentsType(paginatedDocumentsSelection.data);
  const { appDialog, setAppDialog } = useAppDialog();
  const breadcrumbItems = [
    {
      text: t('components.appSidebar.trainModel'),
      key: 'train-model',
    },
  ];

  const { isAppSidebarCollapsed } = useContext(appSidebarContext);
  const paginationPositionClass = clsx(classes.stickyContainer, { 'collapsed-width': isAppSidebarCollapsed });

  const fullTrainingId = useId('full-training-tooltip');
  const dictionaryId = useId('dictionary-only-tooltip');
  const isLoading = documents.isLoading || subscription.isLoading;
  const noDocuments = !documents.isLoading && isEmpty(documents.ids);
  const isTrainingFree = projects.data[projectId]?.hasFreeTraining || false;
  const displayPagination = documents.pageIndex !== 0 && documents.totalPageCount > 1;

  const [modelNameError, setModelNameError] = useState<string | undefined>(undefined);
  const [isEnoughTrainingData, setIsEnoughTrainingData] = useState<boolean>(true);
  const [wasTrainButtonPressed, setWasTrainButtonPressed] = useState<boolean>(false);

  useEffect(() => {
    if (wasTrainButtonPressed) {
      if (trainModel.trainingType === TrainingTypes.FullTraining) {
        setIsEnoughTrainingData(paginatedDocumentsSelection.counts.training.sentence > REQUIRED_TRAINING_SENTENCES);
      } else {
        setIsEnoughTrainingData(paginatedDocumentsSelection.counts.dictionary.document > 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedDocumentsSelection.counts, trainModel.trainingType, wasTrainButtonPressed]);

  const renderInfoTooltip = (tooltipId: string, tooltipContent: string): React.ReactElement => {
    return (
      <TooltipHost content={tooltipContent} id={tooltipId}>
        <IconButton
          iconProps={{ iconName: 'Info' }}
          aria-describedby={tooltipId}
          ariaLabel={t('components.commandBars.moreInformation.aria')}
          styles={{ root: { marginLeft: 2, paddingTop: 2 }, icon: { color: theme.palette.neutralSecondary } }}
        />
      </TooltipHost>
    );
  };

  const handleModelNameChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (!newValue || newValue?.length <= FormValidationLengths.MAX_MODEL_NAME_LENGTH) {
        dispatch({ type: 'SET_MODEL_NAME', payload: newValue || '' });
      }
      if (isEmpty(newValue)) {
        setModelNameError(t('components.forms.generalErrors.required'));
      } else {
        setModelNameError(undefined);
      }
    },
    [dispatch, t]
  );

  const onTrainSubmit = (): void => {
    const isModelName = trainModel.modelName.length > 0;
    let isTraininigData;

    if (trainModel.trainingType === TrainingTypes.FullTraining) {
      isTraininigData = paginatedDocumentsSelection.counts.training.sentence > REQUIRED_TRAINING_SENTENCES;
    } else {
      isTraininigData = paginatedDocumentsSelection.counts.dictionary.document > 0;
    }
    setIsEnoughTrainingData(isTraininigData);

    if (!isModelName) {
      setModelNameError(t('components.forms.generalErrors.required'));
    }
    if (isModelName && isTraininigData) {
      setAppDialog({ contentType: ModelsPopUpTypes.Create, isHidden: false });
    } else {
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-create-train/inline-errors' });
    }
    setWasTrainButtonPressed(true);
  };

  const onSaveSubmit = (): void => {
    if (trainModel.modelName.length === 0) {
      setModelNameError(t('components.forms.generalErrors.required'));
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-create-draft/inline-errors' });
    } else {
      setAppDialog({ contentType: ModelsPopUpTypes.SaveDraft, isHidden: false });
    }
  };

  const onTrainingSelect = useCallback(
    (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
      const currentProject = projects.data[projectId];
      const sourceLanguage = languages[currentProject.languagePair.sourceLanguage];
      const targetLanguage = languages[currentProject.languagePair.targetLanguage];
      let filter = `isAvailable eq 'True' and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}' and isParallel eq true`;
      if (option?.key === TrainingTypes.DictionaryOnly) {
        filter = `isAvailable eq 'True' and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}' and isParallel eq true and documentType eq 'Phrase dictionary,Sentence dictionary'`;
      }
      history.replace({ search: '' });
      batch(() => {
        dispatch({ type: 'CLEAR_ALL_COUNTS' });
        dispatch({ type: 'CLEAR_SELECTED_DOCUMENT' });
        dispatch({ type: 'CLEAR_DOCUMENTS_SELECTION' });
        dispatch(getDocuments({ workspaceId, filter, page: 1, resetPagination: true }));
        dispatch({ type: 'SET_TRAINING_TYPE', payload: option?.key });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, workspaceId, projects, languages]
  );

  const trainingOptions: IChoiceGroupOption[] = [
    {
      key: 'full',
      text: t('pages.trainModel.fullTraining'),
      onRenderField: (props, render): React.ReactElement => {
        return (
          <div className={classes.options}>
            {render?.(props)}
            {renderInfoTooltip(fullTrainingId, t('pages.trainModel.fullTrainingTooltip'))}
          </div>
        );
      },
    },
    {
      key: 'dictionary',
      text: t('pages.trainModel.dictionaryOnly'),
      onRenderField: (props, render): React.ReactElement => {
        return (
          <div className={classes.options}>
            {render?.(props)}
            {renderInfoTooltip(dictionaryId, t('pages.trainModel.dictionaryOnlyTooltip'))}
          </div>
        );
      },
    },
  ];

  const trainModelFooter = (
    <Stack horizontal horizontalAlign="space-between" className={classes.footerStack}>
      {displayPagination && (
        <Stack.Item>
          <Pagination
            totalPageCount={documents.totalPageCount}
            currentPage={documents.pageIndex}
            currentPath={currentRoute ? currentRoute.url : ''}
            isDoubleFooter
          />
        </Stack.Item>
      )}
      <Stack.Item className={classes.buttons}>
        <PrimaryButton
          ariaLabel={t('components.commandBars.trainModel.trainNowButton.text')}
          onClick={(): void => onTrainSubmit()}
          disabled={!isEmpty(modelNameError) || !isEnoughTrainingData}
        >
          {t('components.commandBars.trainModel.trainNowButton.text')}
        </PrimaryButton>
        <DefaultButton
          ariaLabel={t('components.commandBars.trainModel.saveDraftButton.text')}
          onClick={(): void => onSaveSubmit()}
          disabled={!isEmpty(modelNameError)}
        >
          {t('components.commandBars.trainModel.saveDraftButton.text')}
        </DefaultButton>
      </Stack.Item>
    </Stack>
  );

  /**
   * The TrainModelContent table depends on having the current project set in order to render project languages.
   * The loading spinner should only appear on initial page loads - for all subsequent loads that occur
   * due to pagination changes the ShimmeredDetailsList will shimmer to show progress.
   */
  let tableContent;
  if (isLoading || isEmpty(projects.currentProject)) {
    tableContent = (
      <Loading
        loadingText={t('pages.manageDocuments.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (noDocuments && !documents.areDocumentsFiltered) {
    tableContent = <Empty type={EmptyTypes.Documents} />;
  } else {
    tableContent = <ProjectDocumentsTable isMultiSelect showInlineCommands={false} hidePagination />;
  }

  const appDialogContentMap: AppDialogContentMap = {
    [ModelsPopUpTypes.Create]: {
      contentProps: {
        title: t('components.popups.model.train.title'),
        subText: t('components.popups.model.train.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.model.train.actionButton'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            dispatch(
              createModel({
                name: trainModel.modelName,
                projectId,
                documentIds: selectedDocumentsIds,
                isTestingAuto,
                isTuningAuto,
                isAutoTrain: true,
                isAutoDeploy: false,
              })
            );
            dispatch({ type: 'CLEAR_MODEL_NAME' });
          });
          if (isTrainingFree) {
            dispatch({ type: 'USE_FREE_CREDIT', payload: projectId });
          }

          // TODO: Fix bug where this loads the models page before other model is successfully created
          // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/create/Bug?workitem=123535
          history.push(`/workspaces/${workspaceId}/projects/${projectId}/models`);
        },
      },
    },
    [ModelsPopUpTypes.SaveDraft]: {
      contentProps: {
        title: t('components.popups.model.saveDraft.title'),
        subText: t('components.popups.model.saveDraft.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.save'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            dispatch(
              createModel({
                name: trainModel.modelName,
                projectId,
                documentIds: selectedDocumentsIds,
                isTestingAuto,
                isTuningAuto,
                isAutoTrain: false,
                isAutoDeploy: false,
              })
            );
            dispatch({ type: 'CLEAR_MODEL_NAME' });
          });

          history.push(`/workspaces/${workspaceId}/projects/${projectId}/models`);
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const project = projects.data[projectId];

  return (
    <Fragment>
      <Stack>
        <Stack.Item className="content-container">
          <PageHeading>{t('components.appSidebar.trainModel')}</PageHeading>
          <PageBreadcrumb items={breadcrumbItems} />

          {project && <AzureModelAnnouncement azureModelKey={[project.languagePair.id, project.category].join(',')} />}
          <ol className={classes.list}>
            <li>
              {t('pages.trainModel.nameModelStep')}
              <form id="trainModel">
                <TextField
                  // TODO: Update for responsive design
                  // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119688/?workitem=120776
                  name="modelName"
                  styles={{ root: { maxWidth: '480px', width: '100%' } }}
                  className={classes.modelName}
                  label={t('pages.trainModel.modelName')}
                  placeholder={t('pages.trainModel.modelNamePlaceholder')}
                  onChange={handleModelNameChange}
                  errorMessage={modelNameError}
                  value={trainModel.modelName}
                  required
                />
              </form>
            </li>
            <li>
              <span id="radiolabel">{t('pages.trainModel.selectTypeStep')}</span>
              {/* {t('pages.trainModel.selectTypeStep')} */}
              <ChoiceGroup
                aria-labelledby="radiolabel"
                defaultSelectedKey="full"
                options={trainingOptions}
                required
                onChange={onTrainingSelect}
              />
            </li>
            <li>
              {t('pages.trainModel.selectDocumentStep')}
              <ModelProgressIndicator isDocumentSelectionIncomplete={!isEnoughTrainingData} />
              <ModelDocumentsCommandBar />
              <FilterBar kind="documents" />
              {tableContent}
            </li>
          </ol>
        </Stack.Item>
        {!noDocuments && <Stack.Item className={paginationPositionClass}>{trainModelFooter}</Stack.Item>}
      </Stack>
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
    </Fragment>
  );
};

export default TrainModelContent;
