import axios from 'axios';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import normalizeResponse from '../normalizeResponse';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import { subscriptionSchema } from '../schema';
import { TelemetryDispatchTypes } from '../telemetry/telemetry.types';
import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAILED,
  GET_SUBSCRIPTION_SUCCESSFUL,
  ISubscriptionEntities,
  SubscriptionsDispatchTypes,
  UPDATE_SUBSCRIPTION_KEY,
  UPDATE_SUBSCRIPTION_KEY_FAILED,
  UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL,
} from './subscription.types';

import ErrorCategories from '../../utils/constants/errorCategories';
import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';

export const getSubscription = (workspaceId: string) => async (
  dispatch: Dispatch<SubscriptionsDispatchTypes | ErrorDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: GET_SUBSCRIPTION });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}subscriptions?workspaceid=${workspaceId}`);

    if (isEmpty(response.data)) {
      /*
       * A string will be returned if there is no data.
       * This will fail when passed to normalizr, because it expects an object.
       * Reassign the data.response primitive to an object to pass onto normalizr
       */
      Object.assign(response, { data: {} });
    }

    const { entities, result } = await normalizeResponse<ISubscriptionEntities>(response.data, subscriptionSchema);
    const data = entities.subscription[`${result}`] || {};

    return dispatch({
      type: GET_SUBSCRIPTION_SUCCESSFUL,
      payload: {
        workspaceId,
        data,
      },
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_SUBSCRIPTION_FAILED,
        payload: workspaceId,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Subscription,
        },
      });
    });
  }
};

export const updateKeySubscription = (workspaceId: string, subscriptionKey: string, isChangingKey = false) => async (
  dispatch: Dispatch<SubscriptionsDispatchTypes | PopUpErrorsDispatchTypes | TelemetryDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: UPDATE_SUBSCRIPTION_KEY });

    if (isChangingKey) {
      await axios.put(
        `${process.env.REACT_APP_API_URL}subscriptions`,
        {
          subscriptionKey,
          billingRegionCode: 'GBL',
        },
        { params: { workspaceid: workspaceId } }
      );
    } else {
      await axios.post(
        `${process.env.REACT_APP_API_URL}subscriptions`,
        {
          subscriptionKey,
          billingRegionCode: 'GBL',
        },
        { params: { workspaceid: workspaceId } }
      );
    }

    const response = await axios.get(`${process.env.REACT_APP_API_URL}subscriptions?workspaceid=${workspaceId}`);

    if (isEmpty(response.data)) {
      Object.assign(response, { data: {} });
    }

    const { entities, result } = await normalizeResponse<ISubscriptionEntities>(response.data, subscriptionSchema);

    const data = entities.subscription[`${result}`] || {};

    return batch(() => {
      dispatch({
        type: UPDATE_SUBSCRIPTION_KEY_SUCCESSFUL,
        payload: {
          workspaceId,
          data,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-update-key/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.subscriptions.updateKey')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Subscription,
      requestType: RequestTypes.Post,
    });

    return batch(() => {
      dispatch({ type: UPDATE_SUBSCRIPTION_KEY_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-update-key/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};
