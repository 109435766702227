import React from 'react';

export enum GridPanelTypes {
  Details,
  ModelsFilter,
  ProjectsFilter,
  DocumentsFilter,
  UploadHistoryFilter,
  ShareWorkspace,
  Empty,
}

type ContextProps = {
  isGridPanelOpen: boolean;
  gridPanelType: GridPanelTypes | undefined;
  setIsGridPanelOpen: (value: boolean) => void;
  setGridPanelType: (value: GridPanelTypes) => void;
};

const initialState = {
  isGridPanelOpen: false,
  gridPanelType: undefined,
  setIsGridPanelOpen: (): void => {},
  setGridPanelType: (): void => {},
};

export const gridPanelContext = React.createContext<ContextProps>(initialState);
