import { makeStyles } from '@fluentui/react';

const useCopyModelFormStyles = makeStyles((theme) => ({
  container: {
    a: {
      color: theme.semanticColors.link,
    },
  },
}));

export default useCopyModelFormStyles;
