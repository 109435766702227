import { Checkbox, Link, Persona, PersonaSize, Separator, SharedColors, Stack, Text, useTheme } from '@fluentui/react';
import axios from 'axios';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { WorkspaceSettingsCommandBar } from '../../CommandBars';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import TextCallout from '../../TextCallout';
import ContentContainer from '../ContentContainer';
import RetrainModelsHistoryContent from '../RetrainModelsHistoryContent';
import useWorkspaceSettingsStyles from './WorkspaceSettings.styles';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppSelector, useIsOwner } from '../../../utils/hooks';

const WorkspaceSettingsContent: React.FC = () => {
  const theme = useTheme();
  const classes = useWorkspaceSettingsStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { workspaceId } = useParams<IRoutePathParams>();
  const { workspaces, users, sharing, billingRegions, roles, subscription } = useAppSelector((state) => state);
  const breadcrumbItems = [
    {
      text: t('pages.workspaceSettings.pageName'),
      key: 'workspaceSettings',
    },
  ];
  const currentWorkspaceId = workspaces.currentWorkspace.id;

  const roleId = workspaces.data[currentWorkspaceId].role;
  const { roleName } = roles[roleId];
  const isOwner = useIsOwner(currentWorkspaceId);

  const createdById = workspaces.data[currentWorkspaceId].createdBy;
  const createdByUserName = users[createdById] ? users[createdById].userName : 'N/A';

  const sharingIds = workspaces.data[currentWorkspaceId].sharing;
  const hasSharingIds = !isEmpty(sharingIds);
  const { isDefaultWorkspace } = workspaces.data[currentWorkspaceId];

  let subscriptionContent = null;

  const isRetrainModelsRoute = location.pathname.includes('/retrain-models');

  if (isEmpty(subscription.data) || !subscription.data.id) {
    subscriptionContent = (
      <Text>
        {t('pages.workspaceSettings.contentSection.noSubscriptionKey.text')}
        <Link
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/translator-how-to-signup"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('pages.workspaceSettings.contentSection.noSubscriptionKey.linkText')}
        </Link>
      </Text>
    );
  } else {
    subscriptionContent = (
      <Fragment>
        <Stack>
          <TextCallout
            title={t('pages.workspaceSettings.contentSection.labels.subscriptionName')}
            text={subscription.data.name}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.l2 }}>
          <Stack.Item>
            <TextCallout
              title={t('pages.workspaceSettings.contentSection.labels.subscriptionType')}
              text={subscription.data.kind}
            />
          </Stack.Item>
          <Stack.Item>
            <TextCallout
              title={t('pages.workspaceSettings.contentSection.labels.subscriptionRegion')}
              text={billingRegions[subscription.data.region].billingRegionName}
            />
          </Stack.Item>
        </Stack>
        <TextCallout
          title={t('pages.workspaceSettings.contentSection.labels.pricingTier')}
          text={subscription.data.sku}
        />
        {subscription.data.isCMKEnabled && (
          <TextCallout
            title={t('pages.workspaceSettings.contentSection.labels.customerManagedKeys.title')}
            text={t('pages.workspaceSettings.contentSection.labels.customerManagedKeys.text')}
          />
        )}
      </Fragment>
    );
  }

  if (isRetrainModelsRoute) {
    return (
      <ContentContainer>
        <RetrainModelsHistoryContent />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <PageHeading>{t('pages.workspaceSettings.pageName')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      {isOwner && <WorkspaceSettingsCommandBar />}
      <Stack className={classes.root}>
        {/* GENERAL SECTION */}
        <section>
          <Text as="h3" className={clsx(classes.sectionTitle, classes.titleNoSubtitle)}>
            {t('pages.workspaceSettings.contentSection.titles.general')}
          </Text>
          <TextCallout
            title={t('pages.workspaceSettings.contentSection.labels.workspaceName')}
            text={workspaces.currentWorkspace.name}
          />
          <TextCallout title={t('pages.workspaceSettings.contentSection.labels.createdBy')} text={createdByUserName} />
          <TextCallout title={t('pages.workspaceSettings.contentSection.labels.permissions')} text={roleName} />
          {isDefaultWorkspace && (
            <TextCallout
              title={t('pages.workspaceSettings.contentSection.labels.pinned.title')}
              text={t('pages.workspaceSettings.contentSection.labels.pinned.text')}
            />
          )}
          <Separator />
        </section>
        {/* SUBSCRIPTION SECTION */}
        <section>
          <Stack>
            <Text as="h3" className={classes.sectionTitle}>
              {t('pages.workspaceSettings.contentSection.titles.subscription')}
            </Text>
            <Text as="p">{t('pages.workspaceSettings.contentSection.subtitles.subscription')}</Text>
          </Stack>
          {subscriptionContent}
          <Separator />
        </section>
        {/* SHARING SECTION */}
        <section>
          <Stack>
            <Text as="h3" className={classes.sectionTitle}>
              {t('pages.workspaceSettings.contentSection.titles.shareSettings')}
            </Text>
            <Text as="p">
              {hasSharingIds
                ? t('pages.workspaceSettings.contentSection.subtitles.shareSettings.hasSharedItems')
                : t('pages.workspaceSettings.contentSection.subtitles.shareSettings.noSharedItems')}
            </Text>
            {hasSharingIds && (
              <ul className={classes.sharingList}>
                {sharingIds.map((sharingId) => {
                  const { user: userId } = sharing[sharingId];
                  const user = users[userId];

                  return (
                    <li key={userId}>
                      <Persona
                        text={user.userName || user.emailAddress}
                        size={PersonaSize.size24}
                        imageAlt={user.userName || user.emailAddress}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            <Separator />
          </Stack>
          <Stack>
            <Text as="h3" className={classes.sectionTitle}>
              Model version
            </Text>
            <p>
              Learn more about the latest{' '}
              <a
                href="https://learn.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/platform-upgrade"
                style={{ textDecoration: 'underline', color: SharedColors.cyanBlue10 }}
              >
                platform upgrade
              </a>
            </p>
            <div>
              <NavLink to={`/workspaces/${workspaceId}/workspace-settings/retrain-models`} className={classes.link}>
                View model retraining history
              </NavLink>
            </div>
          </Stack>
          <Separator />
        </section>
        <section>
          <Stack>
            <Text as="h3" className={classes.sectionTitle}>
              {t('pages.workspaceSettings.contentSection.publishedModelAccess.heading')}
            </Text>
            <Text as="p">
              {t('pages.workspaceSettings.contentSection.publishedModelAccess.lede')}{' '}
              <a href="https://learn.microsoft.com/azure/cognitive-services/translator/custom-translator/how-to/copy-model">
                {t('pages.workspaceSettings.contentSection.publishedModelAccess.docslink')}
              </a>
              .
            </Text>

            <Text as="h4" className={classes.sectionSubHeading}>
              {t('pages.workspaceSettings.contentSection.publishedModelAccess.subheading')}
            </Text>
            <PublishedModelAccessCheckbox
              label={t('pages.workspaceSettings.contentSection.publishedModelAccess.checkboxLabel')}
            />
          </Stack>
          <Separator />
        </section>
      </Stack>
    </ContentContainer>
  );
};

interface IPublishedModelAccessCheckboxProps {
  label: string;
}

const PublishedModelAccessCheckbox: React.FC<IPublishedModelAccessCheckboxProps> = ({ label }) => {
  const { workspaces } = useAppSelector((state) => state);
  const [isChecked, setIsChecked] = React.useState(workspaces.data[workspaces.currentWorkspace.id].isSecured);
  const onChange = React.useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      setIsChecked(!!checked);

      axios.put(`${process.env.REACT_APP_API_URL}workspaces/${workspaces.currentWorkspace.id}`, {
        name: workspaces.data[workspaces.currentWorkspace.id].name,
        isSecured: !!checked,
      });
    },
    [workspaces.currentWorkspace.id, workspaces.data]
  );

  return (
    <React.Fragment>
      <Checkbox label={label} checked={isChecked} onChange={onChange} />
    </React.Fragment>
  );
};

export default WorkspaceSettingsContent;
