import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearCreateWorkspaceWizard } from '../../../redux/createWorkspaceWizard/createWorkspaceWizardActions';
import { deleteWorkspace, setDefaultWorkspace } from '../../../redux/workspaces/workspacesActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import { CreateWorkspaceWizard } from '../../Forms';
import RenameWorkspaceForm from '../../Forms/RenameWorkspaceForm';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { WorkspacePopUpTypes } from '../../../utils/constants/popUps.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useGetCreateWorkspaceWizardTitle,
  useIsOwner,
} from '../../../utils/hooks';

const WorkspacesCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const { appModal, setAppModal } = useAppModal();
  const { appDialog, setAppDialog } = useAppDialog();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const {
    workspaces,
    entitySelections: { selectedWorkspace },
  } = useAppSelector((state) => state);

  const isOwner = useIsOwner(selectedWorkspace);
  const isDisabledWhenLoading = workspaces.isLoading || workspaces.isDeleting || workspaces.isUpdating;
  let isDefaultWorkspace = false;
  if (selectedWorkspace) {
    isDefaultWorkspace = workspaces.data[selectedWorkspace].isDefaultWorkspace;
  }

  const { slide } = useAppSelector((state) => state.createWorkspaceWizard);
  const wizardTitle = useGetCreateWorkspaceWizardTitle(slide);

  const toggleModal = (): void => {
    setAppModal({
      isOpen: !appModal.isOpen,
    });
  };

  /**
   * TODO: Discuss with Ikuyo overlap in functionality between this page and workspace settings page.
   * Evaluate if deleting/updating subscription key should also be supported from this view, and if
   * the hot key to "Edit workspace settings" is needed.
   */
  const leftItems: ICommandBarItemProps[] = [
    {
      key: 'workspacesCreateNew',
      text: t('components.commandBars.workspaces.createNewButton.text'),
      ariaLabel: t('components.commandBars.workspaces.createNewButton.aria'),
      iconProps: { iconName: 'Add' },
      onClick: (): void =>
        batch(() => {
          setAppModal({ contentType: WorkspacePopUpTypes.Create, isOpen: true });
          appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-create/open' });
        }),
    },
    {
      key: 'workspacesSharing',
      text: t('components.commandBars.common.share'),
      ariaLabel: t('components.commandBars.workspaces.shareButton.aria'),
      iconProps: { iconName: 'Share' },
      disabled: isDisabledWhenLoading || !selectedWorkspace || !isOwner,
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.ShareWorkspace);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
    {
      key: 'workspacesRename',
      text: t('components.commandBars.common.rename'),
      ariaLabel: t('components.commandBars.workspaces.renameButton.aria'),
      iconProps: { iconName: 'Rename' },
      disabled: isDisabledWhenLoading || !selectedWorkspace || !isOwner,
      onClick: (): void => {
        setAppModal({ contentType: WorkspacePopUpTypes.Rename, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-rename/open' });
      },
    },
    {
      key: 'workspacesDelete',
      text: t('components.commandBars.common.delete'),
      ariaLabel: t('components.commandBars.workspaces.deleteButton.aria'),
      iconProps: { iconName: 'Delete' },
      disabled: isDisabledWhenLoading || !selectedWorkspace || !isOwner,
      onClick: (): void => {
        setAppDialog({ contentType: WorkspacePopUpTypes.Delete, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-delete/open' });
      },
    },
    {
      key: 'setDefault',
      text: t('components.commandBars.workspaceSettings.setDefaultButton.text'),
      ariaLabel: t('components.commandBars.workspaceSettings.setDefaultButton.aria'),
      iconProps: { iconName: 'PinNavMenu' },
      disabled: isDisabledWhenLoading || !selectedWorkspace || isDefaultWorkspace,
      onClick: (): void => {
        appDispatch(setDefaultWorkspace(selectedWorkspace));
      },
    },
    // TODO: Implement workspace filter
    // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119688/?workitem=123199
    /** {
      key: 'workspacesFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.workspaces.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      disabled: true,
    }, */
  ];

  let message = t('components.commandBars.common.updating');
  if (workspaces.isRenaming) {
    message = t('components.commandBars.common.renaming');
  }
  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader isVisible={workspaces.isUpdating || workspaces.isRenaming} message={message} />
      ),
    },
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [WorkspacePopUpTypes.Delete]: {
      contentProps: {
        title: t('components.popups.workspace.delete.title'),
        subText: t('components.popups.workspace.delete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.workspace.delete.action'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(deleteWorkspace(selectedWorkspace));
          });
          history.push('/workspaces');
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;
  const handleCreateWorkspaceCancelAndClose = (): void => {
    setAppModal({ isOpen: false });
    appDispatch(clearCreateWorkspaceWizard());
  };

  const appModalContentMap: AppModalContentMap = {
    [WorkspacePopUpTypes.Create]: {
      maxWidth: 600,
      contentProps: {
        title: wizardTitle,
        body: <CreateWorkspaceWizard handleCancelAndClose={handleCreateWorkspaceCancelAndClose} />,
        cleanUp: handleCreateWorkspaceCancelAndClose,
      },
    },
    [WorkspacePopUpTypes.Rename]: {
      maxWidth: 500,
      contentProps: {
        title: t('components.popups.workspace.rename.title'),
        body: <RenameWorkspaceForm handleCancelAndClose={toggleModal} />,
      },
    },
  };
  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar items={leftItems} farItems={rightCommandBarItems} ariaLabel={t('components.commandBars.aria')} />
      <AppDialog
        showCancelButton={appDialog.showCancelButton}
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={appModalContent?.maxWidth || 700}
      />
    </Fragment>
  );
};

export default WorkspacesCommandBar;
