import {
  Dropdown,
  IColumn,
  IDropdownOption,
  IconButton,
  Link,
  Persona,
  PersonaSize,
  PrimaryButton,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { deleteUserRole, updateUserRole } from '../../redux/workspaces/workspacesActions';
import { AppDialog } from '../AppPopUps';
import DetailsListKeyboardAccessible from '../Tables/DetailsListKeyboardAccessible';
import useShareWorkspaceStyles from './ShareWorkspace.styles';

import { WorkspacePopUpTypes } from '../../utils/constants/popUps.types';
import IRoutePathParams from '../../utils/constants/routePathParams.types';
import { AppDialogContentMap, useAppDialog, useAppDispatch, useAppSelector } from '../../utils/hooks';

const ShareWorkspace: React.FC = () => {
  const classes = useShareWorkspaceStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { appDialog, setAppDialog } = useAppDialog();

  const {
    sharing,
    workspaces,
    users,
    entitySelections: { selectedWorkspace },
  } = useAppSelector((state) => state);
  const { workspaceId } = useParams<IRoutePathParams>();

  const [newEmail, setNewEmail] = useState<string>('');
  const [newRole, setNewRole] = useState<number>(0);
  const [selectedSharingId, setSelectedSharingId] = useState<string>('');
  const [currentWorkspace, setCurrentWorkspace] = useState<string>(workspaceId || selectedWorkspace);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    email: undefined,
    role: undefined,
  });

  useEffect(() => {
    if (selectedWorkspace) {
      setCurrentWorkspace(workspaceId || selectedWorkspace);
    }
  }, [selectedWorkspace, workspaceId]);

  const sharingIds = workspaces.data[currentWorkspace].sharing;
  const currentSharingEmails = sharingIds.map((sharingId) => users[sharing[sharingId].user].emailAddress);
  const hasSharingIds = !isEmpty(sharingIds);

  const roles = [
    { key: 1, text: t('components.shareWorkspace.owner') },
    { key: 2, text: t('components.shareWorkspace.editor') },
    { key: 3, text: t('components.shareWorkspace.reader') },
  ];

  const isEmailValid = (email: string): boolean => {
    return email.length > 5 && /\S+@\S+\.\S+/.test(email) && email.length <= 254;
  };

  const onEmailChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
      const inputName = (event.target as HTMLInputElement).name;

      if (newName && isEmailValid(newName)) {
        setErrors({ ...errors, [inputName]: undefined });
      }
      setNewEmail(newName || '');
    },
    [errors]
  );

  const onRoleChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setNewRole(Number(item.key));
      setErrors({ ...errors, role: undefined });
    }
  };

  const onExistingRoleUpdate = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      const sharingId = (event.target as HTMLInputElement).id;
      const { user: userId } = sharing[sharingId];
      appDispatch(updateUserRole(currentWorkspace, users[userId].emailAddress, String(item.key)));
    }
  };

  const handleSubmit = (): void => {
    const hasValidEmail = isEmailValid(newEmail);
    const hasRoleSelected = !(newRole === 0);
    if (!hasValidEmail) {
      setErrors((prevErrors) => ({ ...prevErrors, email: t('components.shareWorkspace.emailError') }));
      return;
    }
    if (!hasRoleSelected) {
      setErrors((prevErrors) => ({ ...prevErrors, role: t('components.forms.generalErrors.required') }));
      return;
    }
    appDispatch(updateUserRole(currentWorkspace, newEmail, String(newRole), true));
    setNewEmail('');
    setNewRole(0);
  };

  const userColumns: IColumn[] = [
    {
      key: 'user',
      name: t('components.shareWorkspace.columns.email'),
      minWidth: 130,
      onRender: (sharingId: string): React.ReactElement => {
        const { user: userId } = sharing[sharingId];
        const user = users[userId];

        return <Persona text={user.emailAddress} size={PersonaSize.size24} imageAlt={user.emailAddress} />;
      },
    },
    {
      key: 'role',
      name: t('components.shareWorkspace.columns.role'),
      minWidth: 170,
      onRender: (sharingId: string): React.ReactElement => {
        const { role, user: userId } = sharing[sharingId];
        const isUpdatingRole = !isEmpty(
          workspaces.updatingRoleEmails.find((email) => email === users[userId].emailAddress)
        );

        let iconContent = null;
        if (isUpdatingRole) {
          iconContent = <Spinner size={SpinnerSize.small} className={classes.loadingIcon} />;
        } else {
          iconContent = (
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel={t('components.gridPanel.closeButton')}
              className={classes.cancelIcon}
              onClick={(): void => {
                setSelectedSharingId(sharingId);
                setAppDialog({ contentType: WorkspacePopUpTypes.DeleteUserRole, isHidden: false });
              }}
            />
          );
        }
        return (
          <Fragment>
            <Dropdown
              id={sharingId}
              disabled={isUpdatingRole}
              className={classes.list}
              onChange={onExistingRoleUpdate}
              options={roles}
              selectedKey={role}
            />
            {iconContent}
          </Fragment>
        );
      },
    },
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [WorkspacePopUpTypes.DeleteUserRole]: {
      contentProps: {
        title: t('components.shareWorkspace.deleteDialog.title'),
        subText: t('components.shareWorkspace.deleteDialog.text', {
          emailAddress: users[sharing[selectedSharingId]?.user]?.emailAddress,
        }),
      },
      confirmationActionProps: {
        text: t('components.shareWorkspace.deleteDialog.actionButton'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(
              deleteUserRole(currentWorkspace, selectedSharingId, users[sharing[selectedSharingId].user].emailAddress)
            );
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  return (
    <Stack className={classes.stack} tokens={{ childrenGap: 10 }}>
      <Stack.Item>
        {hasSharingIds
          ? t('pages.workspaceSettings.contentSection.subtitles.shareSettings.hasSharedItems')
          : t('pages.workspaceSettings.contentSection.subtitles.shareSettings.noSharedItems')}
      </Stack.Item>
      <Stack.Item className={classes.restriction}>
        <Text>{`${t('components.shareWorkspace.restriction.message')} `}</Text>
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/create-manage-workspace#share-workspace-for-collaboration"
        >
          {t('components.shareWorkspace.restriction.link')}
        </Link>
      </Stack.Item>
      <Stack.Item className={classes.input}>
        <TextField
          name="email"
          value={newEmail}
          onChange={onEmailChange}
          placeholder={t('components.shareWorkspace.emailPlaceholder')}
          errorMessage={errors.email}
          className={classes.textInput}
        />
        <Dropdown
          className={classes.dropdown}
          onChange={onRoleChange}
          placeholder={t('components.shareWorkspace.dropdownPlaceholder')}
          options={roles}
          errorMessage={errors.role}
          selectedKey={newRole}
        />
      </Stack.Item>
      <Stack.Item>
        <PrimaryButton onClick={handleSubmit} text={t('components.shareWorkspace.actionButton')} />
      </Stack.Item>
      <Stack.Item>
        {(hasSharingIds || !isEmpty(workspaces.updatingRoleEmails)) && (
          <DetailsListKeyboardAccessible
            disableSelectionZone
            columns={userColumns}
            items={sharingIds || []}
            selectionMode={SelectionMode.none}
          />
        )}
        {/* Show users that are being added to the subscription
            these emails should appear on updateRoleEmails but not on currentSharingEmails */}
        {!isEmpty(workspaces.updatingRoleEmails) &&
          workspaces.updatingRoleEmails.map((emailAddress) => {
            if (isEmpty(currentSharingEmails.find((email) => email === emailAddress))) {
              return (
                <Persona
                  className={classes.loadingPersona}
                  text={emailAddress}
                  size={PersonaSize.size24}
                  imageAlt={emailAddress}
                  onRenderPersonaCoin={(): JSX.Element => {
                    return <Spinner size={SpinnerSize.medium} />;
                  }}
                />
              );
            }
            return <Fragment />;
          })}
      </Stack.Item>
      <AppDialog
        showCancelButton={appDialog.showCancelButton}
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
    </Stack>
  );
};

export default ShareWorkspace;
