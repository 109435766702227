import {
  CLEAR_ALL_ENTITY_SELECTIONS,
  CLEAR_SELECTED_DOCUMENT,
  CLEAR_SELECTED_MODEL,
  CLEAR_SELECTED_PROJECT,
  CLEAR_SELECTED_WORKSPACE,
  EntitySelectionDispatchTypes,
  SET_SELECTED_DOCUMENT,
  SET_SELECTED_MODEL,
  SET_SELECTED_PROJECT,
  SET_SELECTED_WORKSPACE,
} from './entitySelections.types';

interface IEntitySelectionState {
  selectedProject: string;
  selectedModel: string;
  selectedDocuments: string[];
  selectedWorkspace: string;
  previouslySelectedDocuments: string[];
}

const initialState: IEntitySelectionState = {
  selectedProject: '',
  selectedModel: '',
  selectedDocuments: [],
  selectedWorkspace: '',
  previouslySelectedDocuments: [],
};

const entitySelectionsReducer = (
  state: IEntitySelectionState = initialState,
  action: EntitySelectionDispatchTypes
): IEntitySelectionState => {
  switch (action.type) {
    case CLEAR_ALL_ENTITY_SELECTIONS: {
      return initialState;
    }
    case SET_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
      };
    }
    case SET_SELECTED_MODEL: {
      return {
        ...state,
        selectedModel: action.payload,
      };
    }
    case SET_SELECTED_DOCUMENT: {
      return {
        ...state,
        previouslySelectedDocuments: state.selectedDocuments,
        selectedDocuments: action.payload,
      };
    }
    case SET_SELECTED_WORKSPACE: {
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    }
    case CLEAR_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: initialState.selectedProject,
      };
    }
    case CLEAR_SELECTED_MODEL: {
      return {
        ...state,
        selectedModel: initialState.selectedModel,
      };
    }
    case CLEAR_SELECTED_DOCUMENT: {
      return {
        ...state,
        previouslySelectedDocuments: initialState.previouslySelectedDocuments,
        selectedDocuments: initialState.selectedDocuments,
      };
    }
    case CLEAR_SELECTED_WORKSPACE: {
      return {
        ...state,
        selectedWorkspace: initialState.selectedWorkspace,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default entitySelectionsReducer;
