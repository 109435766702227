export const ADD_TO_TELEMETRY_QUEUE = 'ADD_TO_TELEMETRY_QUEUE';
export const DELETE_FROM_TELEMETRY_QUEUE = 'DELETE_FROM_TELEMETRY_QUEUE';

export interface IAddToTelemetryQueue {
  type: typeof ADD_TO_TELEMETRY_QUEUE;
  payload: string;
}

export interface IDeleteFromTelemetryQueue {
  type: typeof DELETE_FROM_TELEMETRY_QUEUE;
}

export type TelemetryDispatchTypes = IAddToTelemetryQueue | IDeleteFromTelemetryQueue;
