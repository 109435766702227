import merge from 'lodash/merge';

import { ILanguageItem, LanguagesDispatchTypes, SET_LANGUAGES } from './languages.types';

export interface ILanguagesState {
  [key: string]: ILanguageItem;
}

const regionsReducer = (state: ILanguagesState = {}, action: LanguagesDispatchTypes): ILanguagesState => {
  switch (action.type) {
    case SET_LANGUAGES: {
      return merge(state, action.payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default regionsReducer;
