import { makeStyles } from '@fluentui/react';

const useWorkspaceContentStyles = makeStyles((theme) => ({
  pivot: {
    selectors: {
      '.ms-Pivot': {
        marginBottom: theme.spacing.s1,
      },
    },
  },
}));

export default useWorkspaceContentStyles;
