import isEmpty from 'lodash/isEmpty';

import { ILanguagesState } from '../redux/languages/languagesReducer';
import { IProjectsState } from '../redux/projects/projectsReducer';

export default function filterDocumentsDispatch({
  showMonolingualDocuments,
  documentSearchTerm,
  selectedDocumentTypeKeys,
  projectId,
  projects,
  languages,
  selectedLanguageKeys,
}: {
  showMonolingualDocuments: boolean | undefined;
  documentSearchTerm: string | string[];
  selectedDocumentTypeKeys: string[];
  projectId: string;
  projects: IProjectsState;
  languages: ILanguagesState;
  selectedLanguageKeys: string[];
}): {
  isFiltering: boolean;
  filter: string;
} {
  let isFiltering = false;
  // build filter string
  let filter = `isAvailable eq 'True' and isParallel eq true`;

  if (showMonolingualDocuments) {
    filter = `isAvailable eq 'True'`;
  }
  if (!isEmpty(documentSearchTerm)) {
    filter += ` and substringof(Name,'${documentSearchTerm}')`;
    isFiltering = true;
  }
  if (!isEmpty(selectedDocumentTypeKeys)) {
    filter += ` and documentType eq '${selectedDocumentTypeKeys.toString()}'`;
    isFiltering = true;
  }
  if (projectId) {
    const currentProject = projects.data[projectId];
    const sourceLanguage = languages[currentProject.languagePair.sourceLanguage];
    const targetLanguage = languages[currentProject.languagePair.targetLanguage];

    filter += ` and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}'`;
  } else if (!isEmpty(selectedLanguageKeys)) {
    filter += ` and languageCode eq '${selectedLanguageKeys.toString()}'`;
    isFiltering = true;
  }

  return {
    isFiltering,
    filter,
  };
}
