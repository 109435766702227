interface IProjectDocumentsTableProps {
  isMultiSelect?: boolean;
  // If the inline actions overflow menu in the name column of the table should be shown
  showInlineCommands?: boolean;
  hidePagination?: boolean;
}

enum SelectionAction {
  Adding = 0,
  Replacing = 1,
  Removing = 2,
}

export { SelectionAction };
export type { IProjectDocumentsTableProps };
