import { IStackTokens, IconButton, Label, Stack, Text, useTheme } from '@fluentui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useFileInputButtonStyles from './FileInputButton.styles';
import IFileInputButton from './FileInputButton.types';

const FileInputButton: React.FC<IFileInputButton> = ({
  text,
  label,
  inputId,
  onChange,
  fileName,
  accept,
  error,
  unselectFile,
}) => {
  const classes = useFileInputButtonStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  let buttonClassName = `${classes.buttonOuter}`;
  if (error) {
    buttonClassName += ` ${classes.buttonError}`;
  }

  const fileNameStackTokens: IStackTokens = {
    childrenGap: theme.spacing.m,
  };

  return (
    <Stack>
      {label && (
        <Label required htmlFor={inputId}>
          {label}
        </Label>
      )}
      {!fileName && (
        <Fragment>
          <div className={buttonClassName}>
            <span>{text}</span>
            <input
              id={inputId}
              name={inputId}
              type="file"
              className={classes.buttonInner}
              onChange={onChange}
              accept={accept}
              required
            />
          </div>
          <Text variant="small" className={classes.errorText}>
            {error}
          </Text>
        </Fragment>
      )}
      {fileName && (
        <Stack
          horizontal
          verticalAlign="center"
          tokens={fileNameStackTokens}
          styles={{ root: { marginLeft: theme.spacing.s1 } }}
        >
          <Text block nowrap variant="medium" className={classes.fileName}>
            {fileName}
          </Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={t('components.popups.common.closeAria')}
            onClick={unselectFile}
            className={classes.closeButton}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default FileInputButton;
