import { Text, TooltipHost } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootStore } from '../../../../redux/Store';
import { TextCell } from '../../SharedTableCells';
import useSharingCellStyles from './SharingCell.styles';

interface ISharingCellProps {
  sharingIds: number[];
}

const SharingCell: React.FC<ISharingCellProps> = ({ sharingIds }) => {
  const classes = useSharingCellStyles();
  const { t } = useTranslation();
  const tooltipId = useId('tooltip');
  const { roles, sharing, users } = useSelector((state: RootStore) => state);

  const sharingTooltipContent = (): string => {
    const sharingList: string[] = [];
    sharingIds.forEach((shareId) => {
      const { user, role } = sharing[shareId];
      const { emailAddress } = users[user];
      const { roleName } = roles[role];

      sharingList.push(`${emailAddress}(${roleName})`);
    });

    return sharingList.join(', ');
  };

  let content;

  if (sharingIds.length > 0) {
    content = (
      <TooltipHost content={`${sharingTooltipContent()}`} id={tooltipId} hostClassName={classes.root}>
        <Text as="button" variant="small" aria-describedby={tooltipId} style={{ all: 'unset' }}>{`${t(
          'components.tables.workspaces.sharingCell.shared'
        )} (${sharingIds.length})`}</Text>
      </TooltipHost>
    );
  } else {
    content = <TextCell text={t('components.tables.workspaces.sharingCell.private')} />;
  }

  return content;
};

export default SharingCell;
