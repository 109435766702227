import { makeStyles } from '@fluentui/react';

import components from '../../../utils/constants/components';

const usePaginatedDetailsListStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: `calc(100vh - ${components.ScrollableDetailsList.documentsListNonScrollableContent}px)`,
    minHeight: '240px',
  },
}));

export default usePaginatedDetailsListStyles;
