import { Stack, useTheme } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';

import useBadgeStyles from './Badge.styles';

interface IBadgeProps {
  text: string | JSX.Element;
  /** Optional: determine the size of the badge. Small ex. tables, default = medium, large ex. Model Details */
  size?: 'small' | 'medium' | 'large';
  /** Optional: default is 'success' displays solid blue badge with white text, 'failure' is a red outline and red text */
  type?: 'success' | 'failure';
  /** Optional: display vertically centered icon */
  icon?: JSX.Element;
}

const Badge: React.FC<IBadgeProps> = ({ text, size = 'medium', type, icon }) => {
  const classes = useBadgeStyles();
  const theme = useTheme();
  const badgeTypeClass = type === 'failure' ? classes.failure : classes.success;
  const badgeClass = clsx(classes.root, classes[size], badgeTypeClass);

  return (
    <Stack className={badgeClass} horizontal tokens={{ childrenGap: theme.spacing.s1 }} verticalAlign="center">
      {icon && <Stack>{icon}</Stack>}
      <Stack.Item>{text}</Stack.Item>
    </Stack>
  );
};

export default Badge;
