import React, { useState } from "react";
import { FontIcon, Text } from "@fluentui/react";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeComponent, useLocalization, withLocalization } from "../../../services/localization";
import styled from "styled-components";
import { INTL } from "../../../util/intlUtil";
import { BannerLocalizationFormatMessages } from "../../../clientResources";

initializeIcons(undefined, { disableWarnings: true });

export interface RecruitmentBannerProps {
    onClose: Function;
    headerText: string;
    subHeaderText: string;
    buttons: JSX.Element;
};

const StyledBannerDiv = styled.div`
    width: 100%;
    height: 100%;
    font-size: 12px;
    outline: 2px solid transparent;
    background-color: #f3f2f1;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 0 0 0 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .studio-banner-left {
        height: 100%;
        padding: 3px;
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        @media only screen and (max-width: 460px) {
            display: inline-block;
        }
        .studio-banner-content{
            margin: 0 8px;
            font-weight: bold;
            font-size: 12px;
            @media only screen and (min-width: 990px) {
                min-width: 160px;
            }
        }
        .studio-banner-sub-content{
            font-size: 12px;
            padding-right: 10px;
            @media only screen and (max-width: 460px) {
                display: inline-block;
                padding-left: 20px;
                padding-top: 5px;           
            }
        }
        .buttons-div{
            @media only screen and (max-width: 460px) {
                display: inline-block;
                padding-left: 20px;    
                padding-top: 5px;
                padding-left: 17px;
            }
        }
        .studio-banner-button {
            box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.15);
            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            height: auto;
            background-color: #fff;
            border: 1px solid #8a8886;
            cursor: pointer;
            margin-left: 10px;
            margin: 3px;
        }
    }
    #studio-banner-close-button {
        width: 32px;
        height: 32px;
        border: none;
        cursor: pointer;
    }
`;

const RecruitmentBannerInternal = (props: RecruitmentBannerProps) => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);

    function handleCancel() {
        setIsBannerVisible(false);
        props.onClose && props.onClose();
    };

    return (
        <div>
            {isBannerVisible
                && (<StyledBannerDiv className="studio-banner">
                    <div className="studio-banner-left">
                        <FontIcon
                            iconName="Info"
                            title={"Info-icon"}
                        />
                        <Text
                            className="studio-banner-content"
                        >
                            {props.headerText}
                        </Text>
                        <Text
                            className="studio-banner-sub-content"
                        >
                            {props.subHeaderText}
                        </Text>
                        <div className="buttons-div">{props.buttons}</div>
                    </div>
                    <button
                        id="studio-banner-close-button"
                        onClick={handleCancel}
                    >
                        <FontIcon
                            title={INTL.formatMessage(BannerLocalizationFormatMessages.Close)}
                            iconName="CalculatorMultiply"
                        />
                    </button>
                </StyledBannerDiv>)}
        </div>
    );
};

export const RecruitmentBannerArea = withLocalization(initializeComponent(RecruitmentBannerInternal));