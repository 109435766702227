import { ADD_TO_TELEMETRY_QUEUE, DELETE_FROM_TELEMETRY_QUEUE, TelemetryDispatchTypes } from './telemetry.types';

interface ITelemetryState {
  messages: string[];
}

const initialState: ITelemetryState = {
  messages: [],
};

const TelemetryReducer = (state: ITelemetryState = initialState, action: TelemetryDispatchTypes): ITelemetryState => {
  switch (action.type) {
    case ADD_TO_TELEMETRY_QUEUE: {
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    }
    case DELETE_FROM_TELEMETRY_QUEUE: {
      const dequeue = (queue: string[]): string[] => {
        queue.shift();
        return queue;
      };
      return {
        ...state,
        messages: dequeue(state.messages),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default TelemetryReducer;
