import { Icon, IconButton, TooltipHost } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import useNameGroupCellStyles from './NameGroupCell.styles';

interface INameGroupCellProps {
  link: {
    href: string;
    iconName?: string;
    name: string;
  };
  moreOptions?: boolean;
  pinItem?: boolean;
  showLink?: boolean;
  isCopied?: boolean;
}

const NameGroupCell: React.FC<INameGroupCellProps> = ({
  link,
  moreOptions = false,
  pinItem = false,
  showLink = true,
  isCopied = false,
}) => {
  const classes = useNameGroupCellStyles();
  const { t } = useTranslation();
  const moreOptionsId = useId('more-options-tooltip');
  const pinItemId = useId('pin-item-tooltip');

  return (
    <div data-test-id="name-group-cell" className={classes.root}>
      {showLink ? (
        <div className={classes.nameGroup}>
          {link.iconName && <Icon iconName={link.iconName} className={classes.nameGroupIcon} />}
          <NavLink className={classes.underlineName} to={link.href}>
            <span className={classes.nameGroupText}>{link.name}</span>
          </NavLink>
          {isCopied && 'Copied model'}
        </div>
      ) : (
        <div className={classes.nameGroup}>
          {link.iconName && <Icon iconName={link.iconName} className={classes.nameGroupIcon} />}
          <span className={`${classes.nameGroupText} noLinkName`}>{link.name}</span>
          {isCopied && 'Copied model'}
        </div>
      )}
      <div>
        {moreOptions && (
          <TooltipHost content={t('components.tables.sharedCells.nameGroupCell.moreOptions.text')} id={moreOptionsId}>
            <IconButton
              iconProps={{ iconName: 'MoreVertical' }}
              title={t('components.tables.sharedCells.nameGroupCell.moreOptions.text')}
              ariaLabel={t('components.tables.sharedCells.nameGroupCell.moreOptions.aria')}
              disabled
            />
          </TooltipHost>
        )}
        {pinItem && (
          <TooltipHost content={t('components.tables.sharedCells.nameGroupCell.pinItem.text')} id={pinItemId}>
            <IconButton
              iconProps={{ iconName: 'Pin' }}
              title={t('components.tables.sharedCells.nameGroupCell.pinItem.text')}
              ariaLabel={t('components.tables.sharedCells.nameGroupCell.pinItem.aria')}
              disabled
            />
          </TooltipHost>
        )}
      </div>
    </div>
  );
};

export default NameGroupCell;
