import { GET_SUPPORTED_LANGUAGES, ILanguage, SupportedLanguagesDispatchTypes } from './supportedLanguages.types';

interface ISuportedLanguagesState {
  data: {
    [key: string]: ILanguage;
  } | null;
}

const initialState: ISuportedLanguagesState = {
  data: null,
};

const supportedLanguagesReducer = (
  state: ISuportedLanguagesState = initialState,
  action: SupportedLanguagesDispatchTypes
): ISuportedLanguagesState => {
  switch (action.type) {
    case GET_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default supportedLanguagesReducer;
