import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { reactPlugin } from '../components/AppInsights';
import Error from '../components/GenericStates/Error';
import { SET_ERROR } from '../redux/error/error.types';

import ErrorStatusCodes from '../utils/constants/ErrorStatusCodes';
import ErrorCategories from '../utils/constants/errorCategories';
import { useAppDispatch } from '../utils/hooks';

const NotFound: React.FC = () => {
  useTrackMetric(reactPlugin, 'NotFoundPage');

  const dispatch = useAppDispatch();
  const [hasSetError, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!hasSetError) {
      dispatch({
        type: SET_ERROR,
        payload: {
          message: '',
          statusCode: ErrorStatusCodes.ResourceNotFound,
          category: ErrorCategories.Fallback,
        },
      });
      setError(true);
    }
  }, [dispatch, hasSetError]);

  return (
    <Fragment>
      <Helmet>
        <title>{process.env.REACT_APP_APPLICATION_NAME}</title>
      </Helmet>
      <Error />
    </Fragment>
  );
};

export default NotFound;
