import { merge } from 'lodash';

import {
  ADD_TRAINING_NOTIFICATION,
  CLEAR_ALL_TRAINING_NOTIFICATIONS,
  CLEAR_TRAINING_NOTIFICATION,
  ITrainingNotificationItem,
  TrainingNotificationsDispatchTypes,
  UPDATE_MODELID_TRAINING_NOTIFICATION,
  UPDATE_TRAINING_NOTIFICATION,
} from './trainingNotifications.types';

import { ModelStatus } from '../../utils/constants/modelStatus';
import { TrainingErrorCodes } from '../../utils/constants/trainingTypes';

export interface ITrainingNotificationsState {
  data: {
    [key: string]: ITrainingNotificationItem;
  };
}

const initialState: ITrainingNotificationsState = { data: {} };

const filterNotificationData = (
  id: string,
  state: {
    [key: string]: ITrainingNotificationItem;
  }
): { [key: string]: ITrainingNotificationItem } => {
  const notificationData = Object.entries(state);

  const filterNotificationDataArray = notificationData.filter(([key]) => {
    return key !== id;
  });

  return Object.fromEntries(filterNotificationDataArray);
};

const trainingNotificationsReducer = (
  state: ITrainingNotificationsState = initialState,
  action: TrainingNotificationsDispatchTypes
): ITrainingNotificationsState => {
  switch (action.type) {
    case ADD_TRAINING_NOTIFICATION: {
      const filteredData = filterNotificationData(action.payload.id, state.data);
      const newNotification = {
        [action.payload.id]: {
          id: action.payload.id,
          name: action.payload.data.name,
          modelStatus: ModelStatus.TrainingSubmitted,
          errorCode: TrainingErrorCodes.NoError,
        },
      };
      return {
        ...state,
        data: merge(filteredData, newNotification),
      };
    }
    case UPDATE_TRAINING_NOTIFICATION: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            modelStatus: action.payload.data.modelStatus,
            errorCode: action.payload.data.errorCode,
          },
        },
      };
    }
    case CLEAR_TRAINING_NOTIFICATION: {
      return {
        ...state,
        data: filterNotificationData(action.payload, state.data),
      };
    }
    case CLEAR_ALL_TRAINING_NOTIFICATIONS: {
      return initialState;
    }
    case UPDATE_MODELID_TRAINING_NOTIFICATION: {
      const { oldId } = action.payload.data;
      const currentNotification = state.data[oldId];
      const filteredData = filterNotificationData(oldId, state.data);
      const newNotification = {
        [action.payload.id]: {
          ...currentNotification,
          id: action.payload.id,
        },
      };
      return {
        ...state,
        data: merge(filteredData, newNotification),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default trainingNotificationsReducer;
