import { DetailsList, DetailsListLayoutMode, Selection, SharedColors } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getModelsVersion, modelsVersionSelect } from '../../../redux/modelsVersion/modelsVersionActions';
import { getProjects } from '../../../redux/projects/projectsActions';
import BleuScore from '../../BleuScore';
import ErrorFallback from '../../ErrorFallback';
import { Loading } from '../../GenericStates';
import ModelVersionModal from '../../ModelVersionModal';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const RetrainModelsHistoryContent: React.FC = () => {
  const { t } = useTranslation();
  const { modelsVersion, projects } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams<IRoutePathParams>();
  const [initialized, setInitialized] = useState(false);

  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: (): void => {
        const selections = selectionState.getSelection();

        dispatch(modelsVersionSelect({ modelIds: selections.map((selection: any) => selection.id) }));
      },
    })
  );

  const breadcrumbItems = [
    {
      text: t('pages.retrainModels.pageName'),
      key: 'retrainModels',
    },
  ];

  const models = modelsVersion.models
    ? modelsVersion.models.map((model) => (model.retrainedModel ? model.retrainedModel : model))
    : [];

  // Fetch models version if needed.
  useEffect(() => {
    batch(() => {
      if ((modelsVersion.fetch && !modelsVersion.isLoading) || (!modelsVersion.isLoading && !modelsVersion.models)) {
        dispatch(getModelsVersion({ workspaceId }));
      }
      if (Object.keys(projects.data).length === 0 && !projects.isLoading) {
        dispatch(getProjects(workspaceId));
      }
    });
  }, [
    dispatch,
    modelsVersion.fetch,
    modelsVersion.isLoading,
    modelsVersion.models,
    projects.data,
    projects.isLoading,
    workspaceId,
  ]);

  useEffect(() => {
    setInitialized(true);
  }, [initialized]);

  if (Object.keys(projects.data).length === 0 || !modelsVersion.models || modelsVersion.isLoading) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Loading loadingText={t('pages.models.contentSection.loading')} isFullHeight />
      </ErrorBoundary>
    );
  }

  return (
    <React.Fragment>
      <PageHeading>{t('pages.retrainModels.pageName')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      <p style={{ padding: '0 8px' }}>{t('pages.retrainModels.lede')}</p>
      <ModelVersionModal variant="command" />
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={models}
        selection={selectionState}
        columns={[
          {
            key: 'modelName',
            name: 'Model name',
            fieldName: 'name',
            minWidth: 240,
            maxWidth: 320,
          },
          {
            key: 'projectName',
            name: 'Project name',
            fieldName: 'projectName',
            minWidth: 140,
            maxWidth: 220,
            onRender: (item): React.ReactElement => {
              return (
                <Link
                  to={`/workspaces/${workspaceId}/projects/${item.projectId}`}
                  style={{
                    color: SharedColors.cyanBlue10,
                    textDecoration: 'underline',
                  }}
                >
                  {projects.data[item.projectId].name}
                </Link>
              );
            },
          },
          {
            key: 'blueScore',
            name: 'BLEU score',
            fieldName: 'bleuScoreCIPunctuated',
            minWidth: 70,
            maxWidth: 100,
            onRender: (item): React.ReactElement | null => {
              const { bleuScoreCIPunctuated, baselineBleuScoreCIPunctuated } = item;

              if (bleuScoreCIPunctuated && baselineBleuScoreCIPunctuated) {
                return (
                  <BleuScore bleuScore={bleuScoreCIPunctuated} baselineBleuScore={baselineBleuScoreCIPunctuated} />
                );
              }

              return null;
            },
          },
          {
            key: 'baselineScore',
            name: 'Baseline Score',
            fieldName: 'baselineBleuScoreCIPunctuated',
            minWidth: 70,
            maxWidth: 100,
          },
          {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 70,
            maxWidth: 100,
            onRender: (item): React.ReactElement => {
              if (item.retrainedFromSourceModelId) {
                return <span>{item.modelStatus}</span>;
              }

              return <span>Not retrained</span>;
            },
          },
          {
            key: 'creditAvailable',
            name: 'Free credit available?',
            fieldName: 'creditAvailable',
            minWidth: 100,
            maxWidth: 120,
            onRender: (item): React.ReactElement => {
              if (item.modelType === 'frantic') {
                return <span>Available</span>;
              }

              return <span>Credit used</span>;
            },
          },
        ]}
        setKey="set"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
      />
    </React.Fragment>
  );
};

export default RetrainModelsHistoryContent;
