import {
  CLEAR_DOCUMENTS,
  CLEAR_MODEL_NAME,
  CLEAR_TRAIN_MODEL_DATA,
  SET_MODEL_NAME,
  SET_TRAINING_TYPE,
  TrainModelDispatchTypes,
  UPDATE_ADD_DOCUMENTS,
  UPDATE_REMOVE_DOCUMENTS,
} from './trainModel.types';

import { TrainingTypes } from '../../utils/constants/trainingTypes';

interface ITrainModelState {
  modelName: string;
  trainingType: string;
  addDocuments: number[];
  removeDocuments: number[];
}

const initialState: ITrainModelState = {
  modelName: '',
  trainingType: TrainingTypes.FullTraining,
  addDocuments: [],
  removeDocuments: [],
};

const trainModelReducer = (
  state: ITrainModelState = initialState,
  action: TrainModelDispatchTypes
): ITrainModelState => {
  switch (action.type) {
    case SET_MODEL_NAME: {
      return {
        ...state,
        modelName: action.payload,
      };
    }
    case SET_TRAINING_TYPE: {
      return {
        ...state,
        trainingType: action.payload,
      };
    }
    case CLEAR_MODEL_NAME: {
      return {
        ...state,
        modelName: initialState.modelName,
      };
    }
    case UPDATE_ADD_DOCUMENTS: {
      return {
        ...state,
        addDocuments: action.payload,
      };
    }
    case UPDATE_REMOVE_DOCUMENTS: {
      return {
        ...state,
        removeDocuments: action.payload,
      };
    }
    case CLEAR_DOCUMENTS: {
      return {
        ...state,
        addDocuments: initialState.addDocuments,
        removeDocuments: initialState.removeDocuments,
      };
    }
    case CLEAR_TRAIN_MODEL_DATA: {
      return initialState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default trainModelReducer;
