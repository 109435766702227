import merge from 'lodash/merge';

import { IRoleItem, RolesDispatchTypes, SET_ROLES } from './roles.types';

interface IRolesState {
  [key: string]: IRoleItem;
}

const entitiesReducer = (state: IRolesState = {}, action: RolesDispatchTypes): IRolesState => {
  switch (action.type) {
    case SET_ROLES: {
      return merge(state, action.payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default entitiesReducer;
