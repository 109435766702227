import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import ErrorFallback from '../components/ErrorFallback';
import { Error, Loading } from '../components/GenericStates';
import { WorkspaceSettingsContent } from '../components/PageContents';
import { getSubscription } from '../redux/subscription/subscriptionActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../utils/hooks';

const WorkspaceSettings: React.FC = () => {
  useTrackMetric(reactPlugin, 'WorkspaceSettingsPage');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams<IRoutePathParams>();
  const {
    workspaces: { ids, isLoading, currentWorkspace },
    subscription,
    error,
  } = useAppSelector((state) => state);
  const isSettingsLoading = isLoading || subscription.isLoading;

  // Fetch workspaces on load
  useEffect(() => {
    const hasWorkspaces = !isEmpty(ids);
    if (!hasWorkspaces && !isLoading && isEmpty(error.message) && error.category !== ErrorCategories.Workspaces) {
      dispatch(getWorkspaces());
    }
  }, [dispatch, ids, isLoading, error, workspaceId]);

  // Fetch workspace subscription data based on currentWorkspace ID being set
  useEffect(() => {
    if (currentWorkspace.id) {
      dispatch(getSubscription(currentWorkspace.id));
    }
  }, [dispatch, currentWorkspace.id]);

  let content = null;

  if (isSettingsLoading) {
    content = <Loading loadingText={t('pages.workspaceSettings.loading')} isFullHeight />;
  } else if (!isEmpty(error.message) && error.category && ErrorCategories.Subscription) {
    content = <Error />;
  } else if (!isEmpty(currentWorkspace)) {
    content = <WorkspaceSettingsContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {/* 
            Using ternary operator here instead of short circuit logic because 'false' will be returned in the title if no name.
            Empty string is used because 'undefined' will be returned in the title otherwise. 
          */}
          {`${process.env.REACT_APP_APPLICATION_NAME} ${
            !isEmpty(currentWorkspace) ? `| ${currentWorkspace.name} ${t('pages.workspaceSettings.settings')}` : ''
          }`}
        </title>
      </Helmet>
      <ErrorBoundary FallbackComponent={ErrorFallback}>{content}</ErrorBoundary>
    </Fragment>
  );
};

export default WorkspaceSettings;
