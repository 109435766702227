export const SET_SHARING = 'SET_SHARING';
export const CLEAR_SHARING = 'CLEAR_SHARING';

export type ISharingItem = {
  id: number;
  user: string;
  role: string;
};

export interface ISetSharing {
  type: typeof SET_SHARING;
  payload: {
    [key: string]: ISharingItem;
  };
}

export interface IClearSharing {
  type: typeof CLEAR_SHARING;
}

export type SharingDispatchTypes = ISetSharing | IClearSharing;
