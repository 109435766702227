import { DefaultButton, IconButton, Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useAppModalStyles from './AppModal.styles';
import { IAppModalProps } from './AppModal.types';

/**
 * Wrapper component for Fluent UI Modal popup.
 * Unlike AppDialog this component is used for complex situations requiring
 * more then text and confirmation action.
 * As well custom styling may be applied to accommodate custom components rendering
 * in the Modal body section.
 */
const AppModal: React.FC<IAppModalProps> = ({
  open = false,
  toggleModal,
  isBlocking = false,
  contentProps = undefined,
  confirmationActionProps,
  isFullWidth = false,
  maxWidth = 'none',
  useDefaultFooter = false,
}) => {
  const classes = useAppModalStyles();
  const { t } = useTranslation();

  const containerClassName = clsx(classes.root, isFullWidth && classes.fullWidth);

  const closeModal = (): void => {
    toggleModal();
    if (contentProps?.cleanUp) {
      contentProps.cleanUp();
    }
  };

  return contentProps ? (
    <Modal
      isOpen={open}
      onDismiss={closeModal}
      isBlocking={isBlocking}
      containerClassName={containerClassName}
      styles={{ main: { maxWidth } }}
    >
      {contentProps.customHeader ? (
        contentProps.customHeader
      ) : (
        <header className={classes.header}>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Text variant="xLarge" as="h2">
              {contentProps.title}
            </Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel={t('components.popups.common.closeAria')}
              onClick={closeModal}
              className={classes.closeButton}
            />
          </Stack>
          {contentProps?.subText && (
            <Text className={classes.subText} as="p">
              {contentProps.subText}
            </Text>
          )}
        </header>
      )}
      <div className={classes.body}>{contentProps.body}</div>
      {useDefaultFooter && (
        <footer className={classes.footer}>
          {confirmationActionProps && (
            <PrimaryButton onClick={confirmationActionProps.onClick} text={confirmationActionProps.text} />
          )}
          <DefaultButton onClick={closeModal} text={t('components.popups.common.cancel')} />
        </footer>
      )}
    </Modal>
  ) : null;
};

export default AppModal;
