import { Text } from '@fluentui/react';
import dayjs from 'dayjs';
import { keys } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IModelItem } from '../../../redux/models/models.types';
import { updateModelPublishStatus } from '../../../redux/models/modelsActions';
import FormActions from '../Shared/FormActions/FormActions';
import { IModelRegionStatusFormItem, IUpdatePublishFormValues } from '../UpdatePublishForm/UpdatePublishForm.types';
import useSwapModelFormStyles from './SwapModelForm.styles';
import { ISwapModelFormProps } from './SwapModelForm.types';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import PublishOperation from '../../../utils/constants/publishOperation';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { useCreateRegionList } from '../../../utils/hooks/usePublishTextHelper';

const SwapModelForm: React.FC<ISwapModelFormProps> = ({ handleCancelAndClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useSwapModelFormStyles();
  const {
    models,
    entitySelections: { selectedModel },
  } = useAppSelector((state) => state);
  const { modelId, projectId } = useParams<IRoutePathParams>();
  let model = models.data[modelId] || null;
  if (model === null) {
    model = models.data[selectedModel] || null;
  }

  const otherModels = keys(models.data);
  let otherModel: IModelItem = model;
  otherModels.forEach((key) => {
    const currentModel = models.data[key];
    if (currentModel.modelStatus === ModelStatus.Published) {
      otherModel = currentModel;
    }
  });

  const regionsText = useCreateRegionList(otherModel.modelRegionStatuses);

  const [formValues] = useState(
    (): IUpdatePublishFormValues => {
      let modelRegionStatuses: IModelRegionStatusFormItem[] = [];
      if (otherModel.modelRegionStatuses) {
        modelRegionStatuses = otherModel.modelRegionStatuses.map((item) => {
          return {
            ...item,
            isChanged: item.isDeployed,
          };
        });
      }
      const initialState = {
        projectId,
        modelId: model.id,
        modelName: model.name,
        nextModelStatus: ModelStatus.Publishing,
        modelRegionStatuses,
      };
      return initialState;
    }
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    dispatch(
      updateModelPublishStatus(formValues, PublishOperation.Swap, {
        modelId: otherModel.id,
        modelName: otherModel.name,
      })
    );
    handleCancelAndClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Text>{`${t('components.forms.swap.subtext', { actualModel: otherModel.name })}`}</Text>
      <Text block className={classes.subHeadings}>{`${t('components.commandBars.model.publishButton.text')}`}</Text>
      <Text block className={classes.newModel}>{`${model.name}`}</Text>
      <span className={classes.oldModel}>
        <Text>{`${t('components.forms.swap.regions')} `}</Text>
        <Text>{`${regionsText}`}</Text>
      </span>
      <Text block className={classes.subHeadings}>{`${t('components.commandBars.model.unpublishButton.text')}`}</Text>
      <Text block className={classes.oldModel}>{`${otherModel.name}`}</Text>
      <span className={classes.oldModel}>
        <Text>{`${t('components.forms.swap.publishedSince')} `}</Text>
        <Text>{dayjs(otherModel.modifiedDate).format('MM/DD/YYYY')}</Text>
      </span>
      <br />
      <span className={classes.oldModel}>
        <Text>{`${t('components.forms.swap.regions')} `}</Text>
        <Text>{`${regionsText}`}</Text>
      </span>
      <FormActions
        primaryButtonText={`${t('components.commandBars.model.publishButton.text')}`}
        isDisabled={false}
        handleCancelAndClose={handleCancelAndClose}
        onCancelTelemetryMessage="model-update-publish-status/cancel"
      />
    </form>
  );
};

export default SwapModelForm;
