import { merge } from 'lodash';

import {
  ADD_DEPLOYMENT_NOTIFICATION,
  CLEAR_ALL_DEPLOYMENT_NOTIFICATIONS,
  CLEAR_DEPLOYMENT_NOTIFICATION,
  DeploymentNotificationsDispatchTypes,
  IDeploymentNotificationItem,
  UPDATE_DEPLOYMENT_NOTIFICATION,
} from './deploymentNotifications.types';

export interface IDeploymentNotificationsState {
  data: {
    [key: string]: IDeploymentNotificationItem;
  };
}

const initialState: IDeploymentNotificationsState = { data: {} };

const filterNotificationData = (
  id: string,
  state: {
    [key: string]: IDeploymentNotificationItem;
  }
): { [key: string]: IDeploymentNotificationItem } => {
  const notificationData = Object.entries(state);

  const filterNotificationDataArray = notificationData.filter(([key]) => {
    return key !== id;
  });

  return Object.fromEntries(filterNotificationDataArray);
};

const deploymentNotificationsReducer = (
  state: IDeploymentNotificationsState = initialState,
  action: DeploymentNotificationsDispatchTypes
): IDeploymentNotificationsState => {
  switch (action.type) {
    case ADD_DEPLOYMENT_NOTIFICATION: {
      const filteredData = filterNotificationData(action.payload.id, state.data);
      const newNotification = {
        [action.payload.id]: {
          id: action.payload.id,
          operation: action.payload.data.notification.operation,
          name: action.payload.data.name,
          regions: action.payload.data.notification.regions,
          swapModelName: action.payload.data.notification.swapModelName,
        },
      };
      return {
        ...state,
        data: merge(filteredData, newNotification),
      };
    }
    case UPDATE_DEPLOYMENT_NOTIFICATION: {
      const modelId = action.payload.id;

      return {
        ...state,
        data: {
          ...state.data,
          [modelId]: {
            ...state.data[modelId],
            regions: state.data[modelId].regions.map((regionItem) => {
              if (regionItem.region === action.payload.data.region) {
                return { ...regionItem, status: action.payload.data.status };
              }
              return regionItem;
            }),
          },
        },
      };
    }
    case CLEAR_DEPLOYMENT_NOTIFICATION: {
      return {
        ...state,
        data: filterNotificationData(action.payload, state.data),
      };
    }
    case CLEAR_ALL_DEPLOYMENT_NOTIFICATIONS: {
      return initialState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default deploymentNotificationsReducer;
