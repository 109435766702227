import merge from 'lodash/merge';
import union from 'lodash/union';

import {
  CLEAR_CURRENT_PROJECT,
  CREATE_PROJECT,
  CREATE_PROJECT_FAILED,
  CREATE_PROJECT_SUCCESSFUL,
  DELETE_PROJECT,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_SUCCESSFUL,
  GET_PROJECT,
  GET_PROJECTS,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_SUCCESSFUL,
  GET_PROJECT_FAILED,
  GET_PROJECT_SUCCESSFUL,
  ICurrentProjectItem,
  IProjectItem,
  ProjectsDispatchTypes,
  SET_CURRENT_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_SUCCESSFUL,
  USE_FREE_CREDIT,
} from './projects.types';

export interface IProjectsState {
  isLoading: boolean;
  isUpdating: boolean;
  ids: string[];
  data: {
    [key: string]: IProjectItem;
  };
  currentProject: ICurrentProjectItem;
}

const initialState: IProjectsState = {
  isLoading: false,
  isUpdating: false,
  ids: [],
  data: {},
  currentProject: {} as ICurrentProjectItem,
};

const projectsReducer = (state: IProjectsState = initialState, action: ProjectsDispatchTypes): IProjectsState => {
  switch (action.type) {
    case GET_PROJECTS: {
      return {
        ...state,
        isLoading: true,
        ids: initialState.ids,
        data: initialState.data,
      };
    }
    case GET_PROJECT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CREATE_PROJECT:
    case UPDATE_PROJECT:
    case DELETE_PROJECT: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case GET_PROJECT_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        ids: union(state.ids, action.payload.ids),
        data: merge(state.data, action.payload.data),
      };
    }
    case GET_PROJECTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        ids: action.payload.ids,
        data: action.payload.data,
      };
    }
    case CREATE_PROJECT_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        ids: action.payload.ids,
        data: action.payload.data,
      };
    }
    case UPDATE_PROJECT_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        data: merge(state.data, action.payload.data),
      };
    }
    case DELETE_PROJECT_SUCCESSFUL: {
      const selectedProjectId = action.payload;

      // Since project.data is an object converting it into an array to filter out the selected project object
      // then rebuilding the object and returning the new filtered project data
      const filterProjectData = (): { [key: string]: IProjectItem } => {
        const projectDataArray = Object.entries(state.data);

        const filterProjectDataArray = projectDataArray.filter(([key]) => {
          return key !== selectedProjectId;
        });

        return Object.fromEntries(filterProjectDataArray);
      };

      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.payload),
        data: filterProjectData(),
        isUpdating: false,
      };
    }
    case GET_PROJECTS_FAILED:
    case GET_PROJECT_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CREATE_PROJECT_FAILED:
    case UPDATE_PROJECT_FAILED:
    case DELETE_PROJECT_FAILED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case USE_FREE_CREDIT: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload],
            hasFreeTraining: false,
          },
        },
      };
    }
    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: action.payload,
      };
    }
    case CLEAR_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: initialState.currentProject,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default projectsReducer;
