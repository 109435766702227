export const GET_MODEL_REGIONS = 'GET_MODEL_REGIONS';
export const GET_MODEL_REGIONS_SUCCESSFUL = 'GET_MODEL_REGIONS_SUCCESSFUL';
export const GET_MODEL_REGIONS_FAILED = 'GET_MODEL_REGIONS_FAILED';
export const SET_MODEL_REGIONS = 'SET_MODEL_REGIONS';

export interface IModelRegionItem {
  region: number;
  regionName: string;
}

export interface IModelRegionsEntities {
  modelRegions: {
    [key: string]: IModelRegionItem;
  };
}

export interface IGetModelRegions {
  type: typeof GET_MODEL_REGIONS;
}

export interface IGetModelRegionsSuccessful {
  type: typeof GET_MODEL_REGIONS_SUCCESSFUL;
  payload: {
    data: {
      [key: string]: IModelRegionItem;
    };
  };
}

export interface IGetModelRegionsFailed {
  type: typeof GET_MODEL_REGIONS_FAILED;
}

export interface ISetModelRegions {
  type: typeof SET_MODEL_REGIONS;
  payload: {
    [key: string]: IModelRegionItem;
  };
}

export type ModelRegionsDispatchTypes =
  | IGetModelRegions
  | IGetModelRegionsSuccessful
  | IGetModelRegionsFailed
  | ISetModelRegions;
