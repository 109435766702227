const components = {
  AppBar: {
    height: 48,
  },
  AppSidebar: {
    expandedWidth: 228,
    collapsedWidth: 48,
  },
  PaginationFooter: {
    height: 72,
  },
  ProjectsTopContent: {
    height: 200,
  },
  ScrollableDetailsList: {
    /**
     * Used when rendering a scrollable DetailsList with a sticky footer.
     * This measure is the sum vertical height of all non-grid components on the page.
     * These components include the  AppBar, PageBreadcrumb (with subtitle), CommandBar,
     * and Pagination.
     */
    documentsListNonScrollableContent: 270,
    documentContentNonScrollableContent: 240,
    testModelResultsNonScrollableContent: 252,
  },
  ContentContainer: {
    rightLeftBorder: 20,
  },
};

export default components;
