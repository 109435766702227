import { Stack } from '@fluentui/react';
import React from 'react';

import { DictionaryTypes, DocumentSetTypes } from '../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import ChooseDictionaryType from './ChooseDictionaryType';
import ChooseDocumentSetType from './ChooseDocumentSetType';
import UploadDocumentsSet from './UploadDocumentsSet';
import useUploadDocumentsWizardStyles from './UploadDocumentsWizard.styles';
import { IUploadDocumentsWizardProps } from './UploadDocumentsWizard.types';

import { useAppSelector } from '../../../utils/hooks';

const UploadDocumentsWizard: React.FC<IUploadDocumentsWizardProps> = ({
  handleCancelAndClose,
  showLanguageSelection = false,
}) => {
  const classes = useUploadDocumentsWizardStyles();
  const { documentSetType, dictionaryType } = useAppSelector((state) => state.uploadDocumentsWizard);

  let wizardContent = null;
  const nonDictionaryDocumentSetTypes: string[] = [
    DocumentSetTypes.Training,
    DocumentSetTypes.Testing,
    DocumentSetTypes.Tuning,
  ];
  const dictionaryTypes: string[] = [DictionaryTypes.PhraseDictionary, DictionaryTypes.SentenceDictionary];

  const isNonDictionaryDocumentSetType = documentSetType && nonDictionaryDocumentSetTypes.includes(documentSetType);
  const isDictionaryDocumentSetType = documentSetType === DocumentSetTypes.Dictionary;
  const isDictionaryType = dictionaryType && dictionaryTypes.includes(dictionaryType);

  if (!documentSetType) {
    wizardContent = (
      <ChooseDocumentSetType
        handleCancelAndClose={handleCancelAndClose}
        showLanguageSelection={showLanguageSelection}
      />
    );
  } else if (isNonDictionaryDocumentSetType || isDictionaryType) {
    wizardContent = (
      <Stack className={classes.content}>
        <UploadDocumentsSet handleCancelAndClose={handleCancelAndClose} />
      </Stack>
    );
  } else if (isDictionaryDocumentSetType && !isDictionaryType) {
    wizardContent = (
      <Stack className={classes.content}>
        <ChooseDictionaryType handleCancelAndClose={handleCancelAndClose} />
      </Stack>
    );
  }

  return wizardContent;
};

export default UploadDocumentsWizard;
