/* eslint-disable @typescript-eslint/no-explicit-any */
import { HubConnectionBuilder } from '@aspnet/signalr';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { batch } from 'react-redux';
import { Middleware } from 'redux';

import { updateCopyNotification } from '../redux/copyNotifications/copyNotificationActions';
import { updateDeploymentNotification } from '../redux/deploymentNotifications/deploymentNotificationsActions';
import { setHasNewDocuments } from '../redux/documents/documentsActions';
import { getModel, updateModelStatus } from '../redux/models/modelsActions';
import { CONNECT_TO_HUB } from '../redux/notifications/notifications.types';
import { getProject } from '../redux/projects/projectsActions';
import { updateTrainingNotification } from '../redux/trainingNotifications/trainingNotificationActions';
import {
  addExtractedFileToUploadNotification,
  updateUploadNotification,
} from '../redux/uploadNotifications/uploadNotificationsActions';
import { ModelStatus, ModelStatusMap } from './constants/modelStatus';
import {
  IDeploymentNotificationReceived,
  IExtractedFileUploadNotificationReceived,
  ITrainingNotificationReceived,
  IUploadNotificationReceived,
  UploadNotificationStatus,
} from './constants/notifications';

const onExtractedFileUploadNotification = (
  notification: IExtractedFileUploadNotificationReceived,
  store: any
): void => {
  const { jobId, status, errorMessage, id, name } = notification;
  const { uploadNotifications } = store.getState();

  const notificationInStore = uploadNotifications.data[jobId];
  if (!isEmpty(notificationInStore)) {
    const payload = {
      id: jobId,
      data: { id, name, status, errorMessage },
    };

    store.dispatch(addExtractedFileToUploadNotification(payload));
  }
};

const onUploadNotification = (notification: IUploadNotificationReceived, store: any): void => {
  const { jobId, errorMessage } = notification;
  let { status } = notification;
  const { uploadNotifications, documents, workspaces } = store.getState();

  const notificationInStore = uploadNotifications.data[jobId];
  if (!isEmpty(notificationInStore)) {
    // There is one case where the backend incorrectly sends a success message on the entire job.
    // This is when a zip has file(s) that have failed during extraction but also contains
    // other files that have succeeded. It is simpler to add this double-check logic
    // to the front-end than to change how upload works on the backend.
    // The logic marks a "success" as a failure if any subfiles have failed.
    let hasFailedSubfiles = false;
    if (!isEmpty(notificationInStore.files)) {
      hasFailedSubfiles = Object.keys(notificationInStore.files).some((id) => {
        return notificationInStore.files[id].status === UploadNotificationStatus.Failed;
      });
    }
    if (hasFailedSubfiles && status === UploadNotificationStatus.Succeeded) {
      status = UploadNotificationStatus.Failed;
    }

    const payload = {
      id: jobId,
      data: {
        status,
        errorMessage,
      },
    };

    if (!documents.areDocumentsFiltered && workspaces.currentWorkspace.id === notificationInStore.workspaceId) {
      batch(() => {
        store.dispatch(updateUploadNotification(payload));
        store.dispatch(setHasNewDocuments(true));
      });
    } else {
      store.dispatch(updateUploadNotification(payload));
    }
  }
};

const onTrainingNotification = (notification: ITrainingNotificationReceived, store: any): void => {
  const { modelId, status, errorCode } = notification;
  const modelStatus = ModelStatusMap.get(status) || ModelStatus.Unknown;
  const { models, trainingNotifications } = store.getState();

  if (!isEmpty(trainingNotifications.data[modelId])) {
    const payload = {
      id: modelId,
      data: {
        modelStatus,
        errorCode,
      },
    };

    if (!isEmpty(models.data[modelId])) {
      batch(() => {
        store.dispatch(updateModelStatus(payload));
        store.dispatch(updateTrainingNotification(payload));
      });
      // If status was succeeded, issue a call to get model to pull bleu score/ etc.
      if (modelStatus === ModelStatus.TrainingSucceeded) {
        store.dispatch(getModel(modelId, true));
      }
    } else {
      store.dispatch(updateTrainingNotification(payload));
    }
  }
};

const onCopyNotification = (notification: ITrainingNotificationReceived, store: any): void => {
  const { modelId, status } = notification;
  const modelStatus = ModelStatusMap.get(status) || ModelStatus.Unknown;
  const { copyNotifications } = store.getState();

  if (!isEmpty(copyNotifications.data[modelId])) {
    const payload = {
      id: modelId,
      data: {
        modelStatus,
      },
    };

    store.dispatch(updateCopyNotification(payload));
  }
};

const onDeploymentNotification = (notification: IDeploymentNotificationReceived, store: any): void => {
  const { regionalStatuses, modelId, projectId } = notification;
  const { projects, models, deploymentNotifications } = store.getState();

  const deploymentNotification = deploymentNotifications.data[modelId];
  if (!isEmpty(deploymentNotification)) {
    // Updates projects and models slices of store by fetching new data for individual project/ model
    if (projects.data[projectId]) {
      store.dispatch(getProject(projectId, true));
    }
    if (models.data[modelId]) {
      store.dispatch(getModel(modelId, true));
    }

    // Updates deployment notifications slice
    Object.keys(regionalStatuses).forEach((key) => {
      const { isDeployed, ...filteredStatus } = regionalStatuses[key];
      const notificationPayload = {
        id: modelId,
        data: {
          ...filteredStatus,
        },
      };
      store.dispatch(updateDeploymentNotification(notificationPayload));
    });
  } else if (isEmpty(deploymentNotification) && models.data[modelId]) {
    // For a successful swap operation, a notification will be received for the model that is undeployed. The
    // undeployed model is not in the deploymentNotification slice of the store, but we still need to ensure that
    // the DetailsLists display up to date information.
    store.dispatch(getModel(modelId, true));
  }
};

/**
 * Requests SignalR access token from Azure Function 'negotiate'. Uses that access
 * token to connect to the Azure SignalR instance directly. If there are errors
 * during the connection process, displays connection issue in the notifications panel.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const notificationsMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === CONNECT_TO_HUB) {
    try {
      axios.get(`${process.env.REACT_APP_AZURE_FUNCTIONS}/negotiate?hubname=notifications`).then((response) => {
        const options = {
          accessTokenFactory: (): string => response.data.accessToken,
        };
        const connection = new HubConnectionBuilder().withUrl(response.data.url, options).build();

        connection.on('trainingNotification', (notification: ITrainingNotificationReceived) => {
          onTrainingNotification(notification, store);
        });

        connection.on('copyNotification', (notification: ITrainingNotificationReceived) => {
          onCopyNotification(notification, store);
        });

        connection.on('deploymentNotification', (notification: IDeploymentNotificationReceived) => {
          onDeploymentNotification(notification, store);
        });

        connection.on('uploadNotification', (notification: IUploadNotificationReceived) => {
          onUploadNotification(notification, store);
        });

        connection.on('extractedFileUploadNotification', (notification: IExtractedFileUploadNotificationReceived) => {
          onExtractedFileUploadNotification(notification, store);
        });

        connection.onclose(() => {
          connection.start();
        });

        connection.start();
      });
    } catch (error) {
      // TODO: Implement integration into notification panel to show that fetching notifications
      // isn't currently working.
    }
  }
  return next(action);
};

export default notificationsMiddleware;
