import { Callout, DirectionalHint, Panel, PanelType } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { isEmpty } from 'lodash';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinksKind, ThemedHeader } from 'sharedui.studios/dist/package';

import { setActivePanel, setIsPanelOpen } from '../../redux/panels/panelsActions';
import { getSubscription } from '../../redux/subscription/subscriptionActions';
import FeedbackPanel from '../FeedbackPanel';
import HelpPanel from '../HelpPanel';
import { NotificationDisplayType, formatNotifications } from '../Notifications/NotificationBase/CSStudioFormating';
import NotificationPanel from '../Notifications/NotificationPanel';
import ProfileCard from '../ProfileCard';
import './AppBar.css';
import useAppBarStyles from './AppBar.styles';

import { AppBarPanels } from '../../utils/constants/appBarPanels';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

const AppBar: React.FC = () => {
  const classes = useAppBarStyles();
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();

  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const [panelContent, setPanelContent] = useState<ReactElement>(<NotificationPanel />);
  const {
    currentUser,
    panels,
    telemetry,
    workspaces,
    subscription,
    notifications,
    trainingNotifications,
    deploymentNotifications,
    uploadNotifications,
    copyNotifications,
  } = useAppSelector((state) => state);
  const buttonId = useId('callout-button');

  const [cSNotifications, setCSNotifications] = useState(
    formatNotifications({
      notifications,
      trainingNotifications,
      deploymentNotifications,
      uploadNotifications,
      copyNotifications,
      displayType: NotificationDisplayType.Popup,
    })
  );

  useEffect(() => {
    setCSNotifications(
      formatNotifications({
        notifications,
        trainingNotifications,
        deploymentNotifications,
        uploadNotifications,
        copyNotifications,
        prevNotifications: cSNotifications,
        displayType: NotificationDisplayType.Popup,
      })
    );
    if (notifications.ids.find((item) => !item.silent)) appDispatch({ type: 'SILENCE_ALL_NOTIFICATIONS' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyNotifications, deploymentNotifications, trainingNotifications, uploadNotifications]);

  // Always listening to telemetry slice to see if there is any message to send
  useEffect(() => {
    if (!isEmpty(telemetry.messages)) {
      appInsights.trackEvent({ name: telemetry.messages[0] });
      appDispatch({ type: 'DELETE_FROM_TELEMETRY_QUEUE' });
    }
  }, [telemetry, appDispatch, appInsights]);

  // Fetch workspace subscription data based on currentWorkspace ID being set
  useEffect(() => {
    if (!isEmpty(workspaces.currentWorkspace.id) && subscription.workspaceId !== workspaces.currentWorkspace.id) {
      appDispatch(getSubscription(workspaces.currentWorkspace.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.currentWorkspace.id]);

  const toggleActionPanel = (panelType: AppBarPanels): void => {
    if (panelType === AppBarPanels.Notification) {
      setPanelContent(<NotificationPanel />);
    }
    if (panelType === AppBarPanels.Help) {
      setPanelContent(<HelpPanel />);
    }
    if (panelType === AppBarPanels.Feedback) {
      setPanelContent(<FeedbackPanel />);
    }
    if (panelType !== panels.activePanel) {
      appDispatch(setActivePanel(panelType));
    }
    appDispatch(setIsPanelOpen(!panels.isPanelOpen));
  };

  const closeActionPanel = (): void => {
    appDispatch(setIsPanelOpen(false));
  };

  const appBarPanels = [
    {
      type: AppBarPanels.Notification,
      name: t('components.appBar.actionButtons.notifications'),
      iconName: 'Ringer',
    },
    {
      type: AppBarPanels.Help,
      name: t('components.appBar.actionButtons.help'),
      iconName: 'Help',
    },
    {
      type: AppBarPanels.Feedback,
      name: t('components.appBar.actionButtons.feedback'),
      iconName: 'Emoji2',
    },
  ];

  const getActivePanelName = (): string => {
    // eslint-disable-next-line no-restricted-syntax
    for (const panel of appBarPanels) {
      if (panel.type === panels.activePanel) {
        return panel.name;
      }
    }
    return '';
  };

  return (
    <Fragment>
      <a href="#page-heading" className={classes.skipToContentLink}>
        Skip to content
      </a>
      <ThemedHeader
        commandBarItems={[
          {
            ariaLabel: t('components.appBar.actionButtons.notifications'),
            iconOnly: true,
            iconProps: {
              iconName: 'Ringer',
            },
            key: 'Notifications',
            text: t('components.appBar.actionButtons.notifications'),
            onClick: (): void => {
              toggleActionPanel(AppBarPanels.Notification);
            },
          },
          {
            ariaLabel: t('components.appBar.actionButtons.help'),
            iconOnly: true,
            iconProps: {
              iconName: 'Help',
            },
            key: 'Help',
            text: t('components.appBar.actionButtons.help'),
            onClick: (): void => {
              toggleActionPanel(AppBarPanels.Help);
            },
          },
          {
            ariaLabel: t('components.appBar.actionButtons.feedback'),
            iconOnly: true,
            iconProps: {
              iconName: 'Emoji2',
            },
            key: 'Feedback',
            text: t('components.appBar.actionButtons.feedback'),
            onClick: (): void => {
              toggleActionPanel(AppBarPanels.Feedback);
            },
          },
        ]}
        // contentAnchorId="callout-button329"
        headerHomePageUrl="https://portal.customtranslator.azure.ai/"
        headerText={t('components.appBar.links.customTranslator')}
        linksMenuItems={[
          LinksKind.AzureMachineLearning,
          LinksKind.AzureAIStudio,
          LinksKind.ContentSafety,
          LinksKind.DocumentIntelligence,
          LinksKind.VisionStudio,
          LinksKind.LanguageStudio,
          LinksKind.SpeechStudio,
        ]}
        isAuthenticated
        profileButtonId={buttonId}
        loginPath="/portal"
        friendlyName={currentUser.username}
        isProfileCardVisible={false}
        onProfileClick={toggleIsCalloutVisible}
        subscription={{
          localeDisplayName: !isEmpty(subscription.data.region) ? subscription.data.region : '',
          name: !isEmpty(subscription.data.name) ? subscription.data.name : '',
          sku: !isEmpty(subscription.data.sku) ? subscription.data.sku : '',
        }}
        notifications={{
          notifications: cSNotifications,
        }}
      />
      {isCalloutVisible && (
        <Callout
          isBeakVisible={false}
          target={`#${buttonId}`}
          directionalHint={DirectionalHint.bottomLeftEdge}
          onDismiss={toggleIsCalloutVisible}
        >
          <ProfileCard />
        </Callout>
      )}
      <Panel
        isOpen={panels.isPanelOpen}
        onDismiss={closeActionPanel}
        closeButtonAriaLabel={t('components.actionPanel.closeButton')}
        headerText={getActivePanelName()}
        isBlocking={false}
        type={PanelType.custom}
        customWidth="400px"
      >
        {panelContent}
      </Panel>
    </Fragment>
  );
};

export default AppBar;
