import { IBaseDocumentItem } from '../documents/documents.types';
import { IFilesState } from '../files/filesReducer';

export const ADD_DOCUMENT_TO_SELECTION = 'ADD_DOCUMENT_TO_SELECTION';
export const REMOVE_DOCUMENT_FROM_SELECTION = 'REMOVE_DOCUMENT_FROM_SELECTION';
export const CLEAR_PAGINATED_DOCUMENTS_SELECTION = 'CLEAR_PAGINATED_DOCUMENTS_SELECTION';
export const CLEAR_ALL_COUNTS = 'CLEAR_ALL_COUNTS';
export const UPDATE_COUNT = 'UPDATE_COUNT';

export interface IDictionaryCountItem {
  sentence: number;
  document: number;
}

export interface ITrainingCountItem {
  sentence: number;
}

export interface ITotalCountItem {
  sentence: number;
  document: number;
  character: number;
}

export interface ICountPayload {
  document: IBaseDocumentItem;
  files: IFilesState;
  isAdding: boolean;
}

export interface IClearAllCounts {
  type: typeof CLEAR_ALL_COUNTS;
}

export interface IAddToCount {
  type: typeof UPDATE_COUNT;
  payload: ICountPayload;
}

export interface IAddDocumentToSelection {
  type: typeof ADD_DOCUMENT_TO_SELECTION;
  payload: IBaseDocumentItem;
}

export interface IRemoveDocumentFromSelection {
  type: typeof REMOVE_DOCUMENT_FROM_SELECTION;
  payload: number;
}

export interface IClearDocumentsSelection {
  type: typeof CLEAR_PAGINATED_DOCUMENTS_SELECTION;
}

export type PaginatedDocumentsSelectionDispatchTypes =
  | IAddDocumentToSelection
  | IRemoveDocumentFromSelection
  | IClearDocumentsSelection
  | IAddToCount
  | IClearAllCounts;
