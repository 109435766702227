import { ChoiceGroup, Stack } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from 'redux';

import dictionaryImage from '../../../../assets/images/documents/dictionary.svg';
import {
  DictionaryTypes,
  SetUploadDocumentWizardTypes,
} from '../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import {
  setInitialUploadDocumentWizard,
  setUploadDocumentsWizard,
} from '../../../../redux/uploadDocumentsWizard/uploadDocumentsWizardActions';
import WizardActions from '../../Shared/WizardActions';
import DocumentSetLabel from '../DocumentSetLabel';
import DocumentTypeDescription from '../DocumentTypeDescription';
import { IChooseDictionaryTypeProps, IChooseDictionaryTypeWizardOption } from './ChooseDictionaryType.types';

import { useAppDispatch } from '../../../../utils/hooks';

const ChooseDictionaryType: React.FC<IChooseDictionaryTypeProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const [documentsType, setDocumentsType] = useState<DictionaryTypes>();
  const appDispatch = useAppDispatch();

  const handleSetChange = useCallback(
    (
      ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
      option?: Partial<IChooseDictionaryTypeWizardOption>
    ): void => {
      if (option) {
        setDocumentsType(option.wizardKey);
      }
    },
    []
  );

  const options: IChooseDictionaryTypeWizardOption[] = [
    {
      key: DictionaryTypes.PhraseDictionary,
      wizardKey: DictionaryTypes.PhraseDictionary,
      text: '',
      onRenderLabel: (): JSX.Element => (
        <DocumentSetLabel
          title={t('components.forms.uploadDocumentsWizard.chooseDictionaryType.phraseLabel.title')}
          text={t('components.forms.uploadDocumentsWizard.chooseDictionaryType.phraseLabel.text')}
          link="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/what-is-dictionary#phrase-dictionary"
        />
      ),
    },
    {
      key: DictionaryTypes.SentenceDictionary,
      wizardKey: DictionaryTypes.SentenceDictionary,
      text: '',
      onRenderLabel: (): JSX.Element => (
        <DocumentSetLabel
          title={t('components.forms.uploadDocumentsWizard.chooseDictionaryType.sentenceLabel.title')}
          text={t('components.forms.uploadDocumentsWizard.chooseDictionaryType.sentenceLabel.text')}
          link="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/what-is-dictionary#sentence-dictionary"
        />
      ),
    },
  ];

  return (
    <Stack>
      <Stack horizontal verticalAlign="center">
        <Stack.Item>
          <DocumentTypeDescription
            image={{
              source: dictionaryImage,
              alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.dictionary'),
            }}
            title={t('components.forms.uploadDocumentsWizard.chooseDictionaryType.description.title')}
            text={t('components.forms.uploadDocumentsWizard.chooseDictionaryType.description.text')}
          />
        </Stack.Item>

        <Stack.Item grow>
          <ChoiceGroup onChange={handleSetChange} options={options} />
        </Stack.Item>
      </Stack>
      <Stack.Item>
        <WizardActions
          backButton={{
            onClick: (): Action => appDispatch(setInitialUploadDocumentWizard()),
          }}
          handleCancelAndClose={handleCancelAndClose}
          confirmationButton={{
            isDisabled: !documentsType,
            text: t('components.popups.common.next'),
            onClick: (): Action =>
              appDispatch(setUploadDocumentsWizard(SetUploadDocumentWizardTypes.Dictionary, documentsType)),
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default ChooseDictionaryType;
