import { IBaseDocumentItem, IDocumentItem } from '../redux/documents/documents.types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const validateDocumentsType = (selectedDocuments: { [key: string]: IBaseDocumentItem | IDocumentItem }) => {
  const selectedDocumentsIds: string[] = [];
  let isTuningAuto = true;
  let isTestingAuto = true;
  Object.keys(selectedDocuments).forEach((value) => {
    selectedDocumentsIds.push(value);
    if (selectedDocuments[value].documentType === 'testing') {
      isTestingAuto = false;
    } else if (selectedDocuments[value].documentType === 'tuning') {
      isTuningAuto = false;
    }
  });

  return { isTestingAuto, isTuningAuto, selectedDocumentsIds };
};

export default validateDocumentsType;
