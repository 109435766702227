import { ILanguageItem } from '../languages/languages.types';

export const GET_UPLOAD_HISTORY = 'GET_UPLOAD_HISTORY';
export const GET_UPLOAD_HISTORY_SUCCESSFUL = 'GET_UPLOAD_HISTORY_SUCCESSFUL';
export const GET_UPLOAD_HISTORY_FAILED = 'GET_UPLOAD_HISTORY_FAILED';

export interface IUploadHistoryJob {
  createdBy: {
    id: string;
    userName: string;
  };
  createdDate: string;
  documentType: string;
  jobId: string;
  languages: ILanguageItem[];
  name: string;
  numberFiles: number;
  numberFilesWithErrors: number;
  status: {
    displayName: string;
    id: number;
  };
}

export interface IUploadHistoryState {
  isLoading: boolean;
  isFiltering: boolean;
  pageIndex: number;
  totalPageCount: number;
  jobs: IUploadHistoryJob[];
  workspaceId: null | string;
  failed: boolean;
}

export interface IUploadHistoryPayload {
  isFiltering: boolean;
}

export type GetUploadHistoryResponse = IUploadHistoryState;

export interface IGetUploadHistory {
  type: typeof GET_UPLOAD_HISTORY;
  payload: IUploadHistoryPayload;
}

export interface IGetUploadHistorySuccessful {
  type: typeof GET_UPLOAD_HISTORY_SUCCESSFUL;
  payload: IUploadHistoryState;
}

export interface IGetUploadHistoryFailed {
  type: typeof GET_UPLOAD_HISTORY_FAILED;
}

export type UploadHistoryDispatchTypes = IGetUploadHistory | IGetUploadHistorySuccessful | IGetUploadHistoryFailed;
