import { FontWeights, makeStyles } from '@fluentui/react';

const useDocumentTypeDescriptionStyles = makeStyles((theme) => ({
  root: {
    width: 190,
    marginRight: theme.spacing.l2,
  },
  image: {
    margin: 'auto',
  },
  title: {
    fontWeight: FontWeights.semibold,
  },
}));

export default useDocumentTypeDescriptionStyles;
