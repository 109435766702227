import { makeStyles } from '@fluentui/react';

const useFeatureCardStyles = makeStyles(() => ({
  container: {
    minWidth: '230px',
  },
  subtitle: {
    fontSize: '16px',
  },
  title: {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: 10,
  },
  link: {
    fontSize: '16px',
    paddingTop: '20px',
  },
  image: {
    alignSelf: 'center',
  },
}));

export default useFeatureCardStyles;
