import merge from 'lodash/merge';

import { CLEAR_USERS, IUserItem, SET_USERS, UsersDispatchTypes } from './users.types';

interface IUsersState {
  [key: string]: IUserItem;
}

const entitiesReducer = (state: IUsersState = {}, action: UsersDispatchTypes): IUsersState => {
  switch (action.type) {
    case SET_USERS: {
      return merge(state, action.payload);
    }
    case CLEAR_USERS: {
      return {};
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default entitiesReducer;
