import { ModelStatus } from './modelStatus';

export enum TrainingStepState {
  NotStarted = 'notStarted',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
}

/**
 * It would be very odd if we actually had a model in that status (created = 'unknown' in the db), for now it will have
 * the same value as trainingSubmitted
 */
export const DisplayedTrainingSteps = {
  [ModelStatus.Unknown]: 0,
  [ModelStatus.TrainingSubmitted]: 0,
  [ModelStatus.DataProcessing]: 1,
  [ModelStatus.DataProcessingFailed]: 1,
  [ModelStatus.TrainingQueued]: 2,
  [ModelStatus.TrainingRunning]: 3,
};
