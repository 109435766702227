import { merge } from 'lodash';

import {
  CategoriesDispatchTypes,
  GET_CATEGORIES,
  GET_CATEGORIES_FAILED,
  GET_CATEGORIES_SUCCESSFUL,
  ICategoryItem,
  SET_CATEGORIES,
} from './categories.types';

export interface ICategoriesState {
  isLoading: boolean;
  data: {
    [key: string]: ICategoryItem;
  };
}

const initialState: ICategoriesState = {
  isLoading: false,
  data: {},
};

const categoriesReducer = (
  state: ICategoriesState = initialState,
  action: CategoriesDispatchTypes
): ICategoriesState => {
  switch (action.type) {
    case GET_CATEGORIES: {
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
      };
    }
    case GET_CATEGORIES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }
    case SET_CATEGORIES: {
      return merge(state, action.payload);
    }
    case GET_CATEGORIES_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default categoriesReducer;
