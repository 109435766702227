import { DISMISS_POP_UP_ERROR, PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from './popUpErrors.types';

interface IPopUpErrorState {
  isHidden: boolean;
  title: string;
  message: string;
  confirmationButtonText: string;
  confirmationButtonAction: string;
  showCancelButton: boolean;
}

const initialState: IPopUpErrorState = {
  isHidden: true,
  title: '',
  message: '',
  confirmationButtonText: '',
  confirmationButtonAction: '',
  showCancelButton: true,
};

const popUpErrorsReducer = (
  state: IPopUpErrorState = initialState,
  action: PopUpErrorsDispatchTypes
): IPopUpErrorState => {
  switch (action.type) {
    case SET_POP_UP_ERROR: {
      return {
        ...state,
        isHidden: false,
        title: action.payload.title,
        message: action.payload.message,
        confirmationButtonText: action.payload.confirmationButtonText,
        confirmationButtonAction: action.payload.confirmationButtonAction,
        showCancelButton: action.payload.showCancelButton,
      };
    }
    case DISMISS_POP_UP_ERROR: {
      return {
        ...state,
        isHidden: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default popUpErrorsReducer;
