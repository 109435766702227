import { makeStyles } from '@fluentui/react';

const useChooseDocumentStyles = makeStyles((theme) => ({
  subtitle: {
    margin: `10 0 ${theme.spacing.s1}`,
  },
  labelBetweenLanguages: {
    display: 'block',
    textAlign: 'center',
    marginTop: 38,
  },
  languageSelection: {
    marginBottom: 30,
    width: '82%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export default useChooseDocumentStyles;
