import { IBreadcrumbItem, Text } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ModelCommandBar } from '../../CommandBars';
import { Empty, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { ModelDocumentsTable } from '../../Tables';
import ContentContainer from '../ContentContainer';
import useModelContentStyles from './ModelContent.styles';
import ModelDetails from './ModelDetails';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { ModelStatus } from '../../../utils/constants/modelStatus';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import { useAppSelector } from '../../../utils/hooks';

const ModelContent: React.FC = () => {
  const classes = useModelContentStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { models, modelDocuments, projects, documents, workspaces } = useAppSelector((state) => state);
  // useRouteMatch has a potential of being undefined which creates more difficulty mutating the URL string
  const modelsUrl = `/workspaces/${workspaceId}/projects/${projectId}/models`;
  const modelName: string = models.data[modelId]?.name || '';

  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t('pages.models.pageName'),
      key: 'model-details',
      onClick: (): void => history.push(modelsUrl),
    },
    {
      text: modelName,
      key: 'model-name',
    },
  ];

  const isLoading =
    projects.isLoading || models.isLoading || models.isCreating || documents.isLoading || workspaces.isLoading;
  const hasModels = !isEmpty(models.ids);
  const hasCurrentProject = !isEmpty(projects.currentProject);
  const hasModelDocuments = !isEmpty(modelDocuments);
  let modelContent = null;

  if (isLoading) {
    modelContent = (
      <Loading
        loadingText={t('pages.model.contentSection.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!isLoading && hasModels && hasCurrentProject && (hasModelDocuments || models.data[modelId].isCopied)) {
    const currentModelStatus = models.data[modelId].modelStatus;
    const currentModelHasFailed =
      currentModelStatus === ModelStatus.TrainingFailed || currentModelStatus === ModelStatus.DataProcessingFailed;
    modelContent = (
      <Fragment>
        {!currentModelHasFailed && <ModelCommandBar />}
        <ModelDetails projectId={projectId} modelId={modelId} />

        {!models.data[modelId].isCopied && (
          <React.Fragment>
            <Text variant="mediumPlus" className={classes.documentSetsTitle}>
              {t('pages.model.contentSection.documentSetsTitle')}
            </Text>
            <ModelDocumentsTable />
          </React.Fragment>
        )}
      </Fragment>
    );
  } else if (!isLoading && isEmpty(models.data[modelId]?.documents)) {
    modelContent = <Empty type={EmptyTypes.Documents} />;
  }

  return (
    <ContentContainer>
      <PageHeading>{modelName}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      {modelContent}
    </ContentContainer>
  );
};

export default ModelContent;
