import { CommandBar, DetailsList, ICommandBarItemProps, Selection, SelectionMode, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, MessageBarButton, PrimaryButton } from '@fluentui/react/lib/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { WizardFlow } from 'sharedui.studios/dist/package/index';

import { getModelsVersion, upgradeModelsVersion } from '../../redux/modelsVersion/modelsVersionActions';
import { getProjects } from '../../redux/projects/projectsActions';
import useModalVersionModalStyles from './ModelVersionModalStyles.styles';

import IRoutePathParams from '../../utils/constants/routePathParams.types';
import { useAppSelector } from '../../utils/hooks';

interface IUpgradableModel {
  key: any;
  modelName: string;
  projectName: string;
  creditAvailable: string;
  lastTrained: string;
}

const ModelVersionModal: React.FC<{ variant: 'settings' | 'message' | 'command' }> = ({ variant }) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [step, setStep] = useState(1);
  const classes = useModalVersionModalStyles();
  const history = useHistory();
  const { workspaceId } = useParams<IRoutePathParams>();
  const { modelsVersion, projects, workspaces } = useAppSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [models, setModels] = useState<IUpgradableModel[]>([]);
  const [selectedModels, setSelectedModels] = useState<any>([]);

  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: (): void => {
        const selections = selectionState.getSelection();

        setSelectedModels(selections);
      },
    })
  );

  const userHasPermissionToUpgrade = [1, 2].includes(workspaces.data[workspaces.currentWorkspace.id].role);

  // Fetch models version if needed.
  useEffect(() => {
    batch(() => {
      if (!modelsVersion.isLoading && !modelsVersion.models) {
        dispatch(getModelsVersion({ workspaceId }));
      }
      if (projects.data && Object.keys(projects.data).length === 0 && !projects.isLoading) {
        dispatch(getProjects(workspaceId));
      }
    });
  }, [dispatch, modelsVersion.isLoading, modelsVersion.models, projects.data, projects.isLoading, workspaceId]);

  useEffect(() => {
    if (projects.data && !projects.isLoading && Array.isArray(modelsVersion.models)) {
      const upgradableModels = modelsVersion.models
        .map((model) => (model.retrainedModel ? model.retrainedModel : model))
        .filter((model) => model.modelType === 'frantic')
        .map((model) => {
          const lastTrained = new Date(model.completionDate);

          return {
            key: model.id,
            modelName: model.name,
            projectName: projects.data[model.projectId].name,
            creditAvailable: 'Yes',
            lastTrained: lastTrained.toDateString(),
          };
        });

      setModels(upgradableModels);
    }
  }, [projects, modelsVersion]);

  useEffect(() => {
    if (step === 2 && modelsVersion.selectedModels) {
      // Select the models that were selected in the retrain models page.
      modelsVersion.selectedModels.forEach((key) => selectionState.setKeySelected(key.toString(), true, false));
    }
  }, [modelsVersion.selectedModels, step, selectionState]);

  function nextStep(): void {
    if (step !== 3) {
      setStep(step + 1);
    }

    switch (step) {
      case 1:
      case 2:
        setStep(step + 1);
        break;
      case 3: {
        const selectedModelIds = selectedModels.map((model: { key: string }) => model.key);
        if (modelsVersion.models && userHasPermissionToUpgrade) {
          const modelsToRetrain = modelsVersion.models
            .filter((model) => selectedModelIds.includes(model.id))
            .map((model) => {
              return {
                name: model.name,
                projectId: model.projectId,
                documentIds: model.documents.map(
                  (doc: { processedDocumentInfo: { documentId: string } }) => doc.processedDocumentInfo.documentId
                ),
                sourceModelIdForRetrain: model.id,
              };
            });

          batch(() => {
            dispatch(upgradeModelsVersion({ workspaceId, models: modelsToRetrain }));
          });

          history.push(`/workspaces/${workspaceId}/workspace-settings/retrain-models`);
          hideModal();
        }

        break;
      }
      default:
        break;
    }
  }

  function prevStep(): void {
    if (step !== 1) {
      setStep(step - 1);
    }
  }

  const nextButtonLabel = step === 3 ? 'Finish and retrain' : 'Next';

  function getStepStatus(key: number): number {
    if (key === step) {
      return 1; // doing
    }

    if (key > step) {
      return 2; // undo or not done
    }

    return 0; // done
  }

  const menuItems = [
    {
      name: 'Overview',
      status: getStepStatus(1),
      key: 1,
    },
    {
      name: 'Model selection',
      status: getStepStatus(2),
      key: 2,
    },
    {
      name: 'Review and finish',
      status: getStepStatus(3),
      key: 3,
    },
  ];

  const wizard = (
    <WizardFlow
      title="Upgrade to latest model"
      currentKey={`${step}`}
      height="600px"
      onDismiss={hideModal}
      menuItems={menuItems}
      hidden={!isModalOpen}
      innerWidth="1100px"
    >
      <WizardFlow.Header>
        {step === 1 && 'Upgrade to the latest model for free'}
        {step === 2 && 'Select models'}
        {step === 3 && 'Review and finish'}
      </WizardFlow.Header>
      <WizardFlow.Content>
        {step === 1 && (
          <React.Fragment>
            <p>
              We worked hard to release a new platform upgrade which delivers big improvement over standard Translator
              and the previous V1 platform in many domains. There is a new training model available for your Translation
              models. To help you upgrade to the new version, we are offering a free credit for each of your currently
              deployed V1 models.
            </p>

            <h3 className={classes.subheading}>How do I retrain?</h3>
            <p>
              You can upgrade by following this upgrade process, checking the upgrade section in Workspace settings, or
              selecting an individual model within a project. We are offering a free credit for one model per deployed
              project which you can see as part of this upgrade or when training and individual model.
            </p>

            <p>
              There is zero downtime when you publish a new model. When you opt to publish a model, we automatically
              unpublish the V1 model and publish the new model in the same region.
            </p>

            <h3 className={classes.subheading}>What happens when I retrain?</h3>
            <p>
              After May 1, 2023, you will no longer be able to publish a V1 model. You have until June 2, 2023 to
              voluntary upgrade. After June 2, 2023, any published V1 models will be automatically upgraded and
              published. There will be no downtime during a V1 model upgrade. All model publishing and in-flight
              translation requests will continue to be served.
            </p>
          </React.Fragment>
        )}
        {step === 2 && (
          <React.Fragment>
            <p>
              Select the deployed models you would retrain with the new model. You will have a chance to review the
              models after retraining.
            </p>

            <DetailsList
              items={models}
              columns={[
                {
                  key: 'column1',
                  name: 'Model name',
                  fieldName: 'modelName',
                  minWidth: 200,
                },
                {
                  key: 'column2',
                  name: 'Project name',
                  fieldName: 'projectName',
                  minWidth: 140,
                },
                {
                  key: 'column3',
                  name: 'Credit available',
                  fieldName: 'creditAvailable',
                  minWidth: 100,
                },
                {
                  key: 'column4',
                  name: 'Last trained',
                  fieldName: 'lastTrained',
                  minWidth: 120,
                },
              ]}
              setKey="set"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              selection={selectionState}
              selectionMode={SelectionMode.multiple}
            />
          </React.Fragment>
        )}
        {step === 3 && (
          <React.Fragment>
            <p>
              Review the models you&apos;d like to retrain. You will have a chance to review the models afterwards
              before publishing.
            </p>

            {!userHasPermissionToUpgrade && <p>You must be an Editor or Owner of this workspace to retrain.</p>}

            <h3 className={classes.subheading}>Models to retrain</h3>
            {selectedModels.length === 0 && (
              <React.Fragment>
                <p>To begin this step you will need to select models.</p>
                <div>
                  <DefaultButton onClick={prevStep}>Select models</DefaultButton>
                </div>
              </React.Fragment>
            )}
            <ul>
              {selectedModels.map((model: { modelName: string; projectName: string }) => (
                <li key={model.modelName + model.projectName}>
                  {model.modelName} ({model.projectName})
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </WizardFlow.Content>
      <WizardFlow.Footer>
        <Stack horizontal wrap horizontalAlign="space-between" tokens={{ childrenGap: 4 }} style={{ width: '100%' }}>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <DefaultButton text="Back" onClick={prevStep} />
            {step !== 3 && (
              <PrimaryButton
                text={nextButtonLabel}
                onClick={nextStep}
                disabled={step === 3 && selectedModels.length === 0}
              />
            )}
          </Stack>
          <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
            {step === 3 && (
              <PrimaryButton
                text={nextButtonLabel}
                onClick={nextStep}
                disabled={step === 3 && selectedModels.length === 0 && userHasPermissionToUpgrade}
              />
            )}
            <DefaultButton text="Cancel" onClick={hideModal} />
          </Stack>
        </Stack>
      </WizardFlow.Footer>
    </WizardFlow>
  );

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'retrain',
      text: t('components.commandBars.retrainModels.retrainButton.text'),
      iconProps: { iconName: 'MachineLearning' },
      onClick: (): void => showModal(),
      disabled: true,
    },
  ];

  return (
    <React.Fragment>
      {variant === 'message' && <MessageBarButton onClick={showModal}>Begin upgrade</MessageBarButton>}
      {variant === 'settings' && <DefaultButton onClick={showModal} text="Retrain published models" />}
      {variant === 'command' && <CommandBar items={leftCommandBarItems} ariaLabel={t('components.commandBars.aria')} />}
      {wizard}
    </React.Fragment>
  );
};

export default ModelVersionModal;
