import { makeStyles } from '@fluentui/react';

const useBadgeStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing.s2} ${theme.spacing.s1}`,
    fontWeight: 100,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    borderRadius: 2,
  },
  success: {
    color: theme.palette.white,
    background: theme.palette.themeDark,
  },
  failure: {
    color: theme.palette.red,
    border: `1px solid ${theme.palette.red}`,
  },
  small: {
    fontSize: 9,
  },
  medium: {
    fontSize: 12,
  },
  large: {
    fontSize: 14,
  },
}));

export default useBadgeStyles;
