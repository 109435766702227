import { makeStyles } from '@fluentui/react';

const useGridPanelStyles = makeStyles(() => ({
  panel: {
    '@media(min-height: 480px)': {
      marginTop: '48px',
    },
  },
}));

export default useGridPanelStyles;
