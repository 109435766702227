import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteWorkspace, setDefaultWorkspace } from '../../../redux/workspaces/workspacesActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import RenameWorkspaceForm from '../../Forms/RenameWorkspaceForm';
import UpdateSubscriptionKeyForm from '../../Forms/UpdateSubscriptionKeyForm';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { WorkspacePopUpTypes } from '../../../utils/constants/popUps.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useIsOwner,
} from '../../../utils/hooks';

const WorkspaceSettingsCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const { appDialog, setAppDialog } = useAppDialog();
  const { appModal, setAppModal } = useAppModal();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const { workspaces, subscription } = useAppSelector((state) => state);

  const currentWorkspaceId = workspaces.currentWorkspace.id;
  const isOwner = useIsOwner(currentWorkspaceId);
  const { isDefaultWorkspace } = workspaces.data[currentWorkspaceId];

  const hasSubscriptionKey = !isEmpty(subscription.data) && !subscription.isLoading;
  const isGlobalRegion = !isEmpty(subscription.data) && subscription.data.region === 'GBL';

  const leftItems: ICommandBarItemProps[] = [
    {
      key: 'share',
      text: t('components.commandBars.workspaceSettings.shareButton.text'),
      ariaLabel: t('components.commandBars.workspaceSettings.shareButton.aria'),
      iconProps: { iconName: 'Share' },
      className: clsx(!isOwner && 'no-display'),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.ShareWorkspace);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
    {
      key: 'changeKey',
      text: t('components.commandBars.workspaceSettings.changeKeyButton.text'),
      ariaLabel: t('components.commandBars.workspaceSettings.changeKeyButton.aria'),
      iconProps: { iconName: 'Permissions' },
      className: clsx((!isGlobalRegion || !isOwner) && 'no-display'),
      onClick: (): void => {
        setAppModal({ contentType: WorkspacePopUpTypes.ChangeKey, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-update-key/open' });
      },
    },
    {
      key: 'addKey',
      text: t('components.commandBars.workspaceSettings.addKeyButton.text'),
      ariaLabel: t('components.commandBars.workspaceSettings.addKeyButton.aria'),
      iconProps: { iconName: 'Permissions' },
      className: clsx((hasSubscriptionKey || !isOwner) && 'no-display'),
      onClick: (): void => {
        setAppModal({ contentType: WorkspacePopUpTypes.AddKey, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-update-key/open' });
      },
    },
    {
      key: 'rename',
      text: t('components.commandBars.common.rename'),
      ariaLabel: t('components.commandBars.workspaceSettings.renameButton.aria'),
      iconProps: { iconName: 'Rename' },
      className: clsx(!isOwner && 'no-display'),
      onClick: (): void => {
        setAppModal({ contentType: WorkspacePopUpTypes.Rename, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-rename/open' });
      },
    },
    {
      key: 'workspacesDelete',
      text: t('components.commandBars.workspaceSettings.deleteButton.text'),
      ariaLabel: t('components.commandBars.workspaceSettings.deleteButton.aria'),
      iconProps: { iconName: 'Delete' },
      className: clsx(!isOwner && 'no-display'),
      onClick: (): void => {
        setAppDialog({ contentType: WorkspacePopUpTypes.Delete, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-delete/open' });
      },
    },
    {
      key: 'setDefault',
      text: t('components.commandBars.workspaceSettings.setDefaultButton.text'),
      ariaLabel: t('components.commandBars.workspaceSettings.setDefaultButton.aria'),
      iconProps: { iconName: 'PinNavMenu' },
      className: clsx(isDefaultWorkspace && 'no-display'),
      onClick: (): void => {
        appDispatch(setDefaultWorkspace(currentWorkspaceId));
      },
    },
  ];

  let message = t('components.commandBars.common.updating');
  if (workspaces.isRenaming) {
    message = t('components.commandBars.common.renaming');
  }
  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader
          isVisible={workspaces.isUpdating || workspaces.isRenaming || subscription.isUpdating}
          message={message}
        />
      ),
    },
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [WorkspacePopUpTypes.Delete]: {
      contentProps: {
        title: t('components.popups.workspace.delete.title'),
        subText: t('components.popups.workspace.delete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.workspace.delete.action'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(deleteWorkspace(currentWorkspaceId));
          });
          history.push('/workspaces');
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const appModalContentMap: AppModalContentMap = {
    [WorkspacePopUpTypes.AddKey]: {
      contentProps: {
        title: t('components.forms.updateSubscriptionKey.addKey.title'),
        body: <UpdateSubscriptionKeyForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 560,
    },
    [WorkspacePopUpTypes.ChangeKey]: {
      contentProps: {
        title: t('components.forms.updateSubscriptionKey.changeKey.title'),
        body: (
          <UpdateSubscriptionKeyForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} isChangingKey />
        ),
      },
      maxWidth: 560,
    },
    [WorkspacePopUpTypes.Rename]: {
      contentProps: {
        title: t('components.popups.workspace.rename.title'),
        body: <RenameWorkspaceForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 500,
    },
  };

  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar items={leftItems} farItems={rightCommandBarItems} ariaLabel={t('components.commandBars.aria')} />
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={appModalContent?.maxWidth || 700}
      />
    </Fragment>
  );
};

export default WorkspaceSettingsCommandBar;
