import { DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootStore } from '../../../redux/Store';
import { updateProject } from '../../../redux/projects/projectsActions';
import useEditProjectFormStyles from './EditProjectForm.styles';
import { IEditProjectFormProps, IEditProjectFormValues } from './EditProjectForm.types';

import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import { ModelStatus } from '../../../utils/constants/modelStatus';
import isProjectLabelValid from '../../../utils/isProjectLabelValid';

const EditProjectForm: React.FC<IEditProjectFormProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const classes = useEditProjectFormStyles();
  const dispatch = useDispatch();
  const { entitySelections, projects } = useSelector((state: RootStore) => state);
  const selectedProject = projects.data[entitySelections.selectedProject] || null;
  const [formValues, setFormValues] = useState(
    (): IEditProjectFormValues => {
      const initialState = {
        projectName: selectedProject.name || '',
        projectDescription: selectedProject.description || '',
        projectCategoryDescription: selectedProject.categoryDescriptor || '',
        projectLabel: selectedProject.label || '',
      };
      return initialState;
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    projectName: undefined,
    projectLabel: undefined,
  });

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      const validationLengthMap: { [key: string]: number } = {
        projectName: FormValidationLengths.MAX_PROJECT_NAME_LENGTH,
        projectDescription: FormValidationLengths.MAX_PROJECT_DESCRIPTION_LENGTH,
        projectCategoryDescription: FormValidationLengths.MAX_PROJECT_CATEGORY_DESCRIPTION_LENGTH,
        projectLabel: FormValidationLengths.MAX_PROJECT_LABEL_LENGTH,
      };

      const inputName = (event.target as HTMLInputElement).name;
      const isRequired = (event.target as HTMLInputElement).required;

      // Setting local input values
      if (!newValue || newValue?.length <= validationLengthMap[inputName]) {
        setFormValues({ ...formValues, [inputName]: newValue || '' });
      }

      // Setting local error values
      if (isRequired && !newValue) {
        setErrors({ ...errors, [inputName]: t('components.forms.generalErrors.required') });
      } else {
        setErrors({ ...errors, [inputName]: undefined });
      }
    },
    [formValues, errors, t]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    if (!isProjectLabelValid(formValues.projectLabel)) {
      setErrors({ ...errors, projectLabel: t('components.forms.editProject.projectLabel.invalid') });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-edit/inline-errors' });
    } else {
      handleCancelAndClose();
      dispatch(updateProject(formValues, entitySelections.selectedProject));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label={t('components.forms.editProject.projectName.label')}
        name="projectName"
        onChange={handleChange}
        value={formValues.projectName}
        placeholder="Project name"
        errorMessage={errors?.projectName}
        required
      />
      <TextField
        label={t('components.forms.editProject.description.label')}
        name="projectDescription"
        onChange={handleChange}
        value={formValues.projectDescription}
        placeholder={t('components.forms.editProject.description.placeholder')}
        multiline
        rows={2}
      />
      <TextField
        label={t('components.forms.editProject.categoryDescription.label')}
        name="projectCategoryDescription"
        onChange={handleChange}
        value={formValues.projectCategoryDescription}
        placeholder={t('components.forms.editProject.categoryDescription.placeholder')}
      />
      {selectedProject?.status !== ModelStatus.Published && (
        <TextField
          label={t('components.forms.editProject.projectLabel.label')}
          name="projectLabel"
          onChange={handleChange}
          value={formValues.projectLabel}
          placeholder={t('components.forms.editProject.projectLabel.placeholder')}
          errorMessage={errors?.projectLabel}
        />
      )}
      <Stack horizontal horizontalAlign="end" className={classes.actionButtons}>
        <PrimaryButton
          type="submit"
          disabled={!isEmpty(Object.values(errors).filter((value) => value))}
          text="Edit project"
        />
        <DefaultButton onClick={handleCancelAndClose} text={t('components.popups.common.cancel')} />
      </Stack>
    </form>
  );
};

export default EditProjectForm;
