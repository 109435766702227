import { makeStyles } from '@fluentui/react';

const useFeedbackPanelStyles = makeStyles(() => ({
  linkList: {
    marginTop: 15,
  },
  iframe: {
    height: '85vh',
    width: '100%',
    border: 'none',
  },
}));

export default useFeedbackPanelStyles;
