import { makeStyles } from '@fluentui/react';

const useErrorFallbackStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    alignItems: 'center',
  },
  item: {
    alignSelf: 'center',
  },
  title: {
    fontSize: '28px',
  },
  subText: {
    fontSize: '16px',
    alignSelf: 'center',
  },
}));

export default useErrorFallbackStyles;
