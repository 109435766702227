/* eslint-disable import/prefer-default-export */
import { Action, Dispatch } from 'redux';

import {
  CLEAR_DICTIONARY_TYPE,
  DictionaryTypes,
  DocumentSetTypes,
  IDocumentLanguageItem,
  SET_DOCUMENTS_LANGUAGE_PAIR,
  SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD,
  SET_UPLOAD_DOCUMENTS_WIZARD,
  SetUploadDocumentWizardTypes,
  UploadDocumentWizardDispatchTypes,
} from './uploadDocumentsWizard.types';

export const setInitialUploadDocumentWizard = () => (dispatch: Dispatch<UploadDocumentWizardDispatchTypes>): Action => {
  return dispatch({
    type: SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD,
  });
};

export const clearDictionaryType = () => (dispatch: Dispatch<UploadDocumentWizardDispatchTypes>): Action => {
  return dispatch({
    type: CLEAR_DICTIONARY_TYPE,
  });
};

export const setUploadDocumentsWizard = (
  type: SetUploadDocumentWizardTypes,
  value: DocumentSetTypes | DictionaryTypes | undefined
) => (dispatch: Dispatch<UploadDocumentWizardDispatchTypes>): Action => {
  return dispatch({
    type: SET_UPLOAD_DOCUMENTS_WIZARD,
    payload: {
      type,
      value,
    },
  });
};

export const setDocumentsLanguagePair = (
  sourceLanguage: IDocumentLanguageItem | undefined,
  targetLanguage: IDocumentLanguageItem | undefined
) => (dispatch: Dispatch<UploadDocumentWizardDispatchTypes>): Action => {
  return dispatch({
    type: SET_DOCUMENTS_LANGUAGE_PAIR,
    payload: {
      sourceLanguage,
      targetLanguage,
    },
  });
};
