import { isEmpty } from 'lodash';

import { IModelItem } from '../../redux/models/models.types';
import { IProjectItem } from '../../redux/projects/projects.types';
import { ModelStatus } from '../constants/modelStatus';
import { useAppSelector } from './appRedux.helpers';
import useAreOtherModelsUpdating from './useAreOtherModelsUpdating';

const hasTrainingSucceeded = (model: IModelItem): boolean => {
  const publishableStatuses: string[] = [
    ModelStatus.PublishFailed,
    ModelStatus.Unpublished,
    ModelStatus.TrainingSucceeded,
    ModelStatus.CopyCompleted,
  ];

  return publishableStatuses.includes(model.modelStatus);
};

const areOtherModelsPublished = (project: IProjectItem): boolean => {
  let hasPublishedModel = false;
  project.modelRegionStatus?.forEach((region) => {
    if (region.isDeployed) {
      hasPublishedModel = true;
    }
  });
  return hasPublishedModel;
};

const useCanModelBePublished = (modelId: string, isSwap: boolean): boolean => {
  const { models, projects } = useAppSelector((state) => state);
  const otherUpdates = useAreOtherModelsUpdating();

  if (isEmpty(models.data) || isEmpty(projects.currentProject) || isEmpty(projects.data)) {
    return false;
  }

  const currentModel = models.data[modelId];
  const currentProject = projects.data[projects.currentProject.id];

  if (currentModel === undefined || currentProject === undefined) {
    return false;
  }

  const isModelStateReady = hasTrainingSucceeded(currentModel);
  const hasPublishedModel = areOtherModelsPublished(currentProject);

  if (isSwap) {
    return isModelStateReady && hasPublishedModel && !otherUpdates;
  }
  return isModelStateReady && !hasPublishedModel && !otherUpdates;
};

export default useCanModelBePublished;
