import { Pivot, PivotItem } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { DocumentsCommandBar, ProjectsCommandBar, UploadHistoryCommandBar } from '../../CommandBars';
import FilterBar from '../../FilterBar';
import { Empty, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import DocumentsTable from '../../Tables/DocumentsTable';
import ProjectsTable from '../../Tables/ProjectsTable';
import UploadHistoryTable from '../../Tables/UploadHistoryTable';
import useWorkspaceContentStyles from './WorkspaceContent.styles';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import WorkspacePivot from '../../../utils/constants/pivots';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import getCurrentPivot from '../../../utils/getCurrentPivot';
import { useAppSelector } from '../../../utils/hooks';

const WorkspaceContent: React.FC = () => {
  const classes = useWorkspaceContentStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ workspaceId: string }>();
  const { workspaces, projects, documents } = useAppSelector((state) => state);

  const pivot = getCurrentPivot(location);

  const breadcrumbItems = [
    {
      text: workspaces.currentWorkspace.name,
      key: workspaces.currentWorkspace.id,
    },
  ];

  let projectsContent = null;

  if (projects.isLoading) {
    projectsContent = (
      <Loading
        loadingText={t('pages.workspace.contentSection.loadingProjects')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!projects.isLoading && !isEmpty(projects.ids)) {
    projectsContent = <ProjectsTable />;
  } else if (!projects.isLoading && isEmpty(projects.ids)) {
    projectsContent = <Empty type={EmptyTypes.Projects} />;
  }

  let documentsContent = null;
  /**
   * The loading spinner should only appear on initial page loads - for all subsequent loads that occur
   * due to pagination changes the ShimmeredDetailsList will shimmer to show progress.
   */
  if (documents.isLoading && documents.totalPageCount === 1) {
    documentsContent = (
      <Loading
        loadingText={t('pages.manageDocuments.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!documents.isLoading && isEmpty(documents.ids) && !documents.areDocumentsFiltered) {
    documentsContent = <Empty type={EmptyTypes.Documents} />;
  } else {
    documentsContent = <DocumentsTable />;
  }

  return (
    <div className="content-container">
      <PageHeading>{workspaces.currentWorkspace.name}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      <Pivot
        className={classes.pivot}
        aria-label={t('pages.workspace.contentSection.pivotSection.aria')}
        selectedKey={pivot}
        onLinkClick={(item?: PivotItem): void => {
          if (item?.props.itemKey === WorkspacePivot.Documents) {
            return history.push(`/workspaces/${params.workspaceId}/${item?.props.itemKey}?page=${documents.pageIndex}`);
          }
          return history.push(`/workspaces/${params.workspaceId}/${item?.props.itemKey}`);
        }}
      >
        <PivotItem
          itemKey={WorkspacePivot.Projects}
          itemIcon="ProductVariant"
          headerText={t('pages.workspace.contentSection.pivotSection.projectsPivot.text')}
          headerButtonProps={{
            ariaLabel: `${t('pages.workspace.contentSection.pivotSection.projectsPivot.aria')}`,
          }}
        >
          <ProjectsCommandBar />
          <FilterBar kind="projects" />
          {projectsContent}
        </PivotItem>
        <PivotItem
          itemKey={WorkspacePivot.Documents}
          itemIcon="DocumentSet"
          headerText={t('pages.workspace.contentSection.pivotSection.documentsPivot.text')}
          headerButtonProps={{
            ariaLabel: `${t('pages.workspace.contentSection.pivotSection.documentsPivot.aria')}`,
          }}
        >
          <DocumentsCommandBar />
          <FilterBar kind="documents" />
          {documentsContent}
        </PivotItem>
        <PivotItem
          itemKey={WorkspacePivot.UploadHistory}
          itemIcon="History"
          headerText={t('pages.workspace.contentSection.pivotSection.uploadHistoryPivot.text')}
          headerButtonProps={{
            ariaLabel: `${t('pages.workspace.contentSection.pivotSection.uploadHistoryPivot.aria')}`,
          }}
        >
          <UploadHistoryCommandBar />
          <FilterBar kind="uploadHistory" />
          <UploadHistoryTable />
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default WorkspaceContent;
