import { combineReducers } from 'redux';

import billingRegionsReducer from './billingRegions/billingRegionsReducer';
import categoriesReducer from './categories/categoriesReducer';
import copyNotificationReducer from './copyNotifications/copyNotificationsReducer';
import createWorkspaceWizardReducer from './createWorkspaceWizard/createWorkspaceWizardReducer';
import currentUserReducer from './currentUser/currentUserReducer';
import deploymentNotificationsReducer from './deploymentNotifications/deploymentNotificationsReducer';
import documentContentReducer from './documentContent/documentContentReducer';
import documentsReducer from './documents/documentsReducer';
import entitySelectionsReducer from './entitySelections/entitySelectionsReducer';
import errorReducer from './error/errorReducer';
import filesReducer from './files/filesReducer';
import filtersReducer from './filters/filtersReducer';
import languagesReducer from './languages/languagesReducer';
import modelDocumentsReducer from './modelDocuments/modelDocumentsReducer';
import modelRegionsReducer from './modelRegions/modelRegionsReducer';
import modelTestResultsReducer from './modelTestResults/modelTestResultsReducer';
import modelsReducer from './models/modelsReducer';
import modelsVersionReducer from './modelsVersion/modelsVersionReducer';
import notificationsReducer from './notifications/notificationsReducer';
import paginatedDocumentsSelectionReducer from './paginatedDocumentsSelection/paginatedDocumentsSelectionReducer';
import panelsReducer from './panels/panelsReducer';
import popUpErrorsReducer from './popUpErrors/popUpErrorsReducer';
import projectsReducer from './projects/projectsReducer';
import rolesReducer from './roles/rolesReducer';
import sharingReducer from './sharing/sharingReducer';
import subscriptionsReducer from './subscription/subscriptionReducer';
import supportedLanguagePairsReducer from './supportedLanguagePairs/supportedLanguagePairsReducer';
import supportedLanguagesReducer from './supportedLanguages/supportedLanguagesReducer';
import telemetryReducer from './telemetry/telemetryReducer';
import trainModelReducer from './trainModel/trainModelReducer';
import trainingNotificationsReducer from './trainingNotifications/trainingNotificationsReducer';
import UploadDocumentsWizardReducer from './uploadDocumentsWizard/uploadDocumentsWizardReducer';
import uploadHistoryReducer from './uploadHistory/uploadHistoryReducer';
import uploadHistoryContentReducer from './uploadHistoryContent/uploadHistoryContentReducer';
import uploadNotificationsReducer from './uploadNotifications/uploadNotificationsReducer';
import usersReducer from './users/usersReducer';
import workspacesReducer from './workspaces/workspacesReducer';

const RootReducer = combineReducers({
  categories: categoriesReducer,
  workspaces: workspacesReducer,
  projects: projectsReducer,
  models: modelsReducer,
  modelsVersion: modelsVersionReducer,
  languages: languagesReducer,
  billingRegions: billingRegionsReducer,
  modelRegions: modelRegionsReducer,
  modelDocuments: modelDocumentsReducer,
  modelTestResults: modelTestResultsReducer,
  roles: rolesReducer,
  sharing: sharingReducer,
  users: usersReducer,
  documents: documentsReducer,
  files: filesReducer,
  entitySelections: entitySelectionsReducer,
  supportedLanguagePairs: supportedLanguagePairsReducer,
  documentContent: documentContentReducer,
  popUpErrors: popUpErrorsReducer,
  uploadDocumentsWizard: UploadDocumentsWizardReducer,
  subscription: subscriptionsReducer,
  trainModel: trainModelReducer,
  createWorkspaceWizard: createWorkspaceWizardReducer,
  error: errorReducer,
  paginatedDocumentsSelection: paginatedDocumentsSelectionReducer,
  currentUser: currentUserReducer,
  notifications: notificationsReducer,
  trainingNotifications: trainingNotificationsReducer,
  deploymentNotifications: deploymentNotificationsReducer,
  uploadNotifications: uploadNotificationsReducer,
  panels: panelsReducer,
  telemetry: telemetryReducer,
  filters: filtersReducer,
  uploadHistory: uploadHistoryReducer,
  uploadHistoryContent: uploadHistoryContentReducer,
  supportedLanguages: supportedLanguagesReducer,
  copyNotifications: copyNotificationReducer,
});

export default RootReducer;
