import { Icon, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import React, { Fragment } from 'react';

import useTrainingStatusStyles from './TrainingStatus.styles';

import { ModelStatus } from '../../../../../utils/constants/modelStatus';
import { capitalize } from '../../../../../utils/textTransform';

interface ITrainingStatusProps {
  status: string;
}

const TrainingStatus: React.FC<ITrainingStatusProps> = ({ status }) => {
  const classes = useTrainingStatusStyles();
  const parentTooltipId = useId('text-tooltip');

  let statusContent = null;

  switch (status) {
    case ModelStatus.TrainingSucceeded:
      statusContent = (
        <Fragment>
          <Icon iconName="Completed" className={clsx(classes.icon, classes.successIcon)} />
          {capitalize(status)}
        </Fragment>
      );
      break;
    case ModelStatus.TrainingFailed:
    case ModelStatus.PublishFailed:
    case ModelStatus.DataProcessingFailed:
      statusContent = (
        <Fragment>
          <Icon iconName="ErrorBadge" className={clsx(classes.icon, classes.failedIcon)} />
          {capitalize(status)}
        </Fragment>
      );
      break;
    default:
      statusContent = capitalize(status);
  }

  return (
    <div className={classes.root}>
      <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={capitalize(status)} id={parentTooltipId}>
        {statusContent}
      </TooltipHost>
    </div>
  );
};

export default TrainingStatus;
