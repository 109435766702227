import { IFileItem } from '../files/files.types';
import { ILanguageItem } from '../languages/languages.types';
import { IUserItem } from '../users/users.types';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESSFUL = 'GET_DOCUMENTS_SUCCESSFUL';
export const GET_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';

export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';
export const CLEAR_CURRENT_DOCUMENT = 'CLEAR_CURRENT_DOCUMENT';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESSFUL = 'GET_DOCUMENT_SUCCESSFUL';
export const GET_DOCUMENT_FAILED = 'GET_DOCUMENT_FAILED';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESSFUL = 'DELETE_DOCUMENT_SUCCESSFUL';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';

export const UPLOAD_DOCUMENT_SET = 'UPLOAD_DOCUMENT_SET';
export const UPLOAD_DOCUMENT_SET_SUCCESSFUL = 'UPLOAD_DOCUMENT_SET_SUCCESSFUL';
export const UPLOAD_DOCUMENT_SET_FAILED = 'UPLOAD_DOCUMENT_SET_FAILED';

export const DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS';
export const DOWNLOAD_DOCUMENTS_SUCCESSFUL = 'DOWNLOAD_DOCUMENTS_SUCCESSFUL';
export const DOWNLOAD_DOCUMENTS_FAILED = 'DOWNLOAD_DOCUMENTS_FAILED';

export const SET_DOCUMENTS_FILTERED = 'SET_DOCUMENTS_FILTERED';
export const SET_HAS_NEW_DOCUMENTS = 'SET_HAS_NEW_DOCUMENTS';

/**
 * workspaceId: Workspace to fetch documents in
 * filter: Filter condition includes languages, and pagination
 * page: Page to fetch results for
 * resetPagination: If pagination in the store should be reset to 0 while the data is being fetched. For
 * consistency & a smooth user experience, pagination shouldn't reset if the user hasn't changed the
 * project and is just advanced forward or backwards a page.
 * prioritizeModel: if this parameter is present, documents will be retrieved sorting first the ones used by this model
 */
export interface IGetDocumentsParams {
  workspaceId: string;
  filter: string;
  page: number;
  resetPagination: boolean;
  prioritizeModel?: string;
}

export interface IBaseDocumentItem {
  id: string;
  characterCount: number;
  documentType: string;
  files: number[];
}

export interface IDocumentItem extends IBaseDocumentItem {
  createdBy: string;
  createdDate: string;
  extractedSentenceCount: number;
  isAvailable: boolean;
  isParallel: boolean;
  languages: number[];
  modifiedBy: string;
  name: string;
  usedByPrioritizedModel: string;
  /** Available by through a Model document */
  alignedSentenceCount?: number;
  /** Available by through a Model document */
  usedSentenceCount?: number;
}

export interface IProcessedDocumentItem {
  alignedSentenceCount: number;
  documentId: string;
  id: string;
  modelId: string;
  usedSentenceCount: number;
}

export interface IDocumentsEntities {
  users: {
    [key: string]: IUserItem;
  };
  languages: {
    [key: string]: ILanguageItem;
  };
  documents: {
    [key: string]: IDocumentItem;
  };
  files: {
    [key: number]: IFileItem;
  };
}

// Object format used for file upload to API
export interface IDocumentDetailsItem {
  DocumentName: string;
  DocumentType: string;
  FileDetails: IFileDetailsItem[];
}

export interface IFileDetailsItem {
  Name: string;
  LanguageCode: string;
  OverwriteIfExists: boolean;
}

export interface IDocumentsPayload {
  ids: string[];
  data: {
    [key: string]: IDocumentItem;
  };
  pageIndex: number;
  totalPageCount: number;
}

export interface IGetDocumentsPayload {
  resetPagination: boolean;
  pageIndex: number;
}

export interface IGetDocuments {
  type: typeof GET_DOCUMENTS;
  payload: IGetDocumentsPayload;
}

export interface IGetDocumentsSuccessful {
  type: typeof GET_DOCUMENTS_SUCCESSFUL;
  payload: IDocumentsPayload;
}

export interface IGetDocumentsFailed {
  type: typeof GET_DOCUMENTS_FAILED;
}

export interface ISetCurrentDocument {
  type: typeof SET_CURRENT_DOCUMENT;
  payload: string;
}

export interface IClearCurrentDocument {
  type: typeof CLEAR_CURRENT_DOCUMENT;
}

export interface IGetDocument {
  type: typeof GET_DOCUMENT;
}

export interface IGetDocumentSuccessful {
  type: typeof GET_DOCUMENT_SUCCESSFUL;
  payload: IDocumentsPayload;
}

export interface IGetDocumentFailed {
  type: typeof GET_DOCUMENT_FAILED;
}

export interface IUploadDocumentSet {
  type: typeof UPLOAD_DOCUMENT_SET;
}

export interface IUploadDocumentSetSuccessful {
  type: typeof UPLOAD_DOCUMENT_SET_SUCCESSFUL;
}

export interface IUploadDocumentSetFailed {
  type: typeof UPLOAD_DOCUMENT_SET_FAILED;
}

export interface IDeleteDocument {
  type: typeof DELETE_DOCUMENT;
}

export interface IDeleteDocumentSuccessful {
  type: typeof DELETE_DOCUMENT_SUCCESSFUL;
  payload: string;
}

export interface IDeleteDocumentFailed {
  type: typeof DELETE_DOCUMENT_FAILED;
}

export interface IDownloadDocuments {
  type: typeof DOWNLOAD_DOCUMENTS;
}

export interface IDownloadDocumentsSuccessful {
  type: typeof DOWNLOAD_DOCUMENTS_SUCCESSFUL;
}

export interface IDownloadDocumentsFailed {
  type: typeof DOWNLOAD_DOCUMENTS_FAILED;
}

export interface ISetDocumentsFiltered {
  type: typeof SET_DOCUMENTS_FILTERED;
  payload: boolean;
}

export interface ISetHasNewDocuments {
  type: typeof SET_HAS_NEW_DOCUMENTS;
  payload: boolean;
}

export type DocumentsDispatchTypes =
  | IGetDocuments
  | IGetDocumentsSuccessful
  | IGetDocumentsFailed
  | ISetCurrentDocument
  | IClearCurrentDocument
  | IGetDocument
  | IGetDocumentSuccessful
  | IGetDocumentFailed
  | IUploadDocumentSet
  | IUploadDocumentSetSuccessful
  | IUploadDocumentSetFailed
  | IDeleteDocument
  | IDeleteDocumentSuccessful
  | IDeleteDocumentFailed
  | IDownloadDocuments
  | IDownloadDocumentsSuccessful
  | IDownloadDocumentsFailed
  | ISetDocumentsFiltered
  | ISetHasNewDocuments;
