import { TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IModelRegionStatusItem } from '../../../../../redux/models/models.types';
import usePublishStatusStyles from './PublishStatus.styles';
import { IPublishedStatusProps } from './PublishedStatus.types';

const combineDeployedRegions = (publishStatusRegion: IModelRegionStatusItem[]): string => {
  const deployedRegions: string[] = [];

  publishStatusRegion.forEach((region) => {
    if (region.isDeployed && region.regionName) {
      deployedRegions.push(region.regionName);
    }
  });

  // TODO: This will need to be updated to support localization https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118908
  return deployedRegions.length > 0 ? deployedRegions.join(', ') : '';
};

const PublishedStatus: React.FC<IPublishedStatusProps> = ({ publishStatusRegion }) => {
  const classes = usePublishStatusStyles();
  const { t } = useTranslation();
  const parentTooltipId = useId('text-tooltip');
  const combinedRegions = combineDeployedRegions(publishStatusRegion);

  return (
    <div className={classes.root}>
      <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={combinedRegions} id={parentTooltipId}>
        <div className={classes.publishedBadge}>
          {t('components.tables.sharedCells.statusCell.publishedStatus.badge')}
        </div>
        <div className={classes.deployedRegions}>{combinedRegions}</div>
      </TooltipHost>
    </div>
  );
};

export default PublishedStatus;
