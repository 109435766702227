import {
  ADD_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_NOTIFICATION,
  CONNECT_TO_HUB,
  INotificationItemId,
  NotificationsDispatchTypes,
  REPLACE_NOTIFICATION_ID,
  SILENCE_ALL_NOTIFICATIONS,
} from './notifications.types';

export interface INotificationsState {
  isConnectedToHub: boolean;
  ids: INotificationItemId[];
}

const initialState: INotificationsState = {
  isConnectedToHub: false,
  ids: [],
};

// TODO: Fix order that notifications are added - newer one's should show at top of list, not bottom.
// https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/create/Bug?workitem=123535
const notificationsReducer = (
  state: INotificationsState = initialState,
  action: NotificationsDispatchTypes
): INotificationsState => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const hasId = state.ids.some((item) => action.payload.id === item.id);
      const ids = hasId ? state.ids : [action.payload].concat(state.ids);
      return {
        ...state,
        ids,
      };
    }
    case REPLACE_NOTIFICATION_ID: {
      const ids = state.ids.map((item) => {
        if (item.id === action.payload.data.oldId) {
          return {
            ...item,
            id: action.payload.id,
          };
        }
        return item;
      });
      return {
        ...state,
        ids,
      };
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        ids: state.ids.filter((item) => item.id !== action.payload.id),
      };
    }
    case CLEAR_ALL_NOTIFICATIONS: {
      return {
        ...state,
        ids: [],
      };
    }
    case CONNECT_TO_HUB: {
      return {
        ...state,
        isConnectedToHub: true,
      };
    }
    case SILENCE_ALL_NOTIFICATIONS: {
      const ids = state.ids.map((item) => ({ ...item, silent: true }));
      return {
        ...state,
        ids,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default notificationsReducer;
