import { makeStyles } from '@fluentui/react';

const useEditProjectFormStyles = makeStyles((theme) => ({
  actionButtons: {
    padding: `${theme.spacing.m} 0`,
    selectors: {
      '.ms-Button': {
        lineHeight: 'normal',
      },
      '& > :first-child': {
        marginRight: theme.spacing.s1,
      },
    },
  },
}));

export default useEditProjectFormStyles;
