import { CommandButton, Link, Stack } from '@fluentui/react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useFeedbackPanelStyles from './FeedbackPanel.styles';

const FeedbackPanel: React.FC = () => {
  const { t } = useTranslation();
  const [showSurvey, setShowSurvey] = useState<boolean>(false);
  const classes = useFeedbackPanelStyles();

  return (
    <Fragment>
      {!showSurvey && (
        <Stack tokens={{ childrenGap: 10 }} className={classes.linkList}>
          <Stack.Item>
            <Link
              href="https://feedback.azure.com/forums/932041-azure-cognitive-services"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('components.feedbackPanel.forum')}
            </Link>
          </Stack.Item>
          <Stack.Item>
            <Link target="_blank" rel="noreferrer noopener" onClick={(): void => setShowSurvey(true)}>
              {t('components.feedbackPanel.survey')}
            </Link>
          </Stack.Item>
        </Stack>
      )}
      {showSurvey && (
        <Stack>
          <Stack.Item>
            <CommandButton
              iconProps={{ iconName: 'OpenInNewTab' }}
              href="https://microsoft.qualtrics.com/jfe/form/SV_40zWLBFYILTkRWl?Kind=CustomTranslator&From=CustomPortal&SurveyNumber="
              text={t('components.feedbackPanel.openNewTab')}
              target="_blank"
              rel="noreferrer noopener"
            />
          </Stack.Item>
          <Stack.Item>
            <iframe
              src="https://microsoft.qualtrics.com/jfe/form/SV_40zWLBFYILTkRWl?Kind=CustomTranslator&From=CustomPortal&SurveyNumber="
              title="Survey"
              className={classes.iframe}
            />
          </Stack.Item>
        </Stack>
      )}
    </Fragment>
  );
};

export default FeedbackPanel;
