/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { DetailsListLayoutMode, IColumn, Selection, SelectionMode } from '@fluentui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import ErrorFallback from '../../ErrorFallback';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import { NameGroupCell, TextCell } from '../SharedTableCells';
import SharingCell from './SharingCell';

import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const WorkspacesTable: React.FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const { billingRegions, roles, users, workspaces } = useAppSelector((state) => state);
  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItem = selectionState.getSelection()[0] || '';

        dispatch({ type: 'SET_SELECTED_WORKSPACE', payload: selectedItem });
      },
    })
  );

  const workspaceColumns: IColumn[] = [
    {
      key: 'workspaceName',
      name: t('components.tables.sharedColumns.name'),
      fieldName: 'name',
      onRender: (workspaceId: string): React.ReactElement => {
        const { name, isDefaultWorkspace } = workspaces.data[workspaceId];

        // TODO: Implement inline grid actions
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118981
        return (
          <NameGroupCell
            link={{ href: `${match.url}/${workspaceId}`, name, iconName: 'MicrosoftTranslatorLogo' }}
            pinItem={isDefaultWorkspace}
          />
        );
      },
      minWidth: 300,
      maxWidth: 500,
      isResizable: true,
      isSorted: false,
      columnActionsMode: 0,
    },
    {
      key: 'region',
      name: t('components.tables.workspaces.columns.regionColumn'),
      onRender: (workspaceId: string): React.ReactElement | null => {
        const { region } = workspaces.data[workspaceId];
        if (region) {
          const { billingRegionName } = billingRegions[region];

          return <TextCell text={billingRegionName} />;
        }

        return null;
      },
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'shared',
      name: t('components.tables.workspaces.columns.sharedColumn'),
      onRender: (workspaceId: string): React.ReactElement | string => (
        <SharingCell sharingIds={workspaces.data[workspaceId].sharing} />
      ),
      minWidth: 75,
      maxWidth: 125,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'permission',
      name: t('components.tables.workspaces.columns.permissionColumn'),
      onRender: (workspaceId: string): React.ReactElement => {
        const { role } = workspaces.data[workspaceId];
        const { roleName } = roles[role];

        return <TextCell text={roleName} />;
      },
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'workspaceCreatedDate',
      name: t('components.tables.sharedColumns.createdDate'),
      onRender: (workspaceId: string): React.ReactElement => {
        const { createdDate } = workspaces.data[workspaceId];

        // TODO: Localize date/time https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119689
        return <TextCell text={dayjs(createdDate).format('MM/DD/YYYY')} />;
      },
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'workspaceCreatedBy',
      name: t('components.tables.sharedColumns.createdBy'),
      onRender: (workspaceId: string): React.ReactElement => {
        const { createdBy } = workspaces.data[workspaceId];
        const user = users[createdBy];

        if (!user) {
          return <React.Fragment>N/A</React.Fragment>;
        }

        const { userName } = users[createdBy];

        return <TextCell text={userName} />;
      },
      minWidth: 125,
      maxWidth: 250,
      isResizable: true,
      isSorted: false,
      columnActionsMode: 0,
    },
  ];

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div data-test-id="workspaces-table">
        <DetailsListKeyboardAccessible
          items={workspaces.ids}
          columns={workspaceColumns}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selectionState}
          selectionMode={SelectionMode.single}
          ariaLabel={t('components.tables.workspaces.tableAria')}
          checkButtonAriaLabel={t('components.tables.workspaces.checkButtonAria')}
        />
      </div>
    </ErrorBoundary>
  );
};

export default WorkspacesTable;
