import { Label, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useLoaderStyles from './CommandBarLoader.styles';
import ICommandBarLoaderProps from './CommandBarLoader.types';

const Loader: React.FC<ICommandBarLoaderProps> = ({ isVisible = false, message = undefined }) => {
  const classes = useLoaderStyles();
  const { t } = useTranslation();
  return (
    <Stack className={clsx(!isVisible && 'no-display')} horizontal verticalAlign="center">
      <Spinner size={SpinnerSize.small} className={classes.spinner} />
      <Label className={classes.label}>{message || t('components.commandBars.common.updating')}</Label>
    </Stack>
  );
};

export default Loader;
