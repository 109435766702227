import { makeStyles } from '@fluentui/react';

const usePublishStatusStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  publishedBadge: {
    display: 'inline-block',
    marginRight: 8,
    padding: '2px 4px',
    color: theme.palette.white,
    fontSize: 9,
    fontWeight: 100,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    background: theme.palette.themeDark,
    borderRadius: 2,
  },
  deployedRegions: {
    display: 'inline-block',
  },
}));

export default usePublishStatusStyles;
