import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import normalizeResponse from '../normalizeResponse';
import { supportedLanguagesSchema } from '../schema';
import {
  GET_SUPPORTED_LANGUAGES,
  ISupportedLanguagesEntities,
  SupportedLanguagesDispatchTypes,
} from './supportedLanguages.types';

const getSupportedLanguages = () => async (
  dispatch: Dispatch<SupportedLanguagesDispatchTypes>
): Promise<Action | void> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}languages`);
    const { entities } = await normalizeResponse<ISupportedLanguagesEntities>(response.data, supportedLanguagesSchema);

    return batch(() => {
      dispatch({
        type: GET_SUPPORTED_LANGUAGES,
        payload: {
          data: entities.supportedLanguages,
        },
      });
    });
  } catch (error) {
    return undefined;
  }
};

export default getSupportedLanguages;
