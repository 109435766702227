import { FontIcon, IGroup, Image, Link, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import helloImage from '../../assets/images/features/hello.svg';
import useHelpPanelStyles from './HelpPanel.styles';

interface IListItem {
  text: string;
  linkText: string;
  linkUrl: string;
}

const HelpPanel: React.FC = () => {
  const classes = useHelpPanelStyles();
  const { t } = useTranslation();

  const links = {
    documentation: 'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/overview',
    privacyStatement: 'https://privacy.microsoft.com/privacystatement',
    trademarks: 'https://www.microsoft.com/legal/intellectualproperty/trademarks',
    serviceTerms: 'https://azure.microsoft.com/support/legal/',
  };
  const groups: IGroup[] = [
    {
      count: 1,
      key: '1',
      name: `${t('components.helpPanel.steps.createWorkspace.title')}`,
      startIndex: 0,
    },
    {
      count: 1,
      key: '2',
      name: `${t('components.helpPanel.steps.createProject.title')}`,
      startIndex: 1,
    },
    {
      count: 1,
      key: '3',
      name: `${t('components.helpPanel.steps.uploadDocuments.title')}`,
      startIndex: 2,
    },
    {
      count: 1,
      key: '4',
      name: `${t('components.helpPanel.steps.trainModel.title')}`,
      startIndex: 3,
    },
    {
      count: 1,
      key: '5',
      name: `${t('components.helpPanel.steps.evaluateModel.title')}`,
      startIndex: 4,
    },
    {
      count: 1,
      key: '6',
      name: `${t('components.helpPanel.steps.publishModel.title')}`,
      startIndex: 5,
    },
    {
      count: 1,
      key: '7',
      name: `${t('components.helpPanel.steps.usePublishedModel.title')}`,
      startIndex: 6,
    },
  ];
  const items: IListItem[] = [
    {
      text: t('components.helpPanel.steps.createWorkspace.text'),
      linkText: t('components.helpPanel.steps.createWorkspace.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/create-manage-workspace',
    },
    {
      text: t('components.helpPanel.steps.createProject.text'),
      linkText: t('components.helpPanel.steps.createProject.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/create-manage-project',
    },
    {
      text: t('components.helpPanel.steps.uploadDocuments.text'),
      linkText: t('components.helpPanel.steps.uploadDocuments.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/create-manage-training-documents',
    },
    {
      text: t('components.helpPanel.steps.trainModel.text'),
      linkText: t('components.helpPanel.steps.trainModel.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/train-custom-model',
    },
    {
      text: t('components.helpPanel.steps.evaluateModel.text'),
      linkText: t('components.helpPanel.steps.evaluateModel.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/view-model-test-translation',
    },
    {
      text: t('components.helpPanel.steps.publishModel.text'),
      linkText: t('components.helpPanel.steps.publishModel.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/publish-model',
    },
    {
      text: t('components.helpPanel.steps.usePublishedModel.text'),
      linkText: t('components.helpPanel.steps.usePublishedModel.linkText'),
      linkUrl:
        'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/translate-with-custom-model',
    },
  ];

  return (
    <React.Fragment>
      <Link href={links.documentation} target="_blank" rel="noreferrer noopener">
        {t('components.helpPanel.documentation')}
      </Link>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack.Item>
          <div className={classes.title}> {t('components.helpPanel.title')}</div>
        </Stack.Item>
        <Stack.Item>
          <Image src={helloImage} alt={t('components.helpPanel.imageAlt')} />
        </Stack.Item>
        <Stack.Item className={classes.list}>
          {groups.map((group) => (
            <details className={classes.details} key={group.key}>
              <summary className={classes.summary}>
                <FontIcon iconName="ChevronRight" className="group-icon group-icon--close" />
                <FontIcon iconName="ChevronDown" className="group-icon group-icon--open" />
                <span>
                  {group.key}: <span className="group-name">{group.name}</span>
                </span>
              </summary>
              <div className={classes.itemContent}>
                {items[group.startIndex].text}{' '}
                <Link href={items[group.startIndex].linkUrl} target="_blank" rel="noreferrer noopener">
                  {items[group.startIndex].linkText}
                </Link>
              </div>
            </details>
          ))}
        </Stack.Item>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 5 }}>
          <Stack.Item>
            <Link href={links.privacyStatement} target="_blank" rel="noreferrer noopener">
              {t('pages.signedOut.footerSection.privacyAndCookies')}
            </Link>
          </Stack.Item>
          <Stack.Item shrink>|</Stack.Item>
          <Stack.Item>
            <Link href={links.trademarks} target="_blank" rel="noreferrer noopener">
              {t('pages.signedOut.footerSection.trademarks')}
            </Link>
          </Stack.Item>
          <Stack.Item shrink>|</Stack.Item>
          <Stack.Item>
            <Link href={links.serviceTerms} target="_blank" rel="noreferrer noopener">
              {t('components.helpPanel.serviceTerms')}
            </Link>
          </Stack.Item>
        </Stack>
        <div className={classes.footerLink}>
          {`${t('components.helpPanel.contactUs')} `}
          <Link href="https://aka.ms/translatorsupport" target="_blank" rel="noreferrer noopener">
            Translator Support
          </Link>
        </div>
      </Stack>
    </React.Fragment>
  );
};

export default HelpPanel;
