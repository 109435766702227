import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@fluentui/react';
import React, { Suspense } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import appTheme from './apptheme';
import App from './components/App';
import LoadingOverlay from './components/LoadingOverlay';
import './index.css';
import Store from './redux/Store';
import * as serviceWorker from './serviceWorker';

import { msalInstance } from './utils/authentication';
import './utils/axiosClient';
import './utils/i18n';
import initializeAndRegisterIcons from './utils/initializeAndRegisterIcons';

initializeAndRegisterIcons();

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <ThemeProvider applyTo="body" className="theme-provider" theme={appTheme}>
        <MsalProvider instance={msalInstance}>
          <Suspense fallback={<LoadingOverlay />}>
            <App />
          </Suspense>
        </MsalProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
