import { IBreadcrumbItem } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TestModelCommandBar } from '../../CommandBars';
import FilterBar from '../../FilterBar';
import { Empty, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { ModelsTable } from '../../Tables';
import ContentContainer from '../ContentContainer';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import filterTestedOrPublishedModels from '../../../utils/filterTestedOrPublishedModels';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const TestModelContent: React.FC = () => {
  const { t } = useTranslation();
  const [modelIdsWithTestResults, setModelIdsWithTestResults] = useState<string[]>([]);
  const { models, modelRegions, workspaces, projects } = useAppSelector((state) => state);
  const appDispatch = useAppDispatch();
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t('pages.testModel.pageName'),
      key: 'testModel',
    },
  ];

  // Models are dependent on GET /regions to complete to access regionNames.
  const isLoading = models.isLoading || modelRegions.isLoading || projects.isLoading;
  const hasModels = !isEmpty(models.testedOrPublishedModelsIds);
  const hasWorkspaces = !isEmpty(workspaces.data);
  const hasCurrentWorkspace = !isEmpty(workspaces.currentWorkspace);
  let modelsContent = null;

  useEffect(() => {
    // Only models that have succeeded training and are not dictionary-only models will have a test set available
    // Once IDs are filtered set those IDs with local state and pass that into the ModelsTable
    const filteredModelIds = filterTestedOrPublishedModels(models, true, models.ids);
    setModelIdsWithTestResults(filteredModelIds);

    // Set unfiltered total of models that can be displayed on the test models page
    if (!isEmpty(models.data)) {
      appDispatch({
        type: 'SET_TESTED_OR_PUBLISHED_MODEL_IDS',
        payload: filterTestedOrPublishedModels(models, true, Object.keys(models?.data)),
      });
    } else {
      appDispatch({
        type: 'SET_TESTED_OR_PUBLISHED_MODEL_IDS',
        payload: [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models.ids]);

  if (isLoading) {
    modelsContent = (
      <Loading
        loadingText={t('pages.models.contentSection.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!isLoading && hasModels && hasWorkspaces && hasCurrentWorkspace) {
    modelsContent = (
      <Fragment>
        <ModelsTable filteredIds={modelIdsWithTestResults} showTrainingTypeColumn={false} />
      </Fragment>
    );
  } else if (!isLoading && !hasModels) {
    modelsContent = <Empty type={EmptyTypes.TestModel} />;
  }

  return (
    <ContentContainer>
      <PageHeading>{t('pages.testModel.pageName')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} subtitle={hasModels ? t('pages.testModel.subtitle') : ''} />
      <TestModelCommandBar />
      <FilterBar kind="models" />
      {modelsContent}
    </ContentContainer>
  );
};

export default TestModelContent;
