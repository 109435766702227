import { useTranslation } from 'react-i18next';

import { CreateWorkspaceWizardSlide } from '../../redux/createWorkspaceWizard/createWorkspaceWizard.types';

const useGetCreateWorkspaceWizardTitle = (slide: CreateWorkspaceWizardSlide): string => {
  const { t } = useTranslation();
  switch (slide) {
    case CreateWorkspaceWizardSlide.Success:
      return t('components.forms.createWorkspaceWizard.titles.success');
    case CreateWorkspaceWizardSlide.AddKey:
      return t('components.forms.createWorkspaceWizard.titles.addKey');
    default:
      return t('components.forms.createWorkspaceWizard.titles.create');
  }
};

export default useGetCreateWorkspaceWizardTitle;
