import { makeStyles } from '@fluentui/react';

const useLoadingOverlayStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 9999999,
  },
  content: {
    height: '100%',
  },
}));

export default useLoadingOverlayStyles;
