import { IStackTokens, Icon, IconButton, ProgressIndicator, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import './ModelProgressIndicator.css';
import useModelProgressIndicatorStyles from './ModelProgressIndicator.styles';
import { IModelProgressIndicatorProps, TitleStatus } from './ModelProgressIndicator.types';

import calculateTrainingCost from '../../utils/calculateTrainingCost';
import REQUIRED_TRAINING_SENTENCES from '../../utils/constants/trainingSentences';
import { TrainingTypes } from '../../utils/constants/trainingTypes';
import { useAppSelector } from '../../utils/hooks';
import { convertToCurrencyFormat, convertToNumberWithCommas } from '../../utils/numbersDisplayConversions';

const ModelProgressIndicator: React.FC<IModelProgressIndicatorProps> = ({ isDocumentSelectionIncomplete = false }) => {
  const classes = useModelProgressIndicatorStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const freeTooltipId = useId('free-tooltip');
  const {
    subscription: {
      data: { billing },
    },
    projects,
    paginatedDocumentsSelection,
    trainModel: { trainingType },
  } = useAppSelector((state) => state);

  const trainingSentenceCount = paginatedDocumentsSelection.counts.training.sentence;
  const totalSentenceCount = paginatedDocumentsSelection.counts.total.sentence;
  const totalDocumentCount = paginatedDocumentsSelection.counts.total.document;
  const totalCharacterCount = paginatedDocumentsSelection.counts.total.character;
  const dictionaryDocumentsCount = paginatedDocumentsSelection.counts.dictionary.document;
  const dictionarySentenceCount = paginatedDocumentsSelection.counts.dictionary.sentence;
  const selectedDocuments = paginatedDocumentsSelection.data;
  const isTrainingFree = projects.data[projects.currentProject.id]?.hasFreeTraining || false;

  let titleStatus = TitleStatus.Neutral;
  if (isDocumentSelectionIncomplete) {
    titleStatus = TitleStatus.Failed;
  } else if (!isEmpty(selectedDocuments)) {
    if (trainingType === TrainingTypes.FullTraining) {
      titleStatus = trainingSentenceCount >= REQUIRED_TRAINING_SENTENCES ? TitleStatus.Success : TitleStatus.Failed;
    } else if (trainingType === TrainingTypes.DictionaryOnly) {
      titleStatus = dictionarySentenceCount > 0 ? TitleStatus.Success : TitleStatus.Failed;
    }
  }

  const parentStackTokens: IStackTokens = { childrenGap: theme.spacing.l1 };

  let indicatorTitle = t('components.modelProgressIndicator.title');
  if (!isEmpty(selectedDocuments)) {
    if (totalDocumentCount > 1) {
      indicatorTitle = t('components.modelProgressIndicator.selectedDocuments', {
        documents: totalDocumentCount,
        sentences: convertToNumberWithCommas(totalSentenceCount),
      });
    } else {
      indicatorTitle = t('components.modelProgressIndicator.selectedDocument', {
        documents: totalDocumentCount,
        sentences: convertToNumberWithCommas(totalSentenceCount),
      });
    }
  }

  let dictionaryText = t('components.modelProgressIndicator.selectedDictionaries', {
    dictionaries: dictionaryDocumentsCount,
  });
  if (dictionaryDocumentsCount === 1) {
    dictionaryText = t('components.modelProgressIndicator.selectedDictionary', {
      dictionaries: dictionaryDocumentsCount,
    });
  }
  let trainingCost =
    !isEmpty(selectedDocuments) && !isEmpty(billing)
      ? convertToCurrencyFormat(calculateTrainingCost(billing, totalCharacterCount))
      : convertToCurrencyFormat(0);
  if (isTrainingFree) {
    trainingCost = t('components.modelProgressIndicator.free');
  }
  return (
    <Fragment>
      <Stack className={clsx(classes.root)}>
        <Stack.Item>
          <div className={`${classes.title} ${titleStatus}`} role="status">
            <Icon
              iconName={titleStatus === TitleStatus.Success ? 'Completed' : 'ErrorBadge'}
              className={`${classes.icon} ${titleStatus}`}
            />
            <Text className={clsx(classes.titleText)}>{indicatorTitle}</Text>
          </div>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal tokens={parentStackTokens} className={clsx(classes.container)}>
            <Stack.Item className={clsx(classes.costSection)}>
              <div>{t('components.modelProgressIndicator.trainingCost')}</div>
              <Text className={`${classes.cost} ${isTrainingFree ? 'free' : ''}`}>{trainingCost}</Text>
              {isTrainingFree && (
                <TooltipHost content={t('components.modelProgressIndicator.freeTooltip')} id={freeTooltipId}>
                  <IconButton
                    iconProps={{ iconName: 'Info' }}
                    ariaLabel={t('components.commandBars.moreInformation.aria')}
                    className={classes.iconTooltip}
                  />
                </TooltipHost>
              )}
            </Stack.Item>
            <Stack.Item grow className={classes.progressIndicatorSection}>
              {trainingType === TrainingTypes.FullTraining && (
                <ProgressIndicator
                  label={t('components.modelProgressIndicator.requiredSentences')}
                  description={t('components.modelProgressIndicator.selectedSentences', {
                    sentences: convertToNumberWithCommas(trainingSentenceCount),
                  })}
                  percentComplete={trainingSentenceCount / REQUIRED_TRAINING_SENTENCES}
                  className={clsx(classes.progressIndicator)}
                />
              )}
              {trainingType === TrainingTypes.DictionaryOnly && (
                <ProgressIndicator
                  progressHidden
                  label={dictionaryText}
                  description={t('components.modelProgressIndicator.selectedSentences', {
                    sentences: dictionarySentenceCount,
                  })}
                  className={clsx(classes.progressIndicator)}
                />
              )}
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Fragment>
  );
};

export default ModelProgressIndicator;
