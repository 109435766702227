import { makeStyles } from '@fluentui/react';

const useModelVersionModalStyles = makeStyles(() => ({
  subheading: {
    fontWeight: '500',
    marginBottom: 0,
  },
}));

export default useModelVersionModalStyles;
