import { makeStyles } from '@fluentui/react';

import components from '../../../utils/constants/components';

const usePaginatedDocumentTableStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: `calc(100vh - ${components.ScrollableDetailsList.documentContentNonScrollableContent}px)`,
    minHeight: '240px',
  },
}));

export default usePaginatedDocumentTableStyles;
