import { Stack } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CLEAR_ALL_ENTITY_SELECTIONS } from '../../redux/entitySelections/entitySelections.types';
import { getModels } from '../../redux/models/modelsActions';
import { DISMISS_POP_UP_ERROR } from '../../redux/popUpErrors/popUpErrors.types';
import { getProjects } from '../../redux/projects/projectsActions';
import AppBar from '../AppBar';
import AppMessageBar from '../AppMessageBar';
import { AppDialog } from '../AppPopUps';
import AppSidebar from '../AppSidebar';
import AppSidebarContext from '../AppSidebar/AppSidebar.context';
import { FilterProjectsForm } from '../Forms';
import FilterDocumentsForm from '../Forms/FilterDocumentsForm';
import FilterModelsForm from '../Forms/FilterModelsForm';
import FilterUploadHistoryForm from '../Forms/FilterUploadHistoryForm';
import GridPanel from '../GridPanel';
import { GridPanelTypes, gridPanelContext } from '../GridPanel/GridPanel.context';
import ModelDetails from '../ModelDetails';
import ShareWorkspace from '../ShareWorkspace';
import useAppLayoutStyles from './AppLayout.styles';
import IAppLayoutProps from './AppLayout.types';

import { PopUpErrorActions } from '../../utils/constants/popUpErrors';
import IRoutePathParams from '../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/appRedux.helpers';

const AppLayout: React.FC<IAppLayoutProps> = ({ children, hasSidebar = true, hasStickyFooter = false }) => {
  const classes = useAppLayoutStyles();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { workspaceId, projectId } = useParams<IRoutePathParams>();
  const {
    isHidden,
    title,
    message,
    confirmationButtonText,
    confirmationButtonAction,
    showCancelButton,
  } = useAppSelector((state) => state.popUpErrors);
  const { entitySelections, models } = useAppSelector((state) => state);
  const { isAppSidebarCollapsed } = useContext(AppSidebarContext);
  const { isGridPanelOpen, gridPanelType } = useContext(gridPanelContext);
  const appContentClass = clsx(classes.content, { 'full-width': isAppSidebarCollapsed || !hasSidebar });
  const appRootClass = clsx(classes.root, { 'has-sticky-footer': hasStickyFooter });

  const confirmationActions = (): void => {
    batch(() => {
      appDispatch({ type: DISMISS_POP_UP_ERROR });
      appDispatch({ type: CLEAR_ALL_ENTITY_SELECTIONS });
      if (confirmationButtonAction === PopUpErrorActions.RefreshProjects) {
        appDispatch(getProjects(workspaceId));
      } else if (confirmationButtonAction === PopUpErrorActions.RefreshPage) {
        history.go(0);
      }
    });
    switch (confirmationButtonAction) {
      case PopUpErrorActions.RefreshProjects: {
        appDispatch(getProjects(workspaceId));
        break;
      }
      case PopUpErrorActions.RefreshModels: {
        appDispatch(getModels(projectId));
        break;
      }
      case PopUpErrorActions.RefreshPage: {
        history.go(0);
        break;
      }
      default:
        history.go(0);
    }
  };

  const toggleActions = (): void => {
    batch(() => {
      appDispatch({ type: DISMISS_POP_UP_ERROR });
      appDispatch({ type: CLEAR_ALL_ENTITY_SELECTIONS });
    });
  };

  let gridPanelContent;
  let panelTitle = t('components.gridPanel.filterTitle');
  let isSharingPanel = false;
  switch (gridPanelType) {
    case GridPanelTypes.ProjectsFilter: {
      gridPanelContent = <FilterProjectsForm />;
      break;
    }
    case GridPanelTypes.ModelsFilter: {
      gridPanelContent = <FilterModelsForm />;
      break;
    }
    case GridPanelTypes.DocumentsFilter: {
      gridPanelContent = <FilterDocumentsForm />;
      break;
    }
    case GridPanelTypes.UploadHistoryFilter: {
      gridPanelContent = <FilterUploadHistoryForm />;
      break;
    }
    case GridPanelTypes.Details: {
      const model = models.data[entitySelections.selectedModel];
      gridPanelContent = <ModelDetails model={model} />;
      panelTitle = model ? model.name : '...';
      break;
    }
    case GridPanelTypes.ShareWorkspace: {
      gridPanelContent = <ShareWorkspace />;
      panelTitle = t('components.shareWorkspace.title');
      isSharingPanel = true;
      break;
    }
    default:
      gridPanelContent = <Fragment />;
      panelTitle = '';
  }

  return (
    <Fragment>
      <AppBar />
      <Stack as="main" className={appRootClass} horizontal={hasSidebar}>
        {hasSidebar && <AppSidebar />}
        <Stack.Item className={appContentClass}>
          <AppMessageBar />
          {children}
        </Stack.Item>
        <Stack.Item>
          <GridPanel
            title={panelTitle}
            body={gridPanelContent}
            isOpen={isGridPanelOpen}
            isSharingPanel={isSharingPanel}
          />
        </Stack.Item>
      </Stack>
      <AppDialog
        hidden={isHidden}
        contentProps={{
          title,
          subText: message,
        }}
        toggleDialog={toggleActions}
        confirmationActionProps={{
          text: confirmationButtonText,
          onClick: confirmationActions,
        }}
        showCancelButton={showCancelButton}
      />
    </Fragment>
  );
};

export default AppLayout;
