/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';

import AppLayout from '../AppLayout';
import IAuthRouteProps from './AuthRoute.types';

const AuthRoute: React.FC<IAuthRouteProps> = ({
  component: Component,
  isAuthed,
  hasSidebar,
  hasStickyFooter,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props): ReactElement =>
        // Bypass auth here if the current environment is set to 'test'
        isAuthed || process.env.REACT_APP_ENV === 'test' ? (
          <AppLayout hasSidebar={hasSidebar} hasStickyFooter={hasStickyFooter}>
            <Component {...props} />
          </AppLayout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthRoute;
