import { IStackTokens, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import error401Image from '../../../assets/images/features/Error401.svg';
import error404Image from '../../../assets/images/features/Error404.svg';
import error500Image from '../../../assets/images/features/Error500.svg';
import useErrorStyles from './Error.styles';
import IErrorProps from './Error.types';

import ErrorStatusCodes from '../../../utils/constants/ErrorStatusCodes';
import { useAppSelector } from '../../../utils/hooks';

const Error: React.FC<IErrorProps> = () => {
  const {
    error: { statusCode },
  } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const classes = useErrorStyles();
  const stackTokens: IStackTokens = { childrenGap: 15 };

  let errorImage;
  let errorTitle;
  let errorSubText;
  let errorImageAlt;

  switch (statusCode) {
    case ErrorStatusCodes.ResourceNotFound: {
      errorImage = error404Image;
      errorImageAlt = t('components.errorContent.404.imageAlt');
      errorTitle = t('components.errorContent.404.title');
      errorSubText = t('components.errorContent.404.subText');
      break;
    }
    case ErrorStatusCodes.Unauthorized: {
      errorImage = error401Image;
      errorImageAlt = t('components.errorContent.401.imageAlt');
      errorTitle = t('components.errorContent.401.title');
      errorSubText = t('components.errorContent.401.subText');
      break;
    }
    case ErrorStatusCodes.InternalServerError: {
      errorImage = error500Image;
      errorImageAlt = t('components.errorContent.500.imageAlt');
      errorTitle = t('components.errorContent.500.title');
      errorSubText = t('components.errorContent.500.subText');
      break;
    }
    default: {
      errorImage = error500Image;
      errorImageAlt = t('components.errorContent.500.imageAlt');
      errorTitle = t('components.errorContent.500.title');
      errorSubText = t('components.errorContent.500.subText');
      break;
    }
  }
  return (
    <div className={classes.root}>
      <Stack tokens={stackTokens}>
        <Stack.Item className={classes.item}>
          <img src={errorImage} alt={errorImageAlt} />
        </Stack.Item>
        <Stack.Item className={classes.item}>
          <Text className={classes.title}>{errorTitle}</Text>
        </Stack.Item>
        <Stack.Item className={classes.item}>
          <Text className={classes.subText}>{errorSubText}</Text>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default Error;
