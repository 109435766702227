import { Link, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDocumentSetStyles from './DocumentSetLabel.styles';
import IDocumentSetLabelProps from './DocumentSetLabel.types';

const DocumentSetLabel: React.FC<IDocumentSetLabelProps> = ({ title, text, link, image, body }) => {
  const classes = useDocumentSetStyles();
  const { t } = useTranslation();

  return (
    <Stack className={classes.root} horizontal>
      <Stack.Item>
        <Stack>
          <Stack.Item>
            <Text as="h4" block className={classes.title}>
              {title}
            </Text>
          </Stack.Item>
          {text && (
            <Stack.Item>
              <Text as="p" block className={classes.text}>
                {text}
                {link && (
                  <Link href={link} target="_blank" rel="noreferrer noopener" className={classes.link}>
                    {t('components.links.learnMore')}
                  </Link>
                )}
              </Text>
            </Stack.Item>
          )}
          {body && <Stack.Item>{body}</Stack.Item>}
        </Stack>
      </Stack.Item>
      {image && (
        <Stack.Item>
          <img className={classes.image} src={image.src} alt={image.alt} />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default DocumentSetLabel;
