import { Check } from '@fluentui/react/lib/Check';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { ISelection } from '@fluentui/react/lib/Selection';
import _ from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean, useForceUpdate, useId } from '@fluentui/react-hooks';
import { Callout, IDropdownStyleProps, IDropdownStyles, IStyleFunctionOrObject, Stack, Text } from '@fluentui/react';
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Selection, SelectionMode, SelectionZone } from '@fluentui/react/lib/Selection';
import { FontWeights, mergeStyleSets } from '@fluentui/react';
import { initializeComponent, withLocalization } from '../../../services/localization';
import { INTL } from '../../../util/intlUtil';
import { TagPickerLocalizationFormatMessages } from '../../../clientResources';

export interface Iitem {
    key: number | string;
    text: string;
};

export interface TagPickerItemProps {
    tagName: string;
    options: Iitem[];
    onSearch?: (value: string) => void;
    onChange?: (name: string, selectedKeys: string[]) => void;
    isMultiSelect?: boolean;
    isCanBeRemoved?: boolean;
    onRemove?: (tagName: string) => void;
    selectedkeys: string[];
    ariaLabel?: string;
};

export interface ISelectionItem {
    item: Iitem;
    itemIndex?: number;
    selection?: ISelection;
};

export const styles = mergeStyleSets({
    defaultContent: {
        border: 'none',
        background: 'rgba(0, 120, 212, 0.1)',
        borderRadius: '16px',
        padding: '2px 8px',
        fontSize: '13px',
        height: 24,
        selectors: {
            '&:hover': { background: 'rgba(0, 120, 212, 0.1)' },
        },
    },
    callout: {
        width: 320,
        padding: '12px 20px',
    },
    title: {
        fontSize: 18,
        marginBottom: 12,
        fontWeight: FontWeights.bold,
    },
});

export const inlineDropdownStyle: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
    root: { display: 'flex' },
    label: { whiteSpace: 'nowrap', lineHeight: 22, marginRight: 8, fontSize: 14, fontWeight: 400 },
    dropdown: { width: 240 },
};

const SelectionItem = ({ item, itemIndex, selection }: ISelectionItem): JSX.Element => {
    const renderItem = () => {
        if (selection && selection.canSelectItem(item) && selection.mode !== SelectionMode.none) {
            return (
                <Stack styles={{ root: { marginRight: 10 } }} data-is-focusable data-selection-toggle>
                    {selection.mode === 1 ? (
                        <Check checked={selection.isKeySelected(item.key.toString())} />
                    ) : (
                        <Checkbox
                            checked={selection.isKeySelected(item.key.toString())}
                            onChange={(event, checked) => selection.setKeySelected(item.key.toString(), !checked, true)}
                        />
                    )}
                </Stack>
            );
        }
        return <></>;
    };
    return (
        <Stack
            horizontal
            verticalAlign="center"
            data-selection-index={itemIndex}
            tabIndex={0}
            styles={{
                root: {
                    lineHeight: '20px',
                    ':not(:first-child)': {
                        marginTop: '16px',
                    },
                },
            }}
        >
            {renderItem()}
            <Text>{item.text}</Text>
        </Stack>
    );
};

const TagPickerItemInternal = ({
    isCanBeRemoved,
    tagName,
    options,
    selectedkeys,
    isMultiSelect,
    onRemove,
    onSearch,
    onChange,
    ariaLabel = '',
}: TagPickerItemProps): JSX.Element => {
    const buttonId = useId(`callout-button-${tagName.replace(/\s*/g, '')}`);
    const labelId = useId(`callout-label-${tagName.replace(/\s*/g, '')}`);

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible, setFalse, setTrue }] = useBoolean(false);
    const [selectionMode, setSelectionMode] = useState(SelectionMode.single);
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (isMultiSelect) {
            setSelectionMode(SelectionMode.multiple);
        }
    }, [isMultiSelect, setSelectionMode]);

    const [items, setItems] = useState<any[]>(options);
    useEffect(() => setItems(options), [options, setItems]);

    // Selection
    const [title, setTitle] = useState('none');
    const defalutSelectedItem = useMemo(() => {
        if (selectedkeys) return options.filter((v) => selectedkeys.some((item) => item === v.key));
        return [];
    }, [selectedkeys, options]);

    const [selectedItem, setSelectedItem] = useState(defalutSelectedItem);

    const selection = useMemo(
        () =>
            new Selection({
                selectionMode,
                onSelectionChanged: () => {
                    setSelectedItem(selection.getSelection());
                    forceUpdate();
                },
                items,
            }),
        [forceUpdate, items, selectionMode]
    );

    useEffect(() => {
        if (selectedkeys && selectedkeys.length !== 0) {
            const firstSelected = options.find((v) => v.key === selectedkeys[0]);
            setTitle(selectedkeys.length === 1 && firstSelected ? firstSelected.text : `${selectedkeys.length} selected`);
            selectedkeys.forEach((v) => selection.setKeySelected(v.toString(), true, true));
        }
    }, [selection, selectedkeys, setTitle, options]);

    // Search
    const onSearchChange = _.debounce((event, newVlaue) => {
        if (onSearch) {
            const searchResult = options.filter((v) => v.text.includes(newVlaue));
            setItems(searchResult);
            onSearch(newVlaue);
        }
    }, 600);

    // Title
    const renderText = useCallback(
        () => (
            <Fragment key={new Date().toString()}>
                <Text>{tagName} : </Text>
                <Label styles={{ root: { marginLeft: 5 } }}> {title} </Label>
            </Fragment>
        ),
        [tagName, title]
    );

    const onDismiss = useCallback(() => {
        setFalse();
        if (items.length !== options.length) setItems(options);
        onChange?.(
            tagName,
            selection.getSelection().map((v) => v.key)
        );
    }, [tagName, setFalse, selection, onChange, items, options]);

    return (
        <Stack styles={{ root: { marginRight: 8 } }}>
            {isCanBeRemoved ? (
                <Stack horizontal verticalAlign="center">
                    <DefaultButton
                        ariaLabel={ariaLabel}
                        ariaDescription={ariaLabel}
                        id={buttonId}
                        className={styles.defaultContent}
                        onClick={toggleIsCalloutVisible}
                        onRenderText={renderText}
                        styles={{
                            splitButtonDivider: { display: 'none' },
                            splitButtonMenuIcon: { fontSize: '12px' },
                            splitButtonMenuButton: {
                                margin: 0,
                                height: 24,
                                borderTopRightRadius: 16,
                                borderBottomRightRadius: 16,
                                backgroundColor: 'rgba(0, 120, 212, 0.1)',
                                border: 'none',
                                selectors: {
                                    '&:hover': { background: 'rgba(0, 120, 212, 0.1)' },
                                },
                            },
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            if (onRemove) onRemove(tagName);
                        }}
                        styles={{ icon: { fontSize: 12 } }}
                        iconProps={{ ariaLabel: INTL.formatMessage(TagPickerLocalizationFormatMessages.Delete), iconName: 'ChromeClose' }}
                    />
                </Stack>
            ) : (
                <DefaultButton
                    ariaLabel={ariaLabel}
                    ariaDescription={ariaLabel}
                    id={buttonId}
                    className={styles.defaultContent}
                    onClick={toggleIsCalloutVisible}
                    onRenderText={renderText}
                />
            )}

            {isCalloutVisible && (
                <Callout
                    ariaDescribedBy={labelId}
                    className={styles.callout}
                    ariaLabelledBy={labelId}
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={onDismiss}
                    setInitialFocus
                >
                    <Label id={labelId} className={styles.title}>
                        {tagName}
                    </Label>
                    {onSearch && <SearchBox placeholder={INTL.formatMessage(TagPickerLocalizationFormatMessages.Search)} onChange={onSearchChange} styles={{ root: { marginBottom: '20px' } }} />}
                    <Stack styles={{ root: { maxHeight: 260, overflowY: 'auto', margin: '16px 0' } }}>
                        <SelectionZone selection={selection} selectionPreservedOnEmptyClick>
                            {items.map((item: Iitem, index: number) => (
                                <SelectionItem key={item.key} item={item} itemIndex={index} selection={selection} />
                            ))}
                        </SelectionZone>
                    </Stack>
                    <Stack horizontalAlign="end">
                        <PrimaryButton text={INTL.formatMessage(TagPickerLocalizationFormatMessages.Apply)} onClick={onDismiss} />
                    </Stack>
                </Callout>
            )}
        </Stack>
    );
};

export const TagPickerItem = withLocalization(initializeComponent(TagPickerItemInternal));