import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import { TestModelResultsContent } from '../components/PageContents';
import { getModelTestResults } from '../redux/modelTestResults/modelTestResultsActions';
import { getModel } from '../redux/models/modelsActions';
import { getProject } from '../redux/projects/projectsActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../utils/hooks';

const TestModelResults: React.FC = () => {
  useTrackMetric(reactPlugin, 'TestModelResultsPage');

  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { workspaces, projects, models, error } = useAppSelector((state) => state);

  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasProjects = !isEmpty(projects.ids);
    const hasModel = !isEmpty(models.data[modelId]);
    const isLoading = workspaces.isLoading || projects.isLoading;

    if (!isLoading) {
      if (!hasWorkspaces && !hasProjects && !hasModel) {
        batch(() => {
          appDispatch(getWorkspaces());
          appDispatch(getProject(projectId));
          appDispatch(getModel(modelId));
        });
      } else if (!hasWorkspaces) {
        appDispatch(getWorkspaces());
      } else if (!hasProjects) {
        appDispatch(getProject(projectId));
      } else if (!hasModel) {
        appDispatch(getModel(modelId));
      }
    }
  }, [
    appDispatch,
    workspaceId,
    workspaces.isLoading,
    workspaces.ids,
    projectId,
    projects.isLoading,
    projects.ids,
    modelId,
    models.data,
  ]);

  // Fetch models on load
  useEffect(() => {
    appDispatch(getModelTestResults(modelId));
  }, [appDispatch, modelId]);

  let content;
  if (!isEmpty(error.message) && error.category === ErrorCategories.TestModelResults) {
    content = <Error />;
  } else {
    content = <TestModelResultsContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('pages.testModel.pageName')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default TestModelResults;
