export enum TitleStatus {
  Neutral = 'neutral',
  Success = 'success',
  Failed = 'failed',
}

export interface IProgressIndicatorData {
  characterCount: number;
  trainingSentenceCount: number;
  dictionarySentenceCount: number;
  dictionaryDocumentsCount: number;
  totalSentenceCount: number;
}

export interface IModelProgressIndicatorProps {
  isDocumentSelectionIncomplete?: boolean;
}
