import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import normalizeResponse from '../normalizeResponse';
import { billingRegionsListSchema } from '../schema';
import {
  BillingRegionsDispatchTypes,
  GET_BILLING_REGIONS,
  GET_BILLING_REGIONS_SUCCESSFUL,
  IBillingRegionEntities,
} from './billingRegions.types';

import ErrorCategories from '../../utils/constants/errorCategories';

const getBillingRegions = () => async (
  dispatch: Dispatch<BillingRegionsDispatchTypes | ErrorDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: GET_BILLING_REGIONS });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}subscriptions/billingregions`);
    const { entities } = await normalizeResponse<IBillingRegionEntities>(response.data, billingRegionsListSchema);
    return dispatch({
      type: GET_BILLING_REGIONS_SUCCESSFUL,
      payload: entities.billingRegions,
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.BillingRegion,
        },
      });
    });
  }
};

export default getBillingRegions;
