import { makeStyles } from '@fluentui/react';

const useRenderLabelTooltipStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    selectors: {
      '::after': {
        paddingRight: 0,
      },
    },
  },
  iconTooltip: {
    width: 25,
    selectors: {
      '.ms-Icon': {
        color: theme.palette.black,
        fontSize: 14,
        position: 'relative',
        top: '2px',
      },
    },
  },
}));

export default useRenderLabelTooltipStyles;
