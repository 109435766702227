/* eslint-disable import/prefer-default-export */
import { Action, Dispatch } from 'redux';

import { CLEAR_CURRENT_USER, CurrentUserDispatchTypes, SET_CURRENT_USER } from './currentUser.types';

export const setCurrentUser = (username: string, email: string, msalId: string) => (
  dispatch: Dispatch<CurrentUserDispatchTypes>
): Action => {
  return dispatch({
    type: SET_CURRENT_USER,
    payload: {
      username,
      email,
      msalId,
    },
  });
};

export const clearCurrentUser = () => (dispatch: Dispatch<CurrentUserDispatchTypes>): Action => {
  return dispatch({
    type: CLEAR_CURRENT_USER,
  });
};
