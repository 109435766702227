import { IBreadcrumbItem } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ModelsCommandBar } from '../../CommandBars';
import FilterBar from '../../FilterBar';
import { Empty, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { ModelsTable } from '../../Tables';
import ContentContainer from '../ContentContainer';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import { useAppSelector } from '../../../utils/hooks';

const ModelsContent: React.FC = () => {
  const { t } = useTranslation();
  const { models, modelRegions, workspaces, projects } = useAppSelector((state) => state);
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t('pages.models.pageName'),
      key: 'model-details',
    },
  ];

  // Models are dependent on GET /regions to complete to access regionNames.
  const isLoading = models.isLoading || modelRegions.isLoading || projects.isLoading;
  const hasModels = !isEmpty(models.data);
  const hasModelRegions = !isEmpty(modelRegions.data);
  const hasWorkspaces = !isEmpty(workspaces.data);
  const hasCurrentWorkspace = !isEmpty(workspaces.currentWorkspace);

  let modelsContent = null;
  if (isLoading) {
    modelsContent = (
      <Loading
        loadingText={t('pages.models.contentSection.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!isLoading && hasModels && hasModelRegions && hasWorkspaces && hasCurrentWorkspace) {
    modelsContent = (
      <Fragment>
        <ModelsTable />
      </Fragment>
    );
  } else if (!isLoading && !hasModels && hasCurrentWorkspace) {
    modelsContent = <Empty type={EmptyTypes.Models} />;
  }

  return (
    <ContentContainer>
      <PageHeading>{t('pages.models.pageName')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      <ModelsCommandBar />
      <FilterBar kind="models" />
      {modelsContent}
    </ContentContainer>
  );
};

export default ModelsContent;
