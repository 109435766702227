import {
  DatePicker,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Label,
  PrimaryButton,
  SearchBox,
  Stack,
  useTheme,
} from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import getUploadHistory from '../../../redux/uploadHistory/uploadHistoryActions';
import { useSharedFilterFormStyles } from '../Shared';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import filterUploadHistoryQuery from '../../../utils/filterUploadHistoryQuery';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const FilterUploadHistoryForm: React.FC = () => {
  const { t } = useTranslation();
  const classes = useSharedFilterFormStyles();
  const theme = useTheme();
  const { supportedLanguages, filters } = useAppSelector((state) => state);
  const [searchTerm, setSearchTerm] = useState(filters.uploadHistory.name);
  const appDispatch = useAppDispatch();
  const { workspaceId } = useParams<IRoutePathParams>();
  const statusOptions: IDropdownOption[] = [
    {
      key: 254,
      text: 'Failed',
    },
    {
      key: 255,
      text: 'Succeeded',
    },
  ];
  // Language dropdown population values
  const languageOptions: IDropdownOption[] = [];
  Object.values(supportedLanguages.data ? supportedLanguages.data : {}).forEach((languageData) => {
    languageOptions.push({
      key: languageData.languageCode,
      text: languageData.displayName,
    });

    languageOptions.sort((a, b) => a.text.localeCompare(b.text));
  });

  const [selectedStatusKey, setSelectedStatusKey] = useState<number | string | null>(filters.uploadHistory.status);
  const [selectedLanguageKey, setSelectedLanguageKey] = useState<number | string | null>(
    filters.uploadHistory.language
  );
  const [afterDate, setAfterDate] = useState<Date | undefined>(filters.uploadHistory.after);
  const [beforeDate, setBeforeDate] = useState<Date | undefined>(filters.uploadHistory.before);
  const bodyStackTokens: IStackTokens = { childrenGap: theme.spacing.l2 };
  const actionButtonStackTokens: IStackTokens = { childrenGap: theme.spacing.s1 };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    const filter = filterUploadHistoryQuery({
      searchTerm,
      selectedStatusKey,
      selectedLanguageKey,
      afterDate,
      beforeDate,
    });

    if (filter.length > 0) {
      batch(() => {
        appDispatch(
          getUploadHistory({
            workspaceId,
            filter,
            currentPage: 1,
          })
        );
        appDispatch({
          type: 'SET_UPLOAD_HISTORY_FILTER_NAME',
          payload: {
            name: searchTerm,
            nameLabel: t('components.forms.filterUploadHistory.nameInput.label'),
          },
        });
        appDispatch({
          type: 'SET_UPLOAD_HISTORY_FILTER_STATUS',
          payload: {
            status: selectedStatusKey,
            statusLabel: t('components.forms.filterUploadHistory.statusDropdown.label'),
          },
        });
        appDispatch({
          type: 'SET_UPLOAD_HISTORY_FILTER_LANGUAGE',
          payload: {
            language: selectedLanguageKey,
            languageLabel: t('components.forms.filterUploadHistory.languageDropdown.label'),
          },
        });
        appDispatch({
          type: 'SET_UPLOAD_HISTORY_FILTER_AFTER',
          payload: {
            after: afterDate,
            afterLabel: t('components.forms.filterUploadHistory.afterDate.label'),
          },
        });
        appDispatch({
          type: 'SET_UPLOAD_HISTORY_FILTER_BEFORE',
          payload: {
            before: beforeDate,
            beforeLabel: t('components.forms.filterUploadHistory.beforeDate.label'),
          },
        });
      });
    } else {
      batch(() => {
        appDispatch(
          getUploadHistory({
            workspaceId,
            currentPage: 1,
          })
        );
      });
    }
  };

  const handleClearAll = (): void => {
    setSearchTerm('');
    setSelectedStatusKey(null);
    setSelectedLanguageKey(null);
    setAfterDate(undefined);
    setBeforeDate(undefined);
  };

  const onSearchTermChange = (event?: React.FormEvent<HTMLInputElement>): void => {
    const term = (event?.target as HTMLInputElement)?.value || '';

    setSearchTerm(term);
  };

  const onStatusChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedStatusKey(item.key);
    }
  };

  const onLanguageChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedLanguageKey(item.key);
    }
  };

  const hasFilterOptions =
    !isEmpty(searchTerm) ||
    selectedStatusKey !== null ||
    selectedLanguageKey !== null ||
    afterDate !== undefined ||
    beforeDate !== undefined;

  return (
    <Stack as="form" className={classes.root} onSubmit={handleSubmit} verticalAlign="space-between">
      <Stack className={classes.body} tokens={bodyStackTokens}>
        <Stack.Item>
          <Label htmlFor="upload-history-search" styles={{ root: { marginBottom: 4 } }}>
            {t('components.forms.filterUploadHistory.nameInput.label')}
          </Label>
          <SearchBox
            id="upload-history-search"
            name="upload-history-search"
            placeholder={t('components.forms.filterCommon.searchPlaceholder')}
            value={searchTerm}
            onChange={(e): void => onSearchTermChange(e)}
            onClear={(): void => setSearchTerm('')}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            id="status"
            label={t('components.forms.filterUploadHistory.statusDropdown.label')}
            placeholder={t('components.forms.filterUploadHistory.statusDropdown.placeholder')}
            options={statusOptions}
            onChange={onStatusChange}
            disabled={!statusOptions}
            className={classes.dropdown}
            selectedKey={selectedStatusKey}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            id="language"
            label={t('components.forms.filterUploadHistory.languageDropdown.label')}
            placeholder={t('components.forms.filterUploadHistory.languageDropdown.placeholder')}
            options={languageOptions}
            onChange={onLanguageChange}
            disabled={!languageOptions}
            className={classes.dropdown}
            selectedKey={selectedLanguageKey}
          />
        </Stack.Item>
        <Stack.Item>
          <DatePicker
            label={t('components.forms.filterUploadHistory.afterDate.label')}
            onSelectDate={(date): void => setAfterDate(date || undefined)}
            value={afterDate}
          />
        </Stack.Item>
        <Stack.Item>
          <DatePicker
            label={t('components.forms.filterUploadHistory.beforeDate.label')}
            onSelectDate={(date): void => setBeforeDate(date || undefined)}
            value={beforeDate}
          />
        </Stack.Item>
      </Stack>
      <Stack as="footer" horizontal tokens={actionButtonStackTokens}>
        <Stack.Item>
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clearAll')}
            onClick={(): void => handleClearAll()}
            disabled={!hasFilterOptions}
          />
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton text={t('components.forms.filterCommon.actionButtons.apply')} type="submit" />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default FilterUploadHistoryForm;
