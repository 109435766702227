import { makeStyles } from '@fluentui/react';

const useSharedUploadFormStyles = makeStyles((theme) => ({
  root: {
    cursor: 'default',
  },
  form: {
    marginBottom: theme.spacing.m,
  },
}));

export default useSharedUploadFormStyles;
