import { makeStyles } from '@fluentui/react';

const useWorkspaceSettingsStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    padding: `${theme.spacing.m} ${theme.spacing.s1} 0`,
    marginBottom: '3rem',
    a: {
      color: theme.semanticColors.link,
    },
  },
  sectionTitle: {
    display: 'inline-block',
    margin: `0 0 ${theme.spacing.s1}`,
    fontSize: 16,
    fontWeight: 600,
  },
  sectionSubHeading: {
    fontWeight: 600,
  },
  titleNoSubtitle: {
    margin: `0 0 ${theme.spacing.m}`,
  },
  sharingList: {
    maxHeight: 400,
    overflowY: 'auto',
    borderTop: `1px solid ${theme.palette.neutralLight}`,
    borderBottom: `1px solid ${theme.palette.neutralLight}`,
    selectors: {
      li: {
        padding: theme.spacing.m,
      },
      'li:nth-child(even)': {
        background: theme.palette.neutralLighterAlt,
      },
    },
  },
  link: {
    display: 'inline-block',
    textDecoration: 'underline',
    color: theme.palette.blue,
    margin: `${theme.spacing.m} 0`,
  },
}));

export default useWorkspaceSettingsStyles;
