import merge from 'lodash/merge';

import { FileDispatchTypes, IFileItem, SET_FILES } from './files.types';

export interface IFilesState {
  [key: number]: IFileItem;
}

const filesReducer = (state: IFilesState = {}, action: FileDispatchTypes): IFilesState => {
  switch (action.type) {
    case SET_FILES: {
      return merge(state, action.payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default filesReducer;
