import { FontWeights, makeStyles } from '@fluentui/react';

const useDocumentSetLabelStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing.l2,
  },
  title: {
    margin: `0 0 ${theme.spacing.s1}`,
    fontWeight: FontWeights.semibold,
  },
  text: {
    maxWidth: 400,
    marginTop: 0,
    marginRight: theme.spacing.l1,
  },
  link: {
    position: 'relative',
    marginLeft: theme.spacing.s2,
    textDecoration: 'underline',
  },
  image: {
    width: 'auto',
    maxHeight: 60,
  },
}));

export default useDocumentSetLabelStyles;
