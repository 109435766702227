const FormValidationLengths = {
  MAX_PROJECT_NAME_LENGTH: 256,
  MAX_PROJECT_DESCRIPTION_LENGTH: 500,
  MAX_PROJECT_CATEGORY_DESCRIPTION_LENGTH: 75,
  MAX_PROJECT_LABEL_LENGTH: 30,
  MAX_DOCUMENT_NAME_LENGTH: 255,
  MAX_DOCUMENT_SET_NAME_LENGTH: 100,
  MAX_MODEL_NAME_LENGTH: 100,
  MAX_WORKSPACE_NAME_LENGTH: 100,
};

export default FormValidationLengths;
