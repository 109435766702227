import { makeStyles } from '@fluentui/react';

const useShareWorkspaceStyles = makeStyles((theme) => ({
  stack: {
    width: '100%',
  },
  textInput: {
    width: 300,
  },
  list: {
    width: 100,
  },
  dropdown: {
    width: 135,
    marginLeft: 5,
  },
  input: {
    display: 'flex',
  },
  itemName: {
    fontSize: 16,
    textOverflow: 'ellipsis',
    display: 'flex',
  },
  cancelIcon: {
    color: theme.palette.neutralDark,
  },
  loadingIcon: {
    marginLeft: 10,
  },
  loadingPersona: {
    margin: 15,
  },
  restriction: {
    maxWidth: 450,
    marginBottom: 5,
  },
}));

export default useShareWorkspaceStyles;
