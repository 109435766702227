export const SET_ROLES = 'SET_ROLES';

export interface IRoleItem {
  id: string;
  roleName: string;
}

export interface ISetRoles {
  type: typeof SET_ROLES;
  payload: {
    [key: string]: IRoleItem;
  };
}

export type RolesDispatchTypes = ISetRoles;
