import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import { TestModelContent } from '../components/PageContents';
import { getModels } from '../redux/models/modelsActions';
import { getProjects } from '../redux/projects/projectsActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../utils/hooks';

const TestModel: React.FC = () => {
  useTrackMetric(reactPlugin, 'TestModelPage');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, projectId } = useParams<IRoutePathParams>();
  const { workspaces, projects, error } = useAppSelector((state) => state);

  // Get supporting data, Workspaces, and Projects
  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasProjects = !isEmpty(projects.ids);
    const isLoading = workspaces.isLoading || projects.isLoading;

    if (!isLoading) {
      if (!hasWorkspaces && !hasProjects) {
        batch(() => {
          // TODO: update to fetch specific workspace when action is available
          dispatch(getWorkspaces());
          dispatch(getProjects(workspaceId));
        });
      } else if (!hasWorkspaces) {
        dispatch(getWorkspaces());
      } else if (!hasProjects) {
        dispatch(getProjects(workspaceId));
      }
    }
  }, [dispatch, workspaceId, workspaces.isLoading, workspaces.ids, projectId, projects.isLoading, projects.ids]);

  // Fetch models on load
  useEffect(() => {
    dispatch(getModels(projectId));
  }, [dispatch, projectId]);

  let content;
  if (!isEmpty(error.message) && error.category === ErrorCategories.Models) {
    content = <Error />;
  } else {
    content = <TestModelContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('pages.testModel.pageName')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default TestModel;
