import { Theme, makeStyles } from '@fluentui/react';

import components from '../../utils/constants/components';

const useAppSidebarStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: components.AppSidebar.expandedWidth,
    width: components.AppSidebar.expandedWidth,
    height: '100%',
    color: theme.palette.white,
    background: theme.palette.white,
    borderRight: `1px solid ${theme.palette.neutralLight}`,
    transition: 'width .5s ease-in-out',
    selectors: {
      '&.collapsed': {
        width: components.AppSidebar.collapsedWidth,
        minWidth: components.AppSidebar.collapsedWidth,
      },
    },
  },
  sidebarToggleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 44,
    borderBottom: `1px solid ${theme.palette.neutralLight}`,
  },
  sidebarLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.themePrimary,
    textDecoration: 'none',
    transition: 'opacity .5s ease-in-out',
    selectors: {
      '&:hover': {
        background: theme.palette.neutralLighter,
      },
      '&.--disabled': {
        pointerEvents: 'none',
        opacity: '.5',
      },
      '&.--active': {
        background: theme.palette.neutralLighter,
      },
    },
  },
  sidebarLinkLoader: {
    margin: '8px 0',
    padding: '0 8px',
  },
  collapsedSidebarLink: {
    justifyContent: 'center',
    width: '100%',
    height: 48,
    padding: 0,
  },
  collapsedSidebarProjectLink: {
    background: theme.palette.neutralLighterAlt,
  },
  expandedSidebarLink: {
    height: 44,
    padding: '0 20px 0 4px',
    color: theme.palette.neutralDark,
  },
  expandedSidebarLinkIcon: {
    flexShrink: 0,
    height: 16,
    margin: '0 4px',
    color: theme.palette.themePrimary,
    fontSize: 16,
    lineHeight: 16,
    textAlign: 'center',
  },
  expandedSidebarLinkText: {
    display: 'block',
    margin: '0 4px',
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nestedSidebarList: {
    selectors: {
      'li:first-child': {
        paddingLeft: 32,
      },
      'li a, li div.--disabled': {
        paddingLeft: 26,
      },
    },
  },
  nestedSidebarListTitle: {
    margin: '5px 0 10px',
    color: theme.palette.neutralDark,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  feedbackLink: {
    marginBottom: 5,
  },
}));

export default useAppSidebarStyles;
