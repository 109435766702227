import { useMsal } from '@azure/msal-react';
import { DefaultButton, Link, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

// import TranslationToggle from '../TranslationToggle';
import msftImage from '../../assets/images/features/ms-logo.svg';
import useNavBarStyles from './NavBar.styles';

import { requestScopes } from '../../utils/authentication';

const NavBar: React.FC = () => {
  const classes = useNavBarStyles();
  const { t } = useTranslation();
  const { instance } = useMsal();
  return (
    <Stack as="header" horizontal horizontalAlign="space-between" verticalAlign="center" className={classes.root}>
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <Link className={classes.image} href="https://www.microsoft.com/" target="_blank" rel="noreferrer noopener">
            <img src={msftImage} alt="Microsoft logo" />
          </Link>
          <Link
            className={classes.link}
            href="https://www.microsoft.com/cognitive-services"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('pages.signedOut.navBarSection.cognitiveServices')}
          </Link>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <DefaultButton
            className={classes.signIn}
            onClick={(): Promise<void> => instance.loginRedirect(requestScopes)}
          >
            {t('pages.signedOut.navBarSection.signIn')}
          </DefaultButton>
          {/* <TranslationToggle /> */}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default NavBar;
