import { TextField } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { updateKeySubscription } from '../../../redux/subscription/subscriptionActions';
import FormActions from '../Shared/FormActions';
import useAddSubscriptionFormStyles from './UpdateSubscriptionKeyForm.styles';
import { IUpdateSubscriptionKeyFormProps, IUpdateSubscriptionKeyFormValues } from './UpdateSubscriptionKeyForm.types';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch } from '../../../utils/hooks/appRedux.helpers';

const UpdateSubscriptionKeyForm: React.FC<IUpdateSubscriptionKeyFormProps> = ({
  handleCancelAndClose,
  isChangingKey = false,
}) => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const classes = useAddSubscriptionFormStyles();
  const { workspaceId } = useParams<IRoutePathParams>();
  const [formValues, setFormValues] = useState(
    (): IUpdateSubscriptionKeyFormValues => {
      const initialState = {
        subscriptionKey: '',
      };
      return initialState;
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    subscriptionKey: undefined,
  });

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newKey?: string) => {
      const inputName = (event.target as HTMLInputElement).name;

      setFormValues({ ...formValues, [inputName]: newKey || '' });
      if (isEmpty(newKey)) {
        setErrors({ ...errors, [inputName]: t('components.forms.generalErrors.required') });
      } else {
        setErrors({ ...errors, [inputName]: undefined });
      }
    },
    [formValues, errors, t]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    if (isEmpty(formValues.subscriptionKey)) {
      setErrors({ ...errors, subscriptionKey: t('components.forms.generalErrors.required') });
      appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-update-key/inline-errors' });
    } else {
      handleCancelAndClose();
      if (isChangingKey) {
        appDispatch(updateKeySubscription(workspaceId, formValues.subscriptionKey, true));
      } else {
        appDispatch(updateKeySubscription(workspaceId, formValues.subscriptionKey));
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.subText}>{t('components.forms.updateSubscriptionKey.subText')} </div>
      <TextField
        label={t('components.forms.updateSubscriptionKey.subscriptionKey')}
        name="subscriptionKey"
        onChange={handleChange}
        errorMessage={errors?.subscriptionKey}
        value={formValues.subscriptionKey}
      />
      <FormActions
        primaryButtonText={t('components.popups.common.save')}
        isDisabled={!isEmpty(Object.values(errors).filter((value) => value))}
        handleCancelAndClose={handleCancelAndClose}
        onCancelTelemetryMessage="workspace-update-key/cancel"
      />
    </form>
  );
};

export default UpdateSubscriptionKeyForm;
