import { IContextualMenuProps, IconButton, ICalloutProps, IContextualMenuItem, IStyle } from "@fluentui/react";
import { INTL } from "../../../util/intlUtil";
import { HeaderLinksMenuListItemsName } from "../../../clientResources";
import { urlLinks } from "../../../util/urlLinks";
import { useConst } from "@fluentui/react-hooks";
import { defaultTheme } from "../../../themes";
import { FontSizes } from '@fluentui/theme';

export enum LinksKind {
    SpeechStudio = "SpeechStudio",
    LanguageStudio = "LanguageStudio",
    CustomTranslator = "CustomTranslator",
    VisionStudio = "VisionStudio",
    DocumentIntelligence = "DocumentIntelligence",
    ContentSafety = "ContentSafety",
    AzureAIStudio = "AzureAIStudio",
    AzureMachineLearning = "AzureMachineLearning",
}

const itemsWithAboveLine: LinksKind[] = [
    LinksKind.AzureAIStudio,
    LinksKind.AzureMachineLearning,
];

export interface ILinkMenuProps {
    items: LinksKind[]; // If the props provide the list
}

export const LinksMenu = (props: ILinkMenuProps) => {
    const contentSafetyLaunchDate = "5/23/2023";
    let pstDate = new Date().toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
    })
    const isContentSafetyLaunched = (contentSafetyLaunchDate < pstDate.toLocaleString());
    const linksMenuItems: IContextualMenuItem[] = [
        {
            key: LinksKind.SpeechStudio,
            text: INTL.formatMessage(HeaderLinksMenuListItemsName.SpeechStudio),
            href: urlLinks.SpeechStudio(),
            target: '_blank',
            iconProps: {
                iconName: "speechLogo-svg",
            }
        },
        {
            key: LinksKind.LanguageStudio,
            text: INTL.formatMessage(HeaderLinksMenuListItemsName.LanguageStudio),
            href: urlLinks.LanguageStudio(),
            target: '_blank',
            iconProps: {
                iconName: "languageLogo-svg",
            }
        },
        {
            key: LinksKind.CustomTranslator,
            text: INTL.formatMessage(HeaderLinksMenuListItemsName.CustomTranslator),
            href: urlLinks.CustomTranslator(),
            target: '_blank',
            iconProps: {
                iconName: "translatorLogo-svg",
            }
        },
        {
            key: LinksKind.VisionStudio,
            name: INTL.formatMessage(HeaderLinksMenuListItemsName.VisionStudio),
            href: urlLinks.VisionStudio(),
            target: '_blank',
            iconProps: {
                iconName: "visionLogo-svg",
            }
        },
        {
            key: LinksKind.DocumentIntelligence,
            name: INTL.formatMessage(HeaderLinksMenuListItemsName.DocumentIntelligence),
            href: urlLinks.DocumentIntelligence(),
            target: '_blank',
            iconProps: {
                iconName: "formRecognizerLogo-svg",
            }
        },
        {
            key: LinksKind.ContentSafety,
            name: isContentSafetyLaunched ? INTL.formatMessage(HeaderLinksMenuListItemsName.ContentSafety) : INTL.formatMessage(HeaderLinksMenuListItemsName.ContentSafety) + INTL.formatMessage(HeaderLinksMenuListItemsName.ComingSoon),
            href: isContentSafetyLaunched ? urlLinks.ContentSafety() : "",
            target: '_blank',
            iconProps: {
                iconName: "contentSafetyLogo-svg",
            }
        },
        {
            key: LinksKind.AzureAIStudio,
            name: INTL.formatMessage(HeaderLinksMenuListItemsName.AzureAIStudio),
            href: urlLinks.AzureAIStudio(),
            target: '_blank',
            iconProps: {
                iconName: "openAILogo-svg",
            }
        },
        {
            key: LinksKind.AzureMachineLearning,
            name: INTL.formatMessage(HeaderLinksMenuListItemsName.AzureMachineLearning),
            href: urlLinks.AzureMachineLearning(),
            target: '_blank',
            iconProps: {
                iconName: "machineLearningLogo-svg",
            }
        },
    ];

    const items = linksMenuItems.filter(item => props.items.includes(item.key as LinksKind));

    const rootStyles: IStyle = {
        marginTop: "4px",
        backgroundColor: defaultTheme.header.palette.themePrimary,
        "ul": {
            backgroundColor: defaultTheme.header.palette.themePrimary,
        },
        "li": {
            padding: "2px",
            color: defaultTheme.body.palette.neutralPrimary,
            backgroundColor: defaultTheme.header.palette.themePrimary,
        },
        "a, button": {
            paddingRight: "20px",
            boxSizing: "border-box",
            border: `1px solid transparent`,
            color: defaultTheme.body.palette.neutralPrimary,
            FontSizes: 14
        },
        "a:hover, button:hover": {
            textDecoration: "underline",
            color: defaultTheme.header.palette.neutralLighter,
            backgroundColor: defaultTheme.header.palette.themePrimary,
            borderColor: `${defaultTheme.header.palette.neutralLighter}`,
        }
    };

    items.forEach((item, index) => {
        if (itemsWithAboveLine.includes(item.key as LinksKind)) rootStyles[`li:nth-child(${index + 1})`] = {
            marginTop: "2px",
            paddingTop: "4px",
            borderTop: `1px solid ${defaultTheme.body.palette.neutralQuaternary}`,
        }
    })

    const menuProps: IContextualMenuProps = useConst({
        alignTargetEdge: false,
        shouldFocusOnMount: true,
        calloutProps: {
            styles: {
                root: rootStyles,
            },
            preventDismissOnEvent:ev=>{
                // to fix the issue when panel isblocking,linkmenu should be persist
                if(ev.type === "focus"){
                    return true;
                }
                // this is default value
                return false;
            }
        } as ICalloutProps,
        items: items,
    });

    return (
        <IconButton
            aria-label={INTL.formatMessage(HeaderLinksMenuListItemsName.LinkToOtherStudios)}
            menuProps={menuProps}
            iconProps={{ iconName: "WaffleOffice365" }}
            style={{ paddingTop: 4, right: 4, color: "#FFFFFF", backgroundColor: "#0078D4" }}
            onRenderMenuIcon={() => null}
        />
    );

};

