import { FontWeights, makeStyles } from '@fluentui/react';

const useCreateProjectFormStyles = makeStyles((theme) => ({
  actionButtons: {
    padding: `${theme.spacing.m} 0`,
    selectors: {
      '.ms-Button': {
        lineHeight: 'normal',
      },
      '& > :first-child': {
        marginRight: theme.spacing.s1,
      },
    },
  },
  formField: {
    marginBottom: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  subtitle: {
    margin: `0 0 ${theme.spacing.l2}`,
    fontSize: '14px',
  },
  labelBetweenLanguages: {
    display: 'block',
    textAlign: 'center',
    marginTop: 38,
  },
  image: {
    display: 'block',
    margin: 'auto',
    width: '120px',
  },
  question: {
    fontWeight: FontWeights.semibold,
  },
  noDisplay: {
    display: 'none',
  },
  advancedOptionsButton: {
    border: 'none',
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
    cursor: 'pointer',
  },
}));

export default useCreateProjectFormStyles;
