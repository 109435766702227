export enum Spacing {
  xSmall = 4,
  small = 8,
  medium = 12,
  large = 16,
  xLarge = 24,
  xxLarge = 32,
}

export enum SpacingType {
  padding = 'padding',
  margin = 'margin',
}
