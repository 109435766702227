import React from 'react';

import { CreateWorkspaceWizardSlide } from '../../../redux/createWorkspaceWizard/createWorkspaceWizard.types';
import AddKey from './AddKey';
import { ICreateWorkspaceWizardProps } from './CreateWorkspaceWizard.types';
import SetWorkspaceName from './SetWorkspaceName';
import CreateWorkspaceWizardSuccess from './Success';

import { useAppSelector } from '../../../utils/hooks';

const CreateWorkspaceWizard: React.FC<ICreateWorkspaceWizardProps> = ({ handleCancelAndClose }) => {
  const { slide } = useAppSelector((state) => state.createWorkspaceWizard);

  let wizardContent = null;

  if (slide === CreateWorkspaceWizardSlide.AddName) {
    wizardContent = <SetWorkspaceName handleCancelAndClose={handleCancelAndClose} />;
  } else if (slide === CreateWorkspaceWizardSlide.AddKey) {
    wizardContent = <AddKey handleCancelAndClose={handleCancelAndClose} />;
  } else if (slide === CreateWorkspaceWizardSlide.Success) {
    wizardContent = <CreateWorkspaceWizardSuccess handleCancelAndClose={handleCancelAndClose} />;
  }

  return wizardContent;
};

export default CreateWorkspaceWizard;
