/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  DetailsListLayoutMode,
  IColumn,
  Overlay,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import ErrorFallback from '../../ErrorFallback';
import { Pagination } from '../../Pagination';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import { NameGroupCell, TextCell } from '../SharedTableCells';
import usePaginatedDetailsListStyles from './DocumentsTable.styles';

import { useAppSelector } from '../../../utils/hooks';
import { capitalize } from '../../../utils/textTransform';

const DocumentsTable: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { documents, languages, users, files } = useAppSelector((state) => state);
  const currentRoute = useRouteMatch('/workspace/:worksapceId/documents');
  const classes = usePaginatedDetailsListStyles();

  const displayPagination = documents.pageIndex !== 0 && documents.totalPageCount > 1;

  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItem = selectionState.getSelection();
        dispatch({ type: 'SET_SELECTED_DOCUMENT', payload: selectedItem });
      },
    })
  );

  // Clear selection when user updates a document
  useEffect(() => {
    if (documents.isUpdating) {
      selectionState.selectToKey('', true);
    }
  }, [selectionState, documents.isUpdating]);

  const documentsTableColumns: IColumn[] = [
    {
      key: 'documentName',
      name: t('components.tables.sharedColumns.name'),
      minWidth: 200,
      maxWidth: 300,
      onRender: (documentId: string): React.ReactElement => {
        const { name } = documents.data[documentId];
        // TODO: Implement inline grid actions
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118981
        return <NameGroupCell link={{ href: `${match.url}/${documentId}`, name }} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'createdDate',
      name: t('components.tables.sharedColumns.createdDate'),
      onRender: (documentId: string): React.ReactElement => {
        const { createdDate } = documents.data[documentId];

        // TODO: Localize date/time https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119689
        return <TextCell text={dayjs(createdDate).format('MM/DD/YYYY')} />;
      },
      minWidth: 80,
      maxWidth: 130,
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'type',
      name: t('components.tables.sharedColumns.type'),
      minWidth: 80,
      maxWidth: 160,
      onRender: (documentId: string): React.ReactElement => {
        const { documentType } = documents.data[documentId];
        return <TextCell text={capitalize(documentType)} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'languages',
      name: t('components.tables.documents.columns.languagesColumn'),
      minWidth: 100,
      maxWidth: 180,
      onRender: (documentId: string): React.ReactElement | null => {
        const documentFiles = documents.data[documentId].files;
        const allLanguages: string[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          allLanguages.push(languages[file.language].displayName);
        }

        const commaSeparatedLanguages = allLanguages.join(', ');
        return <TextCell text={commaSeparatedLanguages} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'englishSentences',
      name: t('components.tables.documents.columns.englishSentencesColumn'),
      minWidth: 80,
      maxWidth: 160,
      onRender: (documentId: string): React.ReactElement | null => {
        const documentFiles = documents.data[documentId].files;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          const fileLanguageCode = languages[file.language].languageCode;
          if (fileLanguageCode === 'en' && file.extractedSentenceCount) {
            return <TextCell text={file.extractedSentenceCount.toLocaleString(language)} />;
          }
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'pairSentences',
      name: t('components.tables.documents.columns.pairSentencesColumn'),
      minWidth: 80,
      maxWidth: 160,
      onRender: (documentId: string): React.ReactElement | null => {
        const documentFiles = documents.data[documentId].files;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          const fileLanguageCode = languages[file.language].languageCode;
          if (fileLanguageCode !== 'en' && file.extractedSentenceCount) {
            return <TextCell text={file.extractedSentenceCount.toLocaleString(language)} />;
          }
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'createdBy[userName]',
      name: t('components.tables.sharedColumns.createdBy'),
      onRender: (documentId: string): React.ReactElement => {
        const { createdBy } = documents.data[documentId];
        const userName = users[createdBy] ? users[createdBy].userName : 'N/A';

        return <TextCell text={userName} />;
      },
      minWidth: 110,
      maxWidth: 250,
      isSorted: false,
      columnActionsMode: 0,
    },
  ];

  const documentsTable = (
    <div className="table-container" data-test-id="documents-table">
      {documents.isUpdating && <Overlay className="cover-content" />}
      <DetailsListKeyboardAccessible
        enableShimmer={documents.isLoading}
        items={documents.ids}
        columns={documentsTableColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.multiple}
        selection={selectionState}
        ariaLabel={t('components.tables.manageDocuments.tableAria')}
        ariaLabelForSelectionColumn={t('components.tables.manageDocuments.columns.columnSelectAria')}
        checkButtonAriaLabel={t('components.tables.manageDocuments.checkButtonAria')}
      />
      {isEmpty(documents.ids) && !documents.isLoading && (
        <Stack horizontalAlign="center">
          <Text>{t('components.forms.filterCommon.noResults')}</Text>
        </Stack>
      )}
    </div>
  );

  const documentsContent = displayPagination ? (
    <Stack>
      <StackItem className={classes.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{documentsTable}</ScrollablePane>
      </StackItem>
      <StackItem>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Pagination
            totalPageCount={documents.totalPageCount}
            currentPage={documents.pageIndex}
            currentPath={currentRoute ? currentRoute.url : ''}
          />
        </Sticky>
      </StackItem>
    </Stack>
  ) : (
    <div>{documentsTable}</div>
  );

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{documentsContent}</ErrorBoundary>;
};

export default DocumentsTable;
