import merge from 'lodash/merge';

import {
  GET_MODEL_REGIONS,
  GET_MODEL_REGIONS_FAILED,
  GET_MODEL_REGIONS_SUCCESSFUL,
  IModelRegionItem,
  ModelRegionsDispatchTypes,
  SET_MODEL_REGIONS,
} from './modelRegions.types';

interface IModelRegionsState {
  isLoading: boolean;
  data: {
    [key: string]: IModelRegionItem;
  };
}

const initialState: IModelRegionsState = {
  isLoading: false,
  data: {},
};

const modelRegionsReducer = (
  state: IModelRegionsState = initialState,
  action: ModelRegionsDispatchTypes
): IModelRegionsState => {
  switch (action.type) {
    case GET_MODEL_REGIONS: {
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
      };
    }
    case GET_MODEL_REGIONS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }
    case GET_MODEL_REGIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SET_MODEL_REGIONS: {
      return merge(state, action.payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default modelRegionsReducer;
