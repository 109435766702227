export const GET_MODELS_VERSION = 'GET_MODELS_VERSION';
export const GET_MODELS_VERSION_SUCCESSFUL = 'GET_MODELS_VERSION_SUCCESSFUL';
export const GET_MODELS_VERSION_FAILED = 'GET_MODELS_VERSION_FAILED';
export const FETCH_MODELS_VERSION = 'FETCH_MODELS_VERSION';
export const MODELS_VERSION_SELECT = 'MODELS_VERSION_SELECT';

export interface IModelsVersionState {
  isLoading: boolean;
  failed: boolean;
  models?: any[];
  selectedModels?: number[] | null;
  fetch: boolean;
}

export interface IGetModelsVersion {
  type: typeof GET_MODELS_VERSION;
  payload: {
    isLoading: boolean;
  };
}

export interface IGetModelsVersionSuccessful {
  type: typeof GET_MODELS_VERSION_SUCCESSFUL;
  payload: IModelsVersionState;
}

export interface IGetModelsVersionFailed {
  type: typeof GET_MODELS_VERSION_FAILED;
}

export interface IFetchModelsVersion {
  type: typeof FETCH_MODELS_VERSION;
  payload: boolean;
}

export interface IModelsVersionSelect {
  type: typeof MODELS_VERSION_SELECT;
  payload: {
    modelIds?: number[] | null;
  };
}

export type GetModelsVersionResponse = IModelsVersionState;

export type ModelsVersionDispatchTypes =
  | IGetModelsVersion
  | IGetModelsVersionSuccessful
  | IGetModelsVersionFailed
  | IFetchModelsVersion
  | IModelsVersionSelect;
