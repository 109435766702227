import {
  ChoiceGroup,
  DefaultButton,
  IBreadcrumbItem,
  IChoiceGroupOption,
  IconButton,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { getDocuments } from '../../../redux/documents/documentsActions';
import { trainDraftModel, updateDraftModel } from '../../../redux/models/modelsActions';
import updatePaginatedDocumentsSelection from '../../../redux/paginatedDocumentsSelection/paginatedDocumentsSelectionActions';
import { AppDialog } from '../../AppPopUps';
import appSidebarContext from '../../AppSidebar/AppSidebar.context';
import { ModelDocumentsCommandBar } from '../../CommandBars';
import { Empty, Loading } from '../../GenericStates';
import ModelProgressIndicator from '../../ModelProgressIndicator';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { Pagination } from '../../Pagination';
import { ProjectDocumentsTable } from '../../Tables';
import { SelectionAction } from '../../Tables/ProjectDocumentsTable/ProjectDocumentsTable.types';
import ModelDetails from '../ModelContent/ModelDetails';
import useDraftModelStyles from '../TrainModelContent/TrainModelContent.styles';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import { ModelsPopUpTypes } from '../../../utils/constants/popUps.types';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import REQUIRED_TRAINING_SENTENCES from '../../../utils/constants/trainingSentences';
import { TrainingTypes } from '../../../utils/constants/trainingTypes';
import { AppDialogContentMap, useAppDialog, useAppDispatch, useAppSelector } from '../../../utils/hooks';

const DraftModelContent: React.FC = () => {
  const { t } = useTranslation();
  const classes = useDraftModelStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentRoute = useRouteMatch(`/workspaces/:workspaceId/projects/:projectId/manage-documents`);
  const {
    projects,
    documents,
    subscription,
    languages,
    models,
    trainModel,
    files,
    paginatedDocumentsSelection,
  } = useAppSelector((state) => state);
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { appDialog, setAppDialog } = useAppDialog();
  const fullTrainingId = useId('full-training-tooltip');
  const dictionaryId = useId('dictionary-only-tooltip');
  const isTrainingFree = projects.data[projectId]?.hasFreeTraining || false;

  const currentModelData = models.data[modelId];
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t('pages.models.pageName'),
      key: 'model-details',
      onClick: (): void => history.goBack(),
    },
    {
      text: currentModelData.name,
      key: 'model-name',
    },
  ];

  const [modelNameError, setModelNameError] = useState<string | undefined>(undefined);
  const [isEnoughTrainingData, setIsEnoughTrainingData] = useState<boolean>(true);
  const [wasTrainButtonPressed, setWasTrainButtonPressed] = useState<boolean>(false);

  const isLoading = documents.isLoading || subscription.isLoading || models.isLoading || projects.isLoading;
  const noDocuments = !documents.isLoading && isEmpty(documents.ids);
  const noChangesToSave =
    isEmpty(trainModel.addDocuments) &&
    isEmpty(trainModel.removeDocuments) &&
    trainModel.modelName === models.data[modelId].name;

  // Set initial value for modelName and for selected documents
  useEffect(() => {
    dispatch({ type: 'SET_MODEL_NAME', payload: currentModelData.name });
    if (!isEmpty(documents.data)) {
      currentModelData.documents.forEach((documentId) => {
        if (
          !isEmpty(documents.ids.filter((id) => Number(id) === Number(documentId))) &&
          !Object.keys(paginatedDocumentsSelection.data).includes(String(documentId))
        ) {
          dispatch(updatePaginatedDocumentsSelection(documents.data[documentId], files, SelectionAction.Adding));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents.data]);

  useEffect(() => {
    if (wasTrainButtonPressed) {
      if (trainModel.trainingType === TrainingTypes.FullTraining) {
        setIsEnoughTrainingData(paginatedDocumentsSelection.counts.training.sentence > REQUIRED_TRAINING_SENTENCES);
      } else {
        setIsEnoughTrainingData(paginatedDocumentsSelection.counts.dictionary.document > 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedDocumentsSelection.counts, trainModel.trainingType, wasTrainButtonPressed]);

  const renderInfoTooltip = (tooltipId: string, tooltipContent: string): React.ReactElement => {
    return (
      <TooltipHost content={tooltipContent} id={tooltipId}>
        <IconButton
          iconProps={{ iconName: 'Info' }}
          ariaLabel={t('components.commandBars.moreInformation.aria')}
          styles={{ root: { marginLeft: 2, paddingTop: 2 }, icon: { color: theme.palette.neutralSecondary } }}
        />
      </TooltipHost>
    );
  };

  const handleModelNameChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (!newValue || newValue?.length <= FormValidationLengths.MAX_MODEL_NAME_LENGTH) {
        dispatch({ type: 'SET_MODEL_NAME', payload: newValue || '' });
      }
      if (isEmpty(newValue)) {
        setModelNameError(t('components.forms.generalErrors.required'));
      } else {
        setModelNameError(undefined);
      }
    },
    [dispatch, t]
  );

  const onTrainingSelect = useCallback(
    (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
      const currentProject = projects.data[projectId];
      const sourceLanguage = languages[currentProject.languagePair.sourceLanguage];
      const targetLanguage = languages[currentProject.languagePair.targetLanguage];
      let filter = `isAvailable eq 'True' and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}' and isParallel eq true`;
      if (option?.key === TrainingTypes.DictionaryOnly) {
        filter = `isAvailable eq 'True' and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}' and isParallel eq true and documentType eq 'Phrase dictionary,Sentence dictionary'`;
      }
      history.replace({ search: '' });
      batch(() => {
        dispatch({ type: 'CLEAR_ALL_COUNTS' });
        dispatch({ type: 'CLEAR_SELECTED_DOCUMENT' });
        dispatch({ type: 'CLEAR_DOCUMENTS_SELECTION' });
        dispatch(getDocuments({ workspaceId, filter, page: 1, resetPagination: true, prioritizeModel: modelId }));
        dispatch({ type: 'SET_TRAINING_TYPE', payload: option?.key });
        dispatch(updateDraftModel(modelId, trainModel.modelName, [], currentModelData.documents));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, workspaceId, projects, languages]
  );

  const onTrainSubmit = (): void => {
    const isModelName = trainModel.modelName.length > 0;
    let isTraininigData;

    if (trainModel.trainingType === TrainingTypes.FullTraining) {
      isTraininigData = paginatedDocumentsSelection.counts.training.sentence > REQUIRED_TRAINING_SENTENCES;
    } else {
      isTraininigData = paginatedDocumentsSelection.counts.dictionary.document > 0;
    }
    setIsEnoughTrainingData(isTraininigData);

    if (!isModelName) {
      setModelNameError(t('components.forms.generalErrors.required'));
    }
    if (isModelName && isTraininigData) {
      setAppDialog({ contentType: ModelsPopUpTypes.Create, isHidden: false });
    } else {
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-train-draft/inline-errors' });
    }
    setWasTrainButtonPressed(true);
  };

  const onSaveSubmit = (): void => {
    if (trainModel.modelName.length === 0) {
      setModelNameError(t('components.forms.generalErrors.required'));
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-save-draft/inline-errors' });
    } else {
      dispatch(updateDraftModel(modelId, trainModel.modelName, trainModel.addDocuments, trainModel.removeDocuments));
    }
  };

  const trainingOptions: IChoiceGroupOption[] = [
    {
      key: 'full',
      text: t('pages.trainModel.fullTraining'),
      onRenderField: (props, render): React.ReactElement => {
        return (
          <div className={classes.options}>
            {render?.(props)}
            {renderInfoTooltip(fullTrainingId, t('pages.trainModel.fullTrainingTooltip'))}
          </div>
        );
      },
    },
    {
      key: 'dictionary',
      text: t('pages.trainModel.dictionaryOnly'),
      onRenderField: (props, render): React.ReactElement => {
        return (
          <div className={classes.options}>
            {render?.(props)}
            {renderInfoTooltip(dictionaryId, t('pages.trainModel.dictionaryOnlyTooltip'))}
          </div>
        );
      },
    },
  ];

  const displayPagination = documents.pageIndex !== 0 && documents.totalPageCount > 1;

  const trainModelAndPaginationFooter = (
    <Stack horizontal horizontalAlign="space-between" className={classes.footerStack}>
      {displayPagination && (
        <Stack.Item>
          <Pagination
            totalPageCount={documents.totalPageCount}
            currentPage={documents.pageIndex}
            currentPath={currentRoute ? currentRoute.url : ''}
            isDoubleFooter
          />
        </Stack.Item>
      )}
      <Stack.Item className={classes.buttons}>
        <PrimaryButton
          disabled={!isEmpty(modelNameError) || !isEnoughTrainingData}
          onClick={(): void => onTrainSubmit()}
        >
          {t('components.commandBars.trainModel.trainNowButton.text')}
        </PrimaryButton>
        <DefaultButton disabled={noChangesToSave || !isEmpty(modelNameError)} onClick={(): void => onSaveSubmit()}>
          {t('components.popups.common.save')}
        </DefaultButton>
        <DefaultButton onClick={(): void => setAppDialog({ contentType: ModelsPopUpTypes.Cancel, isHidden: false })}>
          {t('components.popups.common.cancel')}
        </DefaultButton>
      </Stack.Item>
    </Stack>
  );
  let tableContent;
  if (isLoading || isEmpty(projects.currentProject)) {
    tableContent = (
      <Loading
        loadingText={t('pages.manageDocuments.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (noDocuments && !documents.areDocumentsFiltered) {
    tableContent = <Empty type={EmptyTypes.Documents} />;
  } else {
    tableContent = (
      <Fragment>
        <ModelDocumentsCommandBar />
        <ProjectDocumentsTable isMultiSelect showInlineCommands={false} hidePagination />
      </Fragment>
    );
  }
  useEffect(() => {
    const currentModelDocuments = currentModelData.documents as number[];
    const updatedModelDocuments = Object.keys(paginatedDocumentsSelection.data);
    const addDocuments = updatedModelDocuments.filter((document) => !currentModelDocuments.includes(Number(document)));
    const removeDocuments = currentModelDocuments.filter(
      (document) => !updatedModelDocuments.includes(String(document))
    );
    batch(() => {
      dispatch({ type: 'UPDATE_ADD_DOCUMENTS', payload: addDocuments });
      dispatch({ type: 'UPDATE_REMOVE_DOCUMENTS', payload: removeDocuments });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedDocumentsSelection.data]);

  const trainModelSteps = (
    <ol className={classes.list}>
      <li>
        {t('pages.trainModel.nameModelStep')}
        <TextField
          // TODO: Update for responsive design
          // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119688/?workitem=120776
          name="modelName"
          styles={{ root: { maxWidth: '480px', width: '100%' } }}
          className={classes.modelName}
          label={t('pages.trainModel.modelName')}
          placeholder={t('pages.trainModel.modelNamePlaceholder')}
          onChange={handleModelNameChange}
          errorMessage={modelNameError}
          value={trainModel.modelName}
        />
      </li>
      <li>
        {t('pages.trainModel.selectTypeStep')}
        <ChoiceGroup defaultSelectedKey="full" options={trainingOptions} required onChange={onTrainingSelect} />
      </li>
      <li>
        {t('pages.trainModel.selectDocumentStep')}
        <ModelProgressIndicator isDocumentSelectionIncomplete={!isEnoughTrainingData} />
        {tableContent}
      </li>
    </ol>
  );

  const appDialogContentMap: AppDialogContentMap = {
    [ModelsPopUpTypes.Create]: {
      contentProps: {
        title: t('components.popups.model.draftModel.train.title'),
        subText: t('components.popups.model.draftModel.train.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.model.draftModel.train.actionButton'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            dispatch(
              trainDraftModel(
                modelId,
                projectId,
                models.data[modelId].name,
                !noChangesToSave,
                trainModel.addDocuments,
                trainModel.removeDocuments
              )
            );
          });
          if (isTrainingFree) {
            dispatch({ type: 'USE_FREE_CREDIT', payload: projectId });
          }

          history.goBack();
        },
      },
    },
    [ModelsPopUpTypes.Cancel]: {
      contentProps: {
        title: t('components.popups.model.draftModel.cancel.title'),
        subText: t('components.popups.model.draftModel.cancel.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.model.draftModel.cancel.actionButton'),
        onClick: (): void => {
          setAppDialog({ ...appDialog, isHidden: true });

          history.goBack();
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const { isAppSidebarCollapsed } = useContext(appSidebarContext);
  const paginationPositionClass = clsx(classes.stickyContainer, { 'collapsed-width': isAppSidebarCollapsed });

  return (
    <Fragment>
      <Stack>
        <Stack.Item className="content-container">
          <PageHeading>{currentModelData.name}</PageHeading>
          <PageBreadcrumb items={breadcrumbItems} />
          {!isLoading && <ModelDetails projectId={projectId} modelId={modelId} />}
          {trainModelSteps}
        </Stack.Item>
        {!noDocuments && <Stack.Item className={paginationPositionClass}>{trainModelAndPaginationFooter}</Stack.Item>}
      </Stack>
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
    </Fragment>
  );
};

export default DraftModelContent;
