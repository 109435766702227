import React from 'react';
import { useTranslation } from 'react-i18next';

import { UploadTypes } from '../../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import SingleDocumentForm from '../SingleDocumentForm';
import { IUploadDocumentsSetProps } from '../UploadDocumentsSet.types';

const ZipForm: React.FC<IUploadDocumentsSetProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();

  const subtitle = {
    sizeAndFormatRestriction: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.form.textLine1'),
    detailInformation: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.form.textLine2'),
  };

  const fileInputButton = {
    label: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.zip.form.label'),
    text: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.common.fileInputButtonText'),
    id: 'zip-file',
    acceptedFormats: '.zip',
  };

  return (
    <SingleDocumentForm
      handleCancelAndClose={handleCancelAndClose}
      subtitle={subtitle}
      fileInputButton={fileInputButton}
      formId={UploadTypes.ZipFiles}
    />
  );
};

export default ZipForm;
