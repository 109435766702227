import { isEmpty } from 'lodash';

import { IModelItem } from '../redux/models/models.types';

interface IModels {
  data: {
    [key: string]: IModelItem;
  };
  ids: string[];
}

const filterModels = (models: IModels, modelStatuses: string[], modelName: string): string[] => {
  const filteredModelIds: string[] = [];
  const lowerCaseModelName = modelName.toLocaleLowerCase();
  if (models.data) {
    Object.keys(models.data).forEach((id) => {
      const { name, modelStatus } = models.data[id];
      if (modelName !== '' && !name.toLocaleLowerCase().includes(lowerCaseModelName)) {
        return;
      }
      if (!isEmpty(modelStatuses) && !modelStatuses.includes(modelStatus)) {
        return;
      }

      filteredModelIds.push(id);
    });
  }
  return filteredModelIds;
};

export default filterModels;
