import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IDeploymentRegionItem } from '../../../redux/deploymentNotifications/deploymentNotifications.types';
import DeploymentNotificationRegionalProgress from '../DeploymentNotificationRegionalProgress/DeploymentNotificationRegionalProgress';
import NotificationBase from '../NotificationBase/NotificationBase';
import useNotificationBaseStyles from '../NotificationBase/NotificationBase.styles';
import { IDeploymentNotificationProps } from './DeploymentNotification.types';

import { DeploymentNotificationStatus, NotificationType } from '../../../utils/constants/notifications';
import PublishOperation from '../../../utils/constants/publishOperation';

const DeploymentNotification: React.FC<IDeploymentNotificationProps> = ({ notification }) => {
  const classes = useNotificationBaseStyles();
  const { t } = useTranslation();

  const { regions, operation, name, swapModelName } = notification;

  let hasFailed = false;
  let hasSucceeded = true;
  const failedStates = [DeploymentNotificationStatus.Cancelled, DeploymentNotificationStatus.Failed];
  regions.forEach((region: IDeploymentRegionItem) => {
    if (failedStates.includes(region.status)) {
      hasFailed = true;
    }
    if (region.status !== DeploymentNotificationStatus.Succeeded) {
      hasSucceeded = false;
    }
  });
  const inProgress = !hasFailed && !hasSucceeded;

  let icon;
  let cssClass = classes.notificationItem;
  if (hasSucceeded) {
    cssClass = clsx(classes.notificationItem, { success: true });
    icon = {
      name: 'Completed',
      class: clsx(classes.icon, { success: true }),
    };
  } else if (hasFailed) {
    cssClass = clsx(classes.notificationItem, { failure: true });
    icon = {
      name: 'ErrorBadge',
      class: clsx(classes.icon, { failure: true }),
    };
  }

  let title = '';
  let subtitle = '';
  if (inProgress) {
    switch (operation) {
      case PublishOperation.Publish:
        title = t('components.notificationPanel.deployment.publishingInProgress', { name });
        subtitle = t('components.notificationPanel.deployment.publishingInProgressSubtitle');
        break;
      case PublishOperation.Swap:
        title = t('components.notificationPanel.deployment.publishingInProgress', { name });
        subtitle = t('components.notificationPanel.deployment.swapInProgressSubtitle', { swapModelName });
        break;
      case PublishOperation.Unpublish:
        title = t('components.notificationPanel.deployment.unpublishInProgress', { name });
        subtitle = t('components.notificationPanel.deployment.unpublishSubtitle');
        break;
      case PublishOperation.Update:
        title = t('components.notificationPanel.deployment.updateInProgress', { name });
        subtitle = t('components.notificationPanel.deployment.updateInProgressSubtitle');
        break;
      default:
        break;
    }
  } else if (hasSucceeded) {
    switch (operation) {
      case PublishOperation.Publish:
        title = t('components.notificationPanel.deployment.publishingSucceeded', { name });
        break;
      case PublishOperation.Swap:
        title = t('components.notificationPanel.deployment.publishingSucceeded', { name });
        subtitle = t('components.notificationPanel.deployment.swapSucceededSubtitle', { swapModelName });
        break;
      case PublishOperation.Unpublish:
        title = t('components.notificationPanel.deployment.unpublishSucceeded', { name });
        break;
      case PublishOperation.Update:
        title = t('components.notificationPanel.deployment.updateSucceeded', { name });
        break;
      default:
        break;
    }
  } else if (hasFailed) {
    subtitle = t('components.notificationPanel.deployment.failureSubtitle');
    switch (operation) {
      case PublishOperation.Publish:
        title = t('components.notificationPanel.deployment.publishingFailure', { name });
        break;
      case PublishOperation.Swap:
        title = t('components.notificationPanel.deployment.publishingFailure', { name });
        subtitle = t('components.notificationPanel.deployment.swapFailureSubtitle', { swapModelName });
        break;
      case PublishOperation.Update:
        title = t('components.notificationPanel.deployment.updateFailure', { name });
        break;
      default:
        break;
    }
  }

  const display = { title, subtitle, class: cssClass, icon };

  return (
    <NotificationBase display={display} id={notification.id} type={NotificationType.Deployment}>
      <DeploymentNotificationRegionalProgress notification={notification} />
    </NotificationBase>
  );
};

export default DeploymentNotification;
