import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useFormActionStyles from './FormActions.styles';
import IFormActionsProps from './FormActions.types';

import { useAppDispatch } from '../../../../utils/hooks';

const FormActions: React.FC<IFormActionsProps> = ({
  isDisabled,
  primaryButtonText,
  handleCancelAndClose,
  onCancelTelemetryMessage,
}) => {
  const classes = useFormActionStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();

  return (
    <Stack horizontal horizontalAlign="end" className={classes.actionButtons}>
      <PrimaryButton type="submit" disabled={isDisabled} text={primaryButtonText} />
      <DefaultButton
        onClick={(): void => {
          handleCancelAndClose();
          appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: onCancelTelemetryMessage });
        }}
        text={t('components.popups.common.cancel')}
      />
    </Stack>
  );
};

export default FormActions;
