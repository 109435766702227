import merge from 'lodash/merge';

import { IDocumentItem } from '../documents/documents.types';
import { CLEAR_MODEL_DOCUMENTS, ModelDocumentsDispatchTypes, SET_MODEL_DOCUMENTS } from './modelDocuments.types';

export interface IModelDocumentsState {
  [key: string]: IDocumentItem;
}

const modelDocumentsReducer = (
  state: IModelDocumentsState = {},
  action: ModelDocumentsDispatchTypes
): IModelDocumentsState => {
  switch (action.type) {
    case SET_MODEL_DOCUMENTS: {
      return merge(state, action.payload);
    }
    case CLEAR_MODEL_DOCUMENTS: {
      return {};
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default modelDocumentsReducer;
