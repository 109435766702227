import { makeStyles } from '@fluentui/react';

const useCreateWorkspaceWizardSuccessStyles = makeStyles((theme) => ({
  subtitle: {
    margin: '8px 0 0',
  },
  iconContainer: {
    width: '74px',
    height: '74px',
    background: theme.palette.green,
    borderRadius: '100px',
    margin: '24px auto',
  },
  icon: {
    fontSize: '40px',
    padding: '18px 17px',
    color: 'white',
  },
  infoTitle: {
    color: '#707070',
    lineHeight: '24px',
  },
  workspaceName: {
    lineHeight: '30px',
  },
  button: {
    marginTop: theme.spacing.l1,
  },
}));

export default useCreateWorkspaceWizardSuccessStyles;
