import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import WorkspaceRoles from '../constants/workspaceRoles';
import { useAppSelector } from './appRedux.helpers';

/**
 * @returns Whether or not the current user is a reader in the selected workspace.
 * Defaults to true if data hasn't been loaded yet.
 */
const useIsReader = (): boolean => {
  const { workspaces, roles } = useAppSelector((state) => state);
  const [isReader, setIsReader] = useState<boolean>(true);

  useEffect(() => {
    if (!isEmpty(roles) && !isEmpty(workspaces.currentWorkspace)) {
      const currentWorkspaceId = workspaces.currentWorkspace.id;
      if (!isEmpty(workspaces.data[currentWorkspaceId])) {
        const roleId = workspaces.data[currentWorkspaceId].role;
        const { roleName } = roles[roleId];
        setIsReader(roleName === WorkspaceRoles.Reader);
      }
    }
  }, [workspaces, roles, setIsReader]);

  return isReader;
};

/**
 * @param workspaceId
 * @returns Whether or not the current user is an owner
 * in the workspace. Defaults to false if data hasn't been loaded yet.
 */
const useIsOwner = (workspaceId: string): boolean => {
  const { workspaces, roles } = useAppSelector((state) => state);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  useEffect(() => {
    if (workspaceId && !isEmpty(roles) && !isEmpty(workspaces.data[workspaceId])) {
      const roleId = workspaces.data[workspaceId].role;
      const { roleName } = roles[roleId];
      setIsOwner(roleName === WorkspaceRoles.Owner);
    }
  }, [workspaceId, roles, setIsOwner, workspaces.data]);

  return isOwner;
};

export { useIsReader, useIsOwner };
