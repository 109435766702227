import { IconButton, Label, Link, Text, TooltipHost } from '@fluentui/react';
import React, { Fragment } from 'react';

import useRenderLabelTooltip from './RenderLabelTooltip.styles';
import { IRenderLabelTooltipProps } from './RenderLabelTooltip.types';

const RenderLabelTooltip: React.FC<IRenderLabelTooltipProps> = ({
  htmlFor,
  tooltipId,
  tooltipLabel,
  tooltipContent,
  urlLabel,
  urlContent,
  required = false,
}) => {
  const classes = useRenderLabelTooltip();

  let content;
  if (urlLabel) {
    content = (
      <Text>
        {tooltipContent}{' '}
        <Link href={urlContent} target="_blank" rel="noreferrer noopener">
          {urlLabel}
        </Link>
      </Text>
    );
  } else {
    content = tooltipContent;
  }
  return (
    <Fragment>
      <Label styles={classes} required={required} htmlFor={htmlFor}>
        {tooltipLabel}
      </Label>
      <TooltipHost content={content} id={tooltipId}>
        <IconButton
          iconProps={{ iconName: 'Info', className: classes.iconTooltip }}
          aria-labelledby={tooltipId}
          className={classes.iconTooltip}
        />
      </TooltipHost>
    </Fragment>
  );
};

export default RenderLabelTooltip;
