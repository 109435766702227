import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Label,
  PrimaryButton,
  SearchBox,
  Stack,
  useTheme,
} from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getDocuments } from '../../../redux/documents/documentsActions';
import { useSharedFilterFormStyles } from '../Shared';

import DocumentTypes from '../../../utils/constants/documentTypes';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import filterDocumentsDispatch from '../../../utils/filterDocumentsDispatch';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const FilterDocumentsForm: React.FC = () => {
  const theme = useTheme();
  const classes = useSharedFilterFormStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { projects, languages, filters } = useAppSelector((state) => state);
  const [documentSearchTerm, setDocumentSearchTerm] = useState<string | string[]>(filters.documents.name);
  const [selectedDocumentTypeKeys, setSelectedDocumentTypeKeys] = useState<string[]>(filters.documents.types);
  const [selectedLanguageKeys, setSelectedLanguageKeys] = useState<string[]>(
    filters.documents.languages.map(({ key }) => key)
  );
  const [showMonolingualDocuments, setShowMonolingualDocuments] = useState<boolean | undefined>(
    filters.documents.monolingual
  );

  const hasFilterOptions =
    !isEmpty(documentSearchTerm) ||
    !isEmpty(selectedDocumentTypeKeys) ||
    !isEmpty(selectedLanguageKeys) ||
    showMonolingualDocuments;

  // Document type dropdown population values
  const documentTypeOptions: IDropdownOption[] = [];
  Object.values(DocumentTypes).forEach((type) => {
    documentTypeOptions.push({
      key: type,
      text: type,
    });
  });

  // Language dropdown population values
  const languageOptions: IDropdownOption[] = [];
  Object.values(languages).forEach((languageData) => {
    languageOptions.push({
      key: languageData.languageCode,
      text: languageData.displayName,
    });
  });

  const onDocumentSearchTerm = (event?: React.FormEvent<HTMLInputElement>): void => {
    const searchTerm = (event?.target as HTMLInputElement)?.value || '';

    setDocumentSearchTerm(searchTerm);
  };

  const onTypeChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedDocumentTypeKeys(
        item.selected
          ? [...selectedDocumentTypeKeys, item.key as string]
          : selectedDocumentTypeKeys.filter((key: string) => key !== item.key)
      );
    }
  };

  const onLanguageChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedLanguageKeys(
        item.selected
          ? [...selectedLanguageKeys, item.key as string]
          : selectedLanguageKeys.filter((key: string) => key !== item.key)
      );
    }
  };

  const onMonolingualChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
    setShowMonolingualDocuments(isChecked);
  };

  const handleClearAll = (): void => {
    batch(() => {
      setDocumentSearchTerm('');
      setSelectedDocumentTypeKeys([]);
      setSelectedLanguageKeys([]);
      setShowMonolingualDocuments(false);
    });
  };

  const handleClearDocumentTypeSelection = (): void => {
    setSelectedDocumentTypeKeys([]);
  };

  const handleClearLanguageSelection = (): void => {
    setSelectedLanguageKeys([]);
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const { isFiltering, filter } = filterDocumentsDispatch({
      showMonolingualDocuments,
      documentSearchTerm,
      selectedDocumentTypeKeys,
      projectId,
      projects,
      languages,
      selectedLanguageKeys,
    });

    batch(() => {
      appDispatch(
        getDocuments({
          workspaceId,
          filter,
          page: 1,
          resetPagination: true,
          prioritizeModel: modelId,
        })
      );
      appDispatch({ type: 'SET_DOCUMENTS_FILTERED', payload: isFiltering });
      appDispatch({
        type: 'SET_DOCUMENT_FILTER_NAME',
        payload: {
          name: documentSearchTerm,
          nameLabel: t('components.forms.filterDocuments.searchLabel'),
        },
      });
      appDispatch({
        type: 'SET_DOCUMENT_FILTER_TYPES',
        payload: {
          types: selectedDocumentTypeKeys,
          typesLabel: t('components.forms.filterDocuments.type.label'),
        },
      });
      appDispatch({
        type: 'SET_DOCUMENT_FILTER_LANGUAGES',
        payload: {
          languages: selectedLanguageKeys.map((key) => {
            const languageKey = Object.keys(languages).find((langKey) => languages[langKey].languageCode === key);
            const languageObj = languages[languageKey || '0'];
            return { key, label: languageObj.displayName };
          }),
          languagesLabel: t('components.forms.filterDocuments.language.label'),
        },
      });
      appDispatch({
        type: 'SET_DOCUMENT_FILTER_MONOLINGUAL',
        payload: {
          monolingual: showMonolingualDocuments,
          monolingualLabel: t('components.forms.filterProjects.monolingualCheckbox'),
        },
      });
    });
  };

  const bodyStackTokens: IStackTokens = { childrenGap: theme.spacing.l2 };
  const actionButtonStackTokens: IStackTokens = { childrenGap: theme.spacing.s1 };

  return (
    <Stack as="form" className={classes.root} onSubmit={handleSubmit} verticalAlign="space-between">
      <Stack className={classes.body} tokens={bodyStackTokens}>
        <Stack.Item>
          <Label htmlFor="model-search" styles={{ root: { marginBottom: 4 } }}>
            {t('components.forms.filterDocuments.searchLabel')}
          </Label>
          <SearchBox
            id="model-search"
            name="model-search"
            placeholder={t('components.forms.filterCommon.searchPlaceholder')}
            value={documentSearchTerm.toString()}
            onChange={(e): void => onDocumentSearchTerm(e)}
            onClear={(): void => setDocumentSearchTerm('')}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            placeholder={t('components.forms.filterDocuments.type.placeholder')}
            label={t('components.forms.filterDocuments.type.label')}
            options={documentTypeOptions}
            selectedKeys={selectedDocumentTypeKeys}
            onChange={onTypeChange}
            multiSelect
            disabled={!documentTypeOptions}
            className={classes.dropdown}
          />
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clear')}
            onClick={(): void => handleClearDocumentTypeSelection()}
            disabled={isEmpty(selectedDocumentTypeKeys)}
            className="as-link"
          />
        </Stack.Item>
        {isEmpty(projectId) && (
          <Stack.Item>
            <Dropdown
              placeholder={t('components.forms.filterDocuments.language.placeholder')}
              label={t('components.forms.filterDocuments.language.label')}
              options={languageOptions}
              selectedKeys={selectedLanguageKeys}
              onChange={onLanguageChange}
              multiSelect
              disabled={!languageOptions}
              className={classes.dropdown}
            />
            <DefaultButton
              text={t('components.forms.filterCommon.actionButtons.clear')}
              onClick={(): void => handleClearLanguageSelection()}
              disabled={isEmpty(selectedLanguageKeys)}
              className="as-link"
            />
          </Stack.Item>
        )}
        {isEmpty(projectId) && (
          <Stack.Item>
            <Checkbox
              label={t('components.forms.filterProjects.monolingualCheckbox')}
              checked={showMonolingualDocuments}
              onChange={onMonolingualChange}
            />
          </Stack.Item>
        )}
      </Stack>
      <Stack as="footer" horizontal tokens={actionButtonStackTokens}>
        <Stack.Item>
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clearAll')}
            onClick={(): void => handleClearAll()}
            disabled={!hasFilterOptions}
          />
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton text={t('components.forms.filterCommon.actionButtons.apply')} type="submit" />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default FilterDocumentsForm;
