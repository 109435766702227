import { Breadcrumb, IBreadcrumbProps, Link, Text } from '@fluentui/react';
import React, { Fragment } from 'react';

import usePageBreadcrumbStyles from './PageBreadcrumb.styles';

interface IPageBreadcrumbProps extends IBreadcrumbProps {
  subtitle?: string;
  /** Optional element if subtitle isn't a string but an HTML element */
  subtitleElement?: JSX.Element;
  /** Adds optional link to end of subtitle */
  link?: {
    text: string;
    href: string;
  };
}

/** Used for page titles and breadcrumbs.
 * Leverages the Fluent UI Breadcrumb component while allowing for extended functionality using subtitle and link props */
const PageBreadcrumb: React.FC<IPageBreadcrumbProps> = ({ items, subtitle, link, subtitleElement }) => {
  const classes = usePageBreadcrumbStyles();

  return (
    <Fragment>
      <Breadcrumb data-test-id="page-breadcrumb" items={items} />
      {subtitle && (
        <Text className={classes.subtitle} data-test-id="breadcrumb-text">
          {subtitle}
          {link && (
            // TODO: Investigate why underline does not work as expected and throws error https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119504
            <Link target="_blank" rel="noopener noreferrer" href={link.href} data-test-id="breadcrumb-link">
              {link.text}
            </Link>
          )}
        </Text>
      )}
      {subtitleElement && <div className={classes.subtitleElement}>{subtitleElement}</div>}
    </Fragment>
  );
};

export default PageBreadcrumb;
