import React from 'react';

import useContentContainerStyles from './ContentContainer.styles';

/** Simple container to add padding for page content components */
const ContentContainer: React.FC = ({ children }) => {
  const classes = useContentContainerStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ContentContainer;
