import { Overlay, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React from 'react';

import useLoadingOverlayStyles from './LoadingOverlay.styles';
import ILoadingOverlayProps from './LoadingOverlay.types';

const LoadingOverlay: React.FC<ILoadingOverlayProps> = ({ hasLoadingText = false, loadingText = 'loading' }) => {
  const classes = useLoadingOverlayStyles();

  return (
    <Overlay className={classes.root}>
      <Stack verticalAlign="center" className={classes.content}>
        <Spinner size={SpinnerSize.large} label={hasLoadingText ? loadingText : undefined} />
      </Stack>
    </Overlay>
  );
};

export default LoadingOverlay;
