import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import {
  GET_UPLOAD_HISTORY,
  GET_UPLOAD_HISTORY_FAILED,
  GET_UPLOAD_HISTORY_SUCCESSFUL,
  GetUploadHistoryResponse,
  UploadHistoryDispatchTypes,
} from './uploadHistory.types';

const getUploadHistory = ({
  workspaceId,
  currentPage,
  filter,
}: {
  workspaceId: string;
  currentPage?: number;
  filter?: string;
}) => async (dispatch: Dispatch<UploadHistoryDispatchTypes>): Promise<void | Action | null> => {
  dispatch({
    type: GET_UPLOAD_HISTORY,
    payload: {
      isFiltering: filter ? filter.length > 0 : false,
    },
  });

  try {
    const response = await axios.get<GetUploadHistoryResponse>(
      `${process.env.REACT_APP_API_URL}documents/import/jobs/all`,
      {
        params: { workspaceId, limit: 100, pageIndex: currentPage, $filter: filter },
      }
    );

    return batch(() => {
      dispatch({
        type: GET_UPLOAD_HISTORY_SUCCESSFUL,
        payload: {
          ...response.data,
          isFiltering: filter ? filter.length > 0 : false,
          workspaceId,
        },
      });
    });
  } catch {
    return batch(() => {
      dispatch({
        type: GET_UPLOAD_HISTORY_FAILED,
      });
    });
  }
};

export default getUploadHistory;
