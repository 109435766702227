/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import i18next from 'i18next';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { BillingRegionsDispatchTypes, SET_BILLING_REGIONS } from '../billingRegions/billingRegions.types';
import normalizeResponse from '../normalizeResponse';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import { RolesDispatchTypes, SET_ROLES } from '../roles/roles.types';
import { workspaceSchema } from '../schema';
import { SET_SHARING, SharingDispatchTypes } from '../sharing/sharing.types';
import { GET_SUBSCRIPTION_SUCCESSFUL, SubscriptionsDispatchTypes } from '../subscription/subscription.types';
import { TelemetryDispatchTypes } from '../telemetry/telemetry.types';
import { SET_USERS, UsersDispatchTypes } from '../users/users.types';
import { ADD_WORKSPACE, IWorkspacesEntities, WorkspacesDispatchTypes } from '../workspaces/workspaces.types';
import {
  CLEAR_CREATE_WORKSPACE_WIZARD,
  CREATE_WORKSPACE_FAILED_KEY_VALIDATION,
  CREATE_WORKSPACE_FAILED_OTHER,
  CREATE_WORKSPACE_SUCCESSFUL,
  CreateWorkspaceErrorCodes,
  CreateWorkspaceWizardDispatchTypes,
  CreateWorkspaceWizardSlide,
  ICreateWorkspaceWizardFormValues,
  SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY,
  SET_CREATE_WORKSPACE_WIZARD_NAME,
  SET_CREATE_WORKSPACE_WIZARD_SLIDE,
} from './createWorkspaceWizard.types';

import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';

export const setWorkspaceWizardSlide = (slide: CreateWorkspaceWizardSlide) => (
  dispatch: Dispatch<CreateWorkspaceWizardDispatchTypes>
): Action => {
  return dispatch({
    type: SET_CREATE_WORKSPACE_WIZARD_SLIDE,
    payload: slide,
  });
};

export const clearCreateWorkspaceWizard = () => (dispatch: Dispatch<CreateWorkspaceWizardDispatchTypes>): Action => {
  return dispatch({
    type: CLEAR_CREATE_WORKSPACE_WIZARD,
  });
};

export const setWorkspaceName = (value: string) => (dispatch: Dispatch<CreateWorkspaceWizardDispatchTypes>): Action => {
  return dispatch({
    type: SET_CREATE_WORKSPACE_WIZARD_NAME,
    payload: value,
  });
};

export const setIsValidatingKey = (value: boolean) => (
  dispatch: Dispatch<CreateWorkspaceWizardDispatchTypes>
): Action => {
  return dispatch({
    type: SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY,
    payload: value,
  });
};

export const createWorkspace = (
  workspaceFormValues: ICreateWorkspaceWizardFormValues,
  closeWizard: () => void
) => async (
  dispatch: Dispatch<
    | CreateWorkspaceWizardDispatchTypes
    | PopUpErrorsDispatchTypes
    | BillingRegionsDispatchTypes
    | RolesDispatchTypes
    | SharingDispatchTypes
    | UsersDispatchTypes
    | WorkspacesDispatchTypes
    | SubscriptionsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    const { name, region, key } = workspaceFormValues;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}workspaces`, {
      name,
      subscription: {
        billingRegionCode: region,
        subscriptionKey: key,
      },
    });

    const { entities } = await normalizeResponse<IWorkspacesEntities>(response.data, workspaceSchema);
    const workspaceId = response.data.id;
    const workspace = entities.workspaces[workspaceId];

    const subscriptionId = response.data.subscription.id;
    const subscription = entities.subscription[subscriptionId];

    return batch(() => {
      dispatch({
        type: SET_BILLING_REGIONS,
        payload: entities.billingRegions,
      });
      dispatch({
        type: SET_ROLES,
        payload: entities.roles,
      });
      dispatch({
        type: SET_SHARING,
        payload: entities.sharing,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: GET_SUBSCRIPTION_SUCCESSFUL,
        payload: {
          workspaceId,
          data: subscription,
        },
      });
      dispatch({
        type: ADD_WORKSPACE,
        payload: {
          id: workspaceId,
          workspace,
        },
      });
      dispatch({ type: CREATE_WORKSPACE_SUCCESSFUL });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-create/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { message, errorCode, display }: { display: boolean; message: string; errorCode: number } = err.response.data;
    if (errorCode === CreateWorkspaceErrorCodes.InvalidSubscription) {
      return batch(() => {
        dispatch({ type: CREATE_WORKSPACE_FAILED_KEY_VALIDATION, payload: message });
        dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-create/failure-key' });
      });
    }

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.workspace.create')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Workspaces,
      requestType: RequestTypes.Create,
    });

    return batch(() => {
      closeWizard();
      dispatch({ type: CREATE_WORKSPACE_FAILED_OTHER });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-create/failure-other' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};
