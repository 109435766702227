export const GET_DOCUMENT_CONTENT = 'GET_DOCUMENT_CONTENT';
export const GET_DOCUMENT_CONTENT_SUCCESSFUL = 'GET_DOCUMENT_CONTENT_SUCCESSFUL';
export const GET_DOCUMENT_CONTENT_FAILED = 'GET_DOCUMENT_CONTENT_FAILED';

/**
 * Parameters used for making API call to fetch file contents.
 * id: Document id
 * sourceFileId: Id of the first file to fetch contents for
 * targetFileId: Id of the second file to fetch contents for
 * pageIndex: Page of results to fetch
 * resetPagination: If pagination in the store should be reset to 0 while the data is being fetched. For
 * consistency & a smooth user experience, pagination shouldn't reset if the user hasn't changed the
 * project and is just advanced forward or backwards a page.
 */
export interface IGetDocumentContentParams {
  documentId: string;
  sourceFileId: number;
  targetFileId: number;
  pageIndex: number;
  resetPagination: boolean;
}

export interface IDocumentContent {
  sourceSentence: string;
  targetSentence: string;
}

export interface IDocumentContentTotalPageCountItem {
  sourceTotal: number;
  targetTotal: number;
  max: number;
}

export interface IDocumentContentItem {
  isLoading: boolean;
  data: IDocumentContent[];
  pageIndex: number;
  totalPageCount: IDocumentContentTotalPageCountItem;
  error: string;
}

export interface IDocumentContentSuccessfulPayload {
  id: string;
  data: IDocumentContent[];
  pageIndex: number;
  totalPageCount: IDocumentContentTotalPageCountItem;
}

export interface IGetDocumentContentPayload {
  id: string;
  pageIndex: number;
  resetPagination: boolean;
}

export interface IGetFileDocumentContentFailedPayload {
  id: string;
  error: string;
}

export interface IGetDocumentContent {
  type: typeof GET_DOCUMENT_CONTENT;
  payload: IGetDocumentContentPayload;
}

export interface IGetDocumentContentSuccessful {
  type: typeof GET_DOCUMENT_CONTENT_SUCCESSFUL;
  payload: IDocumentContentSuccessfulPayload;
}

export interface IGetDocumentContentFailed {
  type: typeof GET_DOCUMENT_CONTENT_FAILED;
  payload: IGetFileDocumentContentFailedPayload;
}

export type DocumentContentDispatchTypes =
  | IGetDocumentContent
  | IGetDocumentContentSuccessful
  | IGetDocumentContentFailed;
