import React from 'react';

type ContextProps = {
  isAppSidebarCollapsed: boolean;
  setIsAppSidebarCollapsed: (value: boolean) => void;
};

const initialState = {
  isAppSidebarCollapsed: false,
  setIsAppSidebarCollapsed: (): void => {},
};

const appSidebarContext = React.createContext<ContextProps>(initialState);

export default appSidebarContext;
