import { ActionButton, IStackTokens, Stack, Text } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import bellImage from '../../../assets/images/bell.svg';
import { clearAllNotifications } from '../../../redux/notifications/notificationsActions';
import CopyingNotification from '../CopyingNotification';
import DeploymentNotification from '../DeploymentNotification/DeploymentNotification';
import TrainingNotification from '../TrainingNotification/TrainingNotification';
import UploadNotification from '../UploadNotification';
import useNotificationPanelStyles from './NotificationPanel.styles';

import { NotificationType } from '../../../utils/constants/notifications';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const NotificationPanel: React.FC = () => {
  const classes = useNotificationPanelStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    notifications,
    trainingNotifications,
    deploymentNotifications,
    uploadNotifications,
    copyNotifications,
  } = useAppSelector((state) => state);

  const dismissAll = (): void => {
    appDispatch(clearAllNotifications());
  };

  const emptyStackTokens: IStackTokens = {
    childrenGap: 30,
  };

  const getNotification = (type: NotificationType, id: string): JSX.Element => {
    switch (type) {
      case NotificationType.Deployment:
        return <DeploymentNotification notification={deploymentNotifications.data[id]} />;
      case NotificationType.Training:
        return <TrainingNotification notification={trainingNotifications.data[id]} />;
      case NotificationType.Upload:
        return <UploadNotification notification={uploadNotifications.data[id]} />;
      case NotificationType.Copying:
        return <CopyingNotification notification={copyNotifications.data[id]} />;
      default:
        return <React.Fragment />;
    }
  };

  const notificationContent = notifications.ids.map((notification) => {
    return (
      <Stack.Item key={notification.id} className={classes.notificationItemContainer}>
        {getNotification(notification.type, notification.id)}
      </Stack.Item>
    );
  });

  return (
    <Stack className={classes.notificationContainer} tokens={{ childrenGap: 4 }}>
      {!isEmpty(notifications.ids) && (
        <Fragment>
          <Stack.Item align="end">
            <ActionButton className={classes.dismissAll} onClick={dismissAll}>
              {t('components.notificationPanel.dismissAll')}
            </ActionButton>
          </Stack.Item>
          {notificationContent}
        </Fragment>
      )}
      {isEmpty(notifications.ids) && (
        <Stack tokens={emptyStackTokens}>
          <img
            src={bellImage}
            alt={t('components.notificationPanel.emptyImageAltText')}
            className={classes.emptyImage}
          />
          <Text className={classes.emptyText} variant="medium">
            {t('components.notificationPanel.empty')}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default NotificationPanel;
