import { FontWeights, makeStyles } from '@fluentui/react';

const useTrainingProgressStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    whiteSpace: 'nowrap',
    margin: '1px 0 0 5px',
  },
  blockRoot: {
    whiteSpace: 'wrap',
    margin: '1px 0 0 5px',
  },
  icon: {
    position: 'relative',
    fontSize: 16,
    top: 2,
    marginRight: theme.spacing.s2,
    color: theme.palette.green,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spinner: {
    position: 'relative',
    top: 2,
    marginRight: 8,
    justifyContent: 'flex-start',
  },
  inProgress: {
    color: theme.palette.themePrimary,
    fontWeight: FontWeights.semibold,
  },
  notStarted: {
    position: 'relative',
    color: theme.palette.neutralTertiary,
    marginLeft: theme.spacing.l1,
  },
  progressList: {
    marginBottom: theme.spacing.l1,
  },
  reducedFontSize: {
    fontSize: 12,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  errorIcon: {
    position: 'relative',
    fontSize: 16,
    top: 2,
    marginRight: theme.spacing.s2,
    color: theme.semanticColors.errorIcon,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cancelledIcon: {
    position: 'relative',
    fontSize: 16,
    top: 2,
    marginRight: theme.spacing.s2,
    color: theme.semanticColors.infoIcon,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inlineError: {
    marginLeft: 15,
  },
}));

export default useTrainingProgressStyles;
