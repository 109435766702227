export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export interface ISetCurrentUserPayload {
  username: string;
  email: string;
  msalId: string;
}

export interface IClearCurrentUser {
  type: typeof CLEAR_CURRENT_USER;
}

export interface ISetCurrentUser {
  type: typeof SET_CURRENT_USER;
  payload: ISetCurrentUserPayload;
}

export type CurrentUserDispatchTypes = ISetCurrentUser | IClearCurrentUser;
