import { IModelItem } from '../redux/models/models.types';
import { ModelStatus } from './constants/modelStatus';

const isModelPublished = (currentModel: IModelItem | undefined): boolean => {
  if (currentModel === undefined) {
    return false;
  }
  return currentModel.modelStatus === ModelStatus.Published;
};

export default isModelPublished;
