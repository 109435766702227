/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import i18next from 'i18next';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { IParallelDocumentsFormValues } from '../../components/Forms/UploadDocumentsWizard/UploadDocumentsSet/ParallelDocumentsForm/ParallelDocumentsForm.types';
import { ISingleDocumentFormValues } from '../../components/Forms/UploadDocumentsWizard/UploadDocumentsSet/SingleDocumentForm/SingleDocumentForm.types';
import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import { FileDispatchTypes, SET_FILES } from '../files/files.types';
import { LanguagesDispatchTypes, SET_LANGUAGES } from '../languages/languages.types';
import normalizeResponse from '../normalizeResponse';
import {
  ADD_NOTIFICATION,
  NotificationsDispatchTypes,
  REPLACE_NOTIFICATION_ID,
} from '../notifications/notifications.types';
import { PanelsDispatchTypes, SET_ACTIVE_PANEL } from '../panels/panels.types';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import { documentsListSchema, documentsSchema } from '../schema';
import { TelemetryDispatchTypes } from '../telemetry/telemetry.types';
import {
  ADD_UPLOAD_NOTIFICATION,
  UPDATE_JOBID_UPLOAD_NOTIFICATION,
  UPDATE_UPLOAD_NOTIFICATION,
  UploadNotificationsDispatchTypes,
} from '../uploadNotifications/uploadNotifications.types';
import { SET_USERS, UsersDispatchTypes } from '../users/users.types';
import {
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FAILED,
  DELETE_DOCUMENT_SUCCESSFUL,
  DOWNLOAD_DOCUMENTS,
  DOWNLOAD_DOCUMENTS_FAILED,
  DOWNLOAD_DOCUMENTS_SUCCESSFUL,
  DocumentsDispatchTypes,
  GET_DOCUMENT,
  GET_DOCUMENTS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENTS_SUCCESSFUL,
  GET_DOCUMENT_FAILED,
  GET_DOCUMENT_SUCCESSFUL,
  IDocumentDetailsItem,
  IDocumentsEntities,
  IGetDocumentsParams,
  SET_HAS_NEW_DOCUMENTS,
  UPLOAD_DOCUMENT_SET,
  UPLOAD_DOCUMENT_SET_FAILED,
  UPLOAD_DOCUMENT_SET_SUCCESSFUL,
} from './documents.types';

import addToSignalRGroup from '../../utils/addToSignalRGroup';
import { AppBarPanels } from '../../utils/constants/appBarPanels';
import ErrorCategories from '../../utils/constants/errorCategories';
import { NotificationType, UploadNotificationStatus } from '../../utils/constants/notifications';
import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';

export const getDocument = (id: string) => async (
  dispatch: Dispatch<
    DocumentsDispatchTypes | UsersDispatchTypes | LanguagesDispatchTypes | FileDispatchTypes | ErrorDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: GET_DOCUMENT, payload: id });

    // TODO: Add in hook for returning test data
    // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119772
    const response = await axios.get(`${process.env.REACT_APP_API_URL}documents/${id}`);
    const { entities } = await normalizeResponse<IDocumentsEntities>(response.data, documentsSchema);

    return batch(() => {
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_FILES,
        payload: entities.files,
      });
      dispatch({
        type: GET_DOCUMENT_SUCCESSFUL,
        payload: {
          ids: [Object.keys(entities.documents)[0]],
          data: entities.documents,
          pageIndex: 1,
          totalPageCount: 1,
        },
      });
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_DOCUMENT_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Document,
        },
      });
    });
  }
};

export const getDocuments = (params: IGetDocumentsParams, silent = false) => async (
  dispatch: Dispatch<
    DocumentsDispatchTypes | UsersDispatchTypes | LanguagesDispatchTypes | FileDispatchTypes | ErrorDispatchTypes
  >
): Promise<void | Action | null> => {
  try {
    const { resetPagination, workspaceId, page, filter, prioritizeModel } = params;
    if (!silent) {
      dispatch({ type: GET_DOCUMENTS, payload: { resetPagination, pageIndex: page } });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any;

    if (process.env.REACT_APP_ENV === 'test') {
      const { default: mockData } = await import('../../__test__/fixtures/projectdocuments.data.json');

      response = { data: mockData };
    } else {
      response = await axios.get(`${process.env.REACT_APP_API_URL}documents`, {
        params: {
          workspaceid: workspaceId,
          pageIndex: page,
          includeAllDocumentsFields: false,
          limit: 100,
          $filter: filter,
          prioritizeModel,
        },
      });
    }

    const { entities, result } = await normalizeResponse<IDocumentsEntities>(
      response.data.paginatedDocuments.documents,
      documentsListSchema
    );

    return batch(() => {
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_FILES,
        payload: entities.files,
      });
      dispatch({
        type: GET_DOCUMENTS_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.documents,
          pageIndex: response.data.paginatedDocuments.pageIndex,
          totalPageCount: response.data.paginatedDocuments.totalPageCount,
        },
      });
    });
  } catch (error: any) {
    if (!silent) {
      return batch(() => {
        dispatch({
          type: GET_DOCUMENTS_FAILED,
        });
        dispatch({
          type: SET_ERROR,
          payload: {
            message: error.message,
            statusCode: error.response?.status,
            category: ErrorCategories.Documents,
          },
        });
      });
    }
    return null;
  }
};

export const downloadDocuments = (selectedDocuments: string[], documentName: string) => async (
  dispatch: Dispatch<DocumentsDispatchTypes | PopUpErrorsDispatchTypes | TelemetryDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: DOWNLOAD_DOCUMENTS });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}documents/export`, {
      responseType: 'blob',
      params: {
        documentIds: selectedDocuments,
      },
    });

    const fileContentBlob = new Blob([response.data]);
    const dummyLinkToFileContentBlob = Object.assign(document.createElement('a'), {
      href: URL.createObjectURL(fileContentBlob),
      target: '_blank',
      download: `${documentName}.zip`,
    });
    document.body.appendChild(dummyLinkToFileContentBlob);
    dummyLinkToFileContentBlob.click();

    return batch(() => {
      dispatch({ type: DOWNLOAD_DOCUMENTS_SUCCESSFUL });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-download/success' });
    });
  } catch (error: any) {
    const responseStatusCode: number = error.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = error.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.documents.download.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Documents,
      requestType: RequestTypes.Download,
    });

    return batch(() => {
      dispatch({ type: DOWNLOAD_DOCUMENTS_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-download/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const deleteDocument = (selectedDocument: string) => async (
  dispatch: Dispatch<DocumentsDispatchTypes | PopUpErrorsDispatchTypes | TelemetryDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: DELETE_DOCUMENT });

    await axios.delete(`${process.env.REACT_APP_API_URL}documents/${selectedDocument}`);

    return batch(() => {
      dispatch({ type: DELETE_DOCUMENT_SUCCESSFUL, payload: selectedDocument });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-delete/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.documents.delete.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Documents,
      requestType: RequestTypes.Delete,
    });

    return batch(() => {
      dispatch({ type: DELETE_DOCUMENT_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-delete/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const uploadTMOrZipFile = (tmFormValues: ISingleDocumentFormValues, workspaceId: string) => async (
  dispatch: Dispatch<
    | DocumentsDispatchTypes
    | NotificationsDispatchTypes
    | UploadNotificationsDispatchTypes
    | PanelsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  const temporaryId = uuidv4();
  try {
    // Add notification. We display API failures using notifications
    // since it can take a while for the upload to the API to succeed.
    // Uses a temporary ID which is replaced with the jobId once the upload
    // is accepted by the API.
    const { fileName } = tmFormValues;
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    const zipExtensions = ['.zip', '.gz', '.tgz'];
    const isZip = zipExtensions.some((extension) => {
      return fileName.endsWith(extension);
    });
    const uploadNotificationPayload = {
      id: temporaryId,
      data: {
        name: fileNameWithoutExtension,
        workspaceId,
        isZip,
      },
    };
    batch(() => {
      dispatch({ type: UPLOAD_DOCUMENT_SET });
      dispatch({ type: ADD_UPLOAD_NOTIFICATION, payload: uploadNotificationPayload });
      dispatch({ type: ADD_NOTIFICATION, payload: { id: temporaryId, type: NotificationType.Upload } });
      dispatch({ type: SET_ACTIVE_PANEL, payload: AppBarPanels.Notification });
    });

    const documentDetails: IDocumentDetailsItem[] = [
      {
        DocumentName: '', // Doesn't need to be defined for TM upload
        DocumentType: tmFormValues.documentSetType,
        FileDetails: [],
      },
    ];

    const uploadData = new FormData();

    uploadData.append('Files', tmFormValues.fileUpload);

    documentDetails[0].FileDetails.push({
      Name: tmFormValues.fileName,
      LanguageCode: tmFormValues.languageCode,
      OverwriteIfExists: true,
    });

    const documentDetailsJson = JSON.stringify(documentDetails, null, 2);

    uploadData.append('DocumentDetails', documentDetailsJson);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}documents/import?workspaceId=${workspaceId}`,
      uploadData
    );

    // Subscribe to notifications from the webjob on the processing of this upload
    const { jobId } = response.data;
    addToSignalRGroup(`${jobId}`);

    const updateNotificationPayload = {
      id: jobId,
      data: {
        oldId: temporaryId,
      },
    };

    return batch(() => {
      dispatch({
        type: UPLOAD_DOCUMENT_SET_SUCCESSFUL,
      });
      dispatch({
        type: UPDATE_JOBID_UPLOAD_NOTIFICATION,
        payload: updateNotificationPayload,
      });
      dispatch({
        type: REPLACE_NOTIFICATION_ID,
        payload: updateNotificationPayload,
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-upload-zip/success' });
    });
  } catch (err: any) {
    const { display, message }: { display: boolean; message: string } = err.response.data;

    return batch(() => {
      dispatch({
        type: UPLOAD_DOCUMENT_SET_FAILED,
      });
      dispatch({
        type: UPDATE_UPLOAD_NOTIFICATION,
        payload: {
          id: temporaryId,
          data: {
            status: UploadNotificationStatus.Failed,
            errorMessage: display ? message : '',
          },
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-upload-zip/failure' });
    });
  }
};

export const uploadParallelDocumentsSet = (
  documentSetFormValues: IParallelDocumentsFormValues,
  workspaceId: string
) => async (
  dispatch: Dispatch<
    DocumentsDispatchTypes | UploadNotificationsDispatchTypes | NotificationsDispatchTypes | PanelsDispatchTypes
  >
): Promise<void | Action> => {
  const temporaryId = uuidv4();
  try {
    // Add notification. We display API failures using notifications
    // since it can take a while for the upload to the API to succeed.
    // Uses a temporary ID which is replaced with the jobId once the upload
    // is accepted by the API.
    const uploadNotificationPayload = {
      id: temporaryId,
      data: {
        name: documentSetFormValues.documentSetName,
        workspaceId,
        isZip: false,
      },
    };
    batch(() => {
      dispatch({ type: UPLOAD_DOCUMENT_SET });
      dispatch({ type: ADD_UPLOAD_NOTIFICATION, payload: uploadNotificationPayload });
      dispatch({ type: ADD_NOTIFICATION, payload: { id: temporaryId, type: NotificationType.Upload } });
      dispatch({ type: SET_ACTIVE_PANEL, payload: AppBarPanels.Notification });
    });

    const documentDetails: IDocumentDetailsItem[] = [
      {
        DocumentName: documentSetFormValues.documentSetName,
        DocumentType: documentSetFormValues.documentSetType,
        FileDetails: [],
      },
    ];

    const uploadData = new FormData();

    uploadData.append('Files', documentSetFormValues.sourceFileUpload);

    documentDetails[0].FileDetails.push({
      Name: documentSetFormValues.sourceFileDetails.name,
      LanguageCode: documentSetFormValues.sourceFileDetails.languageCode,
      OverwriteIfExists: true,
    });

    uploadData.append('Files', documentSetFormValues.targetFileUpload);

    documentDetails[0].FileDetails.push({
      Name: documentSetFormValues.targetFileDetails.name,
      LanguageCode: documentSetFormValues.targetFileDetails.languageCode,
      OverwriteIfExists: true,
    });

    const documentDetailsJson = JSON.stringify(documentDetails, null, 2);

    uploadData.append('DocumentDetails', documentDetailsJson);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}documents/import?workspaceId=${workspaceId}`,
      uploadData
    );

    // Subscribe to notifications from the webjob on the processing of this upload
    const { jobId } = response.data;
    addToSignalRGroup(`${jobId}`);

    const updateNotificationPayload = {
      id: jobId,
      data: {
        oldId: temporaryId,
      },
    };

    return batch(() => {
      dispatch({
        type: UPLOAD_DOCUMENT_SET_SUCCESSFUL,
      });
      dispatch({
        type: UPDATE_JOBID_UPLOAD_NOTIFICATION,
        payload: updateNotificationPayload,
      });
      dispatch({
        type: REPLACE_NOTIFICATION_ID,
        payload: updateNotificationPayload,
      });
    });
  } catch (err: any) {
    const { display, message }: { display: boolean; message: string } = err.response.data;

    return batch(() => {
      dispatch({
        type: UPLOAD_DOCUMENT_SET_FAILED,
      });
      dispatch({
        type: UPDATE_UPLOAD_NOTIFICATION,
        payload: {
          id: temporaryId,
          data: {
            status: UploadNotificationStatus.Failed,
            errorMessage: display ? message : '',
          },
        },
      });
    });
  }
};

// Used by upload notifications so views that display documents can silently refresh data to show
// any new uploads
export const setHasNewDocuments = (payload: boolean) => (dispatch: Dispatch<DocumentsDispatchTypes>): Action => {
  return dispatch({
    type: SET_HAS_NEW_DOCUMENTS,
    payload,
  });
};
