import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';

import { useAppSelector } from '../../../utils/hooks';

const ModelDocumentsCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const { documents } = useAppSelector((state) => state);

  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'trainDocumentsFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.documents.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(documents.data),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.DocumentsFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
  ];

  return (
    <Fragment>
      <CommandBar items={[]} ariaLabel={t('components.commandBars.aria')} farItems={rightCommandBarItems} />
    </Fragment>
  );
};

export default ModelDocumentsCommandBar;
