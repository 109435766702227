import { FontWeights, makeStyles } from '@fluentui/react';

const useNavBarStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '70px',
    height: 70,
    padding: '0 32px',
  },
  link: {
    fontSize: '16px',
    fontWeight: FontWeights.semibold,
  },
  image: {
    paddingTop: '2px',
  },
  signIn: {
    backgroundColor: theme.palette.white,
    color: theme.palette.blue,
    border: '0',
  },
}));

export default useNavBarStyles;
