import { makeStyles } from '@fluentui/react';

const useCommandBarLoaderStyles = makeStyles((theme) => ({
  spinner: {
    marginRight: theme.spacing.s2,
  },
  label: {
    marginRight: theme.spacing.s1,
    ...theme.fonts.medium,
  },
}));

export default useCommandBarLoaderStyles;
