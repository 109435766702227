import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';

import useTextCalloutStyles from './TextCallout.styles';
import ITextCalloutProps from './TextCallout.types';

/** Simple bold title with associated description text */
const TextCallout: React.FC<ITextCalloutProps> = ({ title, text, horizontal = false, divider, customStyles }) => {
  const classes = useTextCalloutStyles();

  const titleClass = clsx(horizontal ? classes.titleHorizontal : classes.titleDefault);

  return (
    <Stack className={classes.root} style={customStyles} horizontal={horizontal}>
      <Text as="title" className={titleClass}>
        {title}
      </Text>
      {divider && <span className={classes.divider}>{divider}</span>}
      <Text as="p">{text}</Text>
    </Stack>
  );
};

export default TextCallout;
