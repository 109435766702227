export const SET_UPLOAD_DOCUMENTS_WIZARD = 'SET_UPLOAD_DOCUMENTS_WIZARD';
export const CLEAR_UPLOAD_DOCUMENTS_WIZARD = 'CLEAR_UPLOAD_DOCUMENTS_WIZARD';
export const SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD = 'SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD';
export const CLEAR_DICTIONARY_TYPE = 'CLEAR_DICTIONARY_TYPE';

export const SET_DOCUMENTS_LANGUAGE_PAIR = 'SET_DOCUMENTS_LANGUAGE_PAIR';

export enum SetUploadDocumentWizardTypes {
  Document = 'documentSetType',
  Dictionary = 'dictionaryType',
}

export enum DocumentSetTypes {
  Training = 'training',
  Testing = 'testing',
  Tuning = 'tuning',
  Dictionary = 'dictionary',
}

export enum DictionaryTypes {
  PhraseDictionary = 'phrase dictionary',
  SentenceDictionary = 'sentence dictionary',
}

export enum UploadTypes {
  ParallelDocuments = 'parallelDocuments',
  TranslationMemoryFile = 'translationMemoryFile',
  ZipFiles = 'zipFiles',
}

export interface IDocumentLanguageItem {
  displayName: string;
  languageCode: string;
}

export interface ISetUploadDocumentsWizard {
  type: typeof SET_UPLOAD_DOCUMENTS_WIZARD;
  payload: {
    type: SetUploadDocumentWizardTypes;
    value: DocumentSetTypes | DictionaryTypes | undefined;
  };
}

export interface IClearDictionaryType {
  type: typeof CLEAR_DICTIONARY_TYPE;
}

export interface IClearUploadDocumentsWizard {
  type: typeof CLEAR_UPLOAD_DOCUMENTS_WIZARD;
}

export interface ISetInitialUploadDocumentsWizard {
  type: typeof SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD;
}

export interface ISetDocumentsLanguagePair {
  type: typeof SET_DOCUMENTS_LANGUAGE_PAIR;
  payload: {
    sourceLanguage: IDocumentLanguageItem | undefined;
    targetLanguage: IDocumentLanguageItem | undefined;
  };
}

export type UploadDocumentWizardDispatchTypes =
  | ISetUploadDocumentsWizard
  | IClearUploadDocumentsWizard
  | ISetInitialUploadDocumentsWizard
  | IClearDictionaryType
  | ISetDocumentsLanguagePair;
