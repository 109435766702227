import {
  GET_UPLOAD_HISTORY,
  GET_UPLOAD_HISTORY_FAILED,
  GET_UPLOAD_HISTORY_SUCCESSFUL,
  IUploadHistoryState,
  UploadHistoryDispatchTypes,
} from './uploadHistory.types';

const initialState: IUploadHistoryState = {
  isLoading: false,
  isFiltering: false,
  pageIndex: 1,
  totalPageCount: 1,
  jobs: [],
  workspaceId: null,
  failed: false,
};

const uploadHistoryReducer = (
  state: IUploadHistoryState = initialState,
  action: UploadHistoryDispatchTypes
): IUploadHistoryState => {
  switch (action.type) {
    case GET_UPLOAD_HISTORY: {
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    }
    case GET_UPLOAD_HISTORY_SUCCESSFUL: {
      return {
        ...action.payload,
        jobs: action.payload.jobs ? action.payload.jobs : [],
        isLoading: false,
      };
    }
    case GET_UPLOAD_HISTORY_FAILED: {
      return {
        ...initialState,
        failed: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default uploadHistoryReducer;
