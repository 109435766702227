import { IModelItem } from '../redux/models/models.types';
import { ModelStatus } from './constants/modelStatus';

interface IModels {
  data: {
    [key: string]: IModelItem;
  };
  ids: string[];
}

/**
 * filterModels is used for matching a new set of model IDs that meet the criteria for publishing or testing.
 * Will default to publishing and only match the model status. When filterDictionaryTrainings is true,
 * only models with testing data (full trainings) will be returned.
 */
const filterTestedOrPublishedModels = (models: IModels, filterDictionaryTrainings = false, ids: string[]): string[] => {
  const filteredModelIds: string[] = [];
  ids.forEach((id) => {
    const { trainingSentenceCount } = models.data[id];
    const isFullTraining = trainingSentenceCount > 0;

    const testAndPublishStatuses: string[] = [
      ModelStatus.TrainingSucceeded,
      ModelStatus.Published,
      ModelStatus.Unpublished,
      ModelStatus.Publishing,
      ModelStatus.PublishFailed,
      ModelStatus.UpdatingPublishment,
      ModelStatus.CopyCompleted,
    ];

    const modelInPublishOrTest = testAndPublishStatuses.includes(models.data[id].modelStatus);
    if (modelInPublishOrTest && (isFullTraining || !filterDictionaryTrainings)) {
      filteredModelIds.push(id);
    }
  });
  return filteredModelIds;
};

export default filterTestedOrPublishedModels;
