import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';

import { useAppSelector } from '../../../utils/hooks';

const UploadHistoryCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const {
    uploadHistory: { jobs },
  } = useAppSelector((state) => state);

  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'uploadHistoryFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.uploadHistory.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(jobs),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.UploadHistoryFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
  ];

  return (
    <Fragment>
      <CommandBar ariaLabel={t('components.commandBars.aria')} farItems={rightCommandBarItems} items={[]} />
    </Fragment>
  );
};

export default UploadHistoryCommandBar;
