import { Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IPaginationResults from './PaginationResults.types';

const PaginationResults: React.FC<IPaginationResults> = ({ offset, limit, totalCount }) => {
  const { t } = useTranslation();

  return (
    <Text block as="p" styles={{ root: { marginBottom: 0 } }}>
      {t('components.pagination.results', {
        offset,
        limit: limit > totalCount ? totalCount : limit,
        totalCount,
      })}
    </Text>
  );
};

export default PaginationResults;
