import { Icon } from '@fluentui/react';
import React, { Fragment } from 'react';

import useBleuScoreStyles from './BleuScore.styles';
import { BleuScoreSize, IBleuScoreProps } from './BleuScore.types';

const BleuScore: React.FC<IBleuScoreProps> = ({
  bleuScore,
  baselineBleuScore,
  size = BleuScoreSize.Small,
  fullColor = true,
}) => {
  const classes = useBleuScoreStyles();

  let bleuScoreContent;

  if (bleuScore > baselineBleuScore) {
    bleuScoreContent = (
      <div className={fullColor ? classes.positiveText : ''}>
        <Icon iconName="StockUp" className={`${classes.icon} ${classes.positiveScore} ${size}`} />
        {bleuScore}
      </div>
    );
  } else {
    bleuScoreContent = (
      <div className={fullColor ? classes.negativeText : ''}>
        <Icon iconName="StockDown" className={`${classes.icon} ${classes.negativeScore} ${size}`} />
        {bleuScore}
      </div>
    );
  }
  return <Fragment>{bleuScoreContent}</Fragment>;
};

export default BleuScore;
