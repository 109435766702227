import { keys } from 'lodash';
import { useTranslation } from 'react-i18next';

import { IModelRegionStatusItemBase } from '../../redux/models/models.types';
import { useAppSelector } from './appRedux.helpers';

const useCreateRegionList = (currentRegions: IModelRegionStatusItemBase[] | null): string => {
  const { t } = useTranslation();
  const { modelRegions } = useAppSelector((state) => state);
  const modelRegionData = modelRegions.data;

  if (!currentRegions || !modelRegionData || keys(modelRegionData).length <= 0) {
    return '';
  }
  const currentlyDeployedRegions = currentRegions.filter((item) => item.isDeployed);

  const connectorPhrase = t('components.popups.model.unpublish.subTextAnd');
  let subtext = '';
  for (let i = 0; i < currentlyDeployedRegions.length; i += 1) {
    if (i === 0) {
      subtext = modelRegionData[currentlyDeployedRegions[i].region].regionName;
    } else if (i === currentlyDeployedRegions.length - 1) {
      subtext = `${subtext} ${connectorPhrase} ${modelRegionData[currentlyDeployedRegions[i].region].regionName}`;
    } else {
      subtext = `${subtext},  ${modelRegionData[currentlyDeployedRegions[i].region].regionName}`;
    }
  }

  return subtext;
};

const useCreateUnpublishSubtext = (
  currentRegions: IModelRegionStatusItemBase[] | null,
  subText1: string,
  subText2: string
): string => {
  const regions = useCreateRegionList(currentRegions);
  return `${subText1} ${regions}. ${subText2}`;
};

export { useCreateUnpublishSubtext, useCreateRegionList };
