/**
 * Possible roles that a user can have in the workspace.
 */
enum WorkspaceRoles {
  Owner = 'Owner',
  Editor = 'Editor',
  Reader = 'Reader',
}

export default WorkspaceRoles;
