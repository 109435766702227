enum EmptyTypes {
  Workspaces = 'workspaces',
  Projects = 'projects',
  Documents = 'documents',
  ManageDocuments = 'manage documents',
  Models = 'models',
  TestModel = 'test model',
  PublishModel = 'publish model',
}

export default EmptyTypes;
