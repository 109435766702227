import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAppDialogProps } from './AppDialog.types';

/**
 * Wrapper component for Fluent UI Dialog popup.
 * Unlike AppModal this is component should only be used
 * with a short description text and single confirmation action.
 */
const AppDialog: React.FC<IAppDialogProps> = ({
  hidden = true,
  toggleDialog,
  contentProps = undefined,
  modalProps,
  confirmationActionProps,
  showCancelButton = true,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog hidden={hidden} onDismiss={toggleDialog} dialogContentProps={contentProps} modalProps={modalProps}>
      <DialogFooter>
        {confirmationActionProps && (
          <PrimaryButton onClick={confirmationActionProps.onClick} text={confirmationActionProps.text} />
        )}
        {showCancelButton && <DefaultButton onClick={toggleDialog} text={t('components.popups.common.cancel')} />}
      </DialogFooter>
    </Dialog>
  );
};

export default AppDialog;
