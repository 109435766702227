import { useMsal } from '@azure/msal-react';
import { IStackTokens, PrimaryButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import heroImage from '../../assets/images/features/hero.jpg';
import useHeroStyles from './Hero.styles';

import { requestScopes } from '../../utils/authentication';

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const classes = useHeroStyles();
  const { instance } = useMsal();
  const contentStackTokens: IStackTokens = {
    childrenGap: 35,
  };
  return (
    <Stack className={classes.root}>
      <img className={classes.root} src={heroImage} alt={t('pages.signedOut.heroSection.imageAlt')} />
      <Stack className={classes.content} tokens={contentStackTokens}>
        <Stack.Item>
          <Text className={classes.title}>{t('pages.signedOut.heroSection.title')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text className={classes.subTitle}> {t('pages.signedOut.heroSection.description')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal>
            <PrimaryButton
              className={classes.button}
              href="https://www.microsoft.com/en-us/translator/business/customization/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('pages.signedOut.heroSection.button1Text')}
            </PrimaryButton>
            <PrimaryButton
              className={`${classes.button} signIn`}
              primary
              data-test-id="sign-in-button"
              onClick={(): Promise<void> => instance.loginRedirect(requestScopes)}
            >
              {t('pages.signedOut.heroSection.button2Text')}
            </PrimaryButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default Hero;
