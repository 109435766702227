import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import normalizeResponse from '../normalizeResponse';
import { categoryListSchema } from '../schema';
import {
  CategoriesDispatchTypes,
  GET_CATEGORIES,
  GET_CATEGORIES_FAILED,
  GET_CATEGORIES_SUCCESSFUL,
  ICategoryEntities,
} from './categories.types';

import ErrorCategories from '../../utils/constants/errorCategories';

const getCategories = () => async (
  dispatch: Dispatch<CategoriesDispatchTypes | ErrorDispatchTypes>
): Promise<Action | void> => {
  try {
    dispatch({ type: GET_CATEGORIES });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}categories`);
    const { entities } = await normalizeResponse<ICategoryEntities>(response.data, categoryListSchema);
    return dispatch({
      type: GET_CATEGORIES_SUCCESSFUL,
      payload: {
        data: entities.categories,
      },
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_CATEGORIES_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Categories,
        },
      });
    });
  }
};

export default getCategories;
