import { Stack, StackItem } from "@fluentui/react";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import styled from "styled-components";
import { initializeComponent, withLocalization } from "../../../services/localization";
import { PreviewTag } from "../Tag/PreviewTag";

initializeIcons(undefined, { disableWarnings: true });

export interface GuidingBannerProps {
  icon?: JSX.Element;
  title?: string;
  description: JSX.Element;
  buttons?: JSX.Element;
  isPreview?: boolean;
};

const StyledBanner = styled.div`
  background: rgb(243, 242, 241);
  padding: 20px;
  border-radius: 8px;

  @media only screen and (max-width: 480px) {
    padding:20px 10px; 
  }
  margin-top: 15px;
  margin-bottom: 40px;

  .title-wrap {
    flex-direction: row;
    .preview-tag {
      margin: 15px 0 0 7px;
    }
  }
`
const StyledBannerTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
`
const StyledBannerDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
`
const GuidingBannerInternal = (props: GuidingBannerProps) => {
  const { title, description, icon, buttons,isPreview } = props

  return (
    <StyledBanner>
      <Stack horizontal>
        {icon && <StackItem grow={0} style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>{icon}</StackItem>}
        <StackItem grow={4}>
          <Stack className="title-wrap">
            {title && <StyledBannerTitle>{title}</StyledBannerTitle>}
            {isPreview && <PreviewTag className="preview-tag" />}
          </Stack>
          <StyledBannerDescription>{description}</StyledBannerDescription>
          {buttons}
        </StackItem>
      </Stack>
    </StyledBanner>
  )
};

export const GuidingBannerArea = withLocalization(initializeComponent(GuidingBannerInternal));