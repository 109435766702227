import axios from 'axios';
import i18next from 'i18next';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { BillingRegionsDispatchTypes, SET_BILLING_REGIONS } from '../billingRegions/billingRegions.types';
import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import normalizeResponse from '../normalizeResponse';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import { RolesDispatchTypes, SET_ROLES } from '../roles/roles.types';
import { workspacesListSchema } from '../schema';
import { SET_SHARING, SharingDispatchTypes } from '../sharing/sharing.types';
import { TelemetryDispatchTypes } from '../telemetry/telemetry.types';
import { SET_USERS, UsersDispatchTypes } from '../users/users.types';
import {
  CLEAR_CURRENT_WORKSPACE,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_FAILED,
  DELETE_WORKSPACE_SUCCESSFUL,
  GET_WORKSPACES,
  GET_WORKSPACES_FAILED,
  GET_WORKSPACES_SUCCESSFUL,
  ICurrentWorkspaceItem,
  IWorkspacesEntities,
  RENAME_WORKSPACE,
  RENAME_WORKSPACE_FAILED,
  RENAME_WORKSPACE_SUCCESSFUL,
  SET_CURRENT_WORKSPACE,
  SET_DEFAULT_WORKSPACE,
  SET_DEFAULT_WORKSPACE_FAILED,
  SET_DEFAULT_WORKSPACE_SUCCESSFUL,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_COMPLETED,
  WorkspacesDispatchTypes,
} from './workspaces.types';

import ErrorCategories from '../../utils/constants/errorCategories';
import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';

export const getWorkspaces = () => async (
  dispatch: Dispatch<
    | BillingRegionsDispatchTypes
    | RolesDispatchTypes
    | SharingDispatchTypes
    | UsersDispatchTypes
    | WorkspacesDispatchTypes
    | ErrorDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: GET_WORKSPACES });

    let response;

    if (process.env.REACT_APP_ENV === 'test') {
      const { default: mockData } = await import('../../__test__/fixtures/workspaces.data.json');

      response = { data: mockData };
    } else {
      response = await axios.get(`${process.env.REACT_APP_API_URL}workspaces`);
    }

    const { entities, result } = await normalizeResponse<IWorkspacesEntities>(response.data, workspacesListSchema);

    return batch(() => {
      dispatch({
        type: SET_BILLING_REGIONS,
        payload: entities.billingRegions,
      });
      dispatch({
        type: SET_ROLES,
        payload: entities.roles,
      });
      dispatch({
        type: SET_SHARING,
        payload: entities.sharing,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: GET_WORKSPACES_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.workspaces,
        },
      });
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_WORKSPACES_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Workspaces,
        },
      });
    });
  }
};

export const setCurrentWorkspace = (currentWorkspace: ICurrentWorkspaceItem): WorkspacesDispatchTypes => {
  return {
    type: SET_CURRENT_WORKSPACE,
    payload: currentWorkspace,
  };
};

export const clearCurrentWorkspace = (): WorkspacesDispatchTypes => {
  return {
    type: CLEAR_CURRENT_WORKSPACE,
  };
};

export const deleteWorkspace = (workspaceId: string) => async (
  dispatch: Dispatch<
    | WorkspacesDispatchTypes
    | RolesDispatchTypes
    | SharingDispatchTypes
    | UsersDispatchTypes
    | PopUpErrorsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: DELETE_WORKSPACE });
    await axios.delete(`${process.env.REACT_APP_API_URL}workspaces/${workspaceId}`);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}workspaces`);

    const { entities, result } = await normalizeResponse<IWorkspacesEntities>(response.data, workspacesListSchema);

    return batch(() => {
      dispatch({
        type: SET_ROLES,
        payload: entities.roles,
      });
      dispatch({
        type: SET_SHARING,
        payload: entities.sharing,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: DELETE_WORKSPACE_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.workspaces,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-delete/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.workspaces.delete.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Workspaces,
      requestType: RequestTypes.Delete,
    });

    return batch(() => {
      dispatch({ type: DELETE_WORKSPACE_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-delete/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const renameWorkspace = (workspaceId: string, workspaceName: string) => async (
  dispatch: Dispatch<
    | WorkspacesDispatchTypes
    | RolesDispatchTypes
    | SharingDispatchTypes
    | UsersDispatchTypes
    | PopUpErrorsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: RENAME_WORKSPACE });
    await axios.put(`${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/name`, {
      name: workspaceName,
    });

    return batch(() => {
      dispatch({
        type: RENAME_WORKSPACE_SUCCESSFUL,
        payload: {
          workspaceId,
          workspaceName,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-rename/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.workspaces.rename.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Workspaces,
      requestType: RequestTypes.Rename,
    });

    return batch(() => {
      dispatch({ type: RENAME_WORKSPACE_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-rename/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const updateUserRole = (workspaceId: string, emailAddress: string, roleId: string, isNewUser = false) => async (
  dispatch: Dispatch<
    WorkspacesDispatchTypes | RolesDispatchTypes | SharingDispatchTypes | UsersDispatchTypes | PopUpErrorsDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: UPDATE_USER_ROLE, payload: emailAddress });
    const users = [{ emailAddress, roleId }];
    await axios.post(`${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/users`, users);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}workspaces`);
    const { entities, result } = await normalizeResponse<IWorkspacesEntities>(response.data, workspacesListSchema);

    return batch(() => {
      dispatch({
        type: UPDATE_USER_ROLE_COMPLETED,
        payload: emailAddress,
      });
      dispatch({
        type: SET_ROLES,
        payload: entities.roles,
      });
      dispatch({
        type: SET_SHARING,
        payload: entities.sharing,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: GET_WORKSPACES_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.workspaces,
        },
      });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${
        isNewUser
          ? i18next.t('components.popUpErrors.workspaces.addUser.title')
          : i18next.t('components.popUpErrors.workspaces.updateUser.title')
      }`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Workspaces,
      requestType: RequestTypes.UpdateSharing,
    });

    return batch(() => {
      dispatch({ type: UPDATE_USER_ROLE_COMPLETED, payload: emailAddress });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const deleteUserRole = (workspaceId: string, sharingId: string, emailAddress: string) => async (
  dispatch: Dispatch<
    WorkspacesDispatchTypes | RolesDispatchTypes | SharingDispatchTypes | UsersDispatchTypes | PopUpErrorsDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: UPDATE_USER_ROLE, payload: emailAddress });
    await axios.delete(`${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/users/${sharingId}`);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}workspaces`);
    const { entities, result } = await normalizeResponse<IWorkspacesEntities>(response.data, workspacesListSchema);

    return batch(() => {
      dispatch({
        type: UPDATE_USER_ROLE_COMPLETED,
        payload: emailAddress,
      });
      dispatch({
        type: SET_ROLES,
        payload: entities.roles,
      });
      dispatch({
        type: SET_SHARING,
        payload: entities.sharing,
      });
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: GET_WORKSPACES_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.workspaces,
        },
      });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.workspaces.deleteUser.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Workspaces,
      requestType: RequestTypes.UpdateSharing,
    });

    return batch(() => {
      dispatch({ type: UPDATE_USER_ROLE_COMPLETED, payload: emailAddress });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const setDefaultWorkspace = (workspaceId: string) => async (
  dispatch: Dispatch<WorkspacesDispatchTypes | PopUpErrorsDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: SET_DEFAULT_WORKSPACE });
    await axios.put(`${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/pin`);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}workspaces`);
    const { entities, result } = await normalizeResponse<IWorkspacesEntities>(response.data, workspacesListSchema);

    return dispatch({
      type: SET_DEFAULT_WORKSPACE_SUCCESSFUL,
      payload: {
        ids: result,
        data: entities.workspaces,
      },
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.workspaces.deleteUser.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Workspaces,
      requestType: RequestTypes.UpdateSharing,
    });

    return batch(() => {
      dispatch({ type: SET_DEFAULT_WORKSPACE_FAILED });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};
