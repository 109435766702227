import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { SelectionAction } from '../../components/Tables/ProjectDocumentsTable/ProjectDocumentsTable.types';
import { IBaseDocumentItem } from '../documents/documents.types';
import { IFilesState } from '../files/filesReducer';
import { PaginatedDocumentsSelectionDispatchTypes } from './paginatedDocumentsSelection.types';

const updatePaginatedDocumentsSelection = (
  document: IBaseDocumentItem,
  files: IFilesState,
  selectionAction: SelectionAction
) => (dispatch: Dispatch<PaginatedDocumentsSelectionDispatchTypes>): Action | void => {
  if (selectionAction === SelectionAction.Adding) {
    batch(() => {
      dispatch({ type: 'ADD_DOCUMENT_TO_SELECTION', payload: document });
      dispatch({ type: 'UPDATE_COUNT', payload: { document, files, isAdding: true } });
    });
  } else if (selectionAction === SelectionAction.Removing) {
    batch(() => {
      dispatch({ type: 'REMOVE_DOCUMENT_FROM_SELECTION', payload: Number(document.id) });
      dispatch({ type: 'UPDATE_COUNT', payload: { document, files, isAdding: false } });
    });
  } else if (selectionAction === SelectionAction.Replacing) {
    batch(() => {
      dispatch({ type: 'CLEAR_PAGINATED_DOCUMENTS_SELECTION' });
      dispatch({ type: 'ADD_DOCUMENT_TO_SELECTION', payload: document });
    });
  }
};

export default updatePaginatedDocumentsSelection;
