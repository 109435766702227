import { merge } from 'lodash';

import {
  ADD_UPLOAD_NOTIFICATION,
  CLEAR_ALL_UPLOAD_NOTIFICATIONS,
  CLEAR_UPLOAD_NOTIFICATION,
  IUploadNotificationItem,
  UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION,
  UPDATE_JOBID_UPLOAD_NOTIFICATION,
  UPDATE_UPLOAD_NOTIFICATION,
  UploadNotificationsDispatchTypes,
} from './uploadNotifications.types';

import { UploadNotificationStatus } from '../../utils/constants/notifications';

export interface IUploadNotificationsState {
  data: {
    [key: string]: IUploadNotificationItem;
  };
}

const initialState: IUploadNotificationsState = { data: {} };

const filterNotificationData = (
  id: string,
  state: {
    [key: string]: IUploadNotificationItem;
  }
): { [key: string]: IUploadNotificationItem } => {
  const notificationData = Object.entries(state);

  const filterNotificationDataArray = notificationData.filter(([key]) => {
    return key !== id;
  });

  return Object.fromEntries(filterNotificationDataArray);
};

const uploadNotificationsReducer = (
  state: IUploadNotificationsState = initialState,
  action: UploadNotificationsDispatchTypes
): IUploadNotificationsState => {
  switch (action.type) {
    case ADD_UPLOAD_NOTIFICATION: {
      const filteredData = filterNotificationData(action.payload.id, state.data);
      const newNotification = {
        [action.payload.id]: {
          id: action.payload.id,
          status: UploadNotificationStatus.InProgress,
          name: action.payload.data.name,
          workspaceId: action.payload.data.workspaceId,
          isZip: action.payload.data.isZip,
        },
      };
      return {
        ...state,
        data: merge(filteredData, newNotification),
      };
    }
    case UPDATE_UPLOAD_NOTIFICATION: {
      const { id } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            status: action.payload.data.status,
            errorMessage: action.payload.data.errorMessage,
          },
        },
      };
    }
    case UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION: {
      const jobId = action.payload.id;
      const fileId = action.payload.data.id;

      return {
        ...state,
        data: {
          ...state.data,
          [jobId]: {
            ...state.data[jobId],
            files: {
              ...state.data[jobId].files,
              [fileId]: {
                ...action.payload.data,
              },
            },
          },
        },
      };
    }
    case UPDATE_JOBID_UPLOAD_NOTIFICATION: {
      const { oldId } = action.payload.data;
      const currentNotification = state.data[oldId];
      const filteredData = filterNotificationData(oldId, state.data);
      const newNotification = {
        [action.payload.id]: {
          ...currentNotification,
          id: action.payload.id,
        },
      };
      return {
        ...state,
        data: merge(filteredData, newNotification),
      };
    }
    case CLEAR_UPLOAD_NOTIFICATION: {
      return {
        ...state,
        data: filterNotificationData(action.payload, state.data),
      };
    }
    case CLEAR_ALL_UPLOAD_NOTIFICATIONS: {
      return initialState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default uploadNotificationsReducer;
