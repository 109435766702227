import { FontWeights, makeStyles } from '@fluentui/react';

const useProfileCardStyles = makeStyles(() => ({
  profileCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-end',
    justifyContent: 'top',
  },
  buttonStack: {
    width: 'inherit',
    padding: '10px 0',
  },
  signOutText: {
    alignSelf: 'center',
    fontWeight: FontWeights.regular,
  },
  signOutButton: {
    padding: '0',
    border: 'none',
  },
  personaText: {
    fontSize: '18px',
    fontWeight: '700',
  },
  persona: {
    margin: '0 0 20px 20px',
  },
}));

export default useProfileCardStyles;
