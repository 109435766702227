import { merge, union } from 'lodash';

import {
  CLEAR_MODELS,
  CREATE_MODEL,
  CREATE_MODEL_FAILED,
  CREATE_MODEL_SUCCESSFUL,
  DELETE_MODEL,
  DELETE_MODEL_FAILED,
  DELETE_MODEL_SUCCESSFUL,
  DUPLICATE_MODEL,
  DUPLICATE_MODEL_FAILED,
  DUPLICATE_MODEL_SUCCESSFUL,
  FILTER_MODELS,
  GET_MODEL,
  GET_MODELS,
  GET_MODELS_FAILED,
  GET_MODELS_SUCCESSFUL,
  GET_MODEL_FAILED,
  GET_MODEL_SUCCESSFUL,
  IModelItem,
  MODEL_COPY,
  MODEL_COPY_FAILED,
  MODEL_COPY_SUCCESSFUL,
  ModelsDispatchTypes,
  RENAME_MODEL,
  RENAME_MODEL_FAILED,
  RENAME_MODEL_SUCCESSFUL,
  SET_TESTED_OR_PUBLISHED_MODEL_IDS,
  TRAIN_MODEL,
  TRAIN_MODEL_FAILED,
  TRAIN_MODEL_SUCCESSFUL,
  UPDATE_DRAFT_MODEL,
  UPDATE_DRAFT_MODEL_FAILED,
  UPDATE_DRAFT_MODEL_SUCCESSFUL,
  UPDATE_MODEL_PUBLISH_STATUS,
  UPDATE_MODEL_PUBLISH_STATUS_FAILED,
  UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL,
  UPDATE_MODEL_TRAINING_STATUS,
} from './models.types';

import { ModelStatus } from '../../utils/constants/modelStatus';

interface IModelsState {
  isLoading: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isDuplicating: boolean;
  isCopying: boolean;
  ids: string[];
  testedOrPublishedModelsIds: string[];
  data: {
    [key: string]: IModelItem;
  };
}

const initialState: IModelsState = {
  isLoading: false,
  isUpdating: false,
  isCreating: false,
  isDuplicating: false,
  isCopying: false,
  ids: [],
  testedOrPublishedModelsIds: [],
  data: {},
};

const modelsReducer = (state: IModelsState = initialState, action: ModelsDispatchTypes): IModelsState => {
  switch (action.type) {
    case CLEAR_MODELS: {
      return {
        isLoading: initialState.isLoading,
        isUpdating: initialState.isUpdating,
        isCreating: initialState.isCreating,
        isDuplicating: initialState.isDuplicating,
        isCopying: initialState.isCopying,
        ids: initialState.ids,
        testedOrPublishedModelsIds: initialState.testedOrPublishedModelsIds,
        data: initialState.data,
      };
    }
    case TRAIN_MODEL: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case DUPLICATE_MODEL: {
      return {
        ...state,
        isDuplicating: true,
      };
    }
    case CREATE_MODEL: {
      return {
        ...state,
        isCreating: true,
        ids: initialState.ids,
        data: initialState.data,
      };
    }
    case GET_MODELS: {
      return {
        ...state,
        isLoading: true,
        ids: initialState.ids,
        data: initialState.data,
      };
    }
    case GET_MODEL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_MODEL_PUBLISH_STATUS:
    case UPDATE_DRAFT_MODEL:
    case RENAME_MODEL:
    case DELETE_MODEL: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case TRAIN_MODEL_SUCCESSFUL: {
      return {
        ...state,
        isCreating: false,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload],
            modelStatus: ModelStatus.TrainingSubmitted,
          },
        },
      };
    }
    case DUPLICATE_MODEL_SUCCESSFUL:
    case CREATE_MODEL_SUCCESSFUL:
    case GET_MODEL_SUCCESSFUL: {
      const ids = state.ids.length === 0 ? action.payload.ids : union(state.ids, action.payload.ids);
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        isDuplicating: false,
        ids,
        data: merge(state.data, action.payload.data),
      };
    }
    case GET_MODELS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        isDuplicating: false,
        ids: action.payload.ids,
        data: action.payload.data,
      };
    }
    case UPDATE_DRAFT_MODEL_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            name: action.payload.data.name,
            documents: action.payload.data.documents,
          },
        },
      };
    }
    case RENAME_MODEL_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            name: action.payload.data.name,
          },
        },
      };
    }
    case DELETE_MODEL_SUCCESSFUL: {
      const selectedModelId = action.payload;
      const filterModelData = (): { [key: string]: IModelItem } => {
        const ModelDataArray = Object.entries(state.data);

        const filterModelDataArray = ModelDataArray.filter(([key]) => {
          return key !== selectedModelId;
        });

        return Object.fromEntries(filterModelDataArray);
      };

      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.payload),
        data: filterModelData(),
        isUpdating: false,
      };
    }
    case GET_MODELS_FAILED:
    case GET_MODEL_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DUPLICATE_MODEL_FAILED:
    case TRAIN_MODEL_FAILED:
    case CREATE_MODEL_FAILED: {
      return {
        ...state,
        isCreating: false,
        isDuplicating: false,
      };
    }
    case UPDATE_DRAFT_MODEL_FAILED:
    case RENAME_MODEL_FAILED:
    case DELETE_MODEL_FAILED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL: {
      return {
        ...state,
        isUpdating: false,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            modelStatus: action.payload.data.modelStatus,
            modelRegionStatuses: action.payload.data.modelRegionStatuses,
          },
        },
      };
    }
    case UPDATE_MODEL_PUBLISH_STATUS_FAILED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case UPDATE_MODEL_TRAINING_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            modelStatus: action.payload.data.modelStatus,
            errorCode: action.payload.data.errorCode,
          },
        },
      };
    }
    case FILTER_MODELS: {
      return {
        ...state,
        ids: action.payload,
      };
    }
    case SET_TESTED_OR_PUBLISHED_MODEL_IDS: {
      return {
        ...state,
        testedOrPublishedModelsIds: action.payload,
      };
    }
    case MODEL_COPY:
      return {
        ...state,
        isCopying: true,
      };
    case MODEL_COPY_SUCCESSFUL:
    case MODEL_COPY_FAILED: {
      return {
        ...state,
        isCopying: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default modelsReducer;
