interface IProjectLink {
  icon: string;
  /** Display text */
  title: string;
  translationKey: string;
  partialPathName: string;
  /** Determine whether to use React Router's NavLink exact property */
  isExact: boolean;
}

/** Array of appSidebar project links */
const getProjectLinks = (): IProjectLink[] => {
  const projectLinks = [
    {
      icon: 'CRMArticles',
      title: 'Manage documents',
      translationKey: 'manageDocuments',
      partialPathName: 'manage-documents',
      isExact: false,
    },
    {
      icon: 'MachineLearning',
      title: 'Train model',
      translationKey: 'trainModel',
      partialPathName: 'train-model',
      isExact: false,
    },
    {
      icon: 'Product',
      title: 'Model details',
      translationKey: 'modelDetails',
      partialPathName: 'models',
      isExact: false,
    },
    {
      icon: 'TestBeaker',
      title: 'Test models',
      translationKey: 'testModel',
      partialPathName: 'test',
      isExact: false,
    },
    {
      icon: 'Deploy',
      title: 'Publish model',
      translationKey: 'publishModel',
      partialPathName: 'publish',
      isExact: true,
    },
  ];

  return projectLinks;
};

export default getProjectLinks;
