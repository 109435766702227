import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import emptyBeeFolder from '../../../assets/images/features/BeeFolder.svg';
import emptyMagnifyingGlass from '../../../assets/images/features/MagnifyingGlass.svg';
import emptyDocumentSets from '../../../assets/images/features/NoDocuments.svg';
import useEmptyStyles from './Empty.styles';
import IEmptyProps from './Empty.types';

import EmptyTypes from '../../../utils/constants/emptyTypes';

const Empty: React.FC<IEmptyProps> = ({ type, title }) => {
  const classes = useEmptyStyles();
  const { t } = useTranslation();
  let auxTitle;
  let subText;
  let imageAlt;
  let imageSrc;

  switch (type) {
    case EmptyTypes.Documents: {
      auxTitle = t('pages.empty.documents.title');
      subText = t('pages.empty.documents.subText');
      imageSrc = emptyDocumentSets;
      imageAlt = t('pages.empty.documents.imageAlt');
      break;
    }
    case EmptyTypes.ManageDocuments: {
      auxTitle = t('pages.empty.manageDocuments.title');
      subText = t('pages.empty.manageDocuments.subText');
      imageSrc = emptyDocumentSets;
      imageAlt = t('pages.empty.documents.imageAlt');
      break;
    }
    case EmptyTypes.Models: {
      auxTitle = t('pages.empty.models.title');
      subText = t('pages.empty.models.subText');
      imageSrc = emptyMagnifyingGlass;
      imageAlt = t('pages.empty.models.imageAlt');
      break;
    }
    case EmptyTypes.TestModel: {
      auxTitle = t('pages.empty.models.title');
      subText = t('pages.empty.testModel.subText');
      imageSrc = emptyMagnifyingGlass;
      imageAlt = t('pages.empty.models.imageAlt');
      break;
    }
    case EmptyTypes.PublishModel: {
      auxTitle = t('pages.empty.models.title');
      subText = t('pages.empty.publishModel.subText');
      imageSrc = emptyMagnifyingGlass;
      imageAlt = t('pages.empty.models.imageAlt');
      break;
    }
    case EmptyTypes.Projects: {
      auxTitle = t('pages.empty.projects.title');
      subText = t('pages.empty.projects.subText');
      imageSrc = emptyBeeFolder;
      imageAlt = t('pages.empty.projects.imageAlt');
      break;
    }
    case EmptyTypes.Workspaces: {
      auxTitle = t('pages.empty.workspaces.title');
      subText = t('pages.empty.workspaces.subText');
      imageSrc = emptyBeeFolder;
      imageAlt = t('pages.empty.workspaces.imageAlt');
      break;
    }
    default: {
      auxTitle = title;
      subText = '';
      imageAlt = '';
      imageSrc = null;
    }
  }
  return (
    <Stack horizontalAlign="center" className={classes.root}>
      {imageSrc && (
        <Stack.Item>
          <img src={imageSrc} alt={imageAlt} />
        </Stack.Item>
      )}
      {(title || auxTitle) && (
        <Stack.Item>
          <Text as="h3" variant="mediumPlus" className={classes.title} block>
            {auxTitle}
          </Text>
        </Stack.Item>
      )}
      {subText && (
        <Stack.Item className={classes.subTextContainer}>
          <Text as="p" className={classes.subText} block>
            {subText}
          </Text>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default Empty;
