import { makeStyles } from '@fluentui/react';

import components from '../../utils/constants/components';

const useAppLayoutStyles = makeStyles(() => ({
  root: {
    height: `calc(100% - ${components.AppBar.height}px)`,
    '@media (min-height: 450px)': {
      '&.has-sticky-footer': {
        height: `calc(100% - ${components.AppBar.height}px - ${components.PaginationFooter.height}px)`,
      },
    },
  },
  content: {
    height: '100%',
    width: `calc(100% - ${components.AppSidebar.expandedWidth}px)`,
    overflow: 'auto',
    '&.full-width': {
      width: '100%',
    },
  },
}));

export default useAppLayoutStyles;
