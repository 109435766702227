import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error, Loading } from '../components/GenericStates';
import initialLoadContext from '../components/InitialLoad/InitialLoad.context';
import { WorkspacesContent } from '../components/PageContents';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import { useAppDispatch, useAppSelector } from '../utils/hooks';

const WorkspacesPage: React.FC = () => {
  useTrackMetric(reactPlugin, 'WorkspacesPage');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    workspaces: { ids, isLoading, data, isDeleting },
    error,
  } = useAppSelector((state) => state);
  const { isInitialLoad } = React.useContext(initialLoadContext);

  // Fetch workspaces on load
  useEffect(() => {
    dispatch(getWorkspaces());
  }, [dispatch]);

  let content;
  let defaultId;

  if (isDeleting) {
    content = <Loading loadingText={t('pages.workspaces.deletingWorkspace')} isFullHeight />;
  } else if (isLoading) {
    content = <Loading loadingText={t('pages.workspaces.loadingWorkspaces')} isFullHeight />;
  } else if (!isEmpty(error.message) && error.category && ErrorCategories.Workspaces) {
    content = <Error />;
  } else if (!isLoading) {
    defaultId = ids.find((id) => data[id].isDefaultWorkspace === true);
    if (isInitialLoad === true && (ids.length === 1 || defaultId !== undefined)) {
      defaultId = defaultId ?? ids[0];
      content = <Redirect to={`/workspaces/${defaultId}/projects`} />;
    } else {
      content = <WorkspacesContent />;
    }
  }
  // content = <Loading loadingText={t('pages.workspaces.loadingWorkspaces')} isFullHeight />;
  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('pages.workspaces.headSection.title')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default WorkspacesPage;
