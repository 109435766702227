import { makeStyles } from '@fluentui/react';

const useTrainingStatusStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    position: 'relative',
    top: 2,
    marginRight: 5,
  },
  successIcon: {
    color: theme.palette.green,
  },
  failedIcon: {
    color: theme.palette.red,
  },
}));

export default useTrainingStatusStyles;
