import { CommandBar, ICommandBarItemProps, registerIcons } from '@fluentui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { deleteModel } from '../../../redux/models/modelsActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import { RenameModelForm } from '../../Forms';
import CopyModelForm from '../../Forms/CopyModelForm';
import DuplicateModelForm from '../../Forms/DuplicateModelForm';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import { ModelsPopUpTypes } from '../../../utils/constants/popUps.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useIsReader,
} from '../../../utils/hooks';

registerIcons({
  icons: {
    ColumnArrowRight: (
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 1H3C3.55228 1 4 1.44772 4 2V6H5V2C5 0.895431 4.10457 0 3 0H2C0.895431 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H3C4.10457 15 5 14.1046 5 13V9H4V13C4 13.5523 3.55228 14 3 14H2C1.44772 14 1 13.5523 1 13V2C1 1.44772 1.44772 1 2 1ZM13 0C11.8954 0 11 0.89543 11 2V13C11 14.1046 11.8954 15 13 15H14C15.1046 15 16 14.1046 16 13V2C16 0.895431 15.1046 0 14 0H13ZM14 1C14.5523 1 15 1.44772 15 2V13C15 13.5523 14.5523 14 14 14H13C12.4477 14 12 13.5523 12 13V2C12 1.44772 12.4477 1 13 1H14ZM7.14645 5.14645C7.34171 4.95118 7.65829 4.95118 7.85355 5.14645L9.85355 7.14645C10.0488 7.34171 10.0488 7.65829 9.85355 7.85355L7.85355 9.85355C7.65829 10.0488 7.34171 10.0488 7.14645 9.85355C6.95118 9.65829 6.95118 9.34171 7.14645 9.14645L8.29289 8L3.5 8C3.22386 8 3 7.77614 3 7.5C3 7.22386 3.22386 7 3.5 7L8.29289 7L7.14645 5.85355C6.95118 5.65829 6.95118 5.34171 7.14645 5.14645Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
});

const ModelsCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);

  const {
    entitySelections: { selectedModel },
    models,
  } = useAppSelector((state) => state);
  const { appDialog, setAppDialog } = useAppDialog();
  const { appModal, setAppModal } = useAppModal();

  const isDisabledWhenModelsLoading = models.isLoading;
  const isReader = useIsReader();
  const unableToDeleteStatuses: string[] = [
    ModelStatus.Published,
    ModelStatus.Publishing,
    ModelStatus.Unpublishing,
    ModelStatus.TrainingRunning,
    ModelStatus.TrainingQueued,
    ModelStatus.TrainingSubmitted,
    ModelStatus.DataProcessing,
  ];
  let canModelBeDeleted = false;
  let isSelectedModelCopied = false;
  if (selectedModel) {
    canModelBeDeleted = !unableToDeleteStatuses.includes(models.data[selectedModel].modelStatus);
    isSelectedModelCopied = models.data[selectedModel].isCopied;
  }

  const canModelBeCopied = true; // TODO: Handle if model can be copied logic

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'duplicate',
      text: t('components.commandBars.common.duplicate'),
      iconProps: { iconName: 'Copy' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenModelsLoading || !selectedModel || isSelectedModelCopied,
      onClick: (): void => {
        setAppModal({ contentType: ModelsPopUpTypes.Duplicate, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-duplicate/open' });
      },
    },
    {
      key: 'rename',
      text: t('components.commandBars.common.rename'),
      iconProps: { iconName: 'Rename' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenModelsLoading || !selectedModel,
      onClick: (): void => {
        setAppModal({ contentType: ModelsPopUpTypes.Edit, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-rename/open' });
      },
    },
    {
      key: 'delete',
      text: t('components.commandBars.models.deleteButton.text'),
      iconProps: { iconName: 'Delete' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenModelsLoading || !selectedModel,
      onClick: (): void => {
        if (canModelBeDeleted) {
          setAppDialog({ contentType: ModelsPopUpTypes.Delete, isHidden: false });
          appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-delete/open' });
        } else {
          setAppDialog({ contentType: ModelsPopUpTypes.UnableToDelete, isHidden: false, showCancelButton: false });
        }
      },
    },
    {
      key: 'copy',
      text: t('components.commandBars.models.copyButton.text'),
      iconProps: { iconName: 'ColumnArrowRight' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenModelsLoading || !selectedModel || isSelectedModelCopied,
      onClick: (): void => {
        if (canModelBeCopied) {
          setAppModal({
            contentType: ModelsPopUpTypes.Copy,
            isOpen: true,
          });
        } else {
          setAppModal({
            contentType: ModelsPopUpTypes.UnableToCopy,
            isOpen: true,
          });
        }
      },
    },
    {
      key: 'modelDetails',
      text: 'Show details',
      iconProps: { iconName: 'Info' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenModelsLoading || !selectedModel,
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.Details);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
  ];

  let message = t('components.commandBars.common.updating');
  if (models.isDuplicating) {
    message = t('components.commandBars.common.duplicating');
  }
  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader isVisible={models.isUpdating || models.isDuplicating} message={message} />
      ),
    },
    {
      key: 'modelFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.models.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(models.data),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.ModelsFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
    /** TODO: Model details panel https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119002
    {
      key: 'moreInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    }, */
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [ModelsPopUpTypes.Delete]: {
      contentProps: {
        title: t('components.popups.model.delete.title'),
        subText: t('components.popups.model.delete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.model.delete.action'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(deleteModel(selectedModel));
          });
        },
      },
    },
    [ModelsPopUpTypes.UnableToDelete]: {
      contentProps: {
        title: t('components.popups.model.unableToDelete.title'),
        subText: t('components.popups.model.unableToDelete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.dismiss'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const appModalContentMap: AppModalContentMap = {
    [ModelsPopUpTypes.Edit]: {
      contentProps: {
        title: t('components.popups.model.rename.title'),
        body: <RenameModelForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
    },
    [ModelsPopUpTypes.Duplicate]: {
      contentProps: {
        title: t('components.popups.model.duplicate.title'),
        body: <DuplicateModelForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
    },
    [ModelsPopUpTypes.Copy]: {
      contentProps: {
        title: t('components.popups.model.copy.title'),
        body: <CopyModelForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
    },
  };

  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
      <AppDialog
        showCancelButton={appDialog.showCancelButton}
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={500}
      />
    </Fragment>
  );
};

export default ModelsCommandBar;
