import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Label,
  PrimaryButton,
  SearchBox,
  Stack,
  useTheme,
} from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { clearFilters } from '../../../redux/filters/filtersActions';
import { useSharedFilterFormStyles } from '../Shared';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import filterModels from '../../../utils/filterModels';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const FilterModelsForm: React.FC = () => {
  const theme = useTheme();
  const classes = useSharedFilterFormStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { models, filters } = useAppSelector((state) => state);
  const [modelSearchTerm, setModelSearchTerm] = useState<string>(filters.models.name);

  const [selectedCategoryKeys, setSelectedCategoryKeys] = useState<string[]>(filters.models.categoryNames);

  const hasFilterOptions = !isEmpty(modelSearchTerm) || !isEmpty(selectedCategoryKeys);
  const [statusOptions, setStatusOptions] = useState<IDropdownOption[]>([]);
  // Dropdown population values
  const tempStatusOptions: IDropdownOption[] = [];
  const uniqueModelStatuses = new Set<string>();

  useEffect(() => {
    models.ids.forEach((id) => uniqueModelStatuses.add(models.data[id].modelStatus));
    uniqueModelStatuses.forEach((status) => {
      const unusedStatuses: string[] = [ModelStatus.Unknown, ModelStatus.HubPublished, ModelStatus.MigratedDraft];
      if (!unusedStatuses.includes(status)) {
        tempStatusOptions.push({
          key: status,
          text: status,
        });
      }
    });
    setStatusOptions(tempStatusOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModelSearchTermChange = (event?: React.FormEvent<HTMLInputElement>): void => {
    const searchTerm = (event?.target as HTMLInputElement)?.value || '';

    setModelSearchTerm(searchTerm);
  };

  const onCategoryChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedCategoryKeys(
        item.selected
          ? [...selectedCategoryKeys, item.key as string]
          : selectedCategoryKeys.filter((key: string) => key !== item.key)
      );
    }
  };

  const handleClearAll = (): void => {
    batch(() => {
      setModelSearchTerm('');
      setSelectedCategoryKeys([]);
    });
    appDispatch(clearFilters());
    appDispatch({ type: 'FILTER_MODELS', payload: filterModels(models, [], '') });
  };

  const handleClearStatusSelection = (): void => {
    setSelectedCategoryKeys([]);
    appDispatch({ type: 'FILTER_MODELS', payload: filterModels(models, [], modelSearchTerm) });
    appDispatch({
      type: 'SET_MODEL_FILTER_CATEGORY_NAMES',
      payload: {
        categoryNames: [],
        categoryNamesLabel: t('components.forms.filterModels.statusDropdown'),
      },
    });
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const categoryNames: string[] = [];
    if (!isEmpty(selectedCategoryKeys)) {
      selectedCategoryKeys.forEach((categoryKey) => categoryNames.push(categoryKey));
    }
    appDispatch({
      type: 'SET_MODEL_FILTER_NAME',
      payload: {
        name: modelSearchTerm,
        nameLabel: t('components.forms.filterModels.modelInput.label'),
      },
    });
    appDispatch({
      type: 'SET_MODEL_FILTER_CATEGORY_NAMES',
      payload: {
        categoryNames,
        categoryNamesLabel: t('components.forms.filterModels.statusDropdown'),
      },
    });
    appDispatch({ type: 'FILTER_MODELS', payload: filterModels(models, categoryNames, modelSearchTerm) });
  };

  const bodyStackTokens: IStackTokens = { childrenGap: theme.spacing.l2 };
  const actionButtonStackTokens: IStackTokens = { childrenGap: theme.spacing.s1 };

  return (
    <Stack as="form" className={classes.root} onSubmit={handleSubmit} verticalAlign="space-between">
      <Stack className={classes.body} tokens={bodyStackTokens}>
        <Stack.Item>
          <Label htmlFor="model-search" styles={{ root: { marginBottom: 4 } }}>
            {t('components.forms.filterModels.modelInput.label')}
          </Label>
          <SearchBox
            id="model-search"
            name="model-search"
            placeholder={t('components.forms.filterCommon.searchPlaceholder')}
            value={modelSearchTerm}
            onChange={(e): void => onModelSearchTermChange(e)}
            onClear={(): void => setModelSearchTerm('')}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            placeholder={t('components.forms.filterModels.dropdownPlaceholder')}
            label={t('components.forms.filterModels.statusDropdown')}
            options={statusOptions}
            selectedKeys={selectedCategoryKeys}
            onChange={onCategoryChange}
            multiSelect
            disabled={!statusOptions}
            className={classes.dropdown}
          />
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clear')}
            onClick={(): void => handleClearStatusSelection()}
            disabled={isEmpty(selectedCategoryKeys)}
            className="as-link"
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <Stack.Item>
          <Stack as="footer" horizontal tokens={actionButtonStackTokens}>
            <Stack.Item>
              <DefaultButton
                text={t('components.forms.filterCommon.actionButtons.clearAll')}
                onClick={(): void => handleClearAll()}
                disabled={!hasFilterOptions}
              />
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton text={t('components.forms.filterCommon.actionButtons.apply')} type="submit" />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default FilterModelsForm;
