import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { deleteProject } from '../../../redux/projects/projectsActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import { CreateProjectForm, EditProjectForm } from '../../Forms';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { ProjectPopUpTypes } from '../../../utils/constants/popUps.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useIsReader,
} from '../../../utils/hooks';

const ProjectsCommandBar: React.FC = () => {
  const appDispatch = useAppDispatch();
  const { appDialog, setAppDialog } = useAppDialog();
  const { appModal, setAppModal } = useAppModal();
  const { t } = useTranslation();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const {
    entitySelections: { selectedProject },
    projects,
  } = useAppSelector((state) => state);

  const isDisabledWhenProjectsLoading = projects.isLoading;
  const isReader = useIsReader();
  const noSelection = !selectedProject;

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'projectCreateNewProject',
      text: t('components.commandBars.projects.createNewButton.text'),
      ariaLabel: t('components.commandBars.projects.createNewButton.aria'),
      iconProps: { iconName: 'Add' },
      className: clsx(isReader && 'no-display'),
      onClick: (): void => {
        setAppModal({ contentType: ProjectPopUpTypes.Create, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-create/open' });
      },
    },
    {
      key: 'projectCopyCategoryId',
      text: t('components.commandBars.projects.copyButton.text'),
      iconProps: { iconName: 'Copy' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenProjectsLoading || noSelection,
      onClick: (): void => {
        const categoryId = projects.data[selectedProject].apiDomain;

        navigator.clipboard.writeText(categoryId);
      },
    },
    {
      key: 'projectEdit',
      text: t('components.commandBars.common.edit'),
      ariaLabel: t('components.commandBars.projects.editButton.aria'),
      iconProps: { iconName: 'Edit' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenProjectsLoading || noSelection,
      onClick: (): void => {
        setAppModal({ contentType: ProjectPopUpTypes.Edit, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-edit/open' });
      },
    },
    {
      key: 'projectDelete',
      text: t('components.commandBars.common.delete'),
      ariaLabel: t('components.commandBars.projects.deleteButton.aria'),
      iconProps: { iconName: 'Delete' },
      className: clsx(isReader && 'no-display'),
      disabled: isDisabledWhenProjectsLoading || noSelection,
      onClick: (): void => {
        setAppDialog({ contentType: ProjectPopUpTypes.Delete, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-delete/open' });
      },
    },
  ];

  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => <CommandBarLoader isVisible={projects.isUpdating} />,
    },
    {
      key: 'projectFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.projects.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(projects.data),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.ProjectsFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
    /** TODO: Project details panel 
     https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119000 
    {
      key: 'projectInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    }, */
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [ProjectPopUpTypes.Delete]: {
      contentProps: {
        title: t('components.popups.project.delete.title'),
        subText: t('components.popups.project.delete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.project.delete.action'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(deleteProject(selectedProject));
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const appModalContentMap: AppModalContentMap = {
    [ProjectPopUpTypes.Create]: {
      contentProps: {
        title: t('components.popups.project.create.title'),
        body: <CreateProjectForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 750,
    },
    [ProjectPopUpTypes.Edit]: {
      contentProps: {
        title: t('components.popups.project.edit.title'),
        body: <EditProjectForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 600,
    },
  };

  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={appModalContent?.maxWidth || 700}
      />
    </Fragment>
  );
};

export default ProjectsCommandBar;
