import { makeStyles } from '@fluentui/react';

const useBleuScoreStyles = makeStyles((theme) => ({
  positiveText: {
    color: theme.palette.green,
  },
  negativeText: {
    color: theme.palette.red,
  },
  icon: {
    position: 'relative',
    top: 1,
  },
  positiveScore: {
    color: theme.palette.green,
    selectors: {
      '&.large': {
        top: 6,
      },
    },
  },
  negativeScore: {
    color: theme.palette.red,
    selectors: {
      '&.large': {
        top: 4,
      },
    },
  },
}));

export default useBleuScoreStyles;
