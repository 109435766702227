import { SharedColors, makeStyles } from '@fluentui/react';

const useNameGroupCellStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    selectors: {
      'a, .noLinkName': {
        display: 'flex',
        fontSize: 14,
        overflow: 'hidden',
      },
    },
  },
  nameGroup: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.5rem',
  },
  underlineName: {
    color: SharedColors.cyanBlue10,
    textDecoration: 'underline',
  },
  nameGroupIcon: {
    marginRight: 10,
    color: theme.palette.themePrimary,
    fontSize: 16,
  },
  nameGroupText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useNameGroupCellStyles;
