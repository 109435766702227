import { TextField } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { renameModel } from '../../../redux/models/modelsActions';
import FormActions from '../Shared/FormActions';
import { IRenameModelFormProps, IRenameModelFormValues } from './RenameModelForm.types';

import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/appRedux.helpers';

const RenameModelForm: React.FC<IRenameModelFormProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const { entitySelections, models } = useAppSelector((state) => state);
  const selectedModel = models.data[entitySelections.selectedModel] || null;
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState(
    (): IRenameModelFormValues => {
      const initialState = {
        modelName: selectedModel.name || '',
      };
      return initialState;
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    modelName: undefined,
  });

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
      const inputName = (event.target as HTMLInputElement).name;

      if (!newName || newName?.length <= FormValidationLengths.MAX_MODEL_NAME_LENGTH) {
        setFormValues({ ...formValues, [inputName]: newName || '' });
      }
      if (!newName) {
        setErrors({ ...errors, [inputName]: t('components.forms.generalErrors.required') });
      } else {
        setErrors({ ...errors, [inputName]: undefined });
      }
    },
    [formValues, errors, t]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    if (formValues.modelName.length === 0) {
      setErrors({ ...errors, modelName: t('components.forms.generalErrors.required') });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-rename/inline-errors' });
    } else {
      handleCancelAndClose();
      dispatch(renameModel(formValues, entitySelections.selectedModel));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label={t('components.forms.renameModel.modelName')}
        name="modelName"
        onChange={handleChange}
        errorMessage={errors?.modelName}
        value={formValues.modelName}
        required
      />
      <FormActions
        primaryButtonText={t('components.popups.common.rename')}
        isDisabled={!isEmpty(Object.values(errors).filter((value) => value))}
        handleCancelAndClose={handleCancelAndClose}
        onCancelTelemetryMessage="model-rename/cancel"
      />
    </form>
  );
};

export default RenameModelForm;
