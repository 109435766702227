import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Error } from '../components/GenericStates';
import UploadHistoryContent from '../components/PageContents/UploadHistoryContent';
import getUploadHistoryContent from '../redux/uploadHistoryContent/uploadHistoryContentActions';

import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../utils/hooks';

const UploadHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, uploadHistoryId } = useParams<IRoutePathParams>();
  const { uploadHistoryContent } = useAppSelector((state) => state);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  useEffect(() => {
    if (
      (uploadHistoryContent.data === null && !uploadHistoryContent.isLoading) ||
      (initialized === false && !uploadHistoryContent.isLoading)
    ) {
      if (!uploadHistoryContent.failed) {
        dispatch(
          getUploadHistoryContent({
            workspaceId,
            jobId: uploadHistoryId,
          })
        );
      }
    }
  }, [
    dispatch,
    uploadHistoryContent.data,
    uploadHistoryContent.isLoading,
    uploadHistoryId,
    workspaceId,
    initialized,
    uploadHistoryContent.failed,
  ]);

  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('components.appSidebar.manageDocuments')}
        </title>
      </Helmet>
      {uploadHistoryContent.failed ? <Error /> : <UploadHistoryContent />}
    </Fragment>
  );
};

export default UploadHistory;
