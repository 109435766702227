import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import normalizeResponse from '../normalizeResponse';
import { modelRegionsListSchema } from '../schema';
import {
  GET_MODEL_REGIONS,
  GET_MODEL_REGIONS_FAILED,
  GET_MODEL_REGIONS_SUCCESSFUL,
  IModelRegionsEntities,
  ModelRegionsDispatchTypes,
} from './modelRegions.types';

import ErrorCategories from '../../utils/constants/errorCategories';

const getModelRegions = () => async (
  dispatch: Dispatch<ModelRegionsDispatchTypes | ErrorDispatchTypes>
): Promise<Action | void> => {
  try {
    dispatch({ type: GET_MODEL_REGIONS });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}regions`);

    const { entities } = await normalizeResponse<IModelRegionsEntities>(response.data, modelRegionsListSchema);

    return dispatch({
      type: GET_MODEL_REGIONS_SUCCESSFUL,
      payload: {
        data: entities.modelRegions,
      },
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_MODEL_REGIONS_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.ModelRegions,
        },
      });
    });
  }
};

export default getModelRegions;
