enum ProjectPopUpTypes {
  Edit = 'edit',
  Create = 'create',
  Delete = 'delete',
}

enum DocumentPopUpTypes {
  Upload = 'upload',
  Delete = 'delete',
  Download = 'download',
}

enum TestResultsPopUpTypes {
  Download = 'download',
}

enum ModelsPopUpTypes {
  Create = 'create',
  Edit = 'edit',
  SaveDraft = 'saveDraft',
  Cancel = 'cancel',
  Delete = 'delete',
  UnableToDelete = 'unable to delete',
  Publish = 'publish',
  Swap = 'swap',
  UpdatePublishment = 'update publishment',
  Unpublish = 'unpublish',
  Duplicate = 'duplicate',
  Copy = 'copy',
  UnableToCopy = 'unable to copy',
}

enum WorkspacePopUpTypes {
  Delete = 'delete',
  Create = 'create',
  AddKey = 'add key',
  ChangeKey = 'change key',
  Rename = 'rename',
  DeleteUserRole = 'delete user role',
}

export { ProjectPopUpTypes, DocumentPopUpTypes, WorkspacePopUpTypes, ModelsPopUpTypes, TestResultsPopUpTypes };
