import { ChoiceGroup, Dropdown, IDropdownOption, Stack, Text } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { isEmpty } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import dictionaryImage from '../../../../assets/images/documents/dictionary.svg';
import testingImage from '../../../../assets/images/documents/testing.svg';
import trainingImage from '../../../../assets/images/documents/training.svg';
import tuningImage from '../../../../assets/images/documents/tuning.svg';
import {
  DocumentSetTypes,
  IDocumentLanguageItem,
  SetUploadDocumentWizardTypes,
} from '../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import {
  setDocumentsLanguagePair,
  setUploadDocumentsWizard,
} from '../../../../redux/uploadDocumentsWizard/uploadDocumentsWizardActions';
import WizardActions from '../../Shared/WizardActions';
import { RenderLabelTooltip } from '../../SharedFormElements';
import DocumentSetLabel from '../DocumentSetLabel';
import useChooseDocumentStyles from './ChooseDocument.styles';
import { IChooseDocumentSetTypeProps, IChooseDocumentWizardOption } from './ChooseDocumentSetType.types';

import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

/** Initial view, user can choose what type of documents set will be uploaded. */
const ChooseDocumentSetType: React.FC<IChooseDocumentSetTypeProps> = ({
  handleCancelAndClose,
  showLanguageSelection = false,
}) => {
  const classes = useChooseDocumentStyles();
  const appDispatch = useAppDispatch();
  const { supportedLanguagePairs } = useAppSelector((state) => state);
  const { t } = useTranslation();

  const [documentsType, setDocumentsType] = useState<DocumentSetTypes>();
  const [sourceLanguageList, setSourceLanguageList] = useState<IDropdownOption[]>([]);
  const [targetLanguageList, setTargetLanguageList] = useState([] as IDropdownOption[]);
  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<IDocumentLanguageItem>();
  const [selectedTargetLanguage, setSelectedTargetLanguage] = useState<IDocumentLanguageItem>();

  // Setup source language list
  useEffect(() => {
    if (showLanguageSelection) {
      let dropdownOptions: IDropdownOption[] = [];
      // Populate source language list
      const sourceLanguages = new Map();
      Object.values(supportedLanguagePairs.data).forEach((value) => {
        // Even if there are any duplicates, since this is a map, it'll be omitted.
        sourceLanguages.set(String(value.sourceLanguage.languageCode), {
          text: `${value.sourceLanguage.displayName} (${value.sourceLanguage.languageCode})`,
          displayName: value.sourceLanguage.displayName,
        });
      });
      dropdownOptions = Array.from(sourceLanguages, ([key, data]) => ({
        key,
        text: data.text,
        data: data.displayName,
      }));
      dropdownOptions.sort((a, b) => a.text.localeCompare(b.text));

      setSourceLanguageList(dropdownOptions);
    }
  }, [showLanguageSelection, supportedLanguagePairs]);

  // When the source language changes, repopulate the target language dropdown list and unset the target language
  const onChangeSourceLanguage = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    const newTargetLanguageList: IDropdownOption[] = [];
    if (item !== undefined) {
      Object.values(supportedLanguagePairs.data).forEach((value) => {
        if (String(value.sourceLanguage.languageCode) === item.key) {
          newTargetLanguageList.push({
            key: String(value.targetLanguage.languageCode),
            text: `${value.targetLanguage.displayName} (${value.targetLanguage.languageCode})`,
            data: value.targetLanguage.displayName,
          });
        }
      });
      newTargetLanguageList.sort((a, b) => a.text.localeCompare(b.text));

      setTargetLanguageList(newTargetLanguageList);
      setSelectedSourceLanguage({ displayName: item.data, languageCode: String(item.key) });
      setSelectedTargetLanguage(undefined);
    }
  };

  const onChangeTargetLanguage = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedTargetLanguage({ displayName: item.data, languageCode: String(item.key) });
    }
  };

  const handleSetChange = useCallback(
    (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, option?: Partial<IChooseDocumentWizardOption>): void => {
      if (option) {
        setDocumentsType(option.wizardKey);
      }
    },
    []
  );

  const options: IChooseDocumentWizardOption[] = [
    {
      key: DocumentSetTypes.Training,
      wizardKey: DocumentSetTypes.Training,
      text: '',
      onRenderLabel: (): JSX.Element => (
        <DocumentSetLabel
          title={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.trainingLabel.title')}
          text={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.trainingLabel.text')}
          link="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/training-and-model#training-document-type-for-custom-translator"
          image={{
            src: trainingImage,
            alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.training'),
          }}
        />
      ),
    },
    {
      key: DocumentSetTypes.Testing,
      wizardKey: DocumentSetTypes.Testing,
      text: '',
      onRenderLabel: (): JSX.Element => (
        <DocumentSetLabel
          title={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.testingLabel.title')}
          text={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.testingLabel.text')}
          link="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/training-and-model#testing-dataset-for-custom-translator"
          image={{
            src: testingImage,
            alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.testing'),
          }}
        />
      ),
    },
    {
      key: DocumentSetTypes.Tuning,
      wizardKey: DocumentSetTypes.Tuning,
      text: '',
      onRenderLabel: (): JSX.Element => (
        <DocumentSetLabel
          title={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.tuningLabel.title')}
          text={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.tuningLabel.text')}
          link="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/training-and-model#tuning-document-type-for-custom-translator"
          image={{
            src: tuningImage,
            alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.tuning'),
          }}
        />
      ),
    },
    {
      key: DocumentSetTypes.Dictionary,
      wizardKey: DocumentSetTypes.Dictionary,
      text: '',
      onRenderLabel: (): JSX.Element => (
        <DocumentSetLabel
          title={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.dictionaryLabel.title')}
          text={t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.dictionaryLabel.text')}
          link="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/what-is-dictionary"
          image={{
            src: dictionaryImage,
            alt: t('components.forms.uploadDocumentsWizard.documentImageAlts.dictionary'),
          }}
        />
      ),
    },
  ];

  const sourceLanguageId = useId('source-language-tooltip');
  const targetLanguageId = useId('target-language-tooltip');

  return (
    <Stack>
      {showLanguageSelection && (
        <Fragment>
          <Stack.Item>
            <Text as="p" block className={classes.subtitle}>
              {t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.languageSubtitle')}
            </Text>
          </Stack.Item>
          <Stack horizontal horizontalAlign="space-evenly" verticalAlign="center" className={classes.languageSelection}>
            <Stack.Item grow={2} align="start">
              <Dropdown
                label={t('components.forms.createProject.sourceLanguage.label')}
                options={sourceLanguageList}
                onChange={onChangeSourceLanguage}
                onRenderLabel={(): React.ReactElement => (
                  <RenderLabelTooltip
                    tooltipId={sourceLanguageId}
                    tooltipContent={t('components.forms.createProject.sourceLanguage.tooltip')}
                    tooltipLabel={t('components.forms.createProject.sourceLanguage.label')}
                    required
                  />
                )}
              />
            </Stack.Item>
            <Stack.Item grow align="start">
              <Text className={classes.labelBetweenLanguages}>{t('components.forms.createProject.to')}</Text>
            </Stack.Item>
            <Stack.Item grow={2} align="start">
              <Dropdown
                label={t('components.forms.createProject.targetLanguage.label')}
                options={targetLanguageList}
                onChange={onChangeTargetLanguage}
                defaultSelectedKey={0}
                onRenderLabel={(): React.ReactElement => (
                  <RenderLabelTooltip
                    tooltipId={targetLanguageId}
                    tooltipLabel={t('components.forms.createProject.targetLanguage.label')}
                    tooltipContent={t('components.forms.createProject.targetLanguage.tooltip')}
                    required
                  />
                )}
              />
            </Stack.Item>
          </Stack>
        </Fragment>
      )}

      <Text as="p" block className={classes.subtitle}>
        {showLanguageSelection &&
          `${t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.documentTypePrefix')} `}
        {t('components.forms.uploadDocumentsWizard.chooseDocumentSetType.documentTypeSubtitle')}
      </Text>
      <Stack horizontalAlign="start">
        <ChoiceGroup selectedKey={documentsType} onChange={handleSetChange} options={options} />
      </Stack>
      <Stack.Item>
        <WizardActions
          handleCancelAndClose={handleCancelAndClose}
          confirmationButton={{
            isDisabled: !documentsType || (showLanguageSelection && isEmpty(selectedTargetLanguage)),
            text: t('components.popups.common.next'),
            onClick: (): void => {
              batch(() => {
                appDispatch(setUploadDocumentsWizard(SetUploadDocumentWizardTypes.Document, documentsType));
                appDispatch(setDocumentsLanguagePair(selectedSourceLanguage, selectedTargetLanguage));
              });
            },
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default ChooseDocumentSetType;
