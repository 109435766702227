import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ADD_NOTIFICATION, NotificationsDispatchTypes } from '../notifications/notifications.types';
import {
  ADD_TRAINING_NOTIFICATION,
  CLEAR_TRAINING_NOTIFICATION,
  IAddTrainingNotificationPayload,
  IUpdateTrainingNotificationPayload,
  TrainingNotificationsDispatchTypes,
  UPDATE_TRAINING_NOTIFICATION,
} from './trainingNotifications.types';

import { NotificationType } from '../../utils/constants/notifications';

export const addTrainingNotification = (payload: IAddTrainingNotificationPayload) => (
  dispatch: Dispatch<TrainingNotificationsDispatchTypes | NotificationsDispatchTypes>
): void => {
  return batch(() => {
    dispatch({
      type: ADD_TRAINING_NOTIFICATION,
      payload,
    });
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        id: payload.id,
        type: NotificationType.Training,
      },
    });
  });
};

export const updateTrainingNotification = (payload: IUpdateTrainingNotificationPayload) => (
  dispatch: Dispatch<TrainingNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: UPDATE_TRAINING_NOTIFICATION,
    payload,
  });
};

// TODO: Should remove user from SignalR group as well.
// https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/123536
export const clearTrainingNotification = (id: string) => (
  dispatch: Dispatch<TrainingNotificationsDispatchTypes>
): Action => {
  return dispatch({
    type: CLEAR_TRAINING_NOTIFICATION,
    payload: id,
  });
};
