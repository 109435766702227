import {
  Dropdown,
  IDropdownOption,
  IStackItemTokens,
  IStackTokens,
  Link,
  Spinner,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from 'redux';

import {
  clearCreateWorkspaceWizard,
  createWorkspace,
  setIsValidatingKey,
} from '../../../../redux/createWorkspaceWizard/createWorkspaceWizardActions';
import WizardActions from '../../Shared/WizardActions';
import RenderLabelTooltip from '../../SharedFormElements/RenderLabelTooltip';
import { ICreateWorkspaceWizardProps } from '../CreateWorkspaceWizard.types';
import useAddKeyStyles from './AddKey.styles';

import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

const AddKey: React.FC<ICreateWorkspaceWizardProps> = ({ handleCancelAndClose }) => {
  const classes = useAddKeyStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    billingRegions,
    createWorkspaceWizard: { name, isValidatingKey, keyValidationError },
  } = useAppSelector((state) => state);

  const [key, setKey] = useState<string>('');
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [regions, setRegions] = useState<IDropdownOption[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    key: '',
    region: '',
  });

  useEffect(() => {
    const regionsMap = new Map(Object.entries(billingRegions));
    const dropdownOptions: IDropdownOption[] = [];

    regionsMap.forEach((value) => {
      dropdownOptions.push({ key: String(value.billingRegionCode), text: value.billingRegionName });
    });

    setRegions(dropdownOptions);
  }, [billingRegions]);

  const onChangeRegions = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item !== undefined) {
      setSelectedRegion(String(item.key));
    }
  };

  const handleSubmit = (): Action | void => {
    const hasKey = key.length > 0;
    const hasRegion = selectedRegion.length > 0;

    if (!hasKey) {
      setErrors((prevErrors) => ({ ...prevErrors, key: t('components.forms.generalErrors.required') }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, key: '' }));
    }

    if (!hasRegion) {
      setErrors((prevErrors) => ({ ...prevErrors, region: t('components.forms.generalErrors.required') }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, region: '' }));
    }

    if (hasKey && hasRegion) {
      appDispatch(createWorkspace({ name, region: selectedRegion, key }, handleCancelAndClose));
      appDispatch(setIsValidatingKey(true));
    }
  };

  const handleKeyChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue !== undefined) {
        setKey(newValue);
      }
    },
    []
  );

  const createSubscriptionLink =
    'https://docs.microsoft.com/en-us/azure/cognitive-services/translator/translator-text-how-to-signup';
  const stackTokens: IStackTokens = { childrenGap: 14 };

  const spinnerStyles = {
    circle: {
      height: 50,
      width: 50,
      borderWidth: 2,
    },
  };
  const spinnerStackTokens: IStackItemTokens = { padding: 50 };

  return (
    <Stack tokens={stackTokens}>
      <Stack.Item>
        <Text as="p" className={classes.subtitle}>
          {`${t('components.forms.createWorkspaceWizard.addKey.subtitle')} `}
        </Text>
        <Link href={createSubscriptionLink} target="_blank" rel="noreferrer noopener">
          {t('components.forms.createWorkspaceWizard.addKey.link')}
        </Link>
      </Stack.Item>
      {isValidatingKey && (
        <Stack.Item tokens={spinnerStackTokens}>
          <Spinner styles={spinnerStyles} />
        </Stack.Item>
      )}
      {!isValidatingKey && (
        <Fragment>
          <Stack.Item>
            <Dropdown
              id="createWorkspaceRegion"
              options={regions}
              defaultSelectedKey={selectedRegion}
              onChange={onChangeRegions}
              ariaLabel={t('components.forms.createWorkspaceWizard.addKey.regionTitle')}
              onRenderLabel={(): React.ReactElement => (
                <RenderLabelTooltip
                  htmlFor="createWorkspaceRegion"
                  tooltipId="createWorkspaceRegionTooltip"
                  tooltipContent={t('components.forms.createWorkspaceWizard.addKey.regionTooltip')}
                  tooltipLabel={t('components.forms.createWorkspaceWizard.addKey.regionTitle')}
                  required
                />
              )}
              errorMessage={errors.region}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              id="workspaceKey"
              name="workspaceKey"
              placeholder={t('components.forms.createWorkspaceWizard.addKey.keyPlaceholder')}
              value={key}
              onChange={handleKeyChange}
              errorMessage={keyValidationError || errors.key}
              onRenderLabel={(): React.ReactElement => (
                <RenderLabelTooltip
                  htmlFor="workspaceKey"
                  tooltipId="createWorkspaceKeyTooltip"
                  tooltipContent={t('components.forms.createWorkspaceWizard.addKey.keyTooltip')}
                  tooltipLabel={t('components.forms.createWorkspaceWizard.addKey.keyTitle')}
                  required
                />
              )}
            />
          </Stack.Item>
          <Stack.Item>
            <WizardActions
              backButton={{
                onClick: (): Action => appDispatch(clearCreateWorkspaceWizard()),
              }}
              handleCancelAndClose={handleCancelAndClose}
              confirmationButton={{
                text: t('components.popups.common.next'),
                onClick: handleSubmit,
              }}
            />
          </Stack.Item>
        </Fragment>
      )}
    </Stack>
  );
};

export default AddKey;
