import { FontWeights, makeStyles } from '@fluentui/react';

const useTrainingStatusStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  icon: {
    position: 'relative',
    top: 2,
    marginRight: 5,
  },
  successIcon: {
    color: theme.palette.green,
  },
  failedIcon: {
    color: theme.palette.red,
  },
  text: {
    fontWeight: FontWeights.light,
    fontSize: '16px',
    width: '900px',
  },
}));

export default useTrainingStatusStyles;
