import { makeStyles } from '@fluentui/react';

const useUploadDocumentsWizardStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing.l1,
  },
  reflow: {
    '@media (max-width: 640px)': {
      flexFlow: 'row wrap',
      gap: theme.spacing.l1,
    },
  },
}));

export default useUploadDocumentsWizardStyles;
