/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import {
  DocumentContentDispatchTypes,
  GET_DOCUMENT_CONTENT,
  GET_DOCUMENT_CONTENT_FAILED,
  GET_DOCUMENT_CONTENT_SUCCESSFUL,
  IDocumentContent,
  IGetDocumentContentParams,
} from './documentContent.types';

import ErrorCategories from '../../utils/constants/errorCategories';

export const getDocumentContent = (params: IGetDocumentContentParams) => async (
  dispatch: Dispatch<DocumentContentDispatchTypes | ErrorDispatchTypes>
): Promise<void | Action> => {
  try {
    const { documentId, sourceFileId, targetFileId, pageIndex, resetPagination } = params;
    dispatch({ type: GET_DOCUMENT_CONTENT, payload: { id: documentId, pageIndex, resetPagination } });

    const sourceFileUrl = `${process.env.REACT_APP_API_URL}documents/${documentId}/files/${sourceFileId}/content`;
    const targetFileUrl = `${process.env.REACT_APP_API_URL}documents/${documentId}/files/${targetFileId}/content`;

    const pairedData: IDocumentContent[] = [];
    let sourceTotalPageCount = 0;
    let targetTotalPageCount = 0;
    let maxPageCount = 0;

    // TODO: Add tests and return test data if testing environment
    // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119772

    const limit = 100;
    await axios
      .all([
        axios.get(sourceFileUrl, { params: { pageIndex, limit } }),
        axios.get(targetFileUrl, { params: { pageIndex, limit } }),
      ])
      .then(
        axios.spread((sourceResponse, targetResponse) => {
          // Identify page counts for IDocumentContent
          sourceTotalPageCount = sourceResponse.data.totalPageCount;
          targetTotalPageCount = targetResponse.data.totalPageCount;
          maxPageCount = sourceTotalPageCount > targetTotalPageCount ? sourceTotalPageCount : targetTotalPageCount;

          // Parse data so paired sentences are available in one object
          const sourceSentences = sourceResponse.data.items;
          const targetSentences = targetResponse.data.items;
          const numSourceSentences = sourceSentences.length;
          const numTargetSentences = targetSentences.length;
          const maxSentences = numSourceSentences > numTargetSentences ? numSourceSentences : numTargetSentences;
          for (let i = 0; i < maxSentences; i += 1) {
            let sourceSentence = '';
            let targetSentence = '';
            if (numTargetSentences >= i) {
              targetSentence = targetSentences[i];
            }
            if (numSourceSentences >= i) {
              sourceSentence = sourceSentences[i];
            }
            pairedData.push({ sourceSentence, targetSentence });
          }
        })
      );

    return batch(() => {
      dispatch({
        type: GET_DOCUMENT_CONTENT_SUCCESSFUL,
        payload: {
          id: documentId,
          data: pairedData,
          pageIndex,
          totalPageCount: {
            sourceTotal: sourceTotalPageCount,
            targetTotal: targetTotalPageCount,
            max: maxPageCount,
          },
        },
      });
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_DOCUMENT_CONTENT_FAILED,
        payload: { id: params.documentId, error: error.message },
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Document,
        },
      });
    });
  }
};
