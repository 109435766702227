export const GET_UPLOAD_HISTORY_CONTENT = 'GET_UPLOAD_HISTORY_CONTENT';
export const GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL = 'GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL';
export const GET_UPLOAD_HISTORY_CONTENT_FAILED = 'GET_UPLOAD_HISTORY_CONTENT_FAILED';

export interface IUploadHistoryContent {
  jobName: string;
  fileProcessingStatus: {
    status: {
      displayName: string;
      id: number;
    };
    modifiedDate: string;
    fileName: string;
    documentName: string;
    summary: string;
    id: number;
    language: {
      id: number;
      displayName: string;
      languageCode: string;
    };
  }[];
}

export interface IUploadHistoryContentState {
  isLoading: boolean;
  data: IUploadHistoryContent | null;
  failed?: boolean;
}

export type GetUploadHistoryContentResponse = IUploadHistoryContent;

export interface IGetUploadHistoryContent {
  type: typeof GET_UPLOAD_HISTORY_CONTENT;
}

export interface IGetUploadHistoryContentSuccessful {
  type: typeof GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL;
  payload: IUploadHistoryContent;
}

export interface IGetUploadHistoryContentFailed {
  type: typeof GET_UPLOAD_HISTORY_CONTENT_FAILED;
}

export type UploadHistoryContentDispatchTypes =
  | IGetUploadHistoryContent
  | IGetUploadHistoryContentSuccessful
  | IGetUploadHistoryContentFailed;
