const filterUploadHistoryQuery = ({
  searchTerm,
  selectedStatusKey,
  selectedLanguageKey,
  afterDate,
  beforeDate,
}: {
  searchTerm: string;
  selectedStatusKey: number | string | null;
  selectedLanguageKey: number | string | null;
  afterDate: Date | undefined;
  beforeDate: Date | undefined;
}): string => {
  const filterParts: string[] = [];

  if (searchTerm) {
    filterParts.push(`substringof(Name,'${searchTerm}')`);
  }

  if (selectedStatusKey) {
    filterParts.push(`status eq '${selectedStatusKey}'`);
  }

  if (selectedLanguageKey) {
    filterParts.push(`languageCode eq '${selectedLanguageKey}'`);
  }

  if (afterDate) {
    filterParts.push(`uploadDateStart ge '${afterDate.toISOString()}'`);
  }

  if (beforeDate) {
    filterParts.push(`uploadDateEnd le '${beforeDate.toISOString()}'`);
  }

  return filterParts.join(' and ');
};

export default filterUploadHistoryQuery;
