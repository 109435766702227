import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { downloadTestResults } from '../../../redux/modelTestResults/modelTestResultsActions';
import { AppDialog } from '../../AppPopUps';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { TestResultsPopUpTypes } from '../../../utils/constants/popUps.types';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { AppDialogContentMap, useAppDialog, useAppDispatch, useAppSelector } from '../../../utils/hooks';

const TestModelResultsCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const { modelTestResults } = useAppSelector((state) => state);
  const { appDialog, setAppDialog } = useAppDialog();
  const appDispatch = useAppDispatch();
  const { modelId } = useParams<IRoutePathParams>();
  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'download',
      text: t('components.commandBars.testModelResults.downloadButton.text'),
      ariaLabel: t('components.commandBars.testModelResults.downloadButton.aria'),
      iconProps: { iconName: 'DownloadDocument' },
      disabled: modelTestResults.isLoading,
      onClick: (): void => setAppDialog({ contentType: TestResultsPopUpTypes.Download, isHidden: false }),
    },
  ];

  const rightCommandBarItems: ICommandBarItemProps[] = [
    /** TODO: model details panel
    https://machinetranslation.visualstudio.com/MachineTranslation/_backlogs/backlog/CustomTranslator/Backlog%20items/?workitem=119002
    {
      key: 'moreInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    }, */
    {
      key: 'downloading',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader
          isVisible={modelTestResults.isDownloading}
          message={t('components.commandBars.common.downloading')}
        />
      ),
    },
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [TestResultsPopUpTypes.Download]: {
      contentProps: {
        title: t('components.popups.testModel.download.title'),
        subText: t('components.popups.testModel.download.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.download'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(downloadTestResults(modelId));
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;
  return (
    <Fragment>
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
    </Fragment>
  );
};

export default TestModelResultsCommandBar;
