enum TrainingTypes {
  FullTraining = 'full',
  DictionaryOnly = 'dictionary',
}

enum TrainingErrorCodes {
  NoError = 'noError',
  Unknown = 'unkown', // Preserving misspelling from API
  SelectTestSets = 'selectTestSets',
}

export { TrainingTypes, TrainingErrorCodes };
