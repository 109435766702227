import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import {
  CLEAR_ALL_DEPLOYMENT_NOTIFICATIONS,
  CLEAR_DEPLOYMENT_NOTIFICATION,
  DeploymentNotificationsDispatchTypes,
} from '../deploymentNotifications/deploymentNotifications.types';
import {
  CLEAR_ALL_TRAINING_NOTIFICATIONS,
  CLEAR_TRAINING_NOTIFICATION,
  TrainingNotificationsDispatchTypes,
} from '../trainingNotifications/trainingNotifications.types';
import {
  CLEAR_ALL_UPLOAD_NOTIFICATIONS,
  CLEAR_UPLOAD_NOTIFICATION,
  UploadNotificationsDispatchTypes,
} from '../uploadNotifications/uploadNotifications.types';
import {
  ADD_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_NOTIFICATION,
  CONNECT_TO_HUB,
  IClearNotificationPayload,
  INotificationItemId,
  NotificationsDispatchTypes,
} from './notifications.types';

import { NotificationType } from '../../utils/constants/notifications';

export const clearAllNotifications = () => (
  dispatch: Dispatch<
    | NotificationsDispatchTypes
    | TrainingNotificationsDispatchTypes
    | DeploymentNotificationsDispatchTypes
    | UploadNotificationsDispatchTypes
  >
): void => {
  return batch(() => {
    dispatch({ type: CLEAR_ALL_NOTIFICATIONS });
    dispatch({ type: CLEAR_ALL_TRAINING_NOTIFICATIONS });
    dispatch({ type: CLEAR_ALL_DEPLOYMENT_NOTIFICATIONS });
    dispatch({ type: CLEAR_ALL_UPLOAD_NOTIFICATIONS });
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const determineDispatchType = (type: NotificationType): any => {
  switch (type) {
    case NotificationType.Deployment:
      return CLEAR_DEPLOYMENT_NOTIFICATION;
    case NotificationType.Upload:
      return CLEAR_UPLOAD_NOTIFICATION;
    default:
      return CLEAR_TRAINING_NOTIFICATION;
  }
};

export const clearNotification = (payload: IClearNotificationPayload) => (
  dispatch: Dispatch<
    | NotificationsDispatchTypes
    | TrainingNotificationsDispatchTypes
    | DeploymentNotificationsDispatchTypes
    | UploadNotificationsDispatchTypes
  >
): void => {
  const dispatchType = determineDispatchType(payload.type);

  return batch(() => {
    dispatch({ type: dispatchType, payload: payload.id });
    dispatch({ type: CLEAR_NOTIFICATION, payload });
  });
};

export const addNotification = (payload: INotificationItemId) => (
  dispatch: Dispatch<NotificationsDispatchTypes>
): Action => {
  return dispatch({ type: ADD_NOTIFICATION, payload });
};

export const connectToHub = () => (dispatch: Dispatch<NotificationsDispatchTypes>): void => {
  return batch(() => {
    dispatch({ type: CONNECT_TO_HUB });
  });
};
