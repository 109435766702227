import { useMsal } from '@azure/msal-react';
import { DefaultButton, Persona, PersonaInitialsColor, PersonaSize, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useProfileCardStyles from './ProfileCard.styles';

import { useAppSelector } from '../../utils/hooks';

// TODO: Complete profile card data using graph API: https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/123524

const ProfileCard: React.FC = () => {
  const classes = useProfileCardStyles();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { currentUser } = useAppSelector((state) => state);
  return (
    <div className={classes.profileCard}>
      <Stack className={classes.buttonStack} horizontal horizontalAlign="end">
        <DefaultButton className={classes.signOutButton} onClick={(): Promise<void> => instance.logout()}>
          <Text className={classes.signOutText}>{t('components.appBar.actionButtons.signOut')}</Text>
        </DefaultButton>
      </Stack>
      <Persona
        initialsColor={PersonaInitialsColor.coolGray}
        text={currentUser.username}
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onRenderPrimaryText={() => {
          return <Text className={classes.personaText}>{currentUser.username}</Text>;
        }}
        secondaryText={currentUser.email}
        size={PersonaSize.size72}
        imageAlt={currentUser.username || currentUser.email}
        className={classes.persona}
      />
    </div>
  );
};

export default ProfileCard;
