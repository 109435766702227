import { FontWeights, makeStyles } from '@fluentui/react';

import components from '../../../utils/constants/components';

const useEmptyStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: `calc(100vh - ${components.ProjectsTopContent.height}px)`,
  },
  title: {
    marginBottom: theme.spacing.m,
    fontWeight: FontWeights.semibold,
  },
  subTextContainer: {
    maxWidth: '500px',
  },
  subText: {
    margin: 0,
    textAlign: 'center',
  },
}));

export default useEmptyStyles;
