import merge from 'lodash/merge';

import {
  BillingRegionsDispatchTypes,
  GET_BILLING_REGIONS_SUCCESSFUL,
  IBillingRegionItem,
  SET_BILLING_REGIONS,
} from './billingRegions.types';

interface IBillingRegionsState {
  [key: string]: IBillingRegionItem;
}

const billingRegionsReducer = (
  state: IBillingRegionsState = {},
  action: BillingRegionsDispatchTypes
): IBillingRegionsState => {
  switch (action.type) {
    case GET_BILLING_REGIONS_SUCCESSFUL:
    case SET_BILLING_REGIONS: {
      return merge(state, action.payload);
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default billingRegionsReducer;
