import { MessageBar, SharedColors } from '@fluentui/react';
import React, { useState } from 'react';

/**
 * List of
 * Project Language Pair ID + , + Project Domain ID
 */
const AzureModelKeys = ['38,20', '22,20', '5,20', '18,35', '64,35', '5,35'];

const AzureModelAnnouncement: React.FC<{ azureModelKey?: string }> = ({ azureModelKey = '' }) => {
  const [dismissed, setDismissed] = useState(false);

  if (!AzureModelKeys.includes(azureModelKey)) {
    return null;
  }

  if (dismissed) {
    return null;
  }

  return (
    <MessageBar
      onDismiss={(): void => setDismissed(true)}
      dismissButtonAriaLabel="Close"
      messageBarIconProps={{
        iconName: 'Megaphone',
      }}
    >
      There is an out of the box model available for this language pair and domain on the Azure Marketplace.
      <a
        href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/tausbv1667479865417.demt_tr_001?tab=Overview"
        style={{ textDecoration: 'underline', color: SharedColors.cyanBlue10 }}
      >
        Go to Azure Marketplace
      </a>
      .
    </MessageBar>
  );
};

export default AzureModelAnnouncement;
