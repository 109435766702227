import { Stack, Text } from '@fluentui/react';
import axios from 'axios';
import dayjs from 'dayjs';
import React from 'react';

import useStyles from './ModelDetails.styles';

interface IModelDetailsContent {
  model: {
    id: number;
    modelStatus: string;
  };
}

interface IModelDetailsHistory {
  targetModelId: number;
  targetModelName: string;
  targetProjectName: string;
  targetWorkspaceName: string;
  sourceModelId: number;
  sourceModelName: string;
  sourceProjectName: string;
  sourceWorkspaceName: string;
  createdDate: string;
}

const ModelDetails: React.FC<IModelDetailsContent> = ({ model }) => {
  const [data, setData] = React.useState<IModelDetailsHistory[] | null>();
  const [isLoading, setLoading] = React.useState(false);
  const [noResult, setNoResult] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setData(null);
    setLoading(true);
    setNoResult(false);

    if (model) {
      axios.get(`${process.env.REACT_APP_API_URL}models/${model.id}/copyhistory`).then((response) => {
        const result = response.data[0];

        if (result) {
          setData(response.data);
        } else {
          setNoResult(true);
        }

        setLoading(false);
      });
    }
  }, [model]);

  if (!model) {
    return <p>Select a model for details.</p>;
  }

  if (isLoading) {
    return null;
  }

  if (noResult) {
    return <p>This model does not have a copy history.</p>;
  }

  if (!data) {
    return null;
  }

  const histories = data.map((history) => {
    const isSource = history.sourceModelId === model.id;

    if (isSource) {
      return `${dayjs(history.createdDate).format('MM/DD/YYYY')} copied as ${history.targetModelName} to ${
        history.targetProjectName
      } in workspace ${history.targetWorkspaceName}.`;
    }

    return `${dayjs(history.createdDate).format('MM/DD/YYYY')} copied from ${history.sourceModelName} in ${
      history.sourceProjectName
    } from workspace ${history.sourceWorkspaceName}.`;
  });

  return (
    <React.Fragment>
      <Stack tokens={{ childrenGap: 'm' }} className={classes.main}>
        <section className={classes.section}>
          <Text as="h2" className={classes.heading}>
            Model copy history
          </Text>
          {histories.map((history) => (
            <Text as="p" className={classes.history}>
              {history}
            </Text>
          ))}
        </section>
      </Stack>
    </React.Fragment>
  );
};

export default ModelDetails;
