import { UploadNotificationStatus } from '../../utils/constants/notifications';

export const ADD_UPLOAD_NOTIFICATION = 'ADD_UPLOAD_NOTIFICATION';
export const UPDATE_JOBID_UPLOAD_NOTIFICATION = 'UPDATE_JOBID_UPLOAD_NOTIFICATION';
export const UPDATE_UPLOAD_NOTIFICATION = 'UPDATE_UPLOAD_NOTIFICATION';
export const UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION = 'UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION';
export const CLEAR_UPLOAD_NOTIFICATION = 'CLEAR_UPLOAD_NOTIFICATION';
export const CLEAR_ALL_UPLOAD_NOTIFICATIONS = 'CLEAR_ALL_UPLOAD_NOTIFICATIONS';

export interface IUploadNotificationItem {
  id: string;
  status: UploadNotificationStatus;
  workspaceId: string;
  name: string;
  errorMessage: string;
  isZip: boolean;
  files: {
    [key: string]: IExtractedFileItem;
  };
}

export interface IExtractedFileItem {
  id: string;
  name: string;
  status: UploadNotificationStatus;
  errorMessage: string;
}

export interface IAddUploadNotificationPayload {
  id: string;
  data: {
    name: string;
    workspaceId: string;
    isZip: boolean;
  };
}

export interface IUpdateUploadNotificationPayload {
  id: string;
  data: {
    status: UploadNotificationStatus;
    errorMessage: string;
  };
}

export interface IUpdateExtractedFileUploadNotificationPayload {
  id: string; // Job ID
  data: IExtractedFileItem;
}

export interface IUpdateJobIdUploadNotificationPayload {
  id: string;
  data: {
    oldId: string;
  };
}

export interface IAddUploadNotification {
  type: typeof ADD_UPLOAD_NOTIFICATION;
  payload: IAddUploadNotificationPayload;
}

export interface IUpdateUploadNotification {
  type: typeof UPDATE_UPLOAD_NOTIFICATION;
  payload: IUpdateUploadNotificationPayload;
}

export interface IUpdateExtractedFileUploadNotification {
  type: typeof UPDATE_EXTRACTED_FILE_UPLOAD_NOTIFICATION;
  payload: IUpdateExtractedFileUploadNotificationPayload;
}

export interface IUpdateJobIdUploadNotification {
  type: typeof UPDATE_JOBID_UPLOAD_NOTIFICATION;
  payload: IUpdateJobIdUploadNotificationPayload;
}

export interface IClearUploadNotification {
  type: typeof CLEAR_UPLOAD_NOTIFICATION;
  payload: string;
}

export interface IClearAllUploadNotifications {
  type: typeof CLEAR_ALL_UPLOAD_NOTIFICATIONS;
}

export type UploadNotificationsDispatchTypes =
  | IAddUploadNotification
  | IUpdateJobIdUploadNotification
  | IUpdateUploadNotification
  | IUpdateExtractedFileUploadNotification
  | IClearUploadNotification
  | IClearAllUploadNotifications;
