import { CLEAR_ERROR, ErrorDispatchTypes, IErrorItem, SET_ERROR } from './error.types';

const initialState: IErrorItem = {
  message: undefined,
  statusCode: undefined,
  category: undefined,
};

const ErrorReducer = (state: IErrorItem = initialState, action: ErrorDispatchTypes): IErrorItem => {
  switch (action.type) {
    case SET_ERROR: {
      return {
        message: action.payload.message,
        statusCode: action.payload.statusCode,
        category: action.payload.category,
      };
    }
    case CLEAR_ERROR: {
      return initialState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ErrorReducer;
