import axios from 'axios';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import {
  FETCH_MODELS_VERSION,
  GET_MODELS_VERSION,
  GET_MODELS_VERSION_FAILED,
  GET_MODELS_VERSION_SUCCESSFUL,
  GetModelsVersionResponse,
  MODELS_VERSION_SELECT,
  ModelsVersionDispatchTypes,
} from './modelsVersion.types';

/*

eg, https://texttranslatordev.azurewebsites.net/api/texttranslator/v1.0/workspaces/07da8419-d352-4a59-9830-bfd6cd2586e4/models?$filter=modelType%20eq%20'Frantic'%20and%20status%20eq%20'Deployed'
https://texttranslatordev.azurewebsites.net/api/texttranslator/v1.0/workspaces/workspaces/977bc249-6fad-4ace-955c-0b2c438befc7/models?$filter=modelType+eq+'Frantic'+and+status+eq+'Deployed'

GET workspaces/{id}/models
-	Gets all the models in a workspace
-	Filterable on model version 
-	Filterable on upgraded models

POST workspaces/{id}/models
-	Takes in a list of models where each model looks like the POST /models call
-	Includes additional field on the model object for linking a model to an old model

POST workspaces/{id}/models/deployment
-	Takes in a list of model ids with regions to deploy

*/

const getModelsVersion = ({ workspaceId }: { workspaceId: string }) => async (
  dispatch: Dispatch<ModelsVersionDispatchTypes>
): Promise<void | Action | null> => {
  dispatch({
    type: GET_MODELS_VERSION,
    payload: {
      isLoading: true,
    },
  });

  try {
    const response = await axios.get<GetModelsVersionResponse>(
      `${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/models`,
      {
        params: { $filter: "modelType eq 'Frantic' and status eq 'Deployed'" },
      }
    );

    return batch(() => {
      dispatch({
        type: FETCH_MODELS_VERSION,
        payload: false,
      });
      dispatch({
        type: GET_MODELS_VERSION_SUCCESSFUL,
        payload: {
          ...response.data,
          isLoading: false,
          workspaceId,
        },
      });
    });
  } catch {
    return batch(() => {
      dispatch({
        type: FETCH_MODELS_VERSION,
        payload: false,
      });
      dispatch({
        type: GET_MODELS_VERSION_FAILED,
      });
    });
  }
};

const upgradeModelsVersion = ({
  workspaceId,
  models,
}: {
  models: {
    name: string;
    projectId: string;
    documentIds: string[];
    sourceModelIdForRetrain: string;
  }[];
  workspaceId: string;
}) => async (dispatch: Dispatch<ModelsVersionDispatchTypes>): Promise<void | Action | null> => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}workspaces/${workspaceId}/models`, models);

    return batch(() => {
      dispatch({
        type: FETCH_MODELS_VERSION,
        payload: true,
      });
    });
    // eslint-disable-next-line no-empty
  } catch (err) {
    return null;
  }
};

const modelsVersionSelect = ({ modelIds }: { modelIds: number[] | null }) => (
  dispatch: Dispatch<ModelsVersionDispatchTypes>
): void => {
  dispatch({
    type: MODELS_VERSION_SELECT,
    payload: {
      modelIds,
    },
  });
};

export { getModelsVersion, upgradeModelsVersion, modelsVersionSelect };
