import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import { ModelContent } from '../components/PageContents';
import DraftModelContent from '../components/PageContents/DraftModelContent';
import { getModel } from '../redux/models/modelsActions';
import { getProject } from '../redux/projects/projectsActions';
import { getSubscription } from '../redux/subscription/subscriptionActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import { ModelStatus } from '../utils/constants/modelStatus';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector, useGetProjectDocuments } from '../utils/hooks';

const Model: React.FC = () => {
  useTrackMetric(reactPlugin, 'ModelPage');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, projectId, modelId } = useParams<IRoutePathParams>();
  const { workspaces, projects, models, error, subscription } = useAppSelector((state) => state);

  // On load
  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasProjects = !isEmpty(projects.ids);
    const hasModels = !isEmpty(models.ids);
    const isLoading = workspaces.isLoading || projects.isLoading || models.isLoading;

    if (subscription.isLoading === false && subscription.workspaceId !== workspaceId) {
      dispatch(getSubscription(workspaceId));
    }
    if (!isLoading && isEmpty(error.message)) {
      if (!hasWorkspaces && !hasProjects && !hasModels) {
        batch(() => {
          dispatch(getWorkspaces());
          dispatch(getProject(projectId));
        });
      } else if (!hasWorkspaces) {
        dispatch(getWorkspaces());
      } else if (!hasProjects) {
        dispatch(getProject(projectId));
      }
      dispatch(getModel(modelId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    workspaceId,
    workspaces.isLoading,
    workspaces.ids,
    projectId,
    projects.isLoading,
    projects.ids,
    modelId,
  ]);

  // Clean train_model slice before it starts populating with the actual data
  // This is the information loaded in the draft model page
  useEffect(() => {
    dispatch({ type: 'CLEAR_TRAIN_MODEL_DATA' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetProjectDocuments(false, modelId);

  let content;
  if (!isEmpty(error.message) && error.category) {
    content = <Error />;
  } else if (models.data[modelId]?.modelStatus === ModelStatus.Draft) {
    content = <DraftModelContent />;
  } else {
    content = <ModelContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('pages.models.pageName')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default Model;
