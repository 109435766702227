import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ManageDocumentsCommandBar } from '../../CommandBars';
import FilterBar from '../../FilterBar';
import { Empty, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { ProjectDocumentsTable } from '../../Tables';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import { useAppSelector } from '../../../utils/hooks';

const ManageDocumentsContent: React.FC = () => {
  const { t } = useTranslation();
  const { projects, documents, workspaces } = useAppSelector((state) => state);
  const breadcrumbItems = [
    {
      text: t('components.appSidebar.manageDocuments'),
      key: 'manage-documents',
    },
  ];

  let documentsContent = null;

  /**
   * The ManageDocumentsTable depends on having the current project set in order to render project languages.
   * The loading spinner should only appear on initial page loads - for all subsequent loads that occur
   * due to pagination changes the ShimmeredDetailsList will shimmer to show progress.
   */
  const isInitialDocumentLoad = documents.isLoading && documents.totalPageCount === 1;
  const hasWorkspaces = !isEmpty(workspaces.data);
  const hasCurrentWorkspace = !isEmpty(workspaces.currentWorkspace);

  if (isInitialDocumentLoad || projects.isLoading || isEmpty(projects.currentProject) || !hasWorkspaces) {
    documentsContent = (
      <Loading
        loadingText={t('pages.manageDocuments.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!documents.isLoading && isEmpty(documents.ids) && hasCurrentWorkspace && !documents.areDocumentsFiltered) {
    documentsContent = (
      <Fragment>
        <Empty type={EmptyTypes.ManageDocuments} />
      </Fragment>
    );
  } else if (!projects.isLoading && hasWorkspaces && hasCurrentWorkspace) {
    documentsContent = (
      <Fragment>
        <ProjectDocumentsTable />
      </Fragment>
    );
  }

  return (
    <div className="content-container">
      <PageHeading>{t('components.appSidebar.manageDocuments')}</PageHeading>
      <PageBreadcrumb
        items={breadcrumbItems}
        subtitle={t('pages.manageDocuments.contentSection.subText')}
        link={{
          text: t('pages.manageDocuments.contentSection.subTextLink'),
          href:
            'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/v2-preview/how-to/create-manage-training-documents',
        }}
      />
      <ManageDocumentsCommandBar />
      <FilterBar kind="documents" />
      {documentsContent}
    </div>
  );
};

export default ManageDocumentsContent;
