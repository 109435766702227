import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getDocuments } from '../../redux/documents/documentsActions';
import { getProjects } from '../../redux/projects/projectsActions';
import { getWorkspaces } from '../../redux/workspaces/workspacesActions';
import ErrorCategories from '../constants/errorCategories';
import IRoutePathParams from '../constants/routePathParams.types';
import { useAppSelector } from './appRedux.helpers';
import useQuery from './useQuery';

/**
 * @param isDictionaryOnly Whether or not to filter down to only dictionary documents
 * @param prioritizeModel A model id. When supplied as a parameter to the GET documents call, the API returns documents that
 * are part of this model ordered first. This is used by the draft model page.
 * @param silentRefreshOnNewDocuments Boolean. If true, and "documents.hasNewDocuments" is also true this triggers a new GET documents call.
 * "documents.hasNewDocuments" is set to true after a successful upload to this workspace.
 */
function useGetProjectDocuments(
  isDictionaryOnly?: boolean,
  prioritizeModel?: string,
  silentRefreshOnNewDocuments = false
): void {
  const dispatch = useDispatch();
  const [hasCalledDocuments, setHasCalledDocuments] = useState(false);
  const { workspaceId, projectId } = useParams<IRoutePathParams>();
  const { workspaces, projects, languages, documents, error } = useAppSelector((state) => state);

  // If page query parameter is unset, default to 1
  const query = useQuery();
  let currentPage = Number(query.get('page'));
  if (!currentPage) {
    currentPage = 1;
  }

  useEffect(() => {
    if (error.category !== ErrorCategories.Workspaces && error.category !== ErrorCategories.Projects) {
      if (isEmpty(workspaces.data) && !workspaces.isLoading) {
        dispatch(getWorkspaces());
      }

      if (isEmpty(projects.data) && !projects.isLoading) {
        dispatch(getProjects(workspaceId));
      }
    }
  }, [dispatch, workspaceId, workspaces, workspaces.isLoading, projects, projects.isLoading, error]);

  useEffect(() => {
    const hasCurrentProject = !isEmpty(projects.data[projectId]);
    const hasPageChanged = !documents.isLoading && currentPage && currentPage !== documents.pageIndex;

    // Getting documents relies on using the current project's language pair. This checks
    // that the current project has been fully fetched.
    if (!projects.isLoading && hasCurrentProject) {
      // Handles fetching documents both on initial load, when pagination changes, and when
      // an upload is completed
      const needsSilentRefresh = silentRefreshOnNewDocuments && documents.hasNewDocuments;
      if (!hasCalledDocuments || hasPageChanged || needsSilentRefresh) {
        setHasCalledDocuments(true);
        const currentProject = projects.data[projectId];
        const sourceLanguage = languages[currentProject.languagePair.sourceLanguage];
        const targetLanguage = languages[currentProject.languagePair.targetLanguage];

        let filter = `isAvailable eq 'True' and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}' and isParallel eq true`;
        if (isDictionaryOnly) {
          filter = `isAvailable eq 'True' and projectlanguages eq '${sourceLanguage.languageCode}->${targetLanguage.languageCode}' and isParallel eq true and documentType eq 'Phrase dictionary,Sentence dictionary'`;
        }
        if (prioritizeModel) {
          batch(() => {
            dispatch(
              getDocuments(
                {
                  workspaceId,
                  filter,
                  page: currentPage,
                  resetPagination: !hasCalledDocuments,
                  prioritizeModel,
                },
                needsSilentRefresh
              )
            );
          });
        } else {
          batch(() => {
            dispatch(
              getDocuments(
                { workspaceId, filter, page: currentPage, resetPagination: !hasCalledDocuments },
                documents.hasNewDocuments
              )
            );
          });
        }
      }
    }
  }, [
    dispatch,
    projectId,
    projects.data,
    projects.isLoading,
    documents.pageIndex,
    documents.isLoading,
    documents.hasNewDocuments,
    languages,
    hasCalledDocuments,
    workspaceId,
    currentPage,
    isDictionaryOnly,
    prioritizeModel,
    silentRefreshOnNewDocuments,
  ]);
}

export default useGetProjectDocuments;
