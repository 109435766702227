import { FontWeights, makeStyles } from '@fluentui/react';

const useSwapModelFormStyles = makeStyles((theme) => ({
  subHeadings: {
    paddingTop: theme.spacing.l1,
    margin: `0 0 ${theme.spacing.s1}`,
    fontWeight: FontWeights.semibold,
    paddingLeft: theme.spacing.l1,
  },
  newModel: {
    margin: `0 0 ${theme.spacing.s1}`,
    paddingLeft: theme.spacing.l2,
  },
  oldModel: {
    margin: `0 0 ${theme.spacing.s1}`,
    paddingLeft: theme.spacing.l2,
  },
}));

export default useSwapModelFormStyles;
