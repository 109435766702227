import {
  CLEAR_DICTIONARY_TYPE,
  CLEAR_UPLOAD_DOCUMENTS_WIZARD,
  DictionaryTypes,
  DocumentSetTypes,
  IDocumentLanguageItem,
  SET_DOCUMENTS_LANGUAGE_PAIR,
  SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD,
  SET_UPLOAD_DOCUMENTS_WIZARD,
  UploadDocumentWizardDispatchTypes,
} from './uploadDocumentsWizard.types';

interface IUploadDocumentsState {
  documentSetType: DocumentSetTypes | undefined;
  dictionaryType: DictionaryTypes | undefined;
  uploadType: string;
  sourceLanguage: IDocumentLanguageItem | undefined;
  targetLanguage: IDocumentLanguageItem | undefined;
}

const initialState: IUploadDocumentsState = {
  documentSetType: undefined,
  dictionaryType: undefined,
  uploadType: '',
  sourceLanguage: undefined,
  targetLanguage: undefined,
};

const UploadDocumentsWizardReducer = (
  state: IUploadDocumentsState = initialState,
  action: UploadDocumentWizardDispatchTypes
): IUploadDocumentsState => {
  switch (action.type) {
    case SET_UPLOAD_DOCUMENTS_WIZARD: {
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };
    }
    case SET_INITIAL_UPLOAD_DOCUMENTS_WIZARD: {
      return {
        ...state,
        ...initialState,
      };
    }
    case CLEAR_DICTIONARY_TYPE: {
      return {
        ...state,
        dictionaryType: initialState.dictionaryType,
      };
    }
    case CLEAR_UPLOAD_DOCUMENTS_WIZARD: {
      return {
        ...initialState,
      };
    }
    case SET_DOCUMENTS_LANGUAGE_PAIR: {
      return {
        ...state,
        sourceLanguage: action.payload.sourceLanguage,
        targetLanguage: action.payload.targetLanguage,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default UploadDocumentsWizardReducer;
