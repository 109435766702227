import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Label,
  PrimaryButton,
  SearchBox,
  Stack,
  useTheme,
} from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { getProjects } from '../../../redux/projects/projectsActions';
import { useSharedFilterFormStyles } from '../Shared';
import LanguageDropdownIds from './languageOptions';

import filterProjectsQuery from '../../../utils/filterProjectsQuery';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

// TODO: Remove calls to API and do all filtering client side: https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/122318
const FilterProjectsForm: React.FC = () => {
  const theme = useTheme();
  const classes = useSharedFilterFormStyles();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { categories, languages, workspaces, filters } = useAppSelector((state) => state);
  const [projectSearchTerm, setProjectSearchTerm] = useState<string>(filters.projects.name);
  // Dropdown population values
  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<IDropdownOption[]>([]);
  // User selections
  const [selectedSourceLanguageKeys, setSelectedSourceLanguageKeys] = useState<number[]>(
    filters.projects.sourceLanguages
  );
  const [selectedTargetLanguageKeys, setSelectedTargetLanguageKeys] = useState<number[]>(
    filters.projects.targetLanguages
  );
  const [selectedCategoryKeys, setSelectedCategoryKeys] = useState<number[]>(filters.projects.categories);

  const hasFilterOptions =
    !isEmpty(projectSearchTerm) ||
    !isEmpty(selectedSourceLanguageKeys) ||
    !isEmpty(selectedTargetLanguageKeys) ||
    !isEmpty(selectedCategoryKeys);

  const collateLanguageOptions = useCallback((): void => {
    const languageObjects = languages;
    const languageKeys = Object.keys(languages);

    // Sort alphabetically
    languageKeys.sort((a, b) => {
      const displayNameA = languageObjects[a].displayName.toUpperCase();
      const displayNameB = languageObjects[b].displayName.toUpperCase();
      if (displayNameA < displayNameB) {
        return -1;
      }
      if (displayNameA > displayNameB) {
        return 1;
      }
      return 0;
    });

    const collatedLanguageOptions: IDropdownOption[] = [];

    languageKeys.forEach((languageKey) => {
      collatedLanguageOptions.push({
        key: languageObjects[languageKey].id,
        text: languageObjects[languageKey].displayName,
      });
    });
    setLanguageOptions(collatedLanguageOptions);
  }, [languages]);

  const collateCategoryOptions = useCallback((): void => {
    const categoryObjects = categories.data;
    const categoryKeys = Object.keys(categories.data);

    // Sort alphabetically
    categoryKeys.sort((a, b) => {
      const displayNameA = categoryObjects[a].name.toUpperCase();
      const displayNameB = categoryObjects[b].name.toUpperCase();
      if (displayNameA < displayNameB) {
        return -1;
      }
      if (displayNameA > displayNameB) {
        return 1;
      }
      return 0;
    });

    const collatedCategoryOptions: IDropdownOption[] = [];
    categoryKeys.forEach((categoryKey) => {
      collatedCategoryOptions.push({
        key: categoryObjects[categoryKey].id,
        text: categoryObjects[categoryKey].name,
      });
    });
    setCategoryOptions(collatedCategoryOptions);
  }, [categories.data]);

  useEffect(() => {
    if (!isEmpty(categories.data) && !categories.isLoading) {
      collateCategoryOptions();
    }
  }, [categories.data, categories.isLoading, collateCategoryOptions]);

  useEffect(() => {
    if (!isEmpty(languages)) {
      collateLanguageOptions();
    }
  }, [languages, collateLanguageOptions]);

  const onProjectSearchTermChange = (event?: React.FormEvent<HTMLInputElement>): void => {
    const searchTerm = (event?.target as HTMLInputElement)?.value || '';

    setProjectSearchTerm(searchTerm);
  };

  const onLanguageChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    const whichLanguageDropdown = (event?.target as HTMLInputElement).id;

    if (item) {
      if (whichLanguageDropdown === LanguageDropdownIds.Source) {
        setSelectedSourceLanguageKeys(
          item.selected
            ? [...selectedSourceLanguageKeys, item.key as number]
            : selectedSourceLanguageKeys.filter((key: number) => key !== item.key)
        );
      }

      if (whichLanguageDropdown === LanguageDropdownIds.Target) {
        setSelectedTargetLanguageKeys(
          item.selected
            ? [...selectedTargetLanguageKeys, item.key as number]
            : selectedTargetLanguageKeys.filter((key: number) => key !== item.key)
        );
      }
    }
  };

  const onCategoryChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedCategoryKeys(
        item.selected
          ? [...selectedCategoryKeys, item.key as number]
          : selectedCategoryKeys.filter((key: number) => key !== item.key)
      );
    }
  };

  const handleClearAll = (): void => {
    batch(() => {
      setProjectSearchTerm('');
      setSelectedSourceLanguageKeys([]);
      setSelectedTargetLanguageKeys([]);
      setSelectedCategoryKeys([]);
    });
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    const filterQuery = filterProjectsQuery({
      projectSearchTerm,
      selectedCategoryKeys,
      categories,
      selectedSourceLanguageKeys,
      languages,
      selectedTargetLanguageKeys,
    });

    appDispatch({
      type: 'SET_PROJECT_FILTER_NAME',
      payload: {
        name: projectSearchTerm,
        nameLabel: t('components.forms.filterProjects.projectInput.label'),
      },
    });

    appDispatch({
      type: 'SET_PROJECT_FILTER_SOURCE_LANGUAGES',
      payload: {
        sourceLanguages: selectedSourceLanguageKeys,
        sourceLanguagesLabel: t('components.forms.filterProjects.sourceLanguageDropdown.label'),
      },
    });

    appDispatch({
      type: 'SET_PROJECT_FILTER_TARGET_LANGUAGES',
      payload: {
        targetLanguages: selectedTargetLanguageKeys,
        targetLanguagesLabel: t('components.forms.filterProjects.targetLanguageDropdown.label'),
      },
    });

    appDispatch({
      type: 'SET_PROJECT_FILTER_CATEGORIES',
      payload: {
        categories: selectedCategoryKeys,
        categoriesLabel: t('components.forms.filterProjects.categoryDropdown'),
      },
    });

    appDispatch(getProjects(workspaces.currentWorkspace.id, filterQuery));
  };

  const bodyStackTokens: IStackTokens = { childrenGap: theme.spacing.l2 };
  const actionButtonStackTokens: IStackTokens = { childrenGap: theme.spacing.s1 };

  return (
    <Stack as="form" className={classes.root} onSubmit={handleSubmit} verticalAlign="space-between">
      <Stack className={classes.body} tokens={bodyStackTokens}>
        {/* 
          PROJECT SEARCH
        */}
        <Stack.Item>
          <Label htmlFor="project-search" styles={{ root: { marginBottom: 4 } }}>
            {t('components.forms.filterProjects.projectInput.label')}
          </Label>
          <SearchBox
            id="project-search"
            name="project-search"
            placeholder={t('components.forms.filterCommon.searchPlaceholder')}
            value={projectSearchTerm}
            onChange={(e): void => onProjectSearchTermChange(e)}
            onClear={(): void => setProjectSearchTerm('')}
          />
        </Stack.Item>
        {/* 
            SOURCE LANGUAGE
          */}
        <Stack.Item>
          <Dropdown
            id={LanguageDropdownIds.Source}
            placeholder={t('components.forms.filterProjects.sourceLanguageDropdown.placeholder')}
            label={t('components.forms.filterProjects.sourceLanguageDropdown.label')}
            options={languageOptions}
            multiSelect
            selectedKeys={selectedSourceLanguageKeys}
            onChange={onLanguageChange}
            disabled={!languageOptions}
            className={classes.dropdown}
          />
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clear')}
            onClick={(): void => setSelectedSourceLanguageKeys([])}
            disabled={isEmpty(selectedSourceLanguageKeys)}
            className="as-link"
          />
        </Stack.Item>
        {/* 
            TARGET LANGUAGE
          */}
        <Stack.Item>
          <Dropdown
            id={LanguageDropdownIds.Target}
            placeholder={t('components.forms.filterProjects.targetLanguageDropdown.placeholder')}
            label={t('components.forms.filterProjects.targetLanguageDropdown.label')}
            options={languageOptions}
            multiSelect
            selectedKeys={selectedTargetLanguageKeys}
            onChange={onLanguageChange}
            disabled={!languageOptions}
            className={classes.dropdown}
          />
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clear')}
            onClick={(): void => setSelectedTargetLanguageKeys([])}
            disabled={isEmpty(selectedTargetLanguageKeys)}
            className="as-link"
          />
        </Stack.Item>
        {/* 
            CATEGORY
          */}
        <Stack.Item>
          <Dropdown
            placeholder={t('components.forms.filterProjects.categoryDropdown')}
            label={t('components.forms.filterProjects.categoryDropdown')}
            options={categoryOptions}
            selectedKeys={selectedCategoryKeys}
            onChange={onCategoryChange}
            multiSelect
            disabled={!categoryOptions}
            className={classes.dropdown}
          />
          <DefaultButton
            text={t('components.forms.filterCommon.actionButtons.clear')}
            onClick={(): void => setSelectedCategoryKeys([])}
            disabled={isEmpty(selectedCategoryKeys)}
            className="as-link"
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <Stack.Item>
          <Stack as="footer" horizontal tokens={actionButtonStackTokens}>
            <Stack.Item>
              <DefaultButton
                text={t('components.forms.filterCommon.actionButtons.clearAll')}
                onClick={(): void => handleClearAll()}
                disabled={!hasFilterOptions}
              />
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton text={t('components.forms.filterCommon.actionButtons.apply')} type="submit" />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default FilterProjectsForm;
