import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useTrainingStatusStyles from './TrainingStatus.styles';

import { ModelStatus } from '../../utils/constants/modelStatus';
import { capitalize } from '../../utils/textTransform';

interface ITrainingStatusProps {
  status: string;
  /** Will display filled in icon otherwise will default to outline */
  isSolidIcon?: boolean;
}

/** Displays the model training status text with appropriate icon of success or failure. Default renders text only */
const TrainingStatus: React.FC<ITrainingStatusProps> = ({ status, isSolidIcon = false }) => {
  const classes = useTrainingStatusStyles();
  const { t } = useTranslation();

  let statusContent = null;

  switch (status) {
    case ModelStatus.TrainingSucceeded:
      statusContent = (
        <Fragment>
          <Icon
            iconName={isSolidIcon ? 'CompletedSolid' : 'Completed'}
            className={clsx(classes.icon, classes.successIcon)}
          />
          {capitalize(status)}
        </Fragment>
      );
      break;
    case ModelStatus.TrainingFailed:
    case ModelStatus.PublishFailed:
    case ModelStatus.DataProcessingFailed:
      statusContent = (
        <Fragment>
          <Icon
            iconName={isSolidIcon ? 'StatusErrorFull' : 'ErrorBadge'}
            className={clsx(classes.icon, classes.failedIcon)}
          />
          {capitalize(status)}
        </Fragment>
      );
      break;
    case ModelStatus.TrainingRunning:
    case ModelStatus.DataProcessing:
    case ModelStatus.TrainingQueued:
    case ModelStatus.TrainingSubmitted:
    case ModelStatus.Unknown:
      statusContent = `${t('pages.model.contentSection.details.status.trainingProgress.title')}`;
      break;
    case ModelStatus.Draft: {
      statusContent = (
        <Fragment>
          <Icon iconName="ProductRelease" className={classes.icon} />
          {capitalize(status)}
        </Fragment>
      );
      break;
    }
    default:
      statusContent = capitalize(status);
  }
  return <div className={classes.root}>{statusContent}</div>;
};

export default TrainingStatus;
