import {
  DetailsListLayoutMode,
  IColumn,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { IDocumentContent } from '../../../redux/documentContent/documentContent.types';
import ErrorFallback from '../../ErrorFallback';
import { Pagination } from '../../Pagination';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import usePaginatedDocumentTableStyles from './DocumentTable.styles';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppSelector } from '../../../utils/hooks';

const DocumentTable: React.FC = () => {
  const { t } = useTranslation();
  const classes = usePaginatedDocumentTableStyles();
  const { documentId } = useParams<IRoutePathParams>();
  const location = useLocation();
  const { documentContent, documents, languages, files } = useAppSelector((state) => state);

  // Pagination variables
  const totalPageCount = documentContent[documentId].totalPageCount.max;
  const currentPage = documentContent[documentId].pageIndex;
  const displayPagination = currentPage !== 0 && totalPageCount > 1;

  // Get languages for each file
  const documentFiles = documents.data[documentId].files;
  const sourceFileLanguage = files[documentFiles[0]].language;
  const targetFileLanguage = files[documentFiles[1]].language;
  const sourceLanguage = languages[sourceFileLanguage].displayName;
  const targetLanguage = languages[targetFileLanguage].displayName;

  // TODO: Update to support monolingual documents.
  // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/120410
  const documentContentColumns: IColumn[] = [
    {
      key: 'sourceSentences',
      name: t('components.tables.sharedColumns.sentences', { language: sourceLanguage }),
      minWidth: 200,
      maxWidth: 500,
      onRender: (content: IDocumentContent): React.ReactElement => {
        return <Text>{content.sourceSentence}</Text>;
      },
      columnActionsMode: 0,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: 'targetSentences',
      name: t('components.tables.sharedColumns.sentences', { language: targetLanguage }),
      minWidth: 200,
      maxWidth: 500,
      onRender: (content: IDocumentContent): React.ReactElement => {
        return <Text>{content.targetSentence}</Text>;
      },
      columnActionsMode: 0,
      isMultiline: true,
      isResizable: true,
    },
  ];

  const documentContentTable = (
    <div data-test-id="document-table">
      <DetailsListKeyboardAccessible
        enableShimmer={documentContent[documentId].isLoading}
        items={documentContent[documentId].data}
        columns={documentContentColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        ariaLabel={t('components.tables.manageDocuments.tableAria')}
        ariaLabelForSelectionColumn={t('components.tables.manageDocuments.columns.columnSelectAria')}
        checkButtonAriaLabel={t('components.tables.manageDocuments.checkButtonAria')}
      />
    </div>
  );

  const documentContentView = displayPagination ? (
    <Stack>
      <StackItem className={classes.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{documentContentTable}</ScrollablePane>
      </StackItem>
      <StackItem>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Pagination totalPageCount={totalPageCount} currentPage={currentPage} currentPath={location.pathname} />
        </Sticky>
      </StackItem>
    </Stack>
  ) : (
    <div>{documentContentTable}</div>
  );

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{documentContentView}</ErrorBoundary>;
};

export default DocumentTable;
