import { Link, Stack, Text } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React from 'react';
import { Translation } from 'react-i18next';
import { ClientNotification, ClientNotificationStatus, ClientNotifications } from 'sharedui.studios/dist/package';

import { ICopyNotificationItem } from '../../../redux/copyNotifications/copyNotifications.types';
import { ICopyNotificationsState } from '../../../redux/copyNotifications/copyNotificationsReducer';
import {
  IDeploymentNotificationItem,
  IDeploymentRegionItem,
} from '../../../redux/deploymentNotifications/deploymentNotifications.types';
import { IDeploymentNotificationsState } from '../../../redux/deploymentNotifications/deploymentNotificationsReducer';
import { INotificationsState } from '../../../redux/notifications/notificationsReducer';
import { ITrainingNotificationItem } from '../../../redux/trainingNotifications/trainingNotifications.types';
import { ITrainingNotificationsState } from '../../../redux/trainingNotifications/trainingNotificationsReducer';
import { IUploadNotificationItem } from '../../../redux/uploadNotifications/uploadNotifications.types';
import { IUploadNotificationsState } from '../../../redux/uploadNotifications/uploadNotificationsReducer';
import TrainingProgress from '../../TrainingProgress/TrainingProgress';
import DeploymentNotificationRegionalProgress from '../DeploymentNotificationRegionalProgress';
import ExtractedFileProgress from '../ExtractedFileProgress';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import {
  DeploymentNotificationStatus,
  NotificationType,
  UploadNotificationStatus,
} from '../../../utils/constants/notifications';
import PublishOperation from '../../../utils/constants/publishOperation';
import { TrainingErrorCodes } from '../../../utils/constants/trainingTypes';

enum ClientNotificationDomain {
  Subscription = 'Subscription',
}

export enum NotificationDisplayType {
  Panel = 'Panel',
  Popup = 'Popup',
}

interface IErrorMsgProps {
  text?: JSX.Element;
  url?: string;
  urlText?: JSX.Element;
  postLinkText?: JSX.Element;
}

const ErrorMsg: React.FC<IErrorMsgProps> = ({ text, url, urlText, postLinkText }) => (
  <Stack.Item>
    <Text variant="small">{text}</Text>
    {url && (
      <Link styles={{ root: { fontSize: 12 } }} href={url} target="_blank" rel="noreferrer noopener">
        {urlText}
      </Link>
    )}
    {postLinkText && <Text variant="small">{postLinkText}</Text>}
  </Stack.Item>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LocalContainer = (ns: string, options?: any): JSX.Element => (
  <Translation>{(t): JSX.Element => <React.Fragment>{t(ns, options)}</React.Fragment>}</Translation>
);

const getDeploymentNotificationStatus = (regions: IDeploymentRegionItem[]): ClientNotificationStatus => {
  let hasFailed = false;
  let hasSucceeded = true;
  const failedStates = [DeploymentNotificationStatus.Cancelled, DeploymentNotificationStatus.Failed];
  regions.forEach((region: IDeploymentRegionItem) => {
    if (failedStates.includes(region.status)) {
      hasFailed = true;
    }
    if (region.status !== DeploymentNotificationStatus.Succeeded) {
      hasSucceeded = false;
    }
  });
  if (hasSucceeded) return ClientNotificationStatus.Succeeded;
  if (hasFailed) return ClientNotificationStatus.Failed;
  return ClientNotificationStatus.Processing;
};

const getTrainingNotificationStatus = (modelStatus: string): ClientNotificationStatus => {
  const successStatus: string[] = [ModelStatus.TrainingSucceeded, ModelStatus.Published];
  if (successStatus.includes(modelStatus)) return ClientNotificationStatus.Succeeded;
  const failureStatus: string[] = [
    ModelStatus.DataProcessingFailed,
    ModelStatus.TrainingFailed,
    ModelStatus.PublishFailed,
  ];
  if (failureStatus.includes(modelStatus)) return ClientNotificationStatus.Failed;
  return ClientNotificationStatus.Processing;
};

const getUploadNotificationStatus = (status: UploadNotificationStatus): ClientNotificationStatus => {
  if (status === UploadNotificationStatus.Succeeded) return ClientNotificationStatus.Succeeded;
  if (status === UploadNotificationStatus.Failed) return ClientNotificationStatus.Failed;
  return ClientNotificationStatus.Processing;
};

const getCopyingNotificationStatus = (modelStatus: string | undefined): ClientNotificationStatus =>
  modelStatus === ModelStatus.CopyCompleted ? ClientNotificationStatus.Succeeded : ClientNotificationStatus.Processing;

const formatDeployment = (
  notification: IDeploymentNotificationItem,
  type?: NotificationDisplayType
): ClientNotification => {
  const { regions, operation, name, swapModelName } = notification;

  const status = getDeploymentNotificationStatus(regions);
  let title = <React.Fragment />;
  let subtitle = <React.Fragment />;

  switch (status) {
    case ClientNotificationStatus.Succeeded:
      switch (operation) {
        case PublishOperation.Publish:
          title = LocalContainer('components.notificationPanel.deployment.publishingSucceeded', { name });
          break;
        case PublishOperation.Swap:
          title = LocalContainer('components.notificationPanel.deployment.publishingSucceeded', { name });
          subtitle = LocalContainer('components.notificationPanel.deployment.swapSucceededSubtitle', {
            swapModelName,
          });
          break;
        case PublishOperation.Unpublish:
          title = LocalContainer('components.notificationPanel.deployment.unpublishSucceeded', { name });
          break;
        case PublishOperation.Update:
          title = LocalContainer('components.notificationPanel.deployment.updateSucceeded', { name });
          break;
        default:
          break;
      }
      break;
    case ClientNotificationStatus.Failed:
      subtitle = LocalContainer('components.notificationPanel.deployment.failureSubtitle');
      switch (operation) {
        case PublishOperation.Publish:
          title = LocalContainer('components.notificationPanel.deployment.publishingFailure', { name });
          break;
        case PublishOperation.Swap:
          title = LocalContainer('components.notificationPanel.deployment.publishingFailure', { name });
          subtitle = LocalContainer('components.notificationPanel.deployment.swapFailureSubtitle', { swapModelName });
          break;
        case PublishOperation.Update:
          title = LocalContainer('components.notificationPanel.deployment.updateFailure', { name });
          break;
        default:
          break;
      }
      break;
    case ClientNotificationStatus.Processing:
      switch (operation) {
        case PublishOperation.Publish:
          title = (
            <Translation>
              {(t): JSX.Element => (
                <React.Fragment>
                  {t('components.notificationPanel.deployment.publishingInProgress', { name })}
                </React.Fragment>
              )}
            </Translation>
          );
          // withTranslation('components.notificationPanel.deployment.publishingInProgress', { name })(() => (
          //   <React.Fragment />
          // ));
          // t();
          subtitle = LocalContainer('components.notificationPanel.deployment.publishingInProgressSubtitle');
          break;
        case PublishOperation.Swap:
          title = LocalContainer('components.notificationPanel.deployment.publishingInProgress', { name });
          subtitle = LocalContainer('components.notificationPanel.deployment.swapInProgressSubtitle', {
            swapModelName,
          });
          break;
        case PublishOperation.Unpublish:
          title = LocalContainer('components.notificationPanel.deployment.unpublishInProgress', { name });
          subtitle = LocalContainer('components.notificationPanel.deployment.unpublishSubtitle');
          break;
        case PublishOperation.Update:
          title = LocalContainer('components.notificationPanel.deployment.updateInProgress', { name });
          subtitle = LocalContainer('components.notificationPanel.deployment.updateInProgressSubtitle');
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return {
    id: notification.id,
    domain: ClientNotificationDomain.Subscription, // required
    title,
    message: (
      <React.Fragment>
        {subtitle && <Text variant="small">{subtitle}</Text>}
        {type !== NotificationDisplayType.Popup && (
          <DeploymentNotificationRegionalProgress notification={notification} />
        )}
      </React.Fragment>
    ),
    status,
    createdAt: new Date(), // required, but not using in this case yet
    updatedAt: new Date(), // required, but not using in this case yet
    // tagId?: string,
    // blockBeforeUnload?: boolean,
    // data?: string,
    // domainProperties?: INotificationDomainPropertiesBase,
    silent: false,
  };
};

const formatTraining = (
  notification: ITrainingNotificationItem,
  type?: NotificationDisplayType
): ClientNotification => {
  const { modelStatus, name, errorCode } = notification;
  const error: IErrorMsgProps = {};
  const status = getTrainingNotificationStatus(modelStatus);
  if (status === ClientNotificationStatus.Failed) {
    if (errorCode === TrainingErrorCodes.SelectTestSets) {
      error.text = LocalContainer('pages.model.contentSection.details.status.errorMessage.lackingUniqueSentences');
    } else {
      error.text = LocalContainer('pages.model.contentSection.details.status.errorMessage.defaultWithEmail');
    }
  }
  let title = <React.Fragment />;
  let subtitle = <React.Fragment />;
  switch (modelStatus) {
    case ModelStatus.DataProcessing:
    case ModelStatus.TrainingSubmitted:
    case ModelStatus.TrainingQueued:
    case ModelStatus.TrainingRunning:
      title = LocalContainer('components.notificationPanel.training.inProgress', { name });
      subtitle = LocalContainer('components.notificationPanel.training.defaultMessage'); // ToDo: should change to a real runtime
      break;
    case ModelStatus.TrainingSucceeded:
      title = LocalContainer('components.notificationPanel.training.succeeded', { name });
      break;
    case ModelStatus.TrainingFailed:
    case ModelStatus.DataProcessingFailed:
      title = LocalContainer('components.notificationPanel.training.failure', { name });
      break;
    default:
      break;
  }

  return {
    id: notification.id,
    domain: ClientNotificationDomain.Subscription, // required
    title,
    message: (
      <React.Fragment>
        {subtitle && <Text variant="small">{subtitle}</Text>}
        {type !== NotificationDisplayType.Popup && <TrainingProgress status={modelStatus} notification />}
        {type !== NotificationDisplayType.Popup && error.text && (
          <ErrorMsg text={error.text} url={error.url} urlText={error.urlText} postLinkText={error.postLinkText} />
        )}
      </React.Fragment>
    ),
    status,
    createdAt: new Date(), // required, but not using in this case yet
    updatedAt: new Date(), // required, but not using in this case yet
    // tagId?: string,
    // blockBeforeUnload?: boolean,
    // data?: string,
    // domainProperties?: INotificationDomainPropertiesBase,
    silent: false,
  };
};

const formatUpload = (notification: IUploadNotificationItem): ClientNotification => {
  const { status, name, errorMessage, files } = notification;

  const error: IErrorMsgProps = {};
  const notificationStatus = getUploadNotificationStatus(status);
  if (notificationStatus === ClientNotificationStatus.Failed) {
    // Determine error message
    if (!isEmpty(errorMessage)) {
      error.text = <React.Fragment>{errorMessage}</React.Fragment>;
    } else {
      // For a zip file, individual errors can be shown on each file. If there are no
      // individual errors shown on each filem show the generic error for the zip as
      // a whole.
      const noExtractedFileErrors =
        typeof files === 'object'
          ? Object.keys(files).every((fileId) => {
              return isEmpty(notification.files[fileId].errorMessage);
            })
          : true;
      const showGenericError = !notification.isZip || (notification.isZip && noExtractedFileErrors);
      if (showGenericError) {
        error.text = LocalContainer('components.notificationPanel.upload.genericFailureText1');
        error.url =
          'https://docs.microsoft.com/azure/cognitive-services/translator/custom-translator/document-formats-naming-convention';
        error.urlText = LocalContainer('components.notificationPanel.upload.genericFailureLink');
        error.postLinkText = LocalContainer('components.notificationPanel.upload.genericFailureText2');
      }
    }
  }

  let title = <React.Fragment />;
  let subtitle = <React.Fragment />;
  switch (status) {
    case UploadNotificationStatus.InProgress:
      title = LocalContainer('components.notificationPanel.upload.inProgressTitle', { name });
      subtitle = notification.isZip
        ? LocalContainer('components.notificationPanel.upload.zipInProgressSubtitle')
        : LocalContainer('components.notificationPanel.upload.inProgressSubtitle');
      break;
    case UploadNotificationStatus.Succeeded:
      title = LocalContainer('components.notificationPanel.upload.completedTitle', { name });
      break;
    case UploadNotificationStatus.Failed:
      title = LocalContainer('components.notificationPanel.upload.failedTitle', { name });
      break;
    default:
      break;
  }

  return {
    id: notification.id,
    domain: ClientNotificationDomain.Subscription, // required
    title,
    message: (
      <React.Fragment>
        {subtitle && <Text variant="small">{subtitle}</Text>}
        {!isEmpty(notification.files) && <ExtractedFileProgress notification={notification} />}
        {error.text && (
          <ErrorMsg text={error.text} url={error.url} urlText={error.urlText} postLinkText={error.postLinkText} />
        )}
      </React.Fragment>
    ),
    status: notificationStatus,
    createdAt: new Date(), // required, but not using in this case yet
    updatedAt: new Date(), // required, but not using in this case yet
    // tagId?: string,
    // blockBeforeUnload?: boolean,
    // data?: string,
    // domainProperties?: INotificationDomainPropertiesBase,
    silent: false,
  };
};

const formatCopying = (notification: ICopyNotificationItem): ClientNotification => {
  const { modelName, targetProjectName, targetWorkspaceName, modelStatus } = notification.data;
  const hasSucceeded = modelStatus === ModelStatus.CopyCompleted;
  const copyStatus = hasSucceeded ? 'copied' : 'copying';

  const status = getCopyingNotificationStatus(modelStatus);

  const title = `${modelName} ${copyStatus} to ${targetProjectName} in ${targetWorkspaceName}`;

  return {
    id: notification.id,
    domain: ClientNotificationDomain.Subscription, // required
    title,
    message: '',
    status,
    createdAt: new Date(), // required, but not using in this case yet
    updatedAt: new Date(), // required, but not using in this case yet
    // tagId?: string,
    // blockBeforeUnload?: boolean,
    // data?: string,
    // domainProperties?: INotificationDomainPropertiesBase,
    silent: false,
  };
};
interface IFormatNotifications {
  notifications: INotificationsState;
  trainingNotifications: ITrainingNotificationsState;
  deploymentNotifications: IDeploymentNotificationsState;
  uploadNotifications: IUploadNotificationsState;
  copyNotifications: ICopyNotificationsState;
  prevNotifications?: ClientNotifications;
  displayType?: NotificationDisplayType;
}
export const formatNotifications = (props: IFormatNotifications): ClientNotifications =>
  props.notifications.ids.reduce(
    (
      prev: { [x: string]: ClientNotification },
      item: { type: NotificationType; id: string | number; silent?: boolean }
    ) => {
      const currentNotifications = prev;
      if (props.prevNotifications && props.prevNotifications[item.id]) {
        let hasChange = true;
        switch (item.type) {
          case NotificationType.Deployment:
            hasChange =
              props.prevNotifications[item.id].status !==
              getDeploymentNotificationStatus(props.deploymentNotifications.data[item.id].regions);
            break;
          case NotificationType.Training:
            hasChange =
              props.prevNotifications[item.id].status !==
              getTrainingNotificationStatus(props.trainingNotifications.data[item.id].modelStatus);
            break;
          case NotificationType.Upload:
            hasChange =
              props.prevNotifications[item.id].status !==
              getUploadNotificationStatus(props.uploadNotifications.data[item.id].status);
            break;
          case NotificationType.Copying:
            hasChange =
              props.prevNotifications[item.id].status !==
              getCopyingNotificationStatus(props.copyNotifications.data[item.id].data.modelStatus);
            break;
          default:
            break;
        }
        if (!hasChange) {
          currentNotifications[item.id] = props.prevNotifications[item.id];
          currentNotifications[item.id].silent = true;
          return currentNotifications;
        }
      }
      switch (item.type) {
        case NotificationType.Deployment:
          currentNotifications[item.id] = formatDeployment(
            props.deploymentNotifications.data[item.id],
            props.displayType
          );
          break;
        case NotificationType.Training:
          currentNotifications[item.id] = formatTraining(props.trainingNotifications.data[item.id], props.displayType);
          break;
        case NotificationType.Upload:
          currentNotifications[item.id] = formatUpload(props.uploadNotifications.data[item.id]);
          break;
        case NotificationType.Copying:
          currentNotifications[item.id] = formatCopying(props.copyNotifications.data[item.id]);
          break;
        default:
          break;
      }
      currentNotifications[item.id].silent = false;
      return currentNotifications;
    },
    {} as ClientNotifications
  );

export default { formatNotifications };
