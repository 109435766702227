export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESSFUL = 'GET_CATEGORIES_SUCCESSFUL';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export interface ICategoryItem {
  id: number;
  name: string;
}

export interface ICategoryPayload {
  data: {
    [key: string]: ICategoryItem;
  };
}

export interface ICategoryEntities {
  categories: {
    [key: string]: ICategoryItem;
  };
}

export interface IGetCategories {
  type: typeof GET_CATEGORIES;
}

export interface IGetCategoriesSuccessful {
  type: typeof GET_CATEGORIES_SUCCESSFUL;
  payload: ICategoryPayload;
}

export interface IGetCategoriesFailed {
  type: typeof GET_CATEGORIES_FAILED;
}

export interface ISetCategories {
  type: typeof SET_CATEGORIES;
  payload: {
    data: {
      [key: string]: ICategoryItem;
    };
  };
}

export type CategoriesDispatchTypes = IGetCategories | IGetCategoriesSuccessful | IGetCategoriesFailed | ISetCategories;
