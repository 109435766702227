import { IBillingRegionItem } from '../billingRegions/billingRegions.types';
import { IRoleItem } from '../roles/roles.types';
import { ISharingItem } from '../sharing/sharing.types';
import { ISubscriptionItem } from '../subscription/subscription.types';
import { IUserItem } from '../users/users.types';

export const GET_WORKSPACES = 'GET_WORKSPACES';
export const GET_WORKSPACES_SUCCESSFUL = 'GET_WORKSPACES_SUCCESSFUL';
export const GET_WORKSPACES_FAILED = 'GET_WORKSPACES_FAILED';

export const ADD_WORKSPACE = 'ADD_WORKSPACE';

export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const CLEAR_CURRENT_WORKSPACE = 'CLEAR_CURRENT_WORKSPACE';

export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const DELETE_WORKSPACE_SUCCESSFUL = 'DELETE_WORKSPACE_SUCCESSFUL';
export const DELETE_WORKSPACE_FAILED = 'DELETE_WORKSPACE_FAILED';

export const RENAME_WORKSPACE = 'RENAME_WORKSPACE';
export const RENAME_WORKSPACE_SUCCESSFUL = 'RENAME_WORKSPACE_SUCCESSFUL';
export const RENAME_WORKSPACE_FAILED = 'RENAME_WORKSPACE_FAILED';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_COMPLETED = 'UPDATE_USER_ROLE_COMPLETED';

export const SET_DEFAULT_WORKSPACE = 'SET_DEFAULT_WORKSPACE';
export const SET_DEFAULT_WORKSPACE_SUCCESSFUL = 'SET_DEFAULT_WORKSPACE_SUCCESSFUL';
export const SET_DEFAULT_WORKSPACE_FAILED = 'SET_DEFAULT_WORKSPACE_FAILED';

export interface IWorkspaceItem {
  id: string;
  createdBy: string;
  createdDate: string;
  hasMigrations: boolean;
  isCreator: boolean;
  isDefaultWorkspace: boolean;
  name: string;
  region: string;
  role: number;
  sharing: number[];
  subscription: string;
  isSecured: boolean;
}

export interface IWorkspacesEntities {
  billingRegions: {
    [key: string]: IBillingRegionItem;
  };
  roles: {
    [key: string]: IRoleItem;
  };
  sharing: {
    [key: number]: ISharingItem;
  };
  users: {
    [key: string]: IUserItem;
  };
  workspaces: {
    [key: string]: IWorkspaceItem;
  };
  subscription: {
    [key: string]: ISubscriptionItem;
  };
}

export interface IBillingItem {
  isPaidSubscription: boolean;
  maximumCharacterCount: number;
  pricePerMillionCharacters: number;
}

export interface ICurrentWorkspaceItem {
  id: string;
  name: string;
}

export interface IWorkspacesPayload {
  ids: string[];
  data: {
    [key: string]: IWorkspaceItem;
  };
}

export interface IWorkspacePayload {
  id: string;
  workspace: IWorkspaceItem;
}

export interface IGetWorkspaces {
  type: typeof GET_WORKSPACES;
}

export interface IGetWorkspacesSuccessful {
  type: typeof GET_WORKSPACES_SUCCESSFUL;
  payload: IWorkspacesPayload;
}

export interface IGetWorkspacesFailed {
  type: typeof GET_WORKSPACES_FAILED;
}

export interface IDeleteWorkspace {
  type: typeof DELETE_WORKSPACE;
}

export interface IDeleteWorkspaceSuccessful {
  type: typeof DELETE_WORKSPACE_SUCCESSFUL;
  payload: IWorkspacesPayload;
}

export interface IDeleteWorkspaceFailed {
  type: typeof DELETE_WORKSPACE_FAILED;
}

export interface IRenameWorkspace {
  type: typeof RENAME_WORKSPACE;
}

export interface IRenameWorkspaceSuccessful {
  type: typeof RENAME_WORKSPACE_SUCCESSFUL;
  payload: {
    workspaceId: string;
    workspaceName: string;
  };
}

export interface IRenameWorkspaceFailed {
  type: typeof RENAME_WORKSPACE_FAILED;
}

export interface IAddWorkspace {
  type: typeof ADD_WORKSPACE;
  payload: IWorkspacePayload;
}

export interface ISetCurrentWorkspace {
  type: typeof SET_CURRENT_WORKSPACE;
  payload: ICurrentWorkspaceItem;
}

export interface IClearCurrentWorkspace {
  type: typeof CLEAR_CURRENT_WORKSPACE;
}

export interface IUpdateUserRole {
  type: typeof UPDATE_USER_ROLE;
  payload: string;
}

export interface IUpdateUserRoleCompleted {
  type: typeof UPDATE_USER_ROLE_COMPLETED;
  payload: string;
}

export interface ISetDefaultWorkspace {
  type: typeof SET_DEFAULT_WORKSPACE;
}

export interface ISetDefaultWorkspaceSuccessful {
  type: typeof SET_DEFAULT_WORKSPACE_SUCCESSFUL;
  payload: IWorkspacesPayload;
}

export interface ISetDefaultWorkspaceFailed {
  type: typeof SET_DEFAULT_WORKSPACE_FAILED;
}

export type WorkspacesDispatchTypes =
  | IGetWorkspaces
  | IGetWorkspacesSuccessful
  | IGetWorkspacesFailed
  | ISetCurrentWorkspace
  | IClearCurrentWorkspace
  | IDeleteWorkspace
  | IDeleteWorkspaceSuccessful
  | IDeleteWorkspaceFailed
  | IRenameWorkspace
  | IRenameWorkspaceSuccessful
  | IRenameWorkspaceFailed
  | IUpdateUserRole
  | IUpdateUserRoleCompleted
  | ISetDefaultWorkspace
  | ISetDefaultWorkspaceSuccessful
  | ISetDefaultWorkspaceFailed
  | IAddWorkspace;
