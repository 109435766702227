import { FontWeights, makeStyles } from '@fluentui/react';

const usePageBreadcrumbStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'inline-block',
    margin: `0 0 ${theme.spacing.s1} 0`,
    padding: `0 ${theme.spacing.s1}`,
    fontWeight: FontWeights.regular,
  },
  subtitleElement: {
    padding: `0 ${theme.spacing.s1}`,
  },
}));

export default usePageBreadcrumbStyles;
