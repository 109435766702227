import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import { Error } from '../components/GenericStates';
import { ModelsContent } from '../components/PageContents';
import { getModels } from '../redux/models/modelsActions';
import { getProjects } from '../redux/projects/projectsActions';
import { getWorkspaces } from '../redux/workspaces/workspacesActions';

import ErrorCategories from '../utils/constants/errorCategories';
import IRoutePathParams from '../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../utils/hooks';

const Models: React.FC = () => {
  useTrackMetric(reactPlugin, 'ModelsPage');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId, projectId } = useParams<IRoutePathParams>();
  const { workspaces, projects, models, error } = useAppSelector((state) => state);

  useEffect(() => {
    const hasWorkspaces = !isEmpty(workspaces.ids);
    const hasProjects = !isEmpty(projects.ids);
    const isLoading = workspaces.isLoading || projects.isLoading || models.isCreating;
    // Cleaning slice going back from single model page
    dispatch({ type: 'CLEAR_MODEL_DOCUMENTS' });

    if ((!hasWorkspaces || !hasProjects) && !isLoading && isEmpty(error.message)) {
      batch(() => {
        dispatch(getWorkspaces());
        dispatch(getProjects(workspaceId));
        dispatch(getModels(projectId));
      });
    }
  }, [
    dispatch,
    workspaceId,
    workspaces.isLoading,
    workspaces.ids,
    projectId,
    projects.isLoading,
    projects.ids,
    models.isCreating,
    error.message,
  ]);

  // Fetch models on load
  useEffect(() => {
    dispatch(getModels(projectId));
  }, [dispatch, projectId]);

  let content;
  if (!isEmpty(error.message) && error.category === ErrorCategories.Models) {
    content = <Error />;
  } else {
    content = <ModelsContent />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {process.env.REACT_APP_APPLICATION_NAME} | {t('pages.models.pageName')}
        </title>
      </Helmet>
      {content}
    </Fragment>
  );
};

export default Models;
