import {
  DetailsListLayoutMode,
  DirectionalHint,
  IColumn,
  ScrollablePane,
  ScrollablePaneBase,
  ScrollbarVisibility,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ErrorFallback from '../../ErrorFallback';
import { Pagination, PaginationResults } from '../../Pagination';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import { TextCell } from '../SharedTableCells';
import useTestModelResultsTableStyles from './TestModelResultsTable.styles';

import { useAppSelector, useQuery } from '../../../utils/hooks';

const TestModelResultsTable: React.FC = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const location = useLocation();
  const scrollPanelRef = useRef<ScrollablePaneBase>(null);
  const classes = useTestModelResultsTableStyles();
  const targetLanguageTooltipId = useId('targetLanguageTooltip');
  const { modelTestResults, languages, projects } = useAppSelector((state) => state);
  // API returns all test results and all pagination is being done on client side for improved performance
  const resultsToShow = 20;
  const totalResultsCount = modelTestResults.data.length;
  const [pagination, setPagination] = useState({ offset: 0, limit: resultsToShow });
  const totalPageCount = Math.ceil(totalResultsCount / resultsToShow);
  const paginatedResults = modelTestResults.data.slice(pagination.offset, pagination.limit);

  let currentPage = Number(query.get('page'));
  if (!currentPage) {
    currentPage = 1;
  }

  useEffect(() => {
    setPagination({
      offset: (currentPage - 1) * resultsToShow,
      limit: currentPage * resultsToShow,
    });
  }, [currentPage, resultsToShow]);

  useEffect(() => {
    if (scrollPanelRef) {
      const scrollPanelContainer = scrollPanelRef.current?.contentContainer;
      scrollPanelContainer?.scrollTo({ top: 0 });
    }
  }, [currentPage]);

  // Determining paired language
  const currentProject = projects.data[projects.currentProject.id];
  const sourceLanguage = languages[currentProject.languagePair.sourceLanguage].displayName;
  const targetLanguage = languages[currentProject.languagePair.targetLanguage].displayName;

  const testModelResultsColumns: IColumn[] = [
    {
      key: 'index',
      name: '',
      onRender: (item, index): number | null => {
        return index !== undefined ? index + 1 + pagination.offset : null;
      },
      minWidth: 10,
      maxWidth: 20,
      columnActionsMode: 0,
    },
    {
      key: 'sourceLanguage',
      name: sourceLanguage,
      onRender: ({ source }: { source: string }): JSX.Element => {
        return <TextCell text={source} wrapText variant="medium" />;
      },
      minWidth: 200,
      maxWidth: 700,
      isResizable: true,
      isSorted: false,
      columnActionsMode: 0,
    },
    {
      key: 'targetLanguage',
      name: targetLanguage,
      onRender: ({ target, referenceString, general }): string | JSX.Element => {
        const targetLanguageMap = [
          { key: 'reference', resultKey: referenceString },
          { key: 'newModel', resultKey: target },
          { key: 'baseModel', resultKey: general },
        ];
        return (
          <ul className={classes.targetLanguageList}>
            {targetLanguageMap.map(({ key, resultKey }) => (
              <li key={key}>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <Stack.Item>
                    <TooltipHost
                      id={targetLanguageTooltipId}
                      content={t(`components.tables.testModelResults.targetLanguage.${key}.tooltip`)}
                      directionalHint={DirectionalHint.leftCenter}
                    >
                      <Text variant="small" block>
                        {t(`components.tables.testModelResults.targetLanguage.${key}.title`)}:
                      </Text>
                    </TooltipHost>
                  </Stack.Item>
                  <Stack.Item>
                    <Text as="p">{resultKey}</Text>
                  </Stack.Item>
                </Stack>
              </li>
            ))}
          </ul>
        );
      },
      minWidth: 500,
      isResizable: false,
      isSorted: false,
      columnActionsMode: 0,
    },
  ];

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack>
        <Stack.Item>
          <PaginationResults offset={pagination.offset + 1} limit={pagination.limit} totalCount={totalResultsCount} />
        </Stack.Item>
        <Stack.Item className={classes.container}>
          <ScrollablePane
            id="scrollPanel"
            initialScrollPosition={200}
            scrollbarVisibility={ScrollbarVisibility.auto}
            componentRef={scrollPanelRef}
          >
            <div data-test-id="test-model-results-table">
              <DetailsListKeyboardAccessible
                className={classes.root}
                items={paginatedResults}
                columns={testModelResultsColumns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabel={t('components.tables.workspaces.tableAria')}
                ariaLabelForSelectionColumn={t('components.tables.workspaces.columns.columnSelectAria')}
                checkButtonAriaLabel={t('components.tables.workspaces.checkButtonAria')}
              />
            </div>
          </ScrollablePane>
        </Stack.Item>
        <Stack.Item>
          <Sticky stickyPosition={StickyPositionType.Footer}>
            <Pagination totalPageCount={totalPageCount} currentPage={currentPage} currentPath={location.pathname} />
          </Sticky>
        </Stack.Item>
      </Stack>
    </ErrorBoundary>
  );
};

export default TestModelResultsTable;
