import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { deleteDocument, downloadDocuments } from '../../../redux/documents/documentsActions';
import { AppDialog } from '../../AppPopUps';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { DocumentPopUpTypes } from '../../../utils/constants/popUps.types';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { AppDialogContentMap, useAppDialog, useAppDispatch, useAppSelector, useIsReader } from '../../../utils/hooks';

const DocumentCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { documents } = useAppSelector((state) => state);
  const { documentId } = useParams<IRoutePathParams>();
  const { appDialog, setAppDialog } = useAppDialog();
  const history = useHistory();
  const isReader = useIsReader();

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'documentDownload',
      text: t('components.commandBars.common.download'),
      ariaLabel: t('components.commandBars.documents.downloadButton.aria'),
      iconProps: { iconName: 'Download' },
      disabled: documents.isLoading,
      onClick: (): void => {
        setAppDialog({ contentType: DocumentPopUpTypes.Download, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-download/open' });
      },
    },
    {
      key: 'documentDelete',
      text: t('components.commandBars.common.delete'),
      ariaLabel: t('components.commandBars.documents.deleteButton.aria'),
      iconProps: { iconName: 'Delete' },
      className: clsx(isReader && 'no-display'),
      disabled: documents.isLoading,
      onClick: (): void => {
        setAppDialog({ contentType: DocumentPopUpTypes.Delete, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-delete/open' });
      },
    },
  ];

  let message = t('components.commandBars.common.updating');
  if (documents.isDownloading) {
    message = t('components.commandBars.common.downloading');
  }
  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader isVisible={documents.isUpdating || documents.isDownloading} message={message} />
      ),
    },
    /** TODO: Document details panel
    https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119001
    {
      key: 'documentInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    }, */
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [DocumentPopUpTypes.Delete]: {
      contentProps: {
        title: t('components.popups.document.delete.title'),
        subText: t('components.popups.document.delete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.delete'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(deleteDocument(documentId));
          });
          history.goBack();
        },
      },
    },
    [DocumentPopUpTypes.Download]: {
      contentProps: {
        title: t('components.popups.document.download.title'),
        subText: t('components.popups.document.download.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.download'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(downloadDocuments([documentId], documents.data[documentId].name));
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
    </Fragment>
  );
};

export default DocumentCommandBar;
