import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useWizardActionsStyles from './WizardActions.styles';
import IWizardActionsProps from './WizardActions.types';

const WizardActions: React.FC<IWizardActionsProps> = ({ handleCancelAndClose, confirmationButton, backButton }) => {
  const classes = useWizardActionsStyles();
  const { t } = useTranslation();

  const actionButtonHorizontalAlign = backButton ? 'space-between' : 'end';

  return (
    <Stack horizontal horizontalAlign={actionButtonHorizontalAlign} verticalAlign="end" className={classes.root}>
      {backButton && (
        <Stack.Item>
          <DefaultButton onClick={backButton.onClick} text={t('components.popups.common.back')} />
        </Stack.Item>
      )}

      <Stack.Item className={classes.actionButtons}>
        <PrimaryButton
          disabled={confirmationButton.isDisabled}
          text={confirmationButton.text}
          onClick={confirmationButton.onClick}
          form={confirmationButton.form}
          type={confirmationButton.form ? 'submit' : 'button'}
        />
        <DefaultButton onClick={handleCancelAndClose} text={t('components.popups.common.cancel')} />
      </Stack.Item>
    </Stack>
  );
};

export default WizardActions;
