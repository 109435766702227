import merge from 'lodash/merge';

import { CLEAR_SHARING, ISharingItem, SET_SHARING, SharingDispatchTypes } from './sharing.types';

interface ISharingState {
  [key: string]: ISharingItem;
}

const entitiesReducer = (state: ISharingState = {}, action: SharingDispatchTypes): ISharingState => {
  switch (action.type) {
    case SET_SHARING: {
      return merge(state, action.payload);
    }
    case CLEAR_SHARING: {
      return {};
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default entitiesReducer;
