import { Icon, Link, Stack } from '@fluentui/react';
import React from 'react';

import useFeatureCardStyles from './FeatureCard.styles';

interface IFeatureCardProps {
  image: {
    src: string;
    alt: string;
    className?: string;
  };
  title: string;
  description: string;
  link: {
    text: string;
    href: string;
    target?: string;
  };
}

const FeatureCard: React.FC<IFeatureCardProps> = ({ image, title, description, link }) => {
  const classes = useFeatureCardStyles();
  return (
    <Stack className={classes.container}>
      <Stack.Item className={classes.image}>
        <img src={image.src} alt={image.alt} />
      </Stack.Item>
      <Stack.Item className={classes.title}>{title}</Stack.Item>
      <Stack.Item className={classes.subtitle}>{description}</Stack.Item>
      <Stack.Item className={classes.link}>
        <Link href={link.href} target={link.target || '_self'} rel="noreferrer noopener">
          {link.text} <Icon iconName="ChevronRight" />
        </Link>
      </Stack.Item>
    </Stack>
  );
};

export default FeatureCard;
