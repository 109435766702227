export interface IBleuScoreProps {
  bleuScore: number;
  baselineBleuScore: number;
  size?: BleuScoreSize;
  fullColor?: boolean;
}

export enum BleuScoreSize {
  Small = 'small',
  Large = 'large',
}
