import { FontWeights, makeStyles } from '@fluentui/react';

const useModelDetailsStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: 900,
    height: 'auto',
    margin: `${theme.spacing.l1} 0`,
    padding: `${theme.spacing.l1} ${theme.spacing.m} ${theme.spacing.l1} 30px`,
    border: `2px solid ${theme.palette.neutralLight}`,
  },
  badge: {
    position: 'absolute',
    right: theme.spacing.l2,
  },
  trainingStatus: {
    display: 'block',
    maxWidth: 600,
    marginBottom: theme.spacing.l1,
    fontSize: 18,
    fontWeight: 600,
  },
  bleuScore: {
    fontSize: 32,
    fontWeight: FontWeights.semibold,
  },
  bleuScoreDelta: {
    marginLeft: 32,
    marginBottom: theme.spacing.m,
  },
}));

export default useModelDetailsStyles;
