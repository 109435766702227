import {
  ActionButton,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Stack,
  Text,
  TextField,
  useTheme,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import createProjectImage from '../../../assets/images/create-project.svg';
import { createProject } from '../../../redux/projects/projectsActions';
import AzureModelAnnouncement from '../../AzureModelAnnouncement';
import FormActions from '../Shared/FormActions';
import RenderLabelTooltip from '../SharedFormElements/RenderLabelTooltip';
import useCreateProjectFormStyles from './CreateProjectForm.styles';
import { ICreateProjectFormProps, ICreateProjectFormValues } from './CreateProjectForm.types';

import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/appRedux.helpers';
import isProjectLabelValid from '../../../utils/isProjectLabelValid';

const CreateProjectForm: React.FC<ICreateProjectFormProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const classes = useCreateProjectFormStyles();
  const { categories, supportedLanguagePairs } = useAppSelector((state) => state);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [domainList, setDomainList] = useState<IDropdownOption[]>([]);
  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<string>('');
  const [sourceLanguageList, setSourceLanguageList] = useState<IDropdownOption[]>([]);
  const [targetLanguageList, setTargetLanguageList] = useState([] as IDropdownOption[]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const { workspaceId } = useParams<IRoutePathParams>();
  const [azureModelKey, setAzureModelKey] = useState('');

  const [formValues, setFormValues] = useState(
    (): ICreateProjectFormValues => {
      const initialState = {
        projectName: '',
        projectDescription: '',
        projectCategoryDescription: '',
        projectLabel: '',
        projectLanguagePairId: NaN,
        projectDomainId: NaN,
      };
      return initialState;
    }
  );

  // Set Azure Model Key
  useEffect(() => {
    const { projectLanguagePairId, projectDomainId } = formValues;

    if (projectLanguagePairId && projectDomainId) {
      setAzureModelKey([projectLanguagePairId, projectDomainId].join(','));
    }
  }, [formValues]);

  // Setup sourceLanguages list
  useEffect(() => {
    const categoriesMap = new Map(Object.entries(categories.data));
    let dropdownOptions: IDropdownOption[] = [];

    // Populate domain list
    categoriesMap.forEach((value) => {
      dropdownOptions.push({ key: String(value.id), text: value.name });
    });
    setDomainList(dropdownOptions);

    dropdownOptions = [];
    // Populate source language list
    const sourceLanguages = new Map();
    Object.values(supportedLanguagePairs.data).forEach((value) => {
      // Even if there are any duplicates, since this is a map, it'll be omitted.
      sourceLanguages.set(
        String(value.sourceLanguage.id),
        `${value.sourceLanguage.displayName} (${value.sourceLanguage.languageCode})`
      );
    });
    dropdownOptions = Array.from(sourceLanguages, ([key, text]) => ({ key, text }));
    dropdownOptions.sort((a, b) => a.text.localeCompare(b.text));

    setSourceLanguageList(dropdownOptions);
  }, [categories.data, supportedLanguagePairs]);

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    projectName: undefined,
    projectLabel: undefined,
    projectSourceLanguage: undefined,
    projectLanguagePairId: undefined,
    projectDomainId: undefined,
  });

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      const validationLengthMap: { [key: string]: number } = {
        projectName: FormValidationLengths.MAX_PROJECT_NAME_LENGTH,
        projectDescription: FormValidationLengths.MAX_PROJECT_DESCRIPTION_LENGTH,
        projectDomainDescription: FormValidationLengths.MAX_PROJECT_CATEGORY_DESCRIPTION_LENGTH,
        projectLabel: FormValidationLengths.MAX_PROJECT_LABEL_LENGTH,
      };

      const inputName = (event.target as HTMLInputElement).name;

      if (!newValue || newValue?.length <= validationLengthMap[inputName]) {
        setFormValues({ ...formValues, [inputName]: newValue || '' });
      }
      if (!newValue) {
        setErrors({ ...errors, [inputName]: t('components.forms.generalErrors.required') });
      } else {
        setErrors({ ...errors, [inputName]: undefined });
      }
    },
    [formValues, errors, t]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    const hasProjectName = !(formValues.projectName === '');
    const hasProjectDomainSelected = !Number.isNaN(formValues.projectDomainId);
    const hasProjectSourceLanguageSelected = !(selectedSourceLanguage === '');
    const hasLanguagePairSelected = !Number.isNaN(formValues.projectLanguagePairId);
    let hasInlineErrors = false;

    if (!hasProjectName) {
      setErrors((prevErrors) => ({ ...prevErrors, projectName: t('components.forms.generalErrors.required') }));
      hasInlineErrors = true;
    }
    if (!hasProjectSourceLanguageSelected) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        projectSourceLanguage: t('components.forms.generalErrors.required'),
      }));
      hasInlineErrors = true;
    }
    if (!hasLanguagePairSelected) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        projectLanguagePairId: t('components.forms.generalErrors.required'),
      }));
      hasInlineErrors = true;
    }
    if (!hasProjectDomainSelected) {
      setErrors((prevErrors) => ({ ...prevErrors, projectDomainId: t('components.forms.generalErrors.required') }));
      hasInlineErrors = true;
    }
    if (!isProjectLabelValid(formValues.projectLabel)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        projectLabel: t('components.forms.createProject.projectLabel.invalid'),
      }));
      hasInlineErrors = true;
    }

    if (hasInlineErrors) {
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'project-create/inline-errors' });
    } else {
      handleCancelAndClose();
      dispatch(createProject(formValues, workspaceId));
    }
  };

  // When the source language changes, repopulate the target language dropdown list and unset the target language
  const onChangeSourceLanguage = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    const newTargetLanguageList: IDropdownOption[] = [];
    if (item !== undefined) {
      Object.values(supportedLanguagePairs.data).forEach((value) => {
        if (String(value.sourceLanguage.id) === item.key) {
          newTargetLanguageList.push({
            key: String(value.targetLanguage.id),
            text: `${value.targetLanguage.displayName} (${value.targetLanguage.languageCode})`,
          });
        }
      });
      newTargetLanguageList.sort((a, b) => a.text.localeCompare(b.text));

      setTargetLanguageList(newTargetLanguageList);
      setSelectedSourceLanguage(String(item.key));
      setErrors({ ...errors, projectSourceLanguage: undefined });
      setFormValues({ ...formValues, projectLanguagePairId: NaN });
    }
  };

  const onLanguagePairSelection = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      Object.values(supportedLanguagePairs.data).forEach((element) => {
        if (
          String(element.sourceLanguage.id) === selectedSourceLanguage &&
          String(element.targetLanguage.id) === item.key
        ) {
          setFormValues({ ...formValues, projectLanguagePairId: element.id });
          setErrors({ ...errors, projectLanguagePairId: undefined });
        }
      });
    }
  };

  const onDomainSelection = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setFormValues({ ...formValues, projectDomainId: item.key as number });
      setErrors({ ...errors, projectDomainId: undefined });
    }
  };

  const infoStackTokens: IStackTokens = { maxWidth: 200, childrenGap: theme.spacing.l1 };
  const parentStackTokens: IStackTokens = { childrenGap: theme.spacing.l1 };
  const textFieldStackTokens: IStackTokens = { childrenGap: 11 };
  const projectLabelId = useId('project-label-tooltip');
  const sourceLanguageId = useId('source-language-tooltip');
  const targetLanguageId = useId('target-language-tooltip');
  const domainId = useId('domain-tooltip');

  return (
    <Fragment>
      <Text className={classes.subtitle} variant="mediumPlus" as="p" block>
        {t('components.forms.createProject.subtitle')}
      </Text>
      <Stack horizontal tokens={parentStackTokens}>
        <Stack tokens={infoStackTokens}>
          <Stack.Item>
            <img
              src={createProjectImage}
              alt={t('components.forms.createProject.imageAlt')}
              className={classes.image}
            />
          </Stack.Item>
          <Stack.Item>
            <Text className={classes.question}>{t('components.forms.createProject.projectQuestion')}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text>{t('components.forms.createProject.projectAnswer')}</Text>
          </Stack.Item>
        </Stack>
        <Stack.Item grow>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              label={t('components.forms.createProject.projectName.label')}
              name="projectName"
              value={formValues.projectName}
              onChange={handleChange}
              placeholder={t('components.forms.createProject.projectName.placeholder')}
              errorMessage={errors?.projectName}
              className={classes.formField}
              required
            />
            <Stack horizontal horizontalAlign="space-evenly" verticalAlign="center" className={classes.formField}>
              <Stack.Item grow={2} align="start">
                <Dropdown
                  label={t('components.forms.createProject.sourceLanguage.label')}
                  ariaLabel={t('components.forms.createProject.sourceLanguage.label')}
                  options={sourceLanguageList}
                  onChange={onChangeSourceLanguage}
                  errorMessage={errors?.projectSourceLanguage}
                  required
                  onRenderLabel={(): React.ReactElement => (
                    <RenderLabelTooltip
                      tooltipId={sourceLanguageId}
                      tooltipContent={t('components.forms.createProject.sourceLanguage.tooltip')}
                      tooltipLabel={t('components.forms.createProject.sourceLanguage.label')}
                      required
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item grow align="start">
                <Text className={classes.labelBetweenLanguages}>{t('components.forms.createProject.to')}</Text>
              </Stack.Item>
              <Stack.Item grow={2} align="start">
                <Dropdown
                  label={t('components.forms.createProject.targetLanguage.label')}
                  ariaLabel={t('components.forms.createProject.targetLanguage.label')}
                  options={targetLanguageList}
                  onChange={onLanguagePairSelection}
                  defaultSelectedKey={0}
                  errorMessage={errors?.projectLanguagePairId}
                  required
                  onRenderLabel={(): React.ReactElement => (
                    <RenderLabelTooltip
                      tooltipId={targetLanguageId}
                      tooltipLabel={t('components.forms.createProject.targetLanguage.label')}
                      tooltipContent={t('components.forms.createProject.targetLanguage.tooltip')}
                      required
                    />
                  )}
                />
              </Stack.Item>
            </Stack>
            <Dropdown
              label={t('components.forms.createProject.domain.label')}
              ariaLabel={t('components.forms.createProject.domain.label')}
              placeholder={t('components.forms.createProject.domain.placeholder')}
              options={domainList}
              errorMessage={errors?.projectDomainId}
              onChange={onDomainSelection}
              onRenderLabel={(): React.ReactElement => (
                <RenderLabelTooltip
                  tooltipId={domainId}
                  tooltipLabel={t('components.forms.createProject.domain.label')}
                  tooltipContent={t('components.forms.createProject.domain.tooltip')}
                  required
                />
              )}
              className={classes.formField}
              required
            />
            <ActionButton
              className={classes.advancedOptionsButton}
              onClick={(): void => setShowAdvancedOptions(!showAdvancedOptions)}
            >
              {showAdvancedOptions
                ? t('components.forms.createProject.hideAdvanceOptions')
                : t('components.forms.createProject.showAdvanceOptions')}
            </ActionButton>
            <div className={clsx(!showAdvancedOptions && classes.noDisplay)}>
              <Stack tokens={textFieldStackTokens}>
                <TextField
                  onRenderLabel={(): React.ReactElement => (
                    <RenderLabelTooltip
                      tooltipId={projectLabelId}
                      tooltipLabel={t('components.forms.createProject.projectLabel.label')}
                      tooltipContent={t('components.forms.createProject.projectLabel.tooltip')}
                      urlLabel={t('components.links.learnMore')}
                      urlContent="https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/workspace-and-project#project-labels"
                    />
                  )}
                  ariaLabel={t('components.forms.createProject.projectLabel.label')}
                  name="projectLabel"
                  value={formValues.projectLabel}
                  onChange={handleChange}
                  placeholder={t('components.forms.createProject.projectLabel.placeholder')}
                  multiline
                  rows={2}
                  errorMessage={errors?.projectLabel}
                />
                <TextField
                  label={t('components.forms.createProject.projectDescription.label')}
                  name="projectDescription"
                  value={formValues.projectDescription}
                  onChange={handleChange}
                  placeholder={t('components.forms.createProject.projectDescription.placeholder')}
                  multiline
                  rows={3}
                />
                <TextField
                  label={t('components.forms.createProject.domainDescription.label')}
                  name="projectDomainDescription"
                  value={formValues.projectCategoryDescription}
                  onChange={handleChange}
                  placeholder={t('components.forms.createProject.domainDescription.placeholder')}
                  multiline
                  rows={2}
                />
              </Stack>
            </div>
            <AzureModelAnnouncement azureModelKey={azureModelKey} />
            <FormActions
              primaryButtonText={t('components.popups.project.create.button')}
              isDisabled={!isEmpty(Object.values(errors).filter((value) => value))}
              handleCancelAndClose={handleCancelAndClose}
              onCancelTelemetryMessage="project-create/cancel"
            />
          </form>
        </Stack.Item>
      </Stack>
    </Fragment>
  );
};

export default CreateProjectForm;
