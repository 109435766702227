import { makeStyles } from '@fluentui/react';

const useTextCellStyles = makeStyles(() => ({
  root: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wrapText: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

export default useTextCellStyles;
