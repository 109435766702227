import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTrackMetric } from '@microsoft/applicationinsights-react-js';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { reactPlugin } from '../components/AppInsights';
import SignedOutContent from '../components/PageContents/SignedOutContent';

const SignedOut: React.FC = () => {
  useTrackMetric(reactPlugin, 'SignedOutPage');

  const { inProgress } = useMsal();
  const isAuthed = useIsAuthenticated();
  const history = useHistory();
  const location = useLocation();

  // When user signs in, after the auth process has sucessfully completed, forward them to the Workspace page.
  // Redirection is triggered through the React Router DOM Browser History object, which manages the session.
  useEffect((): void => {
    if (isAuthed && inProgress === 'none') {
      if (location.pathname === '/') {
        history.push('/workspaces');
      }
    }
  }, [history, isAuthed, inProgress, location.pathname]);

  if (!isAuthed) {
    return <SignedOutContent />;
  }
  return null;
};

export default SignedOut;
