import { IColumn, Icon, SelectionMode, SharedColors } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import DetailsListKeyboardAccessible from '../../Tables/DetailsListKeyboardAccessible';
import ContentContainer from '../ContentContainer';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import { useAppSelector } from '../../../utils/hooks';

const UploadHistoryContent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId } = useParams<IRoutePathParams>();
  const { uploadHistoryContent } = useAppSelector((state) => state);

  const breadcrumbItems = [
    {
      text: t('pages.workspace.contentSection.pivotSection.uploadHistoryPivot.text'),
      key: 'upload-history',
      onClick: (): void => history.push(`/workspaces/${workspaceId}/upload-history`),
    },
    {
      text: uploadHistoryContent.data ? uploadHistoryContent.data.jobName : '',
      key: 'upload-history-name',
    },
  ];

  const tableColumns: IColumn[] = [
    {
      key: 'fileName',
      name: t('components.tables.uploadHistory.columns.name'),
      minWidth: 120,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      fieldName: 'fileName',
      onRender: (item): React.ReactElement => <span style={{ fontSize: '14px' }}>{item.fileName}</span>,
    },
    {
      key: 'status',
      name: t('components.tables.uploadHistory.columns.status'),
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      onRender: (item): React.ReactElement => (
        <span>
          {item.status.id === 255 ? (
            <Icon iconName="Completed" style={{ color: SharedColors.green20 }} />
          ) : (
            <Icon iconName="Error" style={{ color: SharedColors.red20 }} />
          )}{' '}
          {item.status.displayName}
        </span>
      ),
    },
    {
      key: 'language',
      name: t('components.tables.uploadHistory.columns.language'),
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      onRender: (item): React.ReactElement => <span>{item.language ? item.language.displayName : null}</span>,
    },
    {
      key: 'error',
      name: t('components.tables.uploadHistory.columns.error'),
      minWidth: 200,
      columnActionsMode: 0,
      isResizable: true,
      fieldName: 'summary',
      onRender: (item): React.ReactElement => <span>{item.summary ? item.summary : 'None'}</span>,
    },
  ];

  return (
    <ContentContainer>
      <PageHeading>{t('pages.workspace.contentSection.pivotSection.uploadHistoryPivot.text')}</PageHeading>
      {uploadHistoryContent.isLoading ? (
        <Loading
          loadingText={t('pages.uploadHistory.loading')}
          spacing={{
            type: SpacingType.padding,
            vertical: Spacing.xxLarge,
            horizontal: Spacing.large,
          }}
        />
      ) : (
        <React.Fragment>
          <PageBreadcrumb items={breadcrumbItems} />
          {uploadHistoryContent.data && (
            <DetailsListKeyboardAccessible
              selectionMode={SelectionMode.none}
              columns={tableColumns}
              items={uploadHistoryContent.data.fileProcessingStatus}
            />
          )}
        </React.Fragment>
      )}
    </ContentContainer>
  );
};

export default UploadHistoryContent;
