/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  DetailsListLayoutMode,
  IColumn,
  Overlay,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import updatePaginatedDocumentsSelection from '../../../redux/paginatedDocumentsSelection/paginatedDocumentsSelectionActions';
import ErrorFallback from '../../ErrorFallback';
import { Pagination } from '../../Pagination';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import usePaginatedDetailsListStyles from '../DocumentsTable/DocumentsTable.styles';
import { NameGroupCell, TextCell } from '../SharedTableCells';
import { IProjectDocumentsTableProps, SelectionAction } from './ProjectDocumentsTable.types';

import { useAppSelector } from '../../../utils/hooks';
import { capitalize } from '../../../utils/textTransform';

const ProjectDocumentsTable: React.FC<IProjectDocumentsTableProps> = ({
  isMultiSelect = true,
  // showInlineCommands = true,
  // There are cases where the pagination is merged with another footer, so the component will import it manually instead of being placed here
  hidePagination = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const match = useRouteMatch();
  const {
    documents,
    projects,
    languages,
    users,
    files,
    entitySelections: { selectedDocuments, previouslySelectedDocuments },
    paginatedDocumentsSelection,
  } = useAppSelector((state) => state);
  const currentRoute = useRouteMatch(`/workspaces/:workspaceId/projects/:projectId/manage-documents`);
  const classes = usePaginatedDetailsListStyles();
  const dispatch = useDispatch();

  // Pagination should display if there is more than one page of results
  const displayPagination = documents.pageIndex !== 0 && documents.totalPageCount > 1 && !hidePagination;

  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItem = selectionState.getSelection();
        dispatch({ type: 'SET_SELECTED_DOCUMENT', payload: selectedItem });
      },
    })
  );

  useEffect(() => {
    documents.ids.forEach((id) => {
      if (id in paginatedDocumentsSelection.data) {
        const itemKey = selectionState.getItems().toString().split(',').indexOf(String(id));
        selectionState.setKeySelected(String(itemKey), true, false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents.ids, paginatedDocumentsSelection.data]);

  useEffect(() => {
    if (documents.isUpdating) {
      selectionState.selectToKey('', true);
    }
  }, [selectionState, documents.isUpdating]);

  // Function that obtains the new document(s) selected/unselected
  // Returns an array with these documents and a SelectionAction to indicate which operation occurred.
  const getDeltaDocumentOnSelectionChange = (
    newSelection: string[],
    currentSelection: string[]
  ): [string[], SelectionAction] => {
    const isCurrentSelectionEmpty = isEmpty(currentSelection);
    const isNewSelectionEmpty = isEmpty(newSelection);
    if (isCurrentSelectionEmpty && isNewSelectionEmpty) {
      return [[], SelectionAction.Adding];
    }
    if (isCurrentSelectionEmpty) {
      return [newSelection, SelectionAction.Adding];
    }
    if (isNewSelectionEmpty) {
      return [currentSelection, SelectionAction.Removing];
    }
    if (!isMultiSelect) {
      return [newSelection, SelectionAction.Replacing];
    }
    if (newSelection.length >= currentSelection.length) {
      return [newSelection.filter((value) => !currentSelection.includes(value)), SelectionAction.Adding];
    }
    return [currentSelection.filter((value) => !newSelection.includes(value)), SelectionAction.Removing];
  };

  useEffect(() => {
    const [deltaDocumentIds, selectionType] = getDeltaDocumentOnSelectionChange(
      selectedDocuments,
      previouslySelectedDocuments
    );
    if (!isEmpty(deltaDocumentIds)) {
      deltaDocumentIds.forEach((id) => {
        if (documents.data[id] !== undefined) {
          const isRemoving = selectionType === SelectionAction.Removing;
          if (isRemoving || !(id in paginatedDocumentsSelection.data)) {
            const currentDocument = documents.data[id];
            dispatch(
              updatePaginatedDocumentsSelection(
                {
                  id: currentDocument.id,
                  documentType: currentDocument.documentType,
                  files: currentDocument.files,
                  characterCount: currentDocument.characterCount,
                },
                files,
                selectionType
              )
            );
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocuments]);

  const currentProject = projects.data[projects.currentProject.id];
  const sourceLanguage = languages[currentProject.languagePair.sourceLanguage];
  const targetLanguage = languages[currentProject.languagePair.targetLanguage];

  const documentsTableColumns: IColumn[] = [
    {
      key: 'documentName',
      name: t('components.tables.sharedColumns.name'),
      minWidth: 200,
      maxWidth: 300,
      onRender: (documentId: string): React.ReactElement => {
        const { name } = documents.data[documentId];
        // TODO: Implement inline grid actions
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118981
        return (
          <NameGroupCell
            link={{ href: `${match.url}/document/${documentId}`, name }} // moreOptions={showInlineCommands}
          />
        );
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'createdDate',
      name: t('components.tables.sharedColumns.createdDate'),
      onRender: (documentId: string): React.ReactElement => {
        const { createdDate } = documents.data[documentId];

        // TODO: Localize date/time https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119689
        return <TextCell text={dayjs(createdDate).format('MM/DD/YYYY')} />;
      },
      minWidth: 80,
      maxWidth: 130,
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'type',
      name: t('components.tables.sharedColumns.type'),
      minWidth: 80,
      maxWidth: 160,
      onRender: (documentId: string): React.ReactElement => {
        const { documentType } = documents.data[documentId];
        return <TextCell text={capitalize(documentType)} />;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'sourceSentences',
      name: t('components.tables.sharedColumns.sentences', { language: sourceLanguage.displayName }),
      minWidth: 100,
      maxWidth: 180,
      onRender: (documentId: string): React.ReactElement | null => {
        const documentFiles = documents.data[documentId].files;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          const fileLanguageCode = languages[file.language].languageCode;
          if (fileLanguageCode === sourceLanguage.languageCode) {
            return <TextCell text={file.extractedSentenceCount.toLocaleString(language)} />;
          }
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'targetSentences',
      name: t('components.tables.sharedColumns.sentences', { language: targetLanguage.displayName }),
      minWidth: 100,
      maxWidth: 180,
      onRender: (documentId: string): React.ReactElement | null => {
        const documentFiles = documents.data[documentId].files;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileId of documentFiles) {
          const file = files[fileId];
          const fileLanguageCode = languages[file.language].languageCode;
          if (fileLanguageCode === targetLanguage.languageCode) {
            return <TextCell text={file.extractedSentenceCount.toLocaleString(language)} />;
          }
        }
        return null;
      },
      columnActionsMode: 0,
      isResizable: true,
    },
    {
      key: 'createdBy[userName]',
      name: t('components.tables.sharedColumns.createdBy'),
      onRender: (documentId: string): React.ReactElement => {
        const { createdBy } = documents.data[documentId];

        if (!users[createdBy]) {
          return <React.Fragment>N/A</React.Fragment>;
        }

        const { userName } = users[createdBy];

        return <TextCell text={userName} />;
      },
      minWidth: 110,
      maxWidth: 250,
      isSorted: false,
      columnActionsMode: 0,
    },
  ];

  const selectionMode = isMultiSelect ? SelectionMode.multiple : SelectionMode.single;

  const documentsTable = (
    <div className="table-container" data-test-id="manage-documents-table">
      {documents.isUpdating && <Overlay className="cover-content" />}
      <DetailsListKeyboardAccessible
        enableShimmer={documents.isLoading}
        items={documents.ids}
        columns={documentsTableColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={selectionMode}
        selection={selectionState}
        ariaLabelForSelectAllCheckbox={t('components.tables.manageDocuments.tableAria')}
        ariaLabelForSelectionColumn={t('components.tables.manageDocuments.columns.columnSelectAria')}
        checkButtonAriaLabel={t('components.tables.manageDocuments.checkButtonAria')}
      />
      {isEmpty(documents.ids) && !documents.isLoading && (
        <Stack horizontalAlign="center">
          <Text>{t('components.forms.filterCommon.noResults')}</Text>
        </Stack>
      )}
    </div>
  );

  const documentsContent = displayPagination ? (
    <Stack>
      <StackItem className={classes.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{documentsTable}</ScrollablePane>
      </StackItem>
      <StackItem>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Pagination
            totalPageCount={documents.totalPageCount}
            currentPage={documents.pageIndex}
            currentPath={currentRoute ? currentRoute.url : ''}
          />
        </Sticky>
      </StackItem>
    </Stack>
  ) : (
    <div>{documentsTable}</div>
  );

  return <ErrorBoundary FallbackComponent={ErrorFallback}>{documentsContent}</ErrorBoundary>;
};

export default ProjectDocumentsTable;
