import { DefaultButton, Dropdown, IDropdownOption, PrimaryButton, Stack, TextField } from '@fluentui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { copyModel } from '../../../redux/models/modelsActions';
import useCopyModelFormStyles from './CopyModelForm.styles';
import { ICopyModelFormProps } from './CopyModelForm.types';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const CopyModelForm: React.FC<ICopyModelFormProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const { workspaces, entitySelections, models, projects } = useAppSelector((state) => state);
  const { workspaceId, projectId } = useParams<IRoutePathParams>();
  const classes = useCopyModelFormStyles();
  const appDispatch = useAppDispatch();
  const [selectedWorkspace, setSelectedWorkspace] = useState<IDropdownOption>();
  const [projectTargets, setProjectTargets] = useState([]);
  const [selectedProject, setSelectedProject] = useState<IDropdownOption>();
  const selectedModel = models.data[entitySelections.selectedModel];
  const [modelName, setModelName] = useState<string>(selectedModel.name);
  const submittable = selectedWorkspace && selectedProject && modelName;
  const { languagePair } = projects.data[projectId];
  const [loading, setLoading] = useState(false);
  const projectErrorMessage =
    !loading && projectTargets.length === 0 && selectedWorkspace
      ? 'This workspace does not have any projects that match the language pair of the selected model.'
      : undefined;

  // Prepare workspaces for dropdown and filter out current workspace
  // since we wouldn't want a user to copy to current workspace.
  const workspaceOptions: IDropdownOption[] = workspaces.ids
    .filter((id) => {
      const isCurrentWorkspace = id === workspaceId;
      const isOwnerOfWorkspace = workspaces.data[id].role === 1;

      if (isCurrentWorkspace) {
        return false;
      }

      if (isOwnerOfWorkspace) {
        return true;
      }

      return false;
    })
    .map((id) => {
      const { name } = workspaces.data[id];

      return {
        key: id,
        text: name,
      };
    });

  const projectOptions: IDropdownOption[] = projectTargets.map((project) => {
    const { id, name } = project;

    return {
      key: id,
      text: name,
    };
  });

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (modelName && selectedProject && selectedProject.key && selectedModel.id && selectedWorkspace) {
      appDispatch(
        copyModel(modelName, selectedModel.id, selectedProject.key, selectedProject.text, selectedWorkspace.text)
      );
      handleCancelAndClose();
    }
  };

  const onWorkspaceChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void | undefined => {
    setSelectedWorkspace(option);
    setProjectTargets([]); // reset projects on workspace selection change
  };

  const onProjectChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void | undefined => {
    setSelectedProject(option);
  };

  // fetch projects for that workspace they have selected
  // do not fetch when on existing workspace.
  useEffect(() => {
    const workspaceIdParam = selectedWorkspace && selectedWorkspace.key ? selectedWorkspace.key : workspaceId;

    if (workspaceId !== workspaceIdParam) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}projects`, {
          params: { workspaceid: workspaceIdParam },
        })
        .then((response) => {
          // filter projects to matching language pair.
          const responseProjectTargets = response.data.projects.filter((project: { languagePair: { id: number } }) => {
            if (project.languagePair.id === languagePair.id) {
              return true;
            }

            return false;
          });

          setProjectTargets(responseProjectTargets);
          setLoading(false);
        });
    }
  }, [selectedWorkspace, workspaceId, languagePair]);

  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <p>
        Copy this model to a project with the same language pair in another workspace where you have Owner role. The
        copied model is not connected to the original model.{' '}
        <a href="https://learn.microsoft.com/azure/cognitive-services/translator/custom-translator/how-to/copy-model">
          Learn more about copied models
        </a>
        .
      </p>

      <Stack tokens={{ childrenGap: 'm' }}>
        <div>
          <Dropdown
            label="Workspace"
            options={workspaceOptions}
            required
            onChange={onWorkspaceChange}
            selectedKey={selectedWorkspace ? selectedWorkspace.key : undefined}
          />
          <NavLink to="/workspaces">Create a new workspace</NavLink>
        </div>

        <div>
          <Dropdown
            label="Project"
            options={projectOptions}
            required
            disabled={projectTargets.length === 0}
            onChange={onProjectChange}
            selectedKey={selectedProject ? selectedProject.key : undefined}
            errorMessage={projectErrorMessage}
          />
          <NavLink
            to={`/workspaces/${selectedWorkspace?.key}/projects`}
            style={{ display: !selectedWorkspace ? 'none' : '' }}
          >
            Create a new project
          </NavLink>
        </div>

        <TextField
          label="Model name"
          required
          onChange={(event, newValue): void => setModelName(newValue || '')}
          value={modelName}
        />

        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 'm' }} style={{ marginTop: '2rem' }}>
          <PrimaryButton
            type="submit"
            text={models.isCopying ? 'Copying ...' : 'Copy model'}
            disabled={!submittable || models.isCopying}
          />
          <DefaultButton
            onClick={handleCancelAndClose}
            text={t('components.popups.common.cancel')}
            disabled={models.isCopying}
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default CopyModelForm;
