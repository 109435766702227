import { FontWeights, makeStyles } from '@fluentui/react';

const useNotificationPanelStyles = makeStyles((theme) => ({
  notificationContainer: {
    marginTop: 10,
  },
  dismissAll: {
    color: theme.palette.themePrimary,
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.themeDarker,
    },
  },
  notificationItemContainer: {
    width: '355px',
  },
  emptyImage: {
    margin: '80px auto 0',
  },
  emptyText: {
    fontWeight: FontWeights.semibold,
    margin: 'auto',
  },
}));

export default useNotificationPanelStyles;
