import { IBillingItem } from '../redux/workspaces/workspaces.types';

const calculateTrainingCost = (billing: IBillingItem, characterCount: number): number => {
  // character count needs to come from the documents
  const oneMillion = 1000000;
  let maxPrice;

  if (characterCount > billing.maximumCharacterCount) {
    maxPrice = billing.pricePerMillionCharacters * (billing.maximumCharacterCount / oneMillion);
  } else {
    maxPrice = billing.pricePerMillionCharacters * (characterCount / oneMillion);
  }

  return maxPrice;
};

export default calculateTrainingCost;
