import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import i18next from 'i18next';
import React, { Fragment, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { SharedComponentsContext } from 'sharedui.studios/dist/package';

import Routes from '../../pages/Routes';
import getBillingRegions from '../../redux/billingRegions/billingRegionsActions';
import getCategories from '../../redux/categories/categoriesActions';
import { setCurrentUser } from '../../redux/currentUser/currentUserActions';
import getModelRegions from '../../redux/modelRegions/modelRegionsActions';
import { connectToHub } from '../../redux/notifications/notificationsActions';
import getSupportedLanguagePairs from '../../redux/supportedLanguagePairs/supportedLanguagePairsActions';
import getSupportedLanguages from '../../redux/supportedLanguages/supportedLanguagesActions';
import { reactPlugin } from '../AppInsights';
import AppSidebarContext from '../AppSidebar/AppSidebar.context';
import { GridPanelTypes, gridPanelContext } from '../GridPanel/GridPanel.context';
import InitialLoadContext from '../InitialLoad/InitialLoad.context';
import LoadingOverlay from '../LoadingOverlay';

import { msalInstance } from '../../utils/authentication';
import { useAppDispatch, useAppSelector } from '../../utils/hooks/appRedux.helpers';

const App: React.FC = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const appDispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state);

  const [hasAttemptedHubConnection, setHasAttemptedHubConnection] = useState<boolean>(false);
  const [isAppSidebarCollapsed, setIsAppSidebarCollapsed] = useState<boolean>(false);
  const [isInitialLoad, setInitialLoad] = useState<boolean>(true);
  const [isGridPanelOpen, setIsGridPanelOpen] = useState<boolean>(false);
  const [gridPanelType, setGridPanelType] = useState<GridPanelTypes>();

  useEffect(() => {
    if (isAuthenticated) {
      // Sends current user's email and name to the store
      const currentAccount = msalInstance.getAllAccounts()[0];
      const username = currentAccount.name ? currentAccount.name : '';
      const email = currentAccount.username;
      appDispatch(setCurrentUser(username, email, currentAccount.localAccountId));
    }
  }, [appDispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      batch(() => {
        appDispatch(getModelRegions());
        appDispatch(getCategories());
        appDispatch(getSupportedLanguagePairs());
        appDispatch(getBillingRegions());
        appDispatch(getSupportedLanguages());
      });
    }
  }, [appDispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !notifications.isConnectedToHub && !hasAttemptedHubConnection) {
      setHasAttemptedHubConnection(true);
      appDispatch(connectToHub());
    }
  }, [appDispatch, notifications, hasAttemptedHubConnection, isAuthenticated]);

  let content;

  if (inProgress !== 'none') {
    content = <LoadingOverlay loadingText="Loading Custom Translator" hasLoadingText />;
  } else {
    content = (
      <Fragment>
        <Routes />
      </Fragment>
    );
  }

  return (
    <SharedComponentsContext.Provider value={{ locale: i18next.language }}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <InitialLoadContext.Provider value={{ isInitialLoad, setInitialLoad }}>
          <AppSidebarContext.Provider value={{ isAppSidebarCollapsed, setIsAppSidebarCollapsed }}>
            <gridPanelContext.Provider value={{ isGridPanelOpen, setIsGridPanelOpen, gridPanelType, setGridPanelType }}>
              {content}
            </gridPanelContext.Provider>
          </AppSidebarContext.Provider>
        </InitialLoadContext.Provider>
      </AppInsightsContext.Provider>
    </SharedComponentsContext.Provider>
  );
};

export default App;
