/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Announced,
  DetailsListLayoutMode,
  IColumn,
  Overlay,
  Selection,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

import { IModelRegionStatusItem } from '../../../redux/models/models.types';
import BleuScore from '../../BleuScore';
import ErrorFallback from '../../ErrorFallback';
import DetailsListKeyboardAccessible from '../DetailsListKeyboardAccessible';
import { NameGroupCell, StatusCell, TextCell } from '../SharedTableCells';

import { ModelStatus } from '../../../utils/constants/modelStatus';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

interface IModelsTableProps {
  /** Filtered IDs allow for custom IDs that meet a certain criteria.
   * Example pages like publish and test can only display specific content and
   * not display all models from the store like on the models page */
  filteredIds?: string[];
  showTrainingTypeColumn?: boolean;
  isNameLink?: boolean;
  showCategory?: boolean;
}

const ModelsTable: React.FC<IModelsTableProps> = ({
  filteredIds,
  showTrainingTypeColumn = true,
  isNameLink = true,
  showCategory = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const { models, projects, modelRegions } = useAppSelector((state) => state);
  const { projectId } = useParams<IRoutePathParams>();
  const { apiDomain } = projects.data[projectId];

  const [selectionState] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItem = selectionState.getSelection()[0] || '';

        dispatch({ type: 'SET_SELECTED_MODEL', payload: selectedItem });
      },
    })
  );

  useEffect(() => {
    if (!isEmpty(filteredIds)) {
      dispatch({ type: 'FILTER_MODELS', payload: filteredIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (models.isUpdating) {
      selectionState.selectToKey('', true);
    }
  }, [selectionState, models.isUpdating]);

  const hideSentenceCounts = (modelId: string): boolean => {
    const inProgressStatus: string[] = [
      ModelStatus.Draft,
      ModelStatus.TrainingQueued,
      ModelStatus.TrainingRunning,
      ModelStatus.TrainingSubmitted,
      ModelStatus.Unknown,
      ModelStatus.DataProcessing,
      ModelStatus.TrainingFailed,
    ];
    const { modelStatus } = models.data[modelId];
    return inProgressStatus.includes(modelStatus);
  };

  const modelsColumns: IColumn[] = [
    {
      key: 'modelName',
      name: t('components.tables.sharedColumns.name'),
      fieldName: 'name',
      onRender: (modelId: string): React.ReactElement => {
        const { name, isCopied } = models.data[modelId];

        // TODO: Implement inline grid actions
        // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/118981
        return (
          <NameGroupCell
            link={{ href: `${match.url}/${modelId}`, name, iconName: 'Product' }}
            showLink={isNameLink}
            isCopied={isCopied}
          />
        );
      },
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
      isSorted: false,
      columnActionsMode: 0,
    },
    {
      key: 'modelStatus',
      name: t('components.tables.models.columns.statusColumn'),
      onRender: (modelId: string): React.ReactElement | null => {
        const { modelStatus, modelRegionStatuses } = models.data[modelId];
        /*
         Because the modelRegionStatuses objects do not include 'regionName' for each region we have to make an updated object with that property
         from the 'modelRegions' object slice in the store.
         In order for the API return data to return 'regionName' we would have to add a new foreign key relationship to the database, 
         which would be a more impactful change than creating a scoped array of objects here.
        */
        const publishStatusRegion: IModelRegionStatusItem[] = [];

        if (!isEmpty(modelRegionStatuses)) {
          modelRegionStatuses?.forEach((modelRegion) => {
            const regionName = { regionName: modelRegions.data[modelRegion.region]?.regionName };
            if (regionName) {
              const updatedRegionStatus = Object.assign(regionName, modelRegion);
              publishStatusRegion.push(updatedRegionStatus);
            }
          });
        }

        return <StatusCell publishStatusRegion={publishStatusRegion} status={modelStatus} />;
      },
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'modelModified',
      name: t('components.tables.models.columns.modifiedColumn'),
      onRender: (modelId: string): React.ReactElement => {
        const { modifiedDate } = models.data[modelId];

        // TODO: Localize date/time https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119689
        return <TextCell text={dayjs(modifiedDate).format('MM/DD/YYYY')} />;
      },
      minWidth: 80,
      maxWidth: 110,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'completionOn',
      name: t('components.tables.models.columns.completionOn'),
      onRender: (modelId: string): React.ReactElement => {
        const { completionDate, modelStatus } = models.data[modelId];

        const completionStatuses: string[] = [
          ModelStatus.TrainingSucceeded,
          ModelStatus.Published,
          ModelStatus.Unpublished,
        ];

        if (completionStatuses.includes(modelStatus)) {
          return <TextCell text={dayjs(completionDate).format('MM/DD/YYYY')} />;
        }

        return <React.Fragment />;
      },
      minWidth: 80,
      maxWidth: 110,
      isResizable: true,
      columnActionsMode: 0,
    },
  ];
  if (showTrainingTypeColumn) {
    modelsColumns.push({
      key: 'modelTrainingType',
      name: t('components.tables.models.columns.trainingTypeColumn'),
      onRender: (modelId: string): React.ReactElement | null => {
        const {
          trainingSentenceCount,
          tuningSentenceCount,
          testingSentenceCount,
          phraseDictionarySentenceCount,
          sentenceDictionarySentenceCount,
        } = models.data[modelId];

        const dictionaryCount = phraseDictionarySentenceCount + sentenceDictionarySentenceCount;
        const trainingCount = trainingSentenceCount + tuningSentenceCount + testingSentenceCount;

        if (trainingCount > 0) {
          return <TextCell text={t('components.tables.models.values.full')} />;
        }
        if (trainingCount === 0 && dictionaryCount > 0) {
          return <TextCell text={t('components.tables.models.values.dictionary')} />;
        }

        return null;
      },
      minWidth: 90,
      maxWidth: 110,
      isResizable: true,
      columnActionsMode: 0,
    });
  }
  modelsColumns.push(
    {
      key: 'modelBleuScore',
      name: t('components.tables.models.columns.bleuScoreColumn'),
      onRender: (modelId: string): React.ReactElement | null => {
        const { bleuScoreCIPunctuated, baselineBleuScoreCIPunctuated } = models.data[modelId];

        if (bleuScoreCIPunctuated && baselineBleuScoreCIPunctuated) {
          return <BleuScore bleuScore={bleuScoreCIPunctuated} baselineBleuScore={baselineBleuScoreCIPunctuated} />;
        }

        return null;
      },
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      columnActionsMode: 0,
    },
    {
      key: 'modelBleuBaselineScore',
      name: t('components.tables.models.columns.baselineScoreColumn'),
      onRender: (modelId: string): React.ReactElement | null => {
        const { baselineBleuScoreCIPunctuated } = models.data[modelId];

        return baselineBleuScoreCIPunctuated ? (
          <TextCell text={baselineBleuScoreCIPunctuated.toLocaleString(language)} />
        ) : null;
      },
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      isSorted: false,
      columnActionsMode: 0,
    }
  );
  if (showCategory) {
    modelsColumns.push({
      key: 'modelCategory',
      name: t('components.tables.models.columns.category'),
      onRender: (): React.ReactElement | null => {
        return <TextCell text={apiDomain} />;
      },
      minWidth: 300,
      isResizable: true,
      isSorted: false,
      columnActionsMode: 0,
    });
  } else {
    modelsColumns.push(
      {
        key: 'modelTraining',
        name: t('components.tables.models.columns.trainingColumn'),
        onRender: (modelId: string): React.ReactElement | null => {
          const { trainingSentenceCount } = models.data[modelId];

          return hideSentenceCounts(modelId) ? null : (
            <TextCell text={trainingSentenceCount.toLocaleString(language)} />
          );
        },
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        columnActionsMode: 0,
      },
      {
        key: 'modelTuning',
        name: t('components.tables.models.columns.tuningColumn'),
        onRender: (modelId: string): React.ReactElement | null => {
          const { tuningSentenceCount } = models.data[modelId];

          return hideSentenceCounts(modelId) ? null : <TextCell text={tuningSentenceCount.toLocaleString(language)} />;
        },
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        isSorted: false,
        columnActionsMode: 0,
      },
      {
        key: 'modelTest',
        name: t('components.tables.models.columns.testColumn'),
        onRender: (modelId: string): React.ReactElement | null => {
          const { testingSentenceCount } = models.data[modelId];

          return hideSentenceCounts(modelId) ? null : <TextCell text={testingSentenceCount.toLocaleString(language)} />;
        },
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        isSorted: false,
        columnActionsMode: 0,
      },
      {
        key: 'modelDictionary',
        name: t('components.tables.models.columns.dictionaryColumn'),
        onRender: (modelId: string): React.ReactElement | null => {
          const { sentenceDictionarySentenceCount, phraseDictionarySentenceCount } = models.data[modelId];
          const dictionaryCount = (sentenceDictionarySentenceCount + phraseDictionarySentenceCount).toLocaleString(
            language
          );

          return hideSentenceCounts(modelId) ? null : <TextCell text={dictionaryCount} />;
        },
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        columnActionsMode: 0,
      }
    );
  }

  const modelIds = filteredIds || models.ids;
  const itemsCount = modelIds.length;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="table-container" data-test-id="model-details-table">
        {models.isUpdating && <Overlay className="cover-content" />}
        {itemsCount > 0 && (
          <Announced
            id="models-details-list-accounced"
            message={`${itemsCount} ${
              itemsCount === 1
                ? t('components.tables.models.announcement.name.singular')
                : t('components.tables.models.announcement.name.plural')
            }`}
          />
        )}
        <DetailsListKeyboardAccessible
          items={modelIds}
          columns={modelsColumns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.single}
          selection={selectionState}
          ariaLabel={t('components.tables.workspaces.tableAria')}
          ariaLabelForSelectionColumn={t('components.tables.workspaces.columns.columnSelectAria')}
          checkButtonAriaLabel={t('components.tables.workspaces.checkButtonAria')}
        />
        {isEmpty(modelIds) && (
          <Stack horizontalAlign="center">
            <Text>{t('components.forms.filterCommon.noResults')}</Text>
          </Stack>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default ModelsTable;
