import { makeStyles } from '@fluentui/react';

const useSignedOutContentStyles = makeStyles((theme) => ({
  feature: {
    marginTop: '40px',
    maxWidth: '280px',
    display: 'flex',
  },
  featureContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: '0 70px',
  },
  footer: {
    margin: '25px 0 15px',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.blue,
    minHeight: '70px',
    color: theme.palette.white,
  },
  link: {
    color: theme.palette.white,
    margin: '25px 0 15px',
  },
}));

export default useSignedOutContentStyles;
