/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import i18next from 'i18next';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import {
  DOWNLOAD_MODEL_TEST_RESULTS,
  DOWNLOAD_MODEL_TEST_RESULTS_FAILED,
  DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL,
  GET_MODEL_TEST_RESULTS,
  GET_MODEL_TEST_RESULTS_SUCCESSFUL,
  ModelTestResultsDispatchTypes,
} from './modelTestResults.types';

import ErrorCategories from '../../utils/constants/errorCategories';
import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';

export const getModelTestResults = (modelId: string) => async (
  dispatch: Dispatch<ModelTestResultsDispatchTypes | ErrorDispatchTypes>
): Promise<Action> => {
  try {
    dispatch({ type: GET_MODEL_TEST_RESULTS });

    const modelTests = await axios.get(`${process.env.REACT_APP_API_URL}models/${modelId}/tests`);
    const { id: testId } = modelTests.data.tests[0];
    const testResults = await axios.get(`${process.env.REACT_APP_API_URL}tests/${testId}/results`);

    return dispatch({
      type: GET_MODEL_TEST_RESULTS_SUCCESSFUL,
      payload: testResults.data,
    });
  } catch (error: any) {
    return dispatch({
      type: SET_ERROR,
      payload: {
        message: error.message,
        statusCode: error.response?.status,
        category: ErrorCategories.TestModelResults,
      },
    });
  }
};

export const downloadTestResults = (modelId: string) => async (
  dispatch: Dispatch<ModelTestResultsDispatchTypes | PopUpErrorsDispatchTypes>
): Promise<Action | void> => {
  try {
    dispatch({ type: DOWNLOAD_MODEL_TEST_RESULTS });
    const modelTests = await axios.get(`${process.env.REACT_APP_API_URL}models/${modelId}/tests`);
    const { id: testId } = modelTests.data.tests[0];
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}tests/${testId}/export`,
      {},
      {
        responseType: 'blob',
      }
    );

    const fileContentBlob = new Blob([response.data]);
    const dummyLinkToFileContentBlob = Object.assign(document.createElement('a'), {
      href: URL.createObjectURL(fileContentBlob),
      target: '_blank',
      download: `testResults_${modelId}.zip`,
    });
    document.body.appendChild(dummyLinkToFileContentBlob);
    dummyLinkToFileContentBlob.click();

    return dispatch({ type: DOWNLOAD_MODEL_TEST_RESULTS_SUCCESSFUL });
  } catch (error: any) {
    const responseStatusCode: number = error.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = error.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.testModel.download.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.ModelTestResults,
      requestType: RequestTypes.Post,
    });

    return batch(() => {
      dispatch({ type: DOWNLOAD_MODEL_TEST_RESULTS_FAILED });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};
