import { Action, Dispatch } from 'redux';

import { PanelsDispatchTypes } from './panels.types';

import { AppBarPanels } from '../../utils/constants/appBarPanels';

const setActivePanel = (panel: AppBarPanels) => (dispatch: Dispatch<PanelsDispatchTypes>): Action | void => {
  dispatch({ type: 'SET_ACTIVE_PANEL', payload: panel });
};

const setIsPanelOpen = (isOpen: boolean) => (dispatch: Dispatch<PanelsDispatchTypes>): Action | void => {
  dispatch({ type: 'SET_IS_PANEL_OPEN', payload: isOpen });
};

const setActiveOpenPanel = (panel: AppBarPanels) => (dispatch: Dispatch<PanelsDispatchTypes>): Action | void => {
  dispatch({ type: 'SET_ACTIVE_OPEN_PANEL', payload: panel });
};

export { setActivePanel, setIsPanelOpen, setActiveOpenPanel };
