import {
  CLEAR_CREATE_WORKSPACE_WIZARD,
  CREATE_WORKSPACE_FAILED_KEY_VALIDATION,
  CREATE_WORKSPACE_FAILED_OTHER,
  CREATE_WORKSPACE_SUCCESSFUL,
  CreateWorkspaceWizardDispatchTypes,
  CreateWorkspaceWizardSlide,
  SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY,
  SET_CREATE_WORKSPACE_WIZARD_NAME,
  SET_CREATE_WORKSPACE_WIZARD_SLIDE,
} from './createWorkspaceWizard.types';

interface ICreateWorkspaceWizardState {
  name: string;
  slide: CreateWorkspaceWizardSlide;
  isValidatingKey: boolean;
  keyValidationError: string;
}

const initialState: ICreateWorkspaceWizardState = {
  name: '',
  slide: CreateWorkspaceWizardSlide.AddName,
  isValidatingKey: false,
  keyValidationError: '',
};

const createWorkspaceWizardReducer = (
  state: ICreateWorkspaceWizardState = initialState,
  action: CreateWorkspaceWizardDispatchTypes
): ICreateWorkspaceWizardState => {
  switch (action.type) {
    case SET_CREATE_WORKSPACE_WIZARD_IS_VALIDATING_KEY: {
      return {
        ...state,
        isValidatingKey: action.payload,
      };
    }
    case SET_CREATE_WORKSPACE_WIZARD_SLIDE: {
      return {
        ...state,
        slide: action.payload,
      };
    }
    case SET_CREATE_WORKSPACE_WIZARD_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case CLEAR_CREATE_WORKSPACE_WIZARD: {
      return {
        ...initialState,
      };
    }
    case CREATE_WORKSPACE_FAILED_KEY_VALIDATION: {
      return {
        ...state,
        isValidatingKey: false,
        keyValidationError: action.payload,
      };
    }
    case CREATE_WORKSPACE_FAILED_OTHER: {
      return {
        ...initialState,
      };
    }
    case CREATE_WORKSPACE_SUCCESSFUL: {
      return {
        ...state,
        isValidatingKey: false,
        keyValidationError: initialState.keyValidationError,
        slide: CreateWorkspaceWizardSlide.Success,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default createWorkspaceWizardReducer;
