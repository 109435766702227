import React from 'react';
import { useTranslation } from 'react-i18next';

import { UploadTypes } from '../../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import SingleDocumentForm from '../SingleDocumentForm';
import { IUploadDocumentsSetProps } from '../UploadDocumentsSet.types';

const TranslationMemoryForm: React.FC<IUploadDocumentsSetProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();

  // TODO: Replace with a new article that describes how to create/ use TM files.
  // https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/122732
  const tmLearnMoreLink =
    'https://docs.microsoft.com/en-us/azure/cognitive-services/translator/custom-translator/document-formats-naming-convention';

  const subtitle = {
    sizeAndFormatRestriction: t(
      'components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.form.textLine1'
    ),
    detailInformation: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.form.textLine2'),
    linkText: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.form.linkText'),
    linkUrl: tmLearnMoreLink,
  };

  const fileInputButton = {
    label: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.translationMemory.form.label'),
    text: t('components.forms.uploadDocumentsWizard.uploadDocumentsSet.common.fileInputButtonText'),
    id: 'tm-file',
    acceptedFormats: '.tmx, .xlf, .xliff, .lcl, .xlsx',
  };

  return (
    <SingleDocumentForm
      handleCancelAndClose={handleCancelAndClose}
      subtitle={subtitle}
      fileInputButton={fileInputButton}
      formId={UploadTypes.TranslationMemoryFile}
    />
  );
};

export default TranslationMemoryForm;
