export const GET_BILLING_REGIONS = 'GET_BILLING_REGIONS';
export const GET_BILLING_REGIONS_SUCCESSFUL = 'GET_BILLING_REGIONS_SUCCESSFUL';
export const SET_BILLING_REGIONS = 'SET_BILLING_REGIONS';

export interface IBillingRegionItem {
  billingRegionCode: string; // This is a unique value
  billingRegionName: string;
}

export interface IBillingRegionEntities {
  billingRegions: {
    [key: string]: IBillingRegionItem;
  };
}
export interface ISetBillingRegions {
  type: typeof SET_BILLING_REGIONS;
  payload: {
    [key: string]: IBillingRegionItem;
  };
}

export interface IBillingRegionsPayload {
  [key: string]: IBillingRegionItem;
}

export interface IGetBillingRegions {
  type: typeof GET_BILLING_REGIONS;
}

export interface IGetBillingRegionsSuccessful {
  type: typeof GET_BILLING_REGIONS_SUCCESSFUL;
  payload: IBillingRegionsPayload;
}

export type BillingRegionsDispatchTypes = ISetBillingRegions | IGetBillingRegions | IGetBillingRegionsSuccessful;
