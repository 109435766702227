import axios from 'axios';
import { Action } from 'history';
import { Dispatch } from 'react';
import { batch } from 'react-redux';

import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import {
  GET_UPLOAD_HISTORY_CONTENT,
  GET_UPLOAD_HISTORY_CONTENT_FAILED,
  GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL,
  GetUploadHistoryContentResponse,
  UploadHistoryContentDispatchTypes,
} from './uploadHistoryContent.types';

import ErrorCategories from '../../utils/constants/errorCategories';

const getUploadHistoryContent = ({ workspaceId, jobId }: { workspaceId: string; jobId: string }) => async (
  dispatch: Dispatch<UploadHistoryContentDispatchTypes | ErrorDispatchTypes>
): Promise<void | Action | null> => {
  dispatch({
    type: GET_UPLOAD_HISTORY_CONTENT,
  });

  try {
    const response = await axios.get<GetUploadHistoryContentResponse>(
      `${process.env.REACT_APP_API_URL}documents/import/jobs/${jobId}`,
      {
        params: { workspaceId, jobId },
      }
    );

    return batch(() => {
      dispatch({
        type: GET_UPLOAD_HISTORY_CONTENT_SUCCESSFUL,
        payload: response.data,
      });
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_UPLOAD_HISTORY_CONTENT_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.UploadHistory,
        },
      });
    });
  }
};

export default getUploadHistoryContent;
