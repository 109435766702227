import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { deleteDocument, downloadDocuments } from '../../../redux/documents/documentsActions';
import { setInitialUploadDocumentWizard } from '../../../redux/uploadDocumentsWizard/uploadDocumentsWizardActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import { UploadDocumentsWizard } from '../../Forms';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import { DocumentPopUpTypes } from '../../../utils/constants/popUps.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useIsReader,
  useRenderUploadDocumentWizardTitle,
} from '../../../utils/hooks';

const DocumentsCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    entitySelections: { selectedDocuments },
    documents,
    uploadDocumentsWizard,
  } = useAppSelector((state) => state);

  const { appModal, setAppModal } = useAppModal();
  const { appDialog, setAppDialog } = useAppDialog();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);

  const isReader = useIsReader();

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'documentCreateNew',
      text: t('components.commandBars.documents.createNewButton.text'),
      ariaLabel: t('components.commandBars.documents.createNewButton.aria'),
      iconProps: { iconName: 'Add' },
      className: clsx(isReader && 'no-display'),
      onClick: (): void =>
        batch(() => {
          setAppModal({ contentType: DocumentPopUpTypes.Upload, isOpen: true });
          appDispatch(setInitialUploadDocumentWizard());
        }),
    },
    {
      key: 'documentDownload',
      text: t('components.commandBars.common.download'),
      ariaLabel: t('components.commandBars.documents.downloadButton.aria'),
      iconProps: { iconName: 'Download' },
      disabled: documents.isLoading || isEmpty(selectedDocuments),
      onClick: (): void => {
        setAppDialog({ contentType: DocumentPopUpTypes.Download, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-download/open' });
      },
    },
    {
      key: 'documentDelete',
      text: t('components.commandBars.common.delete'),
      ariaLabel: t('components.commandBars.documents.deleteButton.aria'),
      iconProps: { iconName: 'Delete' },
      className: clsx(isReader && 'no-display'),
      disabled: documents.isLoading || isEmpty(selectedDocuments) || selectedDocuments.length > 1,
      onClick: (): void => {
        setAppDialog({ contentType: DocumentPopUpTypes.Delete, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'document-delete/open' });
      },
    },
  ];

  let message = t('components.commandBars.common.updating');
  if (documents.isDownloading) {
    message = t('components.commandBars.common.downloading');
  }
  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => (
        <CommandBarLoader isVisible={documents.isUpdating || documents.isDownloading} message={message} />
      ),
    },
    {
      key: 'documentFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.documents.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(documents.data),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.DocumentsFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
    /** TODO: Workspace details panel
    https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119688/?workitem=123056
    {
      key: 'workspaceInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    }, */
  ];

  const appDialogContentMap: AppDialogContentMap = {
    [DocumentPopUpTypes.Delete]: {
      contentProps: {
        title: t('components.popups.document.delete.title'),
        subText: t('components.popups.document.delete.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.delete'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(deleteDocument(selectedDocuments[0]));
          });
        },
      },
    },
    [DocumentPopUpTypes.Download]: {
      contentProps: {
        title: t('components.popups.document.download.title'),
        subText: t('components.popups.document.download.subText'),
      },
      confirmationActionProps: {
        text: t('components.popups.common.download'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            appDispatch(downloadDocuments(selectedDocuments, documents.data[selectedDocuments[0]].name));
          });
        },
      },
    },
  };
  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const wizardTitle = useRenderUploadDocumentWizardTitle(
    uploadDocumentsWizard.documentSetType,
    uploadDocumentsWizard.dictionaryType,
    true
  );
  const appModalContentMap: AppModalContentMap = {
    [DocumentPopUpTypes.Upload]: {
      contentProps: {
        title: wizardTitle,
        body: (
          <UploadDocumentsWizard
            handleCancelAndClose={(): void => setAppModal({ isOpen: false })}
            showLanguageSelection
          />
        ),
      },
    },
  };
  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={700}
      />
      <AppDialog
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
    </Fragment>
  );
};

export default DocumentsCommandBar;
