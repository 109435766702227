import { makeStyles } from '@fluentui/react';

const useDuplicateModelFormStyles = makeStyles((theme) => ({
  optionsTitle: {
    marginTop: theme.spacing.m,
  },
  subText: {
    marginBottom: theme.spacing.m,
  },
}));

export default useDuplicateModelFormStyles;
