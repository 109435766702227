import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateModelPublishStatus } from '../../../redux/models/modelsActions';
import { AppDialog, AppModal } from '../../AppPopUps';
import SwapModelForm from '../../Forms/SwapModelForm/SwapModelForm';
import UpdateModelPublishStatusForm from '../../Forms/UpdatePublishForm';
import { IUpdatePublishFormValues } from '../../Forms/UpdatePublishForm/UpdatePublishForm.types';
import { GridPanelTypes, gridPanelContext } from '../../GridPanel/GridPanel.context';
import { CommandBarLoader } from '../SharedCommandBarElements';

import canModelPublishBeUpdated from '../../../utils/canModelPublishBeUpdated';
import { ModelStatus } from '../../../utils/constants/modelStatus';
import { ModelsPopUpTypes } from '../../../utils/constants/popUps.types';
import PublishOperation from '../../../utils/constants/publishOperation';
import IRoutePathParams from '../../../utils/constants/routePathParams.types';
import {
  AppDialogContentMap,
  AppModalContentMap,
  useAppDialog,
  useAppDispatch,
  useAppModal,
  useAppSelector,
  useIsReader,
} from '../../../utils/hooks';
import useAreOtherModelsUpdating from '../../../utils/hooks/useAreOtherModelsUpdating';
import useCanModelBePublished from '../../../utils/hooks/useCanModelBePublished';
import { useCreateUnpublishSubtext } from '../../../utils/hooks/usePublishTextHelper';
import isModelPublished from '../../../utils/isModelPublished';

const PublishModelCommandBar: React.FC = () => {
  const { t } = useTranslation();
  const { appDialog, setAppDialog } = useAppDialog();
  const { appModal, setAppModal } = useAppModal();
  const { projectId } = useParams<IRoutePathParams>();
  const { isGridPanelOpen, setIsGridPanelOpen, setGridPanelType } = useContext(gridPanelContext);
  const {
    models,
    entitySelections: { selectedModel },
  } = useAppSelector((state) => state);
  const appDispatch = useAppDispatch();

  const areOtherModelsUpdating = useAreOtherModelsUpdating();
  const currentRegions = models.data[selectedModel]?.modelRegionStatuses;
  const modelName = models.data[selectedModel]?.name;
  const unpublishSubtext = useCreateUnpublishSubtext(
    currentRegions,
    t('components.popups.model.unpublish.subText1'),
    t('components.popups.model.unpublish.subText2')
  );
  const canPublish = useCanModelBePublished(selectedModel, false);
  const isPublished = isModelPublished(models.data[selectedModel]);
  const isSwappable = useCanModelBePublished(selectedModel, true);
  const canUpdate = canModelPublishBeUpdated(models.data[selectedModel]) && !areOtherModelsUpdating;

  const isReader = useIsReader();

  const leftCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'publish',
      text: t('components.commandBars.model.publishButton.text'),
      ariaLabel: t('components.commandBars.model.publishButton.aria'),
      iconProps: { iconName: 'Deploy' },
      disabled: !canPublish && !isSwappable,
      className: clsx(isReader && 'no-display'),
      onClick: (): void => {
        if (isSwappable) {
          setAppModal({ contentType: ModelsPopUpTypes.Swap, isOpen: true });
        } else {
          setAppModal({ contentType: ModelsPopUpTypes.Publish, isOpen: true });
        }
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-update-publish-status/open' });
      },
    },
    {
      key: 'unpublish',
      text: t('components.commandBars.model.unpublishButton.text'),
      ariaLabel: t('components.commandBars.model.unpublishButton.aria'),
      iconProps: { iconName: 'Clear' },
      disabled: !isPublished || areOtherModelsUpdating,
      className: clsx(isReader && 'no-display'),
      onClick: (): void => {
        setAppDialog({ contentType: ModelsPopUpTypes.Unpublish, isHidden: false });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-update-publish-status/open' });
      },
    },
    {
      key: 'update',
      text: t('components.commandBars.model.updateButton.text'),
      ariaLabel: t('components.commandBars.model.updateButton.aria'),
      iconProps: { iconName: 'Deploy' },
      disabled: !canUpdate,
      className: clsx(isReader && 'no-display'),
      onClick: (): void => {
        setAppModal({ contentType: ModelsPopUpTypes.UpdatePublishment, isOpen: true });
        appDispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-update-publish-status/open' });
      },
    },
  ];

  const rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'updating',
      commandBarButtonAs: (): JSX.Element => <CommandBarLoader isVisible={models.isUpdating} />,
    },
    {
      key: 'modelFilter',
      text: t('components.commandBars.common.filter'),
      ariaLabel: t('components.commandBars.models.filterButton.aria'),
      iconProps: { iconName: 'Filter' },
      className: isGridPanelOpen ? 'active' : '',
      disabled: isEmpty(models.testedOrPublishedModelsIds),
      onClick: (): void => {
        setGridPanelType(GridPanelTypes.ModelsFilter);
        setIsGridPanelOpen(!isGridPanelOpen);
      },
    },
  ];

  /** TODO: Model details panel 
  https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/119002 
  rightCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'moreInformation',
      text: t('components.commandBars.moreInformation.text'),
      aria: t('components.commandBars.moreInformation.aria'),
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      disabled: true,
    },
  ]; */

  const appDialogContentMap: AppDialogContentMap = {
    [ModelsPopUpTypes.Unpublish]: {
      contentProps: {
        title: t('components.popups.model.unpublish.title'),
        subText: unpublishSubtext,
      },
      confirmationActionProps: {
        text: t('components.popups.model.unpublish.action'),
        onClick: (): void => {
          batch(() => {
            setAppDialog({ ...appDialog, isHidden: true });
            if (currentRegions != null) {
              const regions = currentRegions.map((item) => ({
                region: item.region,
                isDeployed: false,
                isChanged: item.isDeployed,
              }));
              const formValues: IUpdatePublishFormValues = {
                projectId,
                modelId: Number(selectedModel),
                modelName,
                modelRegionStatuses: regions,
                nextModelStatus: ModelStatus.Unpublishing,
              };
              appDispatch(updateModelPublishStatus(formValues, PublishOperation.Unpublish));
            }
          });
        },
      },
    },
  };

  const appDialogContent = appDialog.contentType ? appDialogContentMap[appDialog.contentType] : undefined;

  const appModalContentMap: AppModalContentMap = {
    [ModelsPopUpTypes.Publish]: {
      contentProps: {
        title: t('components.popups.model.publish.title'),
        body: (
          <UpdateModelPublishStatusForm
            type={PublishOperation.Publish}
            handleCancelAndClose={(): void => setAppModal({ isOpen: false })}
          />
        ),
      },
      maxWidth: 560,
    },
    [ModelsPopUpTypes.UpdatePublishment]: {
      contentProps: {
        title: t('components.popups.model.updatePublishRegions.title'),
        body: (
          <UpdateModelPublishStatusForm
            type={PublishOperation.Update}
            handleCancelAndClose={(): void => setAppModal({ isOpen: false })}
          />
        ),
      },
      maxWidth: 560,
    },
    [ModelsPopUpTypes.Swap]: {
      contentProps: {
        title: t('components.popups.model.publish.title'),
        body: <SwapModelForm handleCancelAndClose={(): void => setAppModal({ isOpen: false })} />,
      },
      maxWidth: 560,
    },
  };

  const appModalContent = appModal.contentType ? appModalContentMap[appModal.contentType] : undefined;

  return (
    <Fragment>
      <CommandBar
        items={leftCommandBarItems}
        ariaLabel={t('components.commandBars.aria')}
        farItems={rightCommandBarItems}
      />
      <AppDialog
        showCancelButton={appDialog.showCancelButton}
        hidden={appDialog.isHidden}
        contentProps={appDialogContent?.contentProps}
        toggleDialog={(): void => setAppDialog({ ...appDialog, isHidden: true })}
        confirmationActionProps={appDialogContent?.confirmationActionProps}
      />
      <AppModal
        open={appModal.isOpen}
        toggleModal={(): void => setAppModal({ isOpen: false })}
        contentProps={appModalContent?.contentProps}
        isFullWidth
        maxWidth={appModalContent?.maxWidth || 700}
      />
    </Fragment>
  );
};

export default PublishModelCommandBar;
