import { FontWeights, makeStyles } from '@fluentui/react';

const useHeroStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '550px',
    backgroundSize: 'cover',
    objectFit: 'cover',
  },
  content: {
    position: 'absolute',
    maxWidth: '500px',
    paddingBottom: '15px',
    left: 70,
    paddingTop: 70,
  },
  title: {
    fontSize: '60px',
    fontWeight: FontWeights.semilight,
    paddingBottom: '15px',
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: FontWeights.semilight,
  },
  button: {
    height: '52px',
    fontSize: '18px',
    marginRight: '15px',
    '&.signIn': {
      background: theme.palette.black,
    },
  },
}));

export default useHeroStyles;
