import { TextField } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { renameWorkspace } from '../../../redux/workspaces/workspacesActions';
import FormActions from '../Shared/FormActions';
import useRenameWorkspaceFormStyles from './RenameWorkspaceForm.styles';
import { IRenameWorkspaceFormProps, IRenameWorkspaceFormValues } from './RenameWorkspaceForm.types';

import FormValidationLengths from '../../../utils/constants/formValidationLengths';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/appRedux.helpers';

const RenameWorkspaceForm: React.FC<IRenameWorkspaceFormProps> = ({ handleCancelAndClose }) => {
  const { t } = useTranslation();
  const { entitySelections, workspaces } = useAppSelector((state) => state);
  const currentWorkspaceId = entitySelections.selectedWorkspace || workspaces.currentWorkspace.id;
  const selectedWorkspaceName = workspaces.data[currentWorkspaceId].name || workspaces.currentWorkspace.name;
  const dispatch = useAppDispatch();
  const classes = useRenameWorkspaceFormStyles();

  const [formValues, setFormValues] = useState(
    (): IRenameWorkspaceFormValues => {
      const initialState = {
        workspaceName: selectedWorkspaceName || '',
      };
      return initialState;
    }
  );

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({
    workspaceName: undefined,
  });

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
      const inputName = (event.target as HTMLInputElement).name;
      if (!newName || newName?.length <= FormValidationLengths.MAX_WORKSPACE_NAME_LENGTH) {
        setFormValues({ ...formValues, [inputName]: newName || '' });
      }
      if (!newName) {
        setErrors({ ...errors, [inputName]: t('components.forms.generalErrors.required') });
      } else {
        setErrors({ ...errors, [inputName]: undefined });
      }
    },
    [formValues, errors, t]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (formValues.workspaceName.length === 0) {
      setErrors({ ...errors, workspaceName: t('components.forms.generalErrors.required') });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'workspace-rename/inline-errors' });
    } else {
      handleCancelAndClose();
      dispatch(renameWorkspace(currentWorkspaceId, formValues.workspaceName));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label={t('components.forms.renameWorkspace.workspaceName')}
        name="workspaceName"
        onChange={handleChange}
        errorMessage={errors?.workspaceName}
        value={formValues.workspaceName}
        className={classes.textField}
        required
      />
      <FormActions
        primaryButtonText={t('components.popups.common.rename')}
        isDisabled={!isEmpty(Object.values(errors).filter((value) => value))}
        handleCancelAndClose={handleCancelAndClose}
        onCancelTelemetryMessage="workspace-rename/cancel"
      />
    </form>
  );
};

export default RenameWorkspaceForm;
