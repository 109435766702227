import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { RootStore } from '../../../redux/Store';
import DocumentCommandBar from '../../CommandBars/DocumentCommandBar';
import { Empty, Error, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { DocumentTable } from '../../Tables';
import ContentContainer from '../ContentContainer';

import IRoutePathParams from '../../../utils/constants/routePathParams.types';
// TODO: Use spacing in useTheme hook instead of spacing file
// https://machinetranslation.visualstudio.com/MachineTranslation/_workitems/edit/120542
import { Spacing, SpacingType } from '../../../utils/constants/spacing';

const DocumentContent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId, documentId, projectId, modelId } = useParams<IRoutePathParams>();
  const { documents, documentContent, workspaces, models } = useSelector((state: RootStore) => state);
  const documentName: string = documents.data[documentId]?.name || '';

  // Default to documents pivot for breadcrumb information
  const documentsPivotUrl = `/workspaces/${workspaceId}/documents`;
  let rootBreadcrumbItems = [
    {
      text: t('pages.workspace.contentSection.pivotSection.documentsPivot.text'),
      key: 'documents',
      onClick: (): void => history.push(documentsPivotUrl),
    },
  ];

  // Toggle breadcrumb content depending on source of click
  const isSourceManageDocuments = useRouteMatch('/workspaces/:workspaceId/projects/:projectId/manage-documents');
  const isSourceTrainModel = useRouteMatch('/workspaces/:workspaceId/projects/:projectId/train-model');
  const isSourceModelDetails = useRouteMatch('/workspaces/:workspaceId/projects/:projectId/models/:modelId');
  if (isSourceManageDocuments) {
    rootBreadcrumbItems = [
      {
        text: t('components.appSidebar.manageDocuments'),
        key: 'manage-documents',
        onClick: (): void => history.push(isSourceManageDocuments.url),
      },
    ];
  } else if (isSourceTrainModel) {
    rootBreadcrumbItems = [
      {
        text: t('components.appSidebar.trainModel'),
        key: 'train-model',
        onClick: (): void => history.push(isSourceTrainModel.url),
      },
    ];
  } else if (isSourceModelDetails) {
    const modelName: string = models.data[modelId]?.name || '';
    rootBreadcrumbItems = [
      {
        text: t('components.appSidebar.modelDetails'),
        key: 'model-details',
        onClick: (): void => history.push(`/workspaces/${workspaceId}/projects/${projectId}/models`),
      },
      {
        text: modelName,
        key: 'model-name',
        onClick: (): void => history.push(isSourceModelDetails.url),
      },
    ];
  }

  const breadcrumbItems = [
    ...rootBreadcrumbItems,
    {
      text: documentName,
      key: 'document-name',
    },
  ];

  let documentsContent = null;

  /**
   * The DocumentContent table depends on having all relevant files loaded.
   * The loading spinner should only appear on initial page loads - for all subsequent loads that occur
   * due to pagination changes the ShimmeredDetailsList will shimmer to show progress.
   */

  const isMissingData =
    isEmpty(documentContent) || documentContent[documentId] === undefined || isEmpty(workspaces.currentWorkspace);
  const isInitialLoad =
    isMissingData ||
    (documentContent[documentId].isLoading &&
      workspaces.isLoading &&
      documentContent[documentId].totalPageCount.max === 1);
  if (isInitialLoad) {
    documentsContent = (
      <Loading
        loadingText={t('pages.document.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!isMissingData && !documentContent[documentId].isLoading && isEmpty(documentContent[documentId].data)) {
    documentsContent = <Empty title={t('pages.document.empty')} />;
  } else if (!isMissingData && documentContent[documentId].error) {
    documentsContent = <Error errorText={t('pages.document.error')} />;
  } else if (!isMissingData) {
    documentsContent = (
      <Fragment>
        <DocumentCommandBar />
        <DocumentTable />
      </Fragment>
    );
  }

  return (
    <ContentContainer>
      <PageHeading>{t('pages.workspace.contentSection.pivotSection.documentsPivot.text')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} />
      {documentsContent}
    </ContentContainer>
  );
};

export default DocumentContent;
