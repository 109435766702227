import { merge } from 'lodash';

import {
  GET_SUPPORTED_LANGUAGE_PAIRS,
  GET_SUPPORTED_LANGUAGE_PAIRS_FAILED,
  GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL,
  ISupportedLanguagePairItem,
  SET_SUPPORTED_LANGUAGE_PAIRS,
  SupportedLanguagePairsDispatchTypes,
} from './supportedLanguagePairs.types';

interface ISuportedLanguagePairsState {
  isLoading: boolean;
  data: {
    [key: string]: ISupportedLanguagePairItem;
  };
}

const initialState: ISuportedLanguagePairsState = {
  isLoading: false,
  data: {},
};

const supportedLanguagePairsReducer = (
  state: ISuportedLanguagePairsState = initialState,
  action: SupportedLanguagePairsDispatchTypes
): ISuportedLanguagePairsState => {
  switch (action.type) {
    case GET_SUPPORTED_LANGUAGE_PAIRS: {
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
      };
    }
    case GET_SUPPORTED_LANGUAGE_PAIRS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }
    case SET_SUPPORTED_LANGUAGE_PAIRS: {
      return merge(state, action.payload);
    }
    case GET_SUPPORTED_LANGUAGE_PAIRS_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default supportedLanguagePairsReducer;
