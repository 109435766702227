import { Icon, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useTrainingProgressStyles from '../../TrainingProgress/TrainingProgress.styles';
import {
  IDeploymentNotificationRegionalProgressProps,
  IRegionalStateProps,
} from './DeploymentNotificationRegionalProgress.types';

import { DeploymentNotificationStatus } from '../../../utils/constants/notifications';
import PublishOperation from '../../../utils/constants/publishOperation';
import { useAppSelector } from '../../../utils/hooks';

const RegionalState: React.FC<IRegionalStateProps> = ({ status, parentOperation, regionOperation, region }) => {
  const { t } = useTranslation();
  const classes = useTrainingProgressStyles();
  const { modelRegions } = useAppSelector((state) => state);

  const { regionName } = modelRegions.data[region];
  let regionText = regionName;

  if (parentOperation === PublishOperation.Update && regionOperation === PublishOperation.Publish) {
    regionText = t('components.notificationPanel.deployment.publishRegion', { regionName });
  }
  if (parentOperation === PublishOperation.Update && regionOperation === PublishOperation.Unpublish) {
    regionText = t('components.notificationPanel.deployment.unpublishRegion', { regionName });
  }

  let progressContent;

  switch (status) {
    case DeploymentNotificationStatus.New:
      progressContent = <Text className={clsx(`${classes.reducedFontSize} ${classes.notStarted}`)}>{regionText}</Text>;
      break;
    case DeploymentNotificationStatus.InProgress:
      progressContent = (
        <Fragment>
          <Spinner size={SpinnerSize.xSmall} className={clsx(classes.spinner)} />
          <Text className={classes.reducedFontSize}>{regionText}</Text>
        </Fragment>
      );
      break;
    case DeploymentNotificationStatus.Succeeded:
      progressContent = (
        <Fragment>
          <Icon iconName="Completed" className={clsx(`${classes.icon} ${classes.reducedFontSize}`)} />
          <Text className={classes.reducedFontSize}>{regionText}</Text>
        </Fragment>
      );
      break;
    case DeploymentNotificationStatus.Failed:
      progressContent = (
        <Fragment>
          <Icon iconName="ErrorBadge" className={clsx(`${classes.errorIcon} ${classes.reducedFontSize}`)} />
          <Text className={classes.reducedFontSize}>{regionText}</Text>
        </Fragment>
      );
      break;
    case DeploymentNotificationStatus.Cancelled:
      progressContent = (
        <Fragment>
          <Icon iconName="Blocked2" className={clsx(`${classes.cancelledIcon} ${classes.reducedFontSize}`)} />
          <Text className={classes.reducedFontSize}>{regionText}</Text>
        </Fragment>
      );
      break;
    default:
      break;
  }
  return <div className={classes.root}>{progressContent}</div>;
};

const DeploymentNotificationRegionalProgress: React.FC<IDeploymentNotificationRegionalProgressProps> = ({
  notification,
}) => {
  const classes = useTrainingProgressStyles();

  const regionStates = notification.regions.map((region) => {
    return (
      <Stack.Item key={region.region}>
        <RegionalState
          status={region.status}
          regionOperation={region.operation}
          parentOperation={notification.operation}
          region={region.region}
        />
      </Stack.Item>
    );
  });

  const statusContent = <Stack className={classes.noBottomMargin}>{regionStates}</Stack>;
  return <div>{statusContent}</div>;
};

export default DeploymentNotificationRegionalProgress;
