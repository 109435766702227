import { IBreadcrumbItem } from '@fluentui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PublishModelCommandBar } from '../../CommandBars';
import FilterBar from '../../FilterBar';
import { Empty, Loading } from '../../GenericStates';
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeading from '../../PageHeading';
import { ModelsTable } from '../../Tables';
import ContentContainer from '../ContentContainer';

import EmptyTypes from '../../../utils/constants/emptyTypes';
import { Spacing, SpacingType } from '../../../utils/constants/spacing';
import filterTestedOrPublishedModels from '../../../utils/filterTestedOrPublishedModels';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const PublishModelContent: React.FC = () => {
  const { t } = useTranslation();
  const [publishModelIds, setPublishModelIds] = useState<string[]>([]);
  const { models, modelRegions, projects } = useAppSelector((state) => state);
  const appDispatch = useAppDispatch();
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t('pages.publishModel.pageName'),
      key: 'publishModel',
    },
  ];

  // Models are dependent on GET /regions to complete to access regionNames,
  const isLoading = models.isLoading || modelRegions.isLoading || projects.isLoading;
  const hasModels = !isEmpty(models.testedOrPublishedModelsIds);

  let modelsContent = null;

  useEffect(() => {
    // Filter model IDs by training and deployment status.
    // Once IDs are filtered set those IDs with local state and pass that into the ModelsTable
    const filteredModelIds = filterTestedOrPublishedModels(models, false, models.ids);
    setPublishModelIds(filteredModelIds);

    // Set unfiltered total of models that can be displayed on the publish page
    if (!isEmpty(models.data)) {
      appDispatch({
        type: 'SET_TESTED_OR_PUBLISHED_MODEL_IDS',
        payload: filterTestedOrPublishedModels(models, false, Object.keys(models?.data)),
      });
    } else {
      appDispatch({
        type: 'SET_TESTED_OR_PUBLISHED_MODEL_IDS',
        payload: [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models.ids]);

  if (isLoading) {
    modelsContent = (
      <Loading
        loadingText={t('pages.models.contentSection.loading')}
        spacing={{
          type: SpacingType.padding,
          vertical: Spacing.xxLarge,
          horizontal: Spacing.large,
        }}
      />
    );
  } else if (!isLoading && hasModels) {
    modelsContent = <ModelsTable filteredIds={publishModelIds} isNameLink={false} showCategory />;
  } else if (!isLoading && !hasModels) {
    modelsContent = <Empty type={EmptyTypes.PublishModel} />;
  }

  return (
    <ContentContainer>
      <PageHeading>{t('pages.publishModel.pageName')}</PageHeading>
      <PageBreadcrumb items={breadcrumbItems} subtitle={hasModels ? t('pages.publishModel.subtitle') : ''} />
      {hasModels && <PublishModelCommandBar />}
      <FilterBar kind="models" />
      {modelsContent}
    </ContentContainer>
  );
};

export default PublishModelContent;
